export const isFeatureEnabled = (selectedDeviceDetail: Record<'menu', any>, featureKey: string) => {
    // selectedDeviceDetail이 null이거나 menu가 없는 경우, 기능을 비활성화
    if (!selectedDeviceDetail || !selectedDeviceDetail.menu) {
        return false;
    }
    // 해당 기능의 활성화 여부 찾기
    const feature = selectedDeviceDetail.menu.find((item: any) => item.hasOwnProperty(featureKey));
    if (feature) {
        // 값이 배열인 경우, 배열 길이가 0보다 크면 활성화
        if (Array.isArray(feature[featureKey])) {
            return feature[featureKey].length > 0;
        }
        // 그렇지 않으면 'Y'인 경우에만 활성화
        return feature[featureKey] === 'Y';
    }
    return false;
}