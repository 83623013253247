import { i18n } from 'i18next';

import { getSelectedLanguage, getCountryCode2, setSelectedLangTitle, setCountryCode2, getSelectedLangTitle, setSelectedLanguage } from '../auth';
import countryInfo from '../../assets/locale/regions';
import locales from '../../assets/locale/res';
import phoneExtensionInfo from '../../assets/phone/phoneExtentionInfo.json';
import { LanguageToRepresentativeCountry2KeyType, languageToRepresentativeCountry2 } from '../enums/NationalCodes';

export const getBrowserLanguageAndCountry = () => {
    return navigator.language.split('-');
}

export const importLanguageFromStorage = (i18n: i18n) => {
    const storedLang = getSelectedLanguage() === 'null' ? null : getSelectedLanguage();
    const selectedLangTitle = getSelectedLangTitle();
    const countryCode2 = getCountryCode2();
    if (storedLang && selectedLangTitle && countryCode2) {
        i18n.changeLanguage(storedLang);
        console.log('Stored Language is : ', storedLang);
    } else {
        // 만약 처음으로 Find에 접속했다면 browser language setting을 받아온다.
        const [lang, country] = getBrowserLanguageAndCountry();
        changeLanguageInStorage({ i18n, lang, country })
    }
}

type ChangeLanguageType = {
    i18n: i18n,
    lang: string,
    country?: string,
    willSave?: boolean
};



export const changeLanguageInStorage = ({ i18n, lang, country }: ChangeLanguageType) => {
    let isLangSet = false;
    if (lang && country) { // 국가코드와 언어코드 둘 다 있을 때
        const concatedLanguage = `${lang}_r${country}`;
        const localeList = Object.keys(locales);
        const countryFound = countryInfo.find(info =>
            info.countryCode2 === country.toUpperCase() && info.code === lang
        );
        if (localeList.includes(concatedLanguage) && countryFound) {
            i18n.changeLanguage(concatedLanguage);
            setSelectedLanguage(concatedLanguage);
            setSelectedLangTitle(countryFound.title);
            setCountryCode2(countryFound.countryCode2);
            isLangSet = true;
        } else {
            const countryFound = countryInfo.find(info => info.countryCode2 === country.toUpperCase());
            if (countryFound) {
                i18n.changeLanguage(countryFound.code);
                setSelectedLanguage(countryFound.code);
                setSelectedLangTitle(countryFound.title);
                setCountryCode2(countryFound.countryCode2);
                isLangSet = true;
            }     
        }
    } else if (lang && !country) {
        const countryCode2 = languageToRepresentativeCountry2[lang as LanguageToRepresentativeCountry2KeyType];
        if (countryCode2) {
            const countryFound = countryInfo.find(info => info.countryCode2 === countryCode2.toUpperCase())!;
            i18n.changeLanguage(countryFound.code);
            setSelectedLanguage(countryFound.code);
            setSelectedLangTitle(countryFound.title);
            setCountryCode2(countryFound.countryCode2);
            isLangSet = true;
        }
    }
    if (!isLangSet) {
        i18n.changeLanguage('en'); // 만약 브라우저의 language가 번역으로 지원되지 않는다면 디폴트로 en으로 둔다
        setSelectedLanguage('en');
        setSelectedLangTitle("USA / English");
        setCountryCode2("US");
    }
}


export const getPhoneExtensionFormattedCountryByCode2 = (code: string) => {
    const countryInfo = findPhoneExtensionInfoByCode2(code);
    return `${countryInfo?.title} (+${countryInfo?.extension})`
}

export const findPhoneExtensionInfoByCode2 = (code: string) => {
    return phoneExtensionInfo.find((e) => e.code_2 === code)
}

export const getCurrentPhoneExtensionFormattedCountry = () => getPhoneExtensionFormattedCountryByCode2(getCountryCode2() as string);