import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getParentId } from '../../api/find/userApi/userApi';
import { AuthStateType } from "./authSliceTypes";


const initialState: AuthStateType = {
    isAuthenticated: false,
    isLocationAgreed: false,
    isGuardianAgreed: false,
    parentId: {
        isLoading: false,
        data: null,
        error: null
    }
}

export const asyncGetParentId = createAsyncThunk<any, {deviceId: string}>(
    'auth/asyncGetParentId',
    async () => {
        const response = await getParentId();
        return response;
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthenticated: (state, action) => {
            if (action.payload) {
                state.isAuthenticated = action.payload;
            }
        },
        setOptinLocationAgree: (state, action) => {
            state.isLocationAgreed = action.payload;
        },
        setIsGuardianAgreed: (state, action) => {
            state.isGuardianAgreed = action.payload;
        }
    },
    extraReducers: (builder) => {
        // asyncGetCardList
        builder.addCase(asyncGetParentId.pending, (state, action) => {
            state.parentId.isLoading = true;
        })
        builder.addCase(asyncGetParentId.fulfilled, (state, action) => {
            state.parentId.isLoading = false;
            state.parentId.data = action.payload;
        })
        builder.addCase(asyncGetParentId.rejected, (state, action) => {
            state.parentId.isLoading = false;
            state.parentId.error = action.payload;
        })
    },
});

export const { setAuthenticated, setOptinLocationAgree, setIsGuardianAgreed } = authSlice.actions;

export default authSlice.reducer;