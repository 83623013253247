import React from "react";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserLocation, setLocationPermissionDenied } from "../../../slices/mapSlice";
import { getUserLocation, isValidPosition } from "../../../utills/functions";
import { UserPositionType } from '../../maps/CustomZoomControl/CustomZoomControlTypes';

const UserLocationIconButton = () => {
    const dispatch = useDispatch();
    const locationPermissionDenied = useSelector((state:any) => state.map.locationPermissionDenied);

    /**
     * Action when the user clicks the current location button
     */
    const handleUpdateUserLocation = () => {
        /**
         * Callback function triggered when the user's location is successfully retrieved.
         * This can be used to perform additional operations once the location is found,
         * such as centering the map on the user's location, displaying a marker, etc.
         * @param {*} userPosition
         */
        const onUserLocationSuccess = (userPosition: UserPositionType) => {
            if (!isValidPosition(userPosition)) {
                return;
            }

            dispatch(setCurrentUserLocation(userPosition)); // save as global state
        };

        const onUserLocationDenied = () => {
            dispatch(setLocationPermissionDenied(true));
        }

        // load user's location with callback
        getUserLocation(onUserLocationSuccess, onUserLocationDenied);
    };

    return (
        <button onClick={handleUpdateUserLocation} disabled={locationPermissionDenied}>
            <MyLocationIcon fontSize="inherit" />
        </button>
    );

};

export default UserLocationIconButton;