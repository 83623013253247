import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery, Button } from "@mui/material";
import { MOBILE_PORTRAIT_VIEW_QUERY, TABLET_VIEW_QUERY } from '../utills/constants';
import welcome_logo from "../assets/images/common/app icon.svg";
import { getSamsungAccountSignInURI, isLoggedIn } from "../utills/auth";
import { getSelectedLangTitle } from "../utills/auth";


const LoginBefore = () => {
    const { t } = useTranslation();
    const isMobileView = useMediaQuery(MOBILE_PORTRAIT_VIEW_QUERY);
    const isTabletView = useMediaQuery(TABLET_VIEW_QUERY);
    const naviagte = useNavigate();
    const [lang, setLang] = useState('');

    const handleClickSignIn = () => {
        if (isLoggedIn()) {
            naviagte('/');
        } else {
            // Go to Samsung Account login page (SSO)
            window.location.href = getSamsungAccountSignInURI();

            // set FirstTime Load DeviceList flag
            sessionStorage.setItem('firstLoadMyDeviceList', true);
            sessionStorage.setItem('firstLoadMyItemList', true);
            sessionStorage.setItem('firstLoadFamilyDeviceList', true);
        }
    }

    const selectedLang = getSelectedLangTitle();
    useEffect(() => {
        if (selectedLang === null) {
            setLang('USA / English');
        } else {
            setLang(selectedLang);
        }
    }, [selectedLang]);

    return (
        <>
            <div className={isMobileView ? "login-before-container-mobile" : (isTabletView ? "login-before-container-tablet" : "login-before-container")} >
                <div className="login-before-logo">
                    <img src={welcome_logo} />
                    <p> {t('samsung_find')} </p>
                </div>

                <div className="login-before-descript">
                    <p> {t('lost_something')} </p>
                </div>

                <div className="login-before-button">
                    <Button variant="contained" onClick={handleClickSignIn}>
                        <p> {t('sign_in')} </p>
                    </Button>
                </div>

                {/* <div className="login-before-learn">
                    <a target="_blank" rel="noreferrer noopener" href="https://www.samsung.com/sec/apps/samsung-find">
                        {t('learn_about')}
                    </a>
                </div> */}
            </div >

            <div className={isMobileView ? "login-before-footer-mobile" : (isTabletView ? "login-before-footer-tablet" : "login-before-footer")}>
                <p>
                    <span> &copy;2024 Samsung</span>|
                    <a target="_blank" rel="noreferrer noopener" href="https://account.samsung.com/membership/policy/privacy">
                        {t('privacy_policy')}
                    </a>

                    {isMobileView ? <br /> : '|'}

                    <a target="_blank" rel="noreferrer noopener" href="https://account.samsung.com/membership/policy/terms">
                        {t('terms_and_conditions')}
                    </a>|
                    <Link className="footer-language" to="/languages">
                        {lang}
                    </Link>
                </p>
            </div>
        </>
    );
}

export default LoginBefore;
