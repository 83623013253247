import { createSlice } from "@reduxjs/toolkit";

import { MAP_TYPES } from "../utills/enums/commons";


const initialState = {
    currentMapType: MAP_TYPES.GOOGLE,
    currentUserLocation: null,
    markers: [],
    markersItem: [],
    selectedMarkerIndex: 0,
    mapCenter: null,
    heightOfBottomSheet: 381,
    setMoreMenu: false,
    locationPermissionDenied: false, // 현재 브라우저 해당 사이트에서 사용자 위치정보 거부 여부
}

const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setCurrentMapType: (state, action) => {
            state.currentMapType = action.payload;
        },
        setCurrentUserLocation: (state, action) => {
            state.currentUserLocation = action.payload;
        },
        setMarkers: (state, action) => {
            if (action.payload) {
                state.markers = action.payload;
            }
        },
        setMarkersItem: (state, action) => {
            if (action.payload) {
                state.markersItem = action.payload;
            }
        },
        setMapCenter: (state, action) => {
            if (action.payload) {
                state.mapCenter = action.payload;
            }
        },
        setSelectedMarkerIndex: (state, action) => {
            state.selectedMarkerIndex = action.payload;
        },
        setHeightOfBottomSheet: (state, action) => {
            state.heightOfBottomSheet = action.payload;
        },
        setBottomSheetMoreMenu: (state, action) => {
            state.bottomSheetMoreMenu = action.payload;
        },
        setLocationPermissionDenied: (state, action) => {
            state.locationPermissionDenied = action.payload; // set true or false 
        }
    }
});

export const {
    setCurrentMapType,
    setCurrentUserLocation,
    setMarkers,
    setMarkersItem,
    setMapCenter,
    setSelectedMarkerIndex,
    setHeightOfBottomSheet,
    setBottomSheetMoreMenu,
    setLocationPermissionDenied
} = mapSlice.actions;

export default mapSlice.reducer;