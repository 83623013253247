import React from 'react';
import { TwoVerticalTextFieldsProps } from './TwoVerticalTextFieldsTypes';
import './TwoVerticalTextFieldsStyles.scss';
import LabelTextField from '../LabelTextField';


const TwoVerticalTextFields = ({
    firstTextFieldBundle: {
        title: firstTitle,
        textFieldProps: firstTextFieldProps
    },
    secondTextFieldBundle: {
        title: secondTitle,
        textFieldProps: SecondTextFieldProps
    }
}: TwoVerticalTextFieldsProps) => {
  return (
    <div className="two-vertical-text-fields-container">
        <div className="first-text-field-container">
            <LabelTextField
                title={firstTitle}
                textFieldProps={firstTextFieldProps}
            />
        </div>
        <div className="second-text-field-container">
            <LabelTextField
                title={secondTitle}
                textFieldProps={SecondTextFieldProps}
            />
        </div>
    </div>
  )
}
;
export default TwoVerticalTextFields;