// EncryptedLocalStorage.ts
import CryptoJS from 'crypto-js';


class EncryptedLocalStorage {

    private secretKey: string;

    constructor(secretKey?: string) {
        this.secretKey = secretKey || process.env.REACT_APP_STORAGE_SECRET_KEY || 'defaultKey';
    }

    private encrypt(value: string): string {
        const encrypted = CryptoJS.AES.encrypt(value, this.secretKey).toString();
        return encrypted;
    }

    private decrypt(encryptedValue: string): string {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        return decrypted;
    }

    public setItem(key: string, value: any): void {
        // 값을 문자열로 변환
        const stringValue = JSON.stringify(value);
        const encryptedValue = this.encrypt(stringValue);
        localStorage.setItem(key, encryptedValue);
    }

    public getItem(key: string): any {
        const encryptedValue = localStorage.getItem(key);
        if (encryptedValue) {
            const decrypted = this.decrypt(encryptedValue);
            try {
                // 저장된 값을 원래 타입으로 변환
                return JSON.parse(decrypted);
            } catch (e) {
                // JSON.parse 실패 시 원래 문자열 반환
                return decrypted;
            }
        }
        return null;
    }

    public removeItem(key: string): void {
        console.log(`Removing item - Key: ${key}`);
        localStorage.removeItem(key);
        console.log(`Item removed - Key: ${key}`);
    }

    public clear(): void {
        console.log(`Clearing all items`);
        localStorage.clear();
    }

}


export default EncryptedLocalStorage;