import packageJson from '../../../package.json';

export const showAppVersion = () => {
    console.info(`App version: ${packageJson.version}`)
}

export const disableConsoleLogOnProduction = () => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
        var console = window.console || {};
        console.log = function no_console() {};
    }
}
