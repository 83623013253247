import React, { useEffect, useState } from 'react';
import useCachedImage from '../../hooks/useCachedImage';
import { findImageLinkByModelCode, getDefaultDeviceImageByDeviceType, getDeviceByDeviceId, getIsDeviceOnline } from '../../utills/functions';
import { BUDS_UNITS, DEVICE_TYPES } from '../../utills/enums/Devices';
import { buds_left_text, buds_right_text } from '../../assets/images/deviceCard';


const DeviceClusterMarker = ({ devicesAndUnits, deviceListDataOfCurrentTab, deviceImageListData, selectedDevice, operationsResult, budsSelectedUnitList }) => {
    const LTR_X = "67%", LTR_Y = "46%";
    const RTL_X = "85%", RTL_Y = "46%";

    const [countX, setCountX] = useState(LTR_X);
    const [countY, setCountY] = useState(LTR_Y);

    const dir = document.dir;
    useEffect(() => {
        if (dir === 'ltr') {
            setCountX(LTR_X);
            setCountY(LTR_Y);
        } else {
            setCountX(RTL_X);
            setCountY(RTL_Y);
        }
    }, [dir]);



    const countOfDevice = Array.isArray(devicesAndUnits) ? devicesAndUnits.length : 0;
    if (countOfDevice < 2) {
        return;
    }

    const selectedUnitForBuds = budsSelectedUnitList?.find(item => item.deviceId === selectedDevice?.deviceId)?.selectedUnit;
    const reorderedDeviceIdsAndUnits = reorderDeviceIdsAndUnits(devicesAndUnits, selectedDevice?.deviceId, selectedUnitForBuds);

    if (countOfDevice === 2) {

        const svgOriginWidth = 98; // SVG 컨테이너의 원본 너비
        const svgOriginHeight = 68; // // SVG 컨테이너의 원본 높이
        const deviceImageWidth = 32; // 40; // 각 디바이스 이미지의 너비
        const padding = 14;  // 10 + 4;
        const spacing = (svgOriginWidth - (padding * 2) - (deviceImageWidth * 2)); // 이미지 사이의 간격

        return (
            <svg
                style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -100%)', // 이미지의 중앙 하단이 위치와 정확히 일치하도록 조정
                }}
                height="61.53px"
                viewBox="0 0 98 68"
                version="1.1" xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <radialGradient cx="0%" cy="100%" fx="0%" fy="100%" r="91.7063586%" gradientTransform="translate(0, 1), scale(0.9615, 1), rotate(143.2592), translate(-0, -1)" id="radialGradient-1">
                        <stop stopColor="#000000" stopOpacity="0.6" offset="0%"></stop>
                        <stop stopColor="#000000" stopOpacity="0" offset="100%"></stop>
                    </radialGradient>
                    <filter x="-11.5%" y="-12.0%" width="123.1%" height="124.0%" filterUnits="objectBoundingBox" id="filter-2">
                        <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
                    </filter>
                </defs>
                <g id="2.-Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="2.0_plugin_Pin" transform="translate(-284, -559)">
                        <g id="Multi-device-2" transform="translate(284, 561)">
                            <polygon id="Rectangle-Copy-64" fill="url(#radialGradient-1)" filter="url(#filter-2)" points="50 38 76 38 76 58.3661325 50 63"></polygon>
                            <path d="M49,-1 C56.4558438,-1 63.2058438,2.02207804 68.0918829,6.90811711 C72.9779219,11.7941561 76,18.5441561 76,26 C76,33.3095119 73.0953626,39.9405977 68.3774119,44.8020446 L51.8284291,61.3553377 C51.0473805,62.1363863 50.0236913,62.5269106 49.000002,62.5269106 C47.9763127,62.5269106 46.9526235,62.1363863 46.1715749,61.3553377 L29.9029231,45.086686 C24.8967784,39.9324518 22,33.3036211 22,26 C22,18.544156 25.022078,11.794156 29.908117,6.908117 C34.794156,2.022078 41.544156,-1 49,-1 Z" id="Combined-Shape" stroke="#FFFFFF" strokeWidth="2" fill="#387AFF" fillRule="nonzero"></path>
                            <rect id="Rectangle" fill="#387AFF" x="0" y="0" width="98" height="50" rx="25"></rect>
                            <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="2" fill="#FFFFFF" x="4.21292308" y="4" width="89.3846154" height="42" rx="21"></rect>
                            {/* 아이템이 두 개인 경우 모든 이미지 표시 */}
                            {countOfDevice == 2 && reorderedDeviceIdsAndUnits.slice(0, 2).map((item, index) => {
                                const device = getDeviceByDeviceId(item.deviceId, deviceListDataOfCurrentTab);
                                const isOnline = getIsDeviceOnline(device, operationsResult, item.units);


                                let isSelected;
                                if (selectedUnitForBuds && selectedUnitForBuds !== BUDS_UNITS.BOTH) {
                                    isSelected = selectedDevice?.deviceId === item.deviceId && selectedUnitForBuds === item.units
                                } else {
                                    isSelected = selectedDevice?.deviceId === item.deviceId
                                }
                                return (
                                    <DeviceImage
                                        deviceId={item.deviceId}
                                        index={index}
                                        deviceImageWidth={deviceImageWidth}
                                        padding={padding}
                                        spacing={spacing}
                                        deviceListDataOfCurrentTab={deviceListDataOfCurrentTab}
                                        deviceImageListData={deviceImageListData}
                                        isSelected={isSelected}
                                        isOnline={isOnline}
                                        unitsForBuds={item.units}
                                        selectedUnitForBuds={selectedUnitForBuds}
                                    ></DeviceImage>
                                )
                            })}
                        </g>
                    </g>
                </g>
            </svg>
        )

    } else if (countOfDevice >= 3) {
        const svgOriginWidth = 136; // SVG 컨테이너의 원본 너비
        const svgOriginHeight = 68; // // SVG 컨테이너의 원본 높이
        const deviceImageWidth = 32; // 40; // 각 디바이스 이미지의 너비
        const padding = 14;  // 10 + 4
        const spacing = (svgOriginWidth - (padding * 2) - (deviceImageWidth * 3)) / 2; // 이미지 사이의 간격

        return (
            <svg
                style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -100%)', // 이미지의 중앙 하단이 위치와 정확히 일치하도록 조정
                }}
                height="61.53px"
                viewBox="0 0 136 68"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <radialGradient cx="0%" cy="100%" fx="0%" fy="100%" r="91.7063586%" gradientTransform="translate(0, 1), scale(0.9615, 1), rotate(143.2592), translate(-0, -1)" id="radialGradient-1">
                        <stop stopColor="#000000" stopOpacity="0.6" offset="0%"></stop>
                        <stop stopColor="#000000" stopOpacity="0" offset="100%"></stop>
                    </radialGradient>
                    <filter x="-11.5%" y="-12.0%" width="123.1%" height="124.0%" filterUnits="objectBoundingBox" id="filter-2">
                        <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
                    </filter>
                </defs>
                <g id="2.-Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="2.0_plugin_Pin" transform="translate(-232, -659)">
                        <g id="Group-2" transform="translate(232, 572.9995)">
                            <g id="Multi-device-2" transform="translate(0, 88.0005)">
                                <polygon id="Rectangle-Copy-64" fill="url(#radialGradient-1)" filter="url(#filter-2)" points="67 38 93 38 93 58.3661325 67 63"></polygon>
                                <path d="M68,-1 C75.4558438,-1 82.2058438,2.02207804 87.0918829,6.90811711 C91.9779219,11.7941561 95,18.5441561 95,26 C95,33.3095097 92.0953644,39.9405936 87.3774162,44.8020402 L70.8284291,61.3553377 C70.0473805,62.1363863 69.0236913,62.5269106 68.000002,62.5269106 C66.9763127,62.5269106 65.9526235,62.1363863 65.1715749,61.3553377 L48.9029231,45.086686 C43.8967784,39.9324518 41,33.3036211 41,26 C41,18.544156 44.022078,11.794156 48.908117,6.908117 C53.794156,2.022078 60.544156,-1 68,-1 Z" id="Combined-Shape" stroke="#FFFFFF" strokeWidth="2" fill="#387AFF" fillRule="nonzero"></path>
                                <rect id="Rectangle" fill="#387AFF" x="0" y="0" width="136" height="50" rx="25"></rect>
                                <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="2" fill="#FFFFFF" x="4.352" y="4" width="127.296" height="42" rx="21"></rect>
                                {/* 아이템이 세 개인 경우 모든 이미지 표시 */}
                                {countOfDevice == 3 && reorderedDeviceIdsAndUnits.slice(0, 3).map((item, index) => {
                                    const device = getDeviceByDeviceId(item.deviceId, deviceListDataOfCurrentTab);
                                    const isOnline = getIsDeviceOnline(device, operationsResult, item.units);
                                    let isSelected;
                                    if (selectedUnitForBuds && selectedUnitForBuds !== BUDS_UNITS.BOTH) {
                                        isSelected = selectedDevice?.deviceId === item.deviceId && selectedUnitForBuds === item.units
                                    } else {
                                        isSelected = selectedDevice?.deviceId === item.deviceId
                                    }
                                    return (
                                        <DeviceImage
                                            deviceId={item.deviceId}
                                            index={index}
                                            deviceImageWidth={deviceImageWidth}
                                            padding={padding}
                                            spacing={spacing}
                                            deviceListDataOfCurrentTab={deviceListDataOfCurrentTab}
                                            deviceImageListData={deviceImageListData}
                                            isSelected={isSelected}
                                            isOnline={isOnline}
                                            unitsForBuds={item.units}
                                            selectedUnitForBuds={selectedUnitForBuds}
                                        ></DeviceImage>
                                    )
                                })}

                                {/* 아이템이 3개를 초과하는 경우, 추가 아이템 수 표시 */}
                                {countOfDevice > 3 && (
                                    <>
                                        {reorderedDeviceIdsAndUnits.slice(0, 2).map((item, index) => {
                                            const device = getDeviceByDeviceId(item.deviceId, deviceListDataOfCurrentTab);
                                            const isOnline = getIsDeviceOnline(device, operationsResult, item.units);
                                            let isSelected;
                                            if (selectedUnitForBuds && selectedUnitForBuds !== BUDS_UNITS.BOTH) {
                                                isSelected = selectedDevice?.deviceId === item.deviceId && selectedUnitForBuds === item.units
                                            } else {
                                                isSelected = selectedDevice?.deviceId === item.deviceId
                                            }
                                            return (
                                                <DeviceImage
                                                    deviceId={item.deviceId}
                                                    index={index}
                                                    deviceImageWidth={deviceImageWidth}
                                                    padding={padding}
                                                    spacing={spacing}
                                                    deviceListDataOfCurrentTab={deviceListDataOfCurrentTab}
                                                    deviceImageListData={deviceImageListData}
                                                    isSelected={isSelected}
                                                    isOnline={isOnline}
                                                    unitsForBuds={item.units}
                                                    selectedUnitForBuds={selectedUnitForBuds}
                                                ></DeviceImage>
                                            )
                                        })}
                                        <text x={countX} y={countY} fontSize="16" fill="#010102" letterSpacing="0" fontWeight="600">
                                            +{countOfDevice - 2}
                                        </text>
                                    </>
                                )}
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )

    }
};

const DeviceImage = ({ deviceId, index, deviceImageWidth, padding, spacing, deviceListDataOfCurrentTab, deviceImageListData, isSelected, isOnline, unitsForBuds, selectedUnitForBuds }) => {
    const device = getDeviceByDeviceId(deviceId, deviceListDataOfCurrentTab);
    const deviceType = device?.type
    const defaultImage = getDefaultDeviceImageByDeviceType(deviceType);
    const modelName = device?.modelInfo?.modelName;
    const deviceModelCode = device?.modelInfo?.deviceModelCode;
    const imageUrl = findImageLinkByModelCode(deviceImageListData, deviceModelCode ? deviceModelCode : modelName);
    const imageSrc = useCachedImage(imageUrl, defaultImage);
    const offsetX = 26;  // deviceImageWidth - dot Width(6)
    const statusColor = isOnline ? '#3EBF06' : '#999999';


    return (
        <>
            {
                isSelected
                    ? (<rect x={padding + (deviceImageWidth + spacing) * index} y="9"
                        width={deviceImageWidth} height={deviceImageWidth}
                        fill="rgba(56, 122, 255, 0.2)" rx="16px" ry="16px" />)
                    : null
            }

            <image href={imageSrc} x={padding + (deviceImageWidth + spacing) * index} y="9" width={deviceImageWidth} />

            <rect x={padding + (deviceImageWidth + spacing) * index + offsetX} y="9"
                width={6} height={6}
                fill={statusColor} rx="3px" ry="3px" />
            {
                (deviceType === DEVICE_TYPES.BUDS  && unitsForBuds !== BUDS_UNITS.BOTH)
                    ? <image href={unitsForBuds === BUDS_UNITS.RIGHT ? buds_right_text : buds_left_text}
                        width={12} height={12}
                        x={padding + (deviceImageWidth + spacing) * index} y={deviceImageWidth} />
                    : null
            }
        </>

    )
}


function reorderDeviceIdsAndUnits(deviceIdsAndUnits, selectedDeviceId, selectedUnitForBuds) {
    // deviceIds가 유효한 배열이고, selectedDeviceId가 deviceIds 배열에 포함되어 있는지 확인
    if (Array.isArray(deviceIdsAndUnits)) {
        // 선택된 deviceId와 일치하는 모든 항목을 찾아 배열 앞쪽으로 이동
        if (selectedUnitForBuds && selectedUnitForBuds !== BUDS_UNITS.BOTH) { // unit이 있으면 unit비교 
            const selectedDeviceIdsAndUnits = deviceIdsAndUnits.filter(item => item.deviceId === selectedDeviceId && item.units === selectedUnitForBuds);
            const otherDeviceIdsAndUnits = deviceIdsAndUnits.filter(item => item.deviceId !== selectedDeviceId || item.units !== selectedUnitForBuds);
            return [...selectedDeviceIdsAndUnits, ...otherDeviceIdsAndUnits];
        } else { // unit이 없으면 deviceId만 비교 
            const selectedDeviceIdsAndUnits = deviceIdsAndUnits.filter(item => item.deviceId === selectedDeviceId);
            const otherDeviceIdsAndUnits = deviceIdsAndUnits.filter(item => item.deviceId !== selectedDeviceId);
            return [...selectedDeviceIdsAndUnits, ...otherDeviceIdsAndUnits];
        }

    }
    return deviceIdsAndUnits; // 조건을 만족하지 않는 경우 원본 배열 반환
}

export default DeviceClusterMarker;