import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NotifyMeSliceStateType } from './notifyMeSliceTypes';
import { getNotifyMe, putNotifyMe, putTagNotifyMe } from '../../api/find/notifyMeApi';
import { GetNotifyMeRequestType, PutNotifyMeRequestType, TagPutNotifyMeRequestType } from '../../api/find/notifyMeApi/notifyMeTypes';


const initialState: NotifyMeSliceStateType = {
    isNotifyMeOn: false,
    notifyMeModalOpen: false,
    putNotifyMeState: {
        isLoading: false,
        data: null,
        error: undefined
    },
    getNotifyMeState: {
        isLoading: false,
        data: null,
        error: undefined
    }
}

export const asyncGetNotifyMe = createAsyncThunk(
    'setting/asyncGetNotifyMe',
    async ({ deviceId }: GetNotifyMeRequestType) => {
        const response = await getNotifyMe({ deviceId });
        return { response, deviceId };
    }
);

export const asyncPutNotifyMe = createAsyncThunk(
    'setting/asyncPutNotifyMe',
    async ({ deviceId, item }: PutNotifyMeRequestType) => {
        const response = await putNotifyMe({ deviceId, item });
        return {response, enabled: item.enabled};
    }
);

export const asyncTagPutNotifyMe = createAsyncThunk(
    'setting/asyncTagPutNotifyMe',
    async ({ deviceId, searchingStatus, email, language, displayName }: TagPutNotifyMeRequestType) => {
        const response = await putTagNotifyMe({ deviceId, email, language, displayName, searchingStatus });
        return {response, enabled: searchingStatus === "searching"};
    }
);

export const operationsSlice = createSlice({
    name: 'notifyMe',
    initialState,
    reducers: {
        setIsNotifyMeOn: (state, action: PayloadAction<boolean>) => {
            state.isNotifyMeOn = action.payload;
        },
        setNotifyMeModalOpen: (state, action: PayloadAction<boolean>) => {
            state.notifyMeModalOpen = action.payload;
        },
        clearNotifyMeState: (state) => {
            state.isNotifyMeOn = false;
            state.notifyMeModalOpen = false;
            state.putNotifyMeState.isLoading = false;
            state.putNotifyMeState.data = null;
            state.putNotifyMeState.error = undefined;
            state.getNotifyMeState.isLoading = false;
            state.getNotifyMeState.data = null;
            state.getNotifyMeState.error = undefined;
        }
    },
    extraReducers: (builder) => {
        // asyncGetNotifyMe
        builder.addCase(asyncGetNotifyMe.pending, (state) => {
            state.getNotifyMeState.isLoading = true;
        })
        builder.addCase(asyncGetNotifyMe.fulfilled, (state, action) => {
            state.getNotifyMeState.isLoading = false;
            const { deviceId, response } = action.payload;
            state.getNotifyMeState.data = response
            if (response.find(item => item.target_device_id === deviceId)) {
                state.isNotifyMeOn = true;
            }else {
                state.isNotifyMeOn = false;
            }
        })
        builder.addCase(asyncGetNotifyMe.rejected, (state, action) => {
            state.getNotifyMeState.error = action.error;
        })

        // asyncPutNotifyMe
        builder.addCase(asyncPutNotifyMe.pending, (state) => {
            state.putNotifyMeState.isLoading = true;
        })
        builder.addCase(asyncPutNotifyMe.fulfilled, (state, action) => {
            const { response, enabled } = action.payload;
            state.putNotifyMeState.isLoading = false;
            state.putNotifyMeState.data = response; 
            if (enabled) {
                state.isNotifyMeOn = true;
            } else {
                state.isNotifyMeOn = false;
            }
        })
        builder.addCase(asyncPutNotifyMe.rejected, (state, action) => {
            state.putNotifyMeState.error = action.error;
        })
        // asyncTagPutNotifyMe
        builder.addCase(asyncTagPutNotifyMe.pending, (state) => {
            state.putNotifyMeState.isLoading = true;
        })
        builder.addCase(asyncTagPutNotifyMe.fulfilled, (state, action) => {
            const { response, enabled } = action.payload;
            state.putNotifyMeState.isLoading = false;
            state.putNotifyMeState.data = response; 
            if (enabled) {
                state.isNotifyMeOn = true;
            } else {
                state.isNotifyMeOn = false;
            }
        })
        builder.addCase(asyncTagPutNotifyMe.rejected, (state, action) => {
            state.putNotifyMeState.error = action.error;
        })
    }
});


export const {
    setIsNotifyMeOn,
    setNotifyMeModalOpen,
    clearNotifyMeState,
} = operationsSlice.actions;


export default operationsSlice.reducer;