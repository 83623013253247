import axiosInstance from "../findToSTFAxiosConfig";
import { removeParenthesesValues } from '../../../utills/functions/stringPhasers';
import { DEVICE_TYPES } from "../../../utills/enums/Devices";
import { OPERATION_TYPES } from "../../../utills/enums/commons";
import { GetTrackLocationsRequestType, GetTrackLocationsResponseType, OperationRequestType, OperationResponseType } from "./deviceApiTypes";
import { AxiosResponse } from "axios";
import { STSItemResponseType } from "../findToStfTypes";


/**
 * 특정 Device의 정보를 서버로부터 받아옵니다.
 * @param {*} deviceId
 * @returns
 */

export const getDeviceDetails = async (deviceId: string) => {
    try {
        const response = await axiosInstance.get(`/find/devices/${deviceId}`);
        return response.data?.item;
    } catch (error) {
        console.error('Error fetching device details:', error);
        throw error;
    }
};
/**
 * 특정 Device의 정보를 삭제합니다.
 * @param {*} deviceId
 * @returns
 */

export const removeDevice = async (deviceId: string) => {
    try {
        const response = await axiosInstance.delete(`/find/devices/${deviceId}`);
        return response.data.items;
    } catch (error) {
        console.error('Error remove device API:', error);
        throw error;
    }
};
/**
 * 특정 Device에 Location 을 update하라고 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export const performOperationLocation = async (deviceId: string, userId: string, deviceType?: string) => {
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation?type=${deviceType}`, {
            userId: userId,
            operation: "LOCATION"
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationLocation:', error);
        throw error;
    }
};
/**
 * 특정 Device에 Location 을 update하라고 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export const performOperationCheckConnection = async (deviceId: string, userId: string, deviceType?: string) => {
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation?type=${deviceType}`, {
            userId: userId,
            operation: "CHECK_CONNECTION"
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationLocation:', error);
        throw error;
    }
};


/**
 * 특정 phone에 track location을 시작합니다.
 */
export const performOperationTrackLocation = async ({ deviceId }: OperationRequestType) => {
    try {
        const response: AxiosResponse<OperationResponseType> = await axiosInstance.post(`/find/devices/${deviceId}/operation`, {
            operation: OPERATION_TYPES.TRACK_LOCATION_START
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationTrackLocation:', error);
        throw error;
    }
};


/**
 * 특정 phone에 track location을 중지합니다.
 */
export const stopOperationTrackLocation = async ({ deviceId }: OperationRequestType) => {
    try {
        const response: AxiosResponse<OperationResponseType> = await axiosInstance.post(`/find/devices/${deviceId}/operation`, {
            operation: OPERATION_TYPES.TRACK_LOCATION_STOP
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationTrackLocation:', error);
        throw error;
    }
};


/**
 * 특정 Device의 track location 기능을 통해 얻은 location 정보를 가져옵니다.
 */

export const getTrackLocations = async ({ deviceId, requestId, userId }: GetTrackLocationsRequestType) => {
    try {
        const response: AxiosResponse<GetTrackLocationsResponseType> = await axiosInstance.post(`/find/devices/${deviceId}/getTrackLocations`, {
            requestId,
            userId
        });

        console.log();
        console.log(response);
        return response.data.item;
    } catch (error) {
        console.error('Error calling the getCardList API:', error);
        throw error;
    }
};


/**
 * 특정 Device에 Ring start 을 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export interface TagSpecificFields {
    mnid?: string;
    setupid?: string;
    firmwareversion?: string;
}

export const performOperationRing = async (
    deviceId: string,
    userId: string,
    deviceType?: string,
    tagSpecificFields: TagSpecificFields = {} // TAG에 특화된 필드
) => {
    try {
        const payload = {
            userId: userId,
            operation: "RING",
            ...(deviceType === 'TAG' && tagSpecificFields) // deviceType이 'TAG'일 때만 tagSpecificFields 포함
        };
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation?type=${deviceType}`, payload);
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationRing:', error);
        throw error;
    }
};


/**
 * 특정 Device에 Ring stop을 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export const performOperationRingStop = async (deviceId: string, userId: string) => {
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation`, {
            userId: userId,
            operation: "RING",
            status: 'stop'
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationRingStop:', error);
        throw error;
    }
};
/**
 * EarBuds에 mute 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export const performOperationMute = async (deviceId: string, userId: string, muteOrResum: string) => {
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation`, {
            userId: userId,
            operation: "RING",
            status: muteOrResum // resumeL, resumeR, muteL, muteR
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationMute:', error);
        throw error;
    }
};
/**
 * 특정 Device에 Lock 을 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export const performOperationLock = async ({
    deviceId,
    userId,
    password,
    restrictionNumber,
    lockMessage
}: {
    deviceId: string,
    userId: string,
    password: string,
    restrictionNumber: string,
    lockMessage: string
}) => {
    const payload = {
        userId: userId,
        password,
        operation: "LOCK",
    };
    if (restrictionNumber && removeParenthesesValues(restrictionNumber) !== "") {
        Object.assign(payload, { restrictionNumber });
    }
    if (lockMessage && lockMessage !== "") {
        Object.assign(payload, { lockMessage });
    }
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation`, payload);
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationLock', error);
        throw error;
    }
};
/**
 * 특정 Device에 Erase Data 동작을 요청합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */


export const performOperationErase = async (deviceId: string, userId: string) => {
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation`, {
            userId: userId,
            operation: "WIPE"
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationErase', error);
        throw error;
    }
};
/**
 * 특정 Device에 Erase Data 동작을 취소합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @returns
 */

export const performOperationEraseCancel = async (deviceId: string, userId: string) => {
    try {
        const response: AxiosResponse<STSItemResponseType<OperationResponseType>> = await axiosInstance.delete(`/find/devices/${deviceId}/operation`);
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationEraseCancel:', error);
        throw error;
    }
};


/**
 * 
 * @param deviceId 
 * @param userId 
 * @param deviceType 
 * @returns 
 */
export const performOperationPowerSave = async (deviceId: string, userId: string, deviceType?: string) => {
    try {
        const response = await axiosInstance.post(`/find/devices/${deviceId}/operation?type=${deviceType}`, {
            userId: userId,
            operation: OPERATION_TYPES.POWER_SAVE
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error performOperationLocation:', error);
        throw error;
    }
};



/**
 * MQTT 메시지를 Decryption 합니다.
 * @param {*} deviceId
 * @param {*} userId
 * @param mqttMessage
 * @returns
 */

export const decryptMqttMessage = async (deviceId: string, userId: string, mqttMessage: string) => {
    try {
        const response = await axiosInstance.post(`/find/${deviceId}/decryptMsg`, {
            message: mqttMessage
        });
        return response.data?.item;
    } catch (error) {
        console.error('Error decryptMqttMessage API:', error);
        throw error;
    }
};
/**
 * 특정 Device의 Samsung Pay cardlist를 가져옵니다.
 * @param {*} deviceId
 * @returns
 */

export const getRegisteredCards = async (deviceId: string) => {
    try {
        const response = await axiosInstance.get(`/find/${deviceId}/cards`);
        return response.data?.items;
    } catch (error) {
        console.error('Error calling the getCardList API:', error);
        throw error;
    }
};
