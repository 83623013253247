import React from "react";
import { createAndSendLog } from "../../api/find/loggerApi";
import { LogLevel } from "../../api/find/loggerApi/logTypes";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }


    // 오류가 발생했을 때 호출되는 메서드
    static getDerivedStateFromError(error) {
        // 다음 렌더링에서 폴백 UI가 표시되도록 상태를 업데이트합니다.
        return { hasError: true };
    }


    // 오류 정보를 캐치하는 메서드
    componentDidCatch(error, errorInfo) {
        // 여기에서 오류를 로그하거나 서버로 보고할 수 있습니다.
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        createAndSendLog(ErrorBoundary, error.toString(), LogLevel.ERROR, error, { errorInfo });
    }


    // 컴포넌트 렌더링
    render() {
        if (this.state.hasError) {
            // 오류가 발생하면 대체 UI를 렌더링합니다.
            return (
                <div>
                    <h5>Unexpected Error</h5> {/* Todo: i18n */}
                    <details open style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state?.errorInfo?.componentStack}
                    </details>
                </div>
            );
        }
        // 오류가 없으면 자식 컴포넌트를 정상적으로 렌더링합니다.
        return this.props.children;
    }
}

export default ErrorBoundary;