import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import './LostModeStyles.scss';

import { getDeviceIdFromSearchParams } from '../../utills/functions/urlFunctions';
import NotFound from '../NotFound';
import LostModeDeviceDetailCard from '../../components/cards/LostModeDeviceDetailCard';
import { isMobileOnly } from 'react-device-detect';

const LostMode = () => {
  const location = useLocation();
  const [deviceId, setDeviceId] = useState<null | string>();

  useEffect(() => {
    setDeviceId(getDeviceIdFromSearchParams(location.search));
  }, []);

  if (!deviceId || !isMobileOnly) {
    return <NotFound />;
  }

  return (
    <div className='lost-mode-container'>
      <LostModeDeviceDetailCard
        deviceId={deviceId}
      />
    </div>
  )
}

export default LostMode;