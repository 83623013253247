import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from 'react-svg'

import { ring, blink_spen, blink, refresh, lock, erase_data, extend_battery_life } from "../../../../assets/images/deviceCard";
import { location_history } from "../../../../assets/images/trackLocation";
import { DeviceDetailOperationButtonsGridPropsType } from "./DeviceDetailOperationButtonsGridTypes";
import { DEVICE_ACTION_FEATURES, DEVICE_SUB_TYPES, DEVICE_TYPES } from "../../../../utills/enums/Devices";
import { DEVICE_REGI_STATUS } from "../../../../utills/enums/commons";

const DeviceDetailOperationButtonsGrid = ({
  selectedDeviceDetail,
  deviceType,
  deviceSubType,
  isCurrentUserDevice,
  createActionButtonClickHandler
}: DeviceDetailOperationButtonsGridPropsType) => {
  const { t } = useTranslation();
  const isFeatureEnabled = (selectedDeviceDetail: any, featureKey: any) => {
    // Pending devices do not provide any functions.
    if (selectedDeviceDetail && selectedDeviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED) {
      return false;
    }

    // Make refresh button always visible
    if (featureKey === DEVICE_ACTION_FEATURES.LOCATION) {
      return true;
    }

    // Make tag ring always visible
    if (featureKey === DEVICE_ACTION_FEATURES.RING && deviceType === DEVICE_TYPES.TAG) {
      if (!isCurrentUserDevice) { // 가족기기는 ring 동작 안하므로 숨기기 
        return false
      }
      return true;
    }

    // Fit3 -- 아직까지 featureKey들이 제대로 나오지 않아서 임시조치
    if (deviceSubType === DEVICE_SUB_TYPES.FIT && featureKey === DEVICE_ACTION_FEATURES.VIBRATE) {
      return true;
    }

    // Watch3 : RING, VIBRATE 둘 다 있을 수 있음, VIBRATE는 빼야함
    if (featureKey === DEVICE_ACTION_FEATURES.VIBRATE && deviceType === DEVICE_TYPES.WATCH) {
      return false;
    }

    // battery 아이콘 버튼 표시 여부를 결정합니다. 
    if (featureKey === DEVICE_ACTION_FEATURES.BATTERY && selectedDeviceDetail?.menu) {
      // selectedDeviceDetail.menu.lock.powerSaving : "Y" 인 경우만 true를 반환합니다. 
      const findPowerSaving = (menu: any[]): boolean =>
        menu.some(item =>
          Array.isArray(item.lock) && item.lock.some((lockItem: { [x: string]: string; } | null) => {
            if (typeof lockItem === 'object' && lockItem !== null) {
              return lockItem['powerSaving'] === 'Y';
            }
            return false;
          })
        );
      return findPowerSaving(selectedDeviceDetail?.menu);
    }

    // 가족기기의 경우 blink_light, ring, location 만 표시 
    if (!isCurrentUserDevice) {
      // 가족기기에게 유효한 featureKey 목록
      const validFeatureKeysForOtherDevices = [
        DEVICE_ACTION_FEATURES.RING,
        DEVICE_ACTION_FEATURES.LOCATION,
        DEVICE_ACTION_FEATURES.BLINK
      ];
      // 가족기기에게 유효한 featureKey 목록에 포함되어 있는지 확인
      if (!validFeatureKeysForOtherDevices.includes(featureKey)) {
        return false;
      }
    }


    // selectedDeviceDetail이 null이거나 menu가 없는 경우, 기능을 비활성화
    if (!selectedDeviceDetail || !selectedDeviceDetail.menu) {
      return false;
    }

    // 해당 기능의 활성화 여부 찾기
    const feature = selectedDeviceDetail.menu.find((item: any) => item.hasOwnProperty(featureKey)); // Todo: fix any


    if (feature) {
      // 값이 배열인 경우, 배열 길이가 0보다 크면 활성화
      if (Array.isArray(feature[featureKey])) {
        if (featureKey === "lock" || featureKey === "wipe") {
          if (deviceType === DEVICE_TYPES.PHONE
            || deviceType === DEVICE_TYPES.TAB
            || (deviceType === DEVICE_TYPES.WATCH && deviceSubType !== DEVICE_SUB_TYPES.FIT)) { // FIT 부분의 조건은 임시조치 L33 참고
            return feature[featureKey].length > 0;
          }
        } else {
          return feature[featureKey].length > 0;
        }
      }
      // 그렇지 않으면 'Y'인 경우에만 활성화
      return feature[featureKey] === 'Y';
    }

    return false;
  }

  const buttonValues = [
    {
      feature: DEVICE_ACTION_FEATURES.RING,
      src: ring,
      alt: "Ring",
      text: t("ring")
    },
    {
      feature: DEVICE_ACTION_FEATURES.BLINK,
      src: (deviceType === DEVICE_TYPES.SPEN) ? blink_spen : blink,
      alt: "Blink",
      text: t("blink_light")
    },
    {
      feature: DEVICE_ACTION_FEATURES.VIBRATE,
      src: ring,
      alt: "Vibrate",
      text: t("vibrate")
    },
    {
      feature: DEVICE_ACTION_FEATURES.LOCATION,
      src: refresh,
      alt: "Refresh",
      text: t("refresh")
    },
    {
      feature: DEVICE_ACTION_FEATURES.LOCK,
      src: lock,
      alt: "Lock",
      text: t("lock")
    },
    {
      feature: DEVICE_ACTION_FEATURES.TRACK_LOCATION,
      src: location_history,
      alt: "Track Location",
      text: t("track_location")
    },
    {
      feature: DEVICE_ACTION_FEATURES.WIPE,
      src: erase_data,
      alt: "Erase",
      text: t("erase_data")
    },
    {
      feature: DEVICE_ACTION_FEATURES.BATTERY,
      src: extend_battery_life,
      alt: "Extend Battery Life",
      text: t("extend_battery_life")
    },
  ];



  const enabledFeatures = buttonValues.filter(value => isFeatureEnabled(selectedDeviceDetail, value.feature));

  const getColumns = () => {
    const featureCount = enabledFeatures.length;
    if (featureCount < 3) {
      return featureCount;
    } else if (featureCount === 4 || featureCount === 8) {
      return 4;
    } else {
      return 3;
    }
  }

  return (
    <div className="card-content">
      <Grid container rowSpacing={5} columnSpacing={0} columns={{ xxxs: getColumns() }}>
        {enabledFeatures.map(({ feature, src, alt, text }) => (
          <Grid item xxxs={1}>
            <Button onClick={createActionButtonClickHandler(feature)}>
              <ReactSVG style={{ width: '24px', height: '24px' }} src={src} />
            </Button>
            <p>{text}</p>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DeviceDetailOperationButtonsGrid;
