import { AxiosResponse } from "axios";
import axiosInstance from "../findAxiosConfig";
import { DecryptLocationRequest, DecryptLocationResponse } from "./decryptLocationApi";

export const decryptLocation = async (
    data: DecryptLocationRequest
): Promise<DecryptLocationResponse> => {
    try {
        const response: AxiosResponse<DecryptLocationResponse> = await axiosInstance.post(
            '/geolocation/decrypt',
            data
        );
        return response.data;
    } catch (error) {
        // 에러 처리 로직 추가
        throw error;
    }
};