import { hasDataProperty } from "../../slices/operationSlice/operationSliceTypeGuard";
import { OperationCheckConnectionData } from "../../slices/operationSlice/operationsSliceTypes";
import { RootStateType } from "../../slices/types";
import { OPERATION_TYPES } from "../enums/commons";

export const getBattery = (operationsResult: RootStateType['operations']['operationsResult'], deviceId: string) => {
    if (!operationsResult || !deviceId) {
        return null;
    }
    const checkConnectionInfo = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION];
    if (hasDataProperty<OperationCheckConnectionData>(checkConnectionInfo)) {
        return checkConnectionInfo.data.battery;
    }
}


export const getBatteryLR = (operationsResult: RootStateType['operations']['operationsResult'], deviceId: string) => {
    if (!operationsResult || !deviceId) {
        return null;
    }
    const checkConnectionInfo = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION];
    if (hasDataProperty<OperationCheckConnectionData>(checkConnectionInfo)) {
        return [checkConnectionInfo.data.extra?.left?.battery, checkConnectionInfo.data.extra?.right?.battery] ;
    }
}