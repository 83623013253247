import { useEffect } from 'react';
import { AUTH_KEYS, isLoggedIn, signOutAndRedirectToTimeoutPage } from '../utills/auth';


const KEY_LAST_ACTIVITY_TIME = "KEY_LAST_ACTIVITY_TIME"


const useAutoSignOut = (timeout = 30 * 60 * 1000) => {

    useEffect(() => {
        let logoutTimer;

        const checkActivityTimeout = () => {
            const lastActivityTime = localStorage.getItem(KEY_LAST_ACTIVITY_TIME);
            if (!isLoggedIn()) {
                return;
            }
            if (lastActivityTime) {
                const timeElapsed = new Date() - new Date(lastActivityTime);
                if (timeElapsed > timeout) {
                    signOutAndRedirectToTimeoutPage(); // logout and go to home (login page)
                }
            }
        };

        const resetLogoutTimer = () => {
            localStorage.setItem(KEY_LAST_ACTIVITY_TIME, new Date().toISOString());
            clearTimeout(logoutTimer);
            logoutTimer = setTimeout(() => {
                if (!isLoggedIn()) {
                    return;
                }
                if (navigator.onLine) {
                    signOutAndRedirectToTimeoutPage(); // logout and go to home (login page)
                } else {
                    alert('Network connection is lost. You will be signed out when the connection is restored.');// TODO : alert 생략 하기 
                }
            }, timeout);
        };

        const handleStorageChange = (event) => {
            if (event.key === AUTH_KEYS.ACCESS_TOKEN && !event.newValue)  {
                window.location.href = '/timeout';
            }
        };

        // 앱이 로드될 때 마지막 활동 시간 확인
        checkActivityTimeout();

        // 활동 감지 이벤트 리스너 추가
        window.addEventListener('mousemove', resetLogoutTimer);
        window.addEventListener('keypress', resetLogoutTimer);
        window.addEventListener('storage', handleStorageChange);

        // 초기 타이머 설정
        resetLogoutTimer();

        // 클린업 함수
        return () => {
            clearTimeout(logoutTimer);
            window.removeEventListener('mousemove', resetLogoutTimer);
            window.removeEventListener('keypress', resetLogoutTimer);
            window.removeEventListener('storage', handleStorageChange);
        };

    }, [timeout]);

    return null;
};


export default useAutoSignOut;
