import React from 'react';
import Lottie from 'react-lottie';
import loadingCircleData from '../../../assets/images/lottie/loading_circle.json';
import whiteLoadingCircleData from '../../../assets/images/lottie/white_loading_circle.json';
import { LottieLoadingCirclePropsTypes } from './LottieLoadingCircleTypes';

const LottieLoadingCircle = ({ size = "m", color = "primary" }: LottieLoadingCirclePropsTypes) => {
  const imageSize =
  {
    xs: 20,
    s: 30,
    m: 40,
    l: 50,
    xl: 60,
  };
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: color === "primary" ? loadingCircleData : whiteLoadingCircleData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={imageSize[size]} width={imageSize[size]} />;
};

export default LottieLoadingCircle;