import React from 'react'
import BaseButton from '../BaseButton'
import { ActionButtonProps } from './ActionButtonTypes'

const ActionButton = ({ title, backgroundColor, loadingColor, ...props }: ActionButtonProps) => {
  return (
    <>
        <BaseButton
            sx={{backgroundColor}}
            color="secondary"
            loadingColor={loadingColor ?? 'white'}
            title={title}
            typographyVariant="actionButton"
            {...props}
        />
    </>
  )
}

export default ActionButton