import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CardListStateType } from "./cardListSliceTypes";
import { getRegisteredCards } from '../../api/findToStf/devicesApi/devicesApi';


const initialState: CardListStateType = {
    cardList: {
        isLoading: false,
        data: null,
        error: null
    }
}

export const asyncGetCardList = createAsyncThunk<any, {deviceId: string}>(
    'cardList/asyncGetCardList',
    async ({ deviceId }) => {
        const response = await getRegisteredCards(deviceId);
        return response;
    }
);

export const cardListSlice = createSlice({
    name: "cardList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // asyncGetCardList
        builder.addCase(asyncGetCardList.pending, (state, action) => {
            state.cardList.isLoading = true;
        })
        builder.addCase(asyncGetCardList.fulfilled, (state, action) => {
            state.cardList.isLoading = false;
            state.cardList.data = action.payload;
        })
        builder.addCase(asyncGetCardList.rejected, (state, action) => {
            state.cardList.isLoading = false;
            state.cardList.error = action.payload;
        })
    }
});

// eslint-disable-next-line no-empty-pattern
export const {} = cardListSlice.actions;

export default cardListSlice.reducer;