import React from 'react';
import './TitleOverSubtitleStyles.scss';
import { Typography } from '@mui/material';
import { TitleOverSubTitlePropsType } from './TitleOverSubtitleTypes';

const TitleOverSubTitle = ({
  title,
  subtitle,
  options
}: TitleOverSubTitlePropsType) => {
  return (
    <div className="title-over-subtitle-container">
      <Typography
        color={options?.title?.color ?? "#8C8C91"}
        fontSize={options?.title?.size ?? "13px"}
      >
        {title}
      </Typography>
      <Typography
        color={options?.subtitle?.color ?? "#000000"}
        fontSize={options?.subtitle?.size ?? "17px"}
      >
        {subtitle}
      </Typography>
    </div>
  )
}

export default TitleOverSubTitle;