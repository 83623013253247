import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div>
            <div className="not_found_page">
                <div className="content">
                    <h1>Not Found!</h1> {/* Todo: i18n */}
                    <p>The page you are looking for could not be found.</p> {/* Todo: i18n */}
                    {/* Button back to home */}
                    <Link className="white-fill-button" to="/">
                        BACK TO HOME {/* Todo: i18n */}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
