import { useContext, useEffect } from 'react'
import { HistoryFunctionContext } from '../../contexts/HistoryFunctionContext';

const useHistoryStack = ({ handleClose, message, open }: {handleClose: any, message: any, open: boolean}) => {
    const { historyStack } = useContext(HistoryFunctionContext);
    useEffect(() => {
        if (open) {
            historyStack.push({message, handleClose});
            window.history.pushState({message}, "", window.location.href);
        }
   }, [open]);

    const popHistoryStack = () => {
        historyStack.pop();
    }

   return { popHistoryStack };
}

export default useHistoryStack;