import { useDispatch, useSelector } from 'react-redux';

import useMqttConnections from './useMqttConnections';
import { setOperationResponse, setOperationStart } from '../slices/operationSlice/operationsSlice';
import mqttConnectionsManager from '../managers/MqttConnectionsManager'; // singleton
import timerManager from '../managers/TimerManager'; // singleton
import { OPERATION_TYPES_TYPE } from '../utills/enums/commons';
import { getDeviceDetailByDeviceId } from '../utills/functions';

type StartMqttConnectionType = { // Todo any 바꿔야함 
    selectedDevice?: any,
    deviceDetail?: any
    operationType: OPERATION_TYPES_TYPE
};


const useMqttDeviceOperation = () => {
    const dispatch = useDispatch();
    const { connectMqtt } = useMqttConnections();
    const detailListData = useSelector((state: any) => state.deviceList.detailListData);
    const selectedDeviceData = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceDetailData = getDeviceDetailByDeviceId(selectedDeviceData?.deviceId, detailListData);

    const startMqttConnection = async ({ selectedDevice, deviceDetail, operationType }: StartMqttConnectionType) => {
        if (!deviceDetail && deviceDetailData) {
            deviceDetail = deviceDetailData;
        }
        if (!selectedDevice && selectedDeviceData) {
           selectedDevice = selectedDeviceData; 
        }
        if (!deviceDetail || !selectedDevice) {
            console.log(`deviceDetail: ${deviceDetail}`);
            console.log(`deviceDetail: ${selectedDevice}`);
            return;
        }

        const deviceId = selectedDevice?.deviceId;

        // Start the operation here and set loading 
        dispatch(setOperationStart({ deviceId, operationType }));

        // set timer for 120 sec
        timerManager.startTimer(deviceId, operationType, () => {
            dispatch(setOperationResponse({ deviceId, operationType, data: null, isTimeout: true})); // cancel loading
        }, 60 * 1000);

        // If there is no MQTT connection, try connecting
        if (!mqttConnectionsManager.getConnection(deviceId)) {
            try {
                console.log("connect mqtt")
                connectMqtt(selectedDevice, deviceDetail);
            } catch (error) {
                console.error('Failed to connect MQTT', error);
                return;
            }
        }
    };

    return startMqttConnection;
};


export default useMqttDeviceOperation;