import React from 'react'
import { Box, Typography } from '@mui/material';
import { BulletPointWithTitleAndContentsProps } from './BulletPointWithTitleAndContentTypes';
import './BulletPointWithTitleAndContentStyles.scss';

import LoadingCircle from '../../view-only/LoadingCircle';
import { locked, black_dot, success_blue, list_loading } from "../../../assets/images/deviceCard";
import info from "../../../assets/images/common/info.svg";
import { Stack } from '@mui/system';

const BulletPointWithTitleAndContents = ({ title,
	content,
	content_sec,
	bulletPointBundle: { type, width = '18px' },
	hypertextBundle
}: BulletPointWithTitleAndContentsProps) => {

	const getIcon = () => {
		if (type === "checked") {
			return success_blue;
		}
		if (type === "info") {
			return info;
		}
		if (type === "dot") {
			return black_dot;
		}
		if (type === "loading") {
			return null;
		}
		return type;
	}

	const icon = getIcon();

	return (
		<div className="bullet-point-with-title-and-contents-container">
			{icon
				? (<img src={icon} alt={list_loading} width={width} height="100%" background-color="green" />)
				: (<>
					<div className='loading-container'>
						<div className='width-spacer'></div>
						<div className='loading-circle-width-remover'>
							<LoadingCircle />
						</div>
					</div>
				</>)
			}

			<div className="title-and-contents-container">
				<Typography variant="lockNormalTitle">{title}</Typography>
				<Typography variant="lockNormalContent">{content} </Typography>
				{content_sec ? <Typography variant="lockNormalContent">{content_sec} </Typography> : null}
				{hypertextBundle ? <a href={hypertextBundle.url} onClick={hypertextBundle.onClick} >{hypertextBundle.title}</a> : null}
			</div>
		</div>
	)
}

export default BulletPointWithTitleAndContents;