import { ListItemButton, ListItemText } from '@mui/material';
import React from 'react'
import { TitleListItemButtonProps } from './TitleListItemButtonTypes';
import './TitleListItemButtonStyles.scss';
import LoadingCircle from '../../view-only/LoadingCircle';

const TitleListItemButton = ({ onClick, primaryText, secondaryText, isLoading }: TitleListItemButtonProps) => {
	return (
		<div className='title-list-item-button'>
			<ListItemButton onClick={onClick}>
				<ListItemText
					style={{ maxWidth: "fit-content", wordBreak: "keep-all" }}
					primary={primaryText}
					secondary={secondaryText}
				/>
				{isLoading ?
					(<div className='loading-circle'>
						<LoadingCircle />
				</div>) : null}
			</ListItemButton>
		</div>
	)
}

export default TitleListItemButton;