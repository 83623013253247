import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Card, Button, Typography, Stack, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './DeviceDetailCardStyles.scss';

import PhoneActionCard from '../actions/PhoneActionCard';
import TabletActionCard from '../actions/TabletActionCard';
import BudsActionCard from '../actions/BudsActionCard';
import TagActionCard from '../actions/TagActionCard';
import PinConfirmActionCard from '../actions/PinConFirmActionCard/PinConfirmActionCard';

import { ModalPlaySound, ModalLock, ModalErase, ModalBattery } from '../../modal';
import ModalTrackLocation from '../../modal/ModalTrackLocation';

import {
    findImageLinkByModelCode, getAddressByDeviceId, getDefaultDeviceImageByDeviceType, getDeviceDetailByDeviceId, getDeviceName,
    getEncryptedLocationByDeviceId, getIsDeviceOnline, getTimestampFormatted, isValidPosition
} from '../../../utills/functions';
import { confirmPassword } from '../../../utills/functions/pwConfirmFunctions';
import { formatString } from '../../../utills/functions/stringPhasers';
import { getFindOfflineInfo } from '../../../utills/functions/deviceFunctions';
import { parseDateStringToDateObject } from '../../../utills/functions/time';

import { getUserId } from '../../../utills/auth';
import { OPERATION_TYPES } from '../../../utills/enums/commons';
import { BUDS_UNITS, DEVICE_ACTION_FEATURES, DEVICE_TYPES } from "../../../utills/enums/Devices";


import { setActiveTabType, setIsDeviceDetailOpen, setRemovedDeviceIsLoading, setSelectedDevice, updateSelectedUnitForBuds, upsertDeviceDetail, upsertLocationListData } from '../../../slices/deviceListSlice';
import { setEraseModalOpen } from '../../../slices/modalEraseSlice';
import { asyncGetNotifyMe, clearNotifyMeState, setNotifyMeModalOpen } from '../../../slices/notifyMeSlice';
import { setModalTrackLocationOpen } from '../../../slices/modalTrackLocationSlice';

import useCachedImage from '../../../hooks/useCachedImage';
import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

import { getDeviceDetails, performOperationLocation, performOperationCheckConnection } from '../../../api/findToStf/devicesApi/devicesApi';
import { decryptLocation } from '../../../api/find/decryptLocationApi/decryptLocationTypes';

import DeviceDetailOperationButtonsGrid from './DeviceDetailOperationButtonsGrid';
import DeviceDetailOperationLists from './DeviceDetailOperationLists';
import LoadingCircle from '../../view-only/LoadingCircle';
import { badge_dot_online, badge_dot_offline, toggle_buds_L, toggle_buds_R, spotted_by } from "../../../assets/images/deviceCard";
import { setLostModeModalOpen, setLostModeModalSettingOpen } from '../../../slices/lostModeSlice';
import useHistoryStack from '../../../hooks/useIgnoreBackButton/useHistoryStack';


const DeviceDetailCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobileView } = useIsMobileView();

    // global state
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const isCurrentUserDevice = getUserId() && selectedDevice?.userId === getUserId() ? true : false;

    const detailListData = useSelector((state) => state.deviceList.detailListData);
    const deviceImageListData = useSelector(state => state.deviceList.deviceImageListData);
    const addressListData = useSelector((state) => state.deviceList.addressListData);
    const locationListData = useSelector((state) => state.deviceList.locationListData);
    const activeTabType = useSelector((state) => state.deviceList.activeTabType);


    useHistoryStack({
        message: "DeviceDetailCard", open: true, handleClose: () => {
            dispatch(setActiveTabType(activeTabType));
            dispatch(clearNotifyMeState());
            dispatch(setEraseModalOpen(false));
            dispatch(setIsDeviceDetailOpen(false));
            dispatch(setRemovedDeviceIsLoading(false));
        }
    });



    // variables from global state
    const displayName = getDeviceName(selectedDevice);
    const deviceType = selectedDevice && selectedDevice.type ? selectedDevice.type : null;
    const deviceSubType = selectedDevice?.modelInfo?.deviceSubType;
    const modelName = selectedDevice?.modelInfo?.modelName; // ex. SM-
    const deviceModelCode = selectedDevice?.modelInfo?.deviceModelCode;
    const currentAddressData = getAddressByDeviceId(selectedDevice?.deviceId, addressListData);
    const lastUpdatedAddress = currentAddressData?.address;
    const lastUpdatedAddressTime = getTimestampFormatted(currentAddressData?.timeCreated, 'Do MMM h:mm A');
    const imageUrl = findImageLinkByModelCode( // deviceModelCode 없으면 modelName으로 이미지 찾기 
        deviceImageListData,
        deviceModelCode ? deviceModelCode : modelName
    );
    const defaultImage = getDefaultDeviceImageByDeviceType(selectedDevice?.type);
    const imageSrc = useCachedImage(imageUrl, defaultImage);


    // component state
    const [selectedDeviceDetail, setSelectedDeviceDetail] = useState(null);

    // custom hook 
    const { operationsResult, startOperation } = useDeviceOperations();
    const isLoadingCheckConnection = operationsResult[selectedDevice?.deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.isLoading;

    // Modal
    const [openSound, setOpenSound] = useState(false);
    const [openLock, setOpenLock] = useState(false);
    const [openBattery, setOpenBattery] = useState(false);
    const [isLocationPinModalOpen, setIsLocationPinModalOpen] = useState(false);
    const [isLocationPinError, setIsLocationPinError] = useState(false);
    const [locationPinValue, setLocationPinValue] = useState("");
    const [isLocationPinLoading, setIsLocationPinLoading] = useState(false);
    const selectedUnitForBuds = useSelector(state =>
        state.deviceList.budsSelectedUnitList?.find(detail => detail?.deviceId === selectedDevice?.deviceId)?.selectedUnit
    );


    useEffect(() => {
        // Open the pin input modal to decrypt the location.
        openLocationPinModalIfNeeded();
    }, []);

    const openLocationPinModalIfNeeded = () => {
        if (currentAddressData && currentAddressData.isEncrypted === true) {
            setIsLocationPinModalOpen(true);
        } else {
            setIsLocationPinModalOpen(false);
        }
    }


    /**
     * Update details 
     * when selecting a device 
     * from the device list or map
     */
    useEffect(() => {
        if (!selectedDevice) {
            handleClose();
        }
        const deviceId = selectedDevice?.deviceId;
        if (deviceId) {
            dispatch(asyncGetNotifyMe({ deviceId }));
        }
        if (!selectedDevice || !selectedDevice?.deviceId) {
            setSelectedDeviceDetail(null);
            return;
        }
        updateSelectedDeviceDetail(selectedDevice?.deviceId);
    }, [selectedDevice]);

    const handleMobileBackButton = () => {
        dispatch(setEraseModalOpen(false));
        dispatch(setModalTrackLocationOpen(false));
        dispatch(setNotifyMeModalOpen(false));
        dispatch(setLostModeModalOpen(false));
        dispatch(setLostModeModalSettingOpen(false));
    }


    /**
     * Update with detail data for the selected deviceId
     * @param {*} deviceId 
     */
    const updateSelectedDeviceDetail = (deviceId) => {
        const existingDetail = getDeviceDetailByDeviceId(selectedDevice?.deviceId, detailListData);
        if (existingDetail) {
            setSelectedDeviceDetail(existingDetail);
        } else {
            getDeviceDetails(deviceId)
                .then(detail => {
                    dispatch(upsertDeviceDetail({ deviceId, detail }));
                    setSelectedDeviceDetail(detail);
                })
                .catch(e => console.error(e))
        }
    }

    const onRefreshButtonClick = () => {
        sendRefreshOperations(selectedDevice, selectedDeviceDetail);

    }

    const sendRefreshOperations = (device, deviceDetail) => {
        if (!device) {
            return;
        }
        const deviceId = device.deviceId;
        const userId = device.userId;
        const deviceType = device.type;

        startOperation(device, deviceDetail, OPERATION_TYPES.LOCATION,
            () => {
                return performOperationLocation(deviceId, userId, deviceType)
                    .then(responseData => {
                        return responseData
                    })
                    .catch(e => {
                        throw e;
                    })
            }
        );

        startOperation(device, deviceDetail, OPERATION_TYPES.CHECK_CONNECTION,
            () => {
                return performOperationCheckConnection(deviceId, userId, deviceType)
                    .then(responseData => responseData)
                    .catch(e => {
                        throw e;
                    })
            }
        );
    }

    const handleClose = () => {
        // Mobile Accordion menu표시를 위해 selected 유지해야 함. 다른 수정사항과 겹친다면 확인 필요.
        // PC browser에서는 연속으로 동일한 list 선택할때를 위해서 null이어야 함.
        if (!isMobileView) {
            dispatch(setSelectedDevice(null));
        }
        dispatch(setActiveTabType(activeTabType));
        dispatch(clearNotifyMeState());
        dispatch(setEraseModalOpen(false));
        dispatch(setIsDeviceDetailOpen(false));
        dispatch(setRemovedDeviceIsLoading(false));
    }

    // Modal - Ring
    const handleSoundModalOpen = () => {
        setOpenSound(true);
    }
    const handleSoundModalClose = () => {
        setOpenSound(false);
    };

    // Modal - Lock
    const handleLockModalOpen = () => {
        setOpenLock(true);
    }
    const handleLockModalClose = () => {
        setOpenLock(false);
    };


    // Modal - Erase
    const handleEraseModalOpen = () => {
        confirmPassword(setEraseModalOpen(true));
    }

    // Modal - Battery
    const handleBatteryModalOpen = () => {
        setOpenBattery(true);
    }
    const handleBatteryModalClose = () => {
        setOpenBattery(false);
    };

    // Modal - Track Location

    const createActionButtonClickHandler = (featureType) => {
        if (featureType === DEVICE_ACTION_FEATURES.BATTERY) {
            return handleBatteryModalOpen;
        } else if (featureType === DEVICE_ACTION_FEATURES.LOCATION) {
            return onRefreshButtonClick;
        } else if (featureType === DEVICE_ACTION_FEATURES.LOCK) {
            return handleLockModalOpen;
        } else if (featureType === DEVICE_ACTION_FEATURES.RING || featureType === DEVICE_ACTION_FEATURES.BLINK || featureType === DEVICE_ACTION_FEATURES.VIBRATE) {
            return handleSoundModalOpen;
        } else if (featureType === DEVICE_ACTION_FEATURES.WIPE) {
            return handleEraseModalOpen;
        } else if (featureType === DEVICE_ACTION_FEATURES.TRACK_LOCATION) {
            return () => {
                dispatch(setModalTrackLocationOpen(true));
            };
        }
    }

    // Modal - Enter PIN to decrypt location 
    const handleLocationPinConfirm = async () => {
        try {

            setIsLocationPinLoading(true);
            // 위치정보 PIN 모달 확인 버튼 로직입니다.
            const pin = locationPinValue;
            // locationPinValue가 6자리의 숫자인지 확인
            if (!/^\d{6}$/.test(pin)) {
                throw new Error('Invalid location PIN value.');
            }

            // offlineKeyPair 필드가 존재하면 추출, 없으면 기본값으로 빈 객체 사용
            const offlineKeyPair = selectedDeviceDetail?.lastSelectedDevice?.offlineKeyPair || {};
            // 필요한 필드 추출
            const { privateKey, iv, userId } = offlineKeyPair;
            const { latitude, longitude, timeCreated } = getEncryptedLocationByDeviceId(selectedDevice?.deviceId, locationListData);
            // decryptLocation api 호출
            const decryptedLocation = await decryptLocation({
                pin,
                iv,
                privateKey,
                guid: userId,
                latitude: latitude,
                longitude: longitude,
            });

            if (!isValidPosition(decryptedLocation)) {
                throw new Error('Invalid location received from API.');
            }

            // 새 객체 생성
            const locationData = [{
                deviceId: selectedDevice?.deviceId,
                geolocations: [
                    {
                        latitude: decryptedLocation?.latitude,
                        longitude: decryptedLocation?.longitude,
                        timeCreated: timeCreated
                    }
                ]
            },];

            dispatch(upsertLocationListData(locationData));
            handleLocationPinClose();
        } catch (error) {
            // 에러 구문으로 던진 경우
            console.error('Error during location PIN confirmation:', error);
            setIsLocationPinError(true);
            setLocationPinValue("");
        } finally {
            setIsLocationPinLoading(false);
        }
    };

    const handleLocationPinClose = () => {
        // 위치정보 PIN 모달 취소 버튼 로직
        setIsLocationPinModalOpen(false);
        setIsLocationPinError(false);
        setLocationPinValue("");
        setIsLocationPinLoading(false);
    };

    const handleLocationPinChange = (e) => {
        const newPinValue = e.target.value;
        // 숫자 6자리로 제한
        if (/^\d{0,6}$/.test(newPinValue)) {
            setLocationPinValue(newPinValue);
            setIsLocationPinError(newPinValue.length !== 6 && newPinValue.length !== 0);
        }
    };

    // Buds select left / right
    const handleBudsLRClick = () => {
        if (selectedUnitForBuds === BUDS_UNITS.RIGHT) {
            dispatch(updateSelectedUnitForBuds({ deviceId: selectedDevice?.deviceId, selectedUnit: BUDS_UNITS.LEFT }));
        } else {
            dispatch(updateSelectedUnitForBuds({ deviceId: selectedDevice?.deviceId, selectedUnit: BUDS_UNITS.RIGHT }));
        }
    }

    const getTimeAndLocationField = () => {
        const findOfflineInfo = getFindOfflineInfo(selectedDeviceDetail);
        const AddressInfo = <Typography variant='addressInfoField'> {lastUpdatedAddress ? lastUpdatedAddress : t('location_unknown')}</Typography>;
        const LastUpdateTime = <Typography variant='lastUpdateField'>{lastUpdatedAddressTime ? formatString(t('last_updated'), lastUpdatedAddressTime) : null}</Typography>;
        if (findOfflineInfo) {
            const { flagUpdateTime, isOn } = findOfflineInfo;
            const recentFoundDate = new Date(currentAddressData?.timeCreated);
            const flagUpdateDate = new Date(parseDateStringToDateObject(flagUpdateTime));
            if (!getIsDeviceOnline(selectedDevice, operationsResult, selectedUnitForBuds) && isOn && (recentFoundDate > flagUpdateDate) && deviceType !== DEVICE_TYPES.SPEN) {
                return (
                    <>
                        <div style={{ fontSize: "5px" }} onClick={openLocationPinModalIfNeeded}>
                            {AddressInfo}
                        </div>
                        <div className='spotted-by-container'>
                            <img src={spotted_by} alt="" />
                            <Typography variant='lastUpdateField'>{t('spotted_by')}</Typography>
                        </div>
                        <Typography variant='lastUpdateField'>{LastUpdateTime}</Typography>
                    </>
                );
            }
        }
        return (
            <>
                <Typography variant='addressInfoField' style={{ textAlign: "center" }}>
                    {AddressInfo}
                </Typography>
                <br />
                <Typography variant='lastUpdateField'>
                    {LastUpdateTime}
                </Typography>
            </>
        );
    }

    // for className
    const getClassName = () => {
        if (isMobileView) {
            return "detail-card-mobile"
        } else {
            return "detail-card"
        }
    }


    return (
        <div className={getClassName()}>
            <Card className={"outer-card"}>
                <div className="closeButton">
                    <Button onClick={() => handleClose()}>
                        <ArrowBackIosNewIcon />
                    </Button>
                </div>

                <div className="scrollable-area">
                    <div className="device-image-area">
                        <div className="device-image-background">
                            <img className="device-image" src={imageSrc} alt=" " />
                            <img className="device-status" src={getIsDeviceOnline(selectedDevice, operationsResult, selectedUnitForBuds) ? badge_dot_online : badge_dot_offline} alt=" " />
                        </div>
                    </div>

                    {/* 공통부분 : device type별로 UI 동일, 내부 지원하는 기능 아이콘 구성만 다름 */}
                    <Card className="inner-card">
                        <Stack sx={{
                            alignItems: "center",
                            margin: "60px 24px 0px",  // 상/좌우/하,
                            height: "100%"
                        }}>
                            <Stack
                                sx={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography sx={{ height: '100%' }} variant='deviceDetailTitle'>
                                    {(deviceType === DEVICE_TYPES.BUDS && selectedUnitForBuds !== BUDS_UNITS.BOTH)
                                        ? (selectedUnitForBuds === BUDS_UNITS.RIGHT ? '[' + t('right') + '] ' : '[' + t('left') + '] ')
                                        : null
                                    }
                                    {displayName}
                                </Typography>
                                {(deviceType === DEVICE_TYPES.BUDS && selectedUnitForBuds !== BUDS_UNITS.BOTH)
                                    ? <img
                                        src={selectedUnitForBuds === BUDS_UNITS.RIGHT ? toggle_buds_R : toggle_buds_L}
                                        alt='L/R'
                                        onClick={() => { handleBudsLRClick() }} />
                                    : null
                                }
                            </Stack>
                            <Box
                                style={{
                                    height: "100%",
                                    textAlign: "center"
                                }}>
                                {getTimeAndLocationField()}
                            </Box>
                            {
                                isLoadingCheckConnection
                                    ? <LoadingCircle></LoadingCircle>
                                    : null
                            }
                        </Stack>

                        <DeviceDetailOperationButtonsGrid
                            selectedDeviceDetail={selectedDeviceDetail}
                            deviceSubType={deviceSubType}
                            deviceType={deviceType}
                            isCurrentUserDevice={isCurrentUserDevice}
                            createActionButtonClickHandler={createActionButtonClickHandler}
                        />
                    </Card>

                    <div>
                        {/* device type별로 다른 액션 */}
                        {renderActionButtonsCard(deviceType, selectedDevice?.deviceId, selectedDeviceDetail)}
                    </div>
                </div>
            </Card>

            {/* modal dialog */}
            <ModalTrackLocation />
            <ModalPlaySound open={openSound} onClose={handleSoundModalClose} passedValue={selectedDeviceDetail} />
            <ModalLock open={openLock} onClose={handleLockModalClose} passedValue={selectedDeviceDetail} />
            <ModalErase deviceId={selectedDevice?.deviceId} />
            <PinConfirmActionCard
                open={isLocationPinModalOpen}
                onClickOk={handleLocationPinConfirm}
                onClickCancel={handleLocationPinClose}
                error={isLocationPinError}
                isLoading={isLocationPinLoading}
                value={locationPinValue}
                title={t('location_encrypted')}
                onChange={handleLocationPinChange}
            />

            <ModalBattery open={openBattery} onClose={handleBatteryModalClose} deviceDetail={selectedDeviceDetail} />

        </div>
    )
}


/**
 * Returns the appropriate action buttons card component based on the given device type.
 *
 * @param {string} deviceType - The type of the device (e.g., 'PHONE', 'TAB', 'WATCH', etc.)
 * @return {JSX.Element|null} The corresponding device component or null if the type is unrecognized.
 */
function renderActionButtonsCard(deviceType, deviceId, selectedDeviceDetail) {
    const components = {
        PHONE: <PhoneActionCard deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />,
        TAB: <TabletActionCard deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />,
        WATCH: <DeviceDetailOperationLists deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />,
        BUDS: <BudsActionCard deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />,
        SPEN: <DeviceDetailOperationLists deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />,
        TAG: <TagActionCard deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />,
        WEARABLE: <DeviceDetailOperationLists deviceId={deviceId} selectedDeviceDetail={selectedDeviceDetail} />
    };
    return components[deviceType] || null;
}

export default DeviceDetailCard
