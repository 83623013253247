import React, { useEffect, useState } from 'react';
import { Card, MenuItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { useTranslation } from "react-i18next";

import CustomBatteryGaugeBuds from './CustomBatteryGaugeBuds';
import { ModalLost, ModalIMEI, ModalRemoveDevice } from '../../modal';
import ModalLostSetting from '../../modal/ModalLostSetting';
import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { DEVICE_REGI_STATUS, OPERATION_TYPES } from '../../../utills/enums/commons';
import NotifyMeItemCard from './NotifyMeItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { getLostModeByDeviceIdAction, setLostModeModalOpen, setLostModeModalSettingOpen } from '../../../slices/lostModeSlice';
import { isFeatureEnabled } from '../../../utills/functions/flagFunctions';
import useSelectedDeviceDetailExtended from '../../../hooks/useSelectedDeviceDetailExtended/useSelectedDeviceDetailExtended';
import { getCountryCode } from '../../../utills/auth';
import useDeviceBattery from '../../../hooks/useDeviceBattery';
import { BUDS_UNITS } from '../../../utills/enums/Devices';
import { getIsDeviceOnline } from '../../../utills/functions';
import { NATIONAL_CODE_TWO_TO_THREE } from '../../../utills/enums/NationalCodes';


const BudsActionCard = ({ deviceId, selectedDeviceDetail }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedDeviceDetailExtended = useSelectedDeviceDetailExtended();

    // global state
    const selectedUnitForBuds = useSelector(state =>
        state.deviceList.budsSelectedUnitList?.find(detail => detail.deviceId === deviceId)?.selectedUnit
    );
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const { operationsResult, startOperation } = useDeviceOperations();
    const batteryLevelForBudsL = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.extra?.left?.battery;
    const batteryLevelForBudsR = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.extra?.right?.battery;
    const isPendingDevice = selectedDeviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED;

    // Modal
    const [openIMEI, setOpenIMEI] = useState(false);
    const [openRemoveDevice, setOpenRemoveDevice] = useState(false);
    const {
        getLostModeState: {
            isLoading,
            data: {
                enabled: lostModeEnabled,
            },
            errors
        },
    } = useSelector((state) => state.lostMode);


    useEffect(() => {
        dispatch(getLostModeByDeviceIdAction(deviceId));
    }, []);


    // Modal - LostMode
    const handleLostModeModalOpen = () => {
        // 'Off' 였을때  설명 dialog를 열어야하고
        // 'On' 이었을때  셋팅 dialog를 열어야 함.
        if (!lostModeEnabled) {
            dispatch(setLostModeModalOpen(true));
        } else {
            dispatch(setLostModeModalSettingOpen(true));
        }
    }
    const handleLostModeModalClose = () => {
        dispatch(setLostModeModalOpen(false));
    };
    const handleLostSettingModalClose = () => {
        dispatch(setLostModeModalSettingOpen(false));
    };

    // Modal - IMEI / MAC
    const handleIMEIModalOpen = () => {
        setOpenIMEI(true);
    }
    const handleIMEIModalClose = () => {
        setOpenIMEI(false);
    };

    // Modal - RemoveDevice
    const handleRemoveDeviceModalOpen = () => {
        setOpenRemoveDevice(true);
    }
    const handleRemoveDeviceModalClose = () => {
        setOpenRemoveDevice(false);
    };

    const isLostModeShown = () => {
        return isFeatureEnabled(selectedDeviceDetailExtended, 'lostMode') && (getCountryCode() === NATIONAL_CODE_TWO_TO_THREE.KR || getCountryCode() === NATIONAL_CODE_TWO_TO_THREE.US) && !isPendingDevice;
    }


    return (
        <div className="action-card">
            {!getIsDeviceOnline(selectedDevice, operationsResult, selectedUnitForBuds) && !isPendingDevice
                ? <NotifyMeItemCard deviceId={deviceId} />
                : null
            }
            {isLostModeShown() ?
                (
                    <Card className="inner-card-lostmode">
                        <ListItemButton onClick={handleLostModeModalOpen}>
                            <ListItemText>{t('lost_mode')}</ListItemText>
                            <ListItemIcon className="inner-card-lostmode-status">
                                {lostModeEnabled ? t('on') : t('off')}
                            </ListItemIcon>
                        </ListItemButton>
                    </Card>
                )
                : null}

            {/* <LostModeItemCard deviceId={deviceId} /> */}

            {
                !isPendingDevice && (
                    <Card className="inner-card">
                        <MenuItem>
                            <CustomBatteryGaugeBuds
                                batteryLevelL={batteryLevelForBudsL}
                                batteryLevelR={batteryLevelForBudsR}>
                            </CustomBatteryGaugeBuds>
                        </MenuItem>
                    </Card>
                )
            }


            <Card className="inner-card-list">
                <MenuItem onClick={handleIMEIModalOpen}>
                    {t('device_info')}
                </MenuItem>
                <hr />
                <MenuItem onClick={handleRemoveDeviceModalOpen}>
                    {t('remove_device')}
                </MenuItem>
            </Card>

            {/* modal dialog */}
            <ModalLost />
            <ModalLostSetting />
            <ModalIMEI open={openIMEI} onClose={handleIMEIModalClose} passedValue={selectedDeviceDetail} />
            <ModalRemoveDevice open={openRemoveDevice} onClose={handleRemoveDeviceModalClose} deviceId={deviceId} />
        </div >
    );
}

export default BudsActionCard;
