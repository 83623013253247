import React from 'react';
import useCachedImage from '../../hooks/useCachedImage';
import { getDefaultDeviceImageByDeviceType } from '../../utills/functions';
import { buds_left_text, buds_right_text } from '../../assets/images/deviceCard';
import { BUDS_UNITS } from '../../utills/enums/Devices';


const DeviceMarkerImage = ({ deviceImageUrl, deviceType, isOnline, unitsForBuds, selectedUnitForBuds, width, height }) => {

    const defaultImage = getDefaultDeviceImageByDeviceType(deviceType);
    const imageSrc = useCachedImage(deviceImageUrl, defaultImage);

    const offsetX = 26;
    const statusColor = isOnline ? '#3EBF06' : '#999999';


    return (

        <svg
            style={{
                position: 'absolute',
                transform: 'translate(-50%, -100%)', // 이미지의 중앙 하단이 위치와 정확히 일치하도록 조정
            }}
            width={width ? width : "52px"}
            height={height ? height : "61.53px"}
            viewBox="0 0 58 66" version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <radialGradient cx="0%" cy="100%" fx="0%" fy="100%" r="91.7063586%" gradientTransform="translate(0.000000,1.000000),scale(0.961538,1.000000),rotate(143.259185),translate(-0.000000,-1.000000)" id="radialGradient-1">
                    <stop stopColor="#000000" stopOpacity="0.6" offset="0%"></stop>
                    <stop stopColor="#000000" stopOpacity="0" offset="100%"></stop>
                </radialGradient>
                <filter x="-11.5%" y="-12.0%" width="123.1%" height="124.0%" filterUnits="objectBoundingBox" id="filter-2">
                    <feGaussianBlur stdDeviation="1" in="SourceGraphic"></feGaussianBlur>
                </filter>
            </defs>
            <g id="icon_plugin/marker_pin_selected" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="img/icon/card_marker_dark-copy-3" transform="translate(2.000000, 2.000000)">
                    <polygon id="Rectangle-Copy-64" fill="url(#radialGradient-1)" filter="url(#filter-2)" points="25 38 51 38 51 58.3661325 25 63"></polygon>
                    <path d="M26,-1 C33.4558438,-1 40.2058439,2.02207806 45.091883,6.90811716 C49.9779219,11.7941562 53,18.5441561 53,26 C53,33.3095149 50.0953603,39.9406032 45.377406,44.8020506 L45.377406,44.8020506 L26.0000836,64.1838465 L6.90292311,45.086686 C1.89677838,39.9324518 -1,33.3036211 -1,26 C-1,18.544156 2.022078,11.794156 6.908117,6.908117 C11.794156,2.022078 18.544156,-1 26,-1 Z" id="Combined-Shape" stroke="#FFFFFF" strokeWidth="2" fill="#387AFF" fillRule="nonzero"></path>
                    <path d="M26,2.20688378 C12.859425,2.20688378 2.20688378,12.859425 2.20688378,26 C2.20688378,39.1405745 12.859425,49.7931162 26,49.7931162 C39.1405745,49.7931162 49.7931162,39.1405745 49.7931162,26 C49.7931162,12.859425 39.1405745,2.20688378 26,2.20688378 Z" id="Path" fill="#FCFCFC" fillRule="nonzero"></path>
                    <image className='marker-device-image' href={imageSrc} x="10" y="9" height="32" width="32" />

                    <rect x={9 + offsetX} y="9" width={6} height={6} fill={statusColor} rx="3px" ry="3px" />

                    {
                        (unitsForBuds && selectedUnitForBuds !== BUDS_UNITS.BOTH)
                            ? <image href={unitsForBuds === BUDS_UNITS.RIGHT ? buds_right_text : buds_left_text}
                                x={9} y={29} />
                            : null
                    }
                </g>
            </g>
        </svg>
    )
};

export default DeviceMarkerImage;
