import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { postAgreementStatus, postSendMessageToParent } from '../api/find/optinApi/optinApi';
import { signOutAndRedirectToHome, setOptinStatus, setOptinLocation, getCountryCode, getChildAccountSupported, getUserName } from '../utills/auth';
import { useMediaQuery, Button } from "@mui/material";
import { TABLET_VIEW_QUERY } from '../utills/constants';
import { useIsMobileView } from "../hooks/useIsMobileView";
import { anonymizeKoreanName } from "../utills/functions";

//  Samsung Find 사용 동의가 필요한 경우,  Onboarding 화면으로 이동.
//  Samsung Find 사용 동의가 필요하지 않은경우,  Main service화면(MAP)으로 바로 이동.

const OnboardingLocation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobilePortraitView } = useIsMobileView();
    const isTabletView = useMediaQuery(TABLET_VIEW_QUERY);

    /**
     * KOR 국가의 아동 계정일 경우
     * 보호자 Password 인증 후, 동의 완료 시 
     * 동의 내역을 문자 메시지로 발송합니다. 
     * (한국향 기능으로 국문 발송만 지원, DID 발행X, 문구는 App과 동일하게 맞춤)
     */
    function notifyParentAfterConsent() {
        const { isChildAccountSupported, isChild } = getChildAccountSupported();
        const countryCode = getCountryCode();
        // Check Condition (Country : KOR, Account : Child's)
        if (isChildAccountSupported && isChild && countryCode === 'KOR') {
            // Send Message to parent
            const userName = getUserName();
            const anonymizedName = anonymizeKoreanName(userName);
            postSendMessageToParent(`[삼성계정] 보호자 개인정보 동의 안내 \n\n ${anonymizedName}님의 Samsung Find에서 아래 항목을 동의했습니다.\n- 위치정보 이용약관\n\n* 본인의 활동이 아닌 경우 1588-3366으로 문의 바랍니다.`);
        }
    }

    async function doUpdateOptin() {
        try {
            // API call 
            // const userId = getUserId();
            const respLocation = await postAgreementStatus(1);  // All '동의'로 업데이트 요청

            if (respLocation !== null) {
                notifyParentAfterConsent();
                setOptinStatus(true);
                setOptinLocation(true);

                // go to <Home>
                navigate('/', { replace: true });
            }
        } catch (error) {
            console.error("error =================== " + error);
        }
    }


    const handleClickContinue = () => {
        console.log("OnboardingLocation - Click Agree");
        doUpdateOptin();
    }

    const handleClickCancel = () => {
        console.log("OnboardingLocation - Click Cancel");
        // Optin은 최종 단계에서 일괄 업데이트 하기로 함 (feature set 참고)
        // Onboarding flow 모두 완료 시점에 모든 동의 값을 서버에 저장함
        // [PM_1123] Onboarding flow 중 이탈 후 재진입 시 처음부터 다시 진행 정책 추가

        // SA login 취소되고 Login 화면으로 돌아감.
        signOutAndRedirectToHome();
    }

    const getOnBoardingClassName = () => {
        if (isMobilePortraitView) {
            return "onboarding-container-mobile";
        } else if (isTabletView) {
            return "onboarding-container-tablet";
        } else {
            return "onboarding-container";
        }
    }


    return (
        <div className={getOnBoardingClassName()} >
            <div className="onboarding-header">
                <p>{t('samsung_find')}</p>
            </div>

            <div className="onboarding-notitle-content">
                <p>{t('sf_explanation_long')}</p>
            </div>

            <div className="onboarding-button">
                <Button variant="contained" onClick={handleClickContinue}>
                    {t('agree')}
                </Button>
                <Button variant="contained" onClick={handleClickCancel}>
                    {t('cancel')}
                </Button>
            </div>
        </div>
    );
}

export default OnboardingLocation;