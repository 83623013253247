import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './TrackLocationBlocksStyles.scss';

import ModalBottomButton from '../../../buttons/ModalBottomButton/ModalBottomButton';
import { track_location_pin } from '../../../../assets/images/trackLocation';
import { t } from 'i18next';
import { ActionBlockTypes } from './TrackLocationBlocksTypes';
import { getTextByDeviceType } from '../../../../utills/functions/branchFunctions';
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from '../../../../utills/enums/Devices';
import { formatNumber } from '../../../../utills/functions/stringPhasers';


export const ContentBlock = ({deviceType}: {deviceType: DEVICE_TYPES_TYPE}) => (
    <div className='modal-track-location-content-container'>
        <Stack alignItems='center'>
            <img className='track-location-icon' src={track_location_pin} alt='Track Location'  />
            <Box marginTop='30px'>
                <Typography textAlign="center">{
                    getTextByDeviceType(
                        deviceType,
                        {
                            [DEVICE_TYPES.PHONE]: formatNumber(t('phone_location_will_update_every_minutes_so_you_can_see_it_moving'), '15'),
                            [DEVICE_TYPES.TAB]: formatNumber(t('tablet_location_will_update_every_minutes_so_you_can_see_it_moving'), '15')
                        }
                    )
                }</Typography>
            </Box>
        </Stack>
    </div>
);

export const ActionBlock = ({ onActionButtonClick }: ActionBlockTypes) => (
    <>
        <ModalBottomButton title={t('start')} onClick={onActionButtonClick} />
    </>
);
