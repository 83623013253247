import React from 'react'
import BaseButton from '../BaseButton'
import { ModalBottomButtonProps } from './ModalBottomButtonTypes'
import './ModalBottomButtonStyles.scss';

const ModalBottomButton = ({ title, isLoading, ...props }: ModalBottomButtonProps) => {
  return (
    <div className='modal-bottom-button-container'>
        <BaseButton
            title={title}
            isLoading={isLoading}
            variant="contained"
            typographyVariant="modalBottomButton"
            text-transform="none"
            loadingColor='white'
            {...props}
        />
    </div>
  )
}

export default ModalBottomButton