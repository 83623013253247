import _ from 'lodash';
import { GetHistoryApiResponse } from "../../api/findToStf/historyApi/historyApiTypes"

export const checkUserHistoryEmpty = (data: GetHistoryApiResponse['items']) => {
    const history: Array<boolean> = [];
    data.forEach(item => {
        if (
            item.simChangeHistory.length !== 0
            || item.locationHistory.length !== 0
            || item.lockHistory.length !== 0
            || item.operationHist.length !== 0
            || item.callHistory.length !== 0)
        {
            history.push(false);
        } else {
            history.push(true);
        }
    });
    return !_.includes(history, false);
}