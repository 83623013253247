
export const MAP_TYPES = {
    GOOGLE: 'MAP_TYPE_GOOGLE',
    KAKAO: 'MAP_TYPE_KAKAO',
    NONE: 'MAP_TYPE_NONE',
} as const;
export type MAP_TYPES_TYPE = typeof MAP_TYPES[keyof typeof MAP_TYPES];

// device operation types 
export const OPERATION_TYPES = {
    CHECK_CONNECTION : 'CHECK_CONNECTION',
    LOCATION : 'LOCATION',
    RING : 'RING',
    LOCK : 'LOCK',
    WIPE : 'WIPE',
    TRACK_LOCATION_START: 'TRACK_LOCATION_START',
    TRACK_LOCATION_STOP: 'TRACK_LOCATION_STOP',
    POWER_SAVE : 'POWER_SAVE',
} as const;
export type OPERATION_TYPES_TYPE = typeof OPERATION_TYPES[keyof typeof OPERATION_TYPES];

export const SCOPE = {
    DOMESTIC: 'domestic',
    INTERNATIONAL: 'international'
} as const;
export type SCOPE_TYPE = typeof SCOPE[keyof typeof SCOPE];

// value of deviceDetail.lastSelectedDevice.mgRegiStatus
export const DEVICE_REGI_STATUS = {
    NOT_REGISTERED: 'N',
    REGISTERED: 'Y'
} as const;
export type DEVICE_REGI_STATUS_TYPE = typeof DEVICE_REGI_STATUS[keyof typeof DEVICE_REGI_STATUS];

