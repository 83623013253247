import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isSafari } from 'react-device-detect';

/* Pages */
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import LoginBefore from "../pages/LoginBefore";
import Onboarding from "../pages/Onboarding";
import OnboardingLocation from "../pages/OnboardingLocation";
import OnboardingGuardian from "../pages/OnboardingGuardian";
import Languages from "../pages/Languages";


/* Theme & RTL */

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import AuthRedirect from "../pages/AuthRedirect";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnAuthenticatedRoute from "./UnauthenticatedRoute";
import Settings from '../pages/Settings';
import ConfirmPWRedirect from '../pages/ConfirmPWRedirect';
import DeviceNotSupported from '../components/view-only/DeviceNotSupported';
import LostMode from '../pages/LostMode';
import Timeout from '../pages/Timeout';


const AppRouter = () => {
    // change theme using i18n
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    document.dir = i18n.dir();  // for current language 'rtl' or 'ltr';  
    console.log("document.dir =========================" + document.dir);

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });
    const cacheLtr = createCache({
        key: 'muiltr',
        stylisPlugins: [],
    });

    if (isSafari) {
        return (<DeviceNotSupported />)
    }


    return (
        <CacheProvider value={document.dir === 'rtl' ? cacheRtl : cacheLtr}>
            <Routes>
                {/* SA 로그인 상태에서만 접근 가능한 페이지 */}
                <Route path="/" element={<AuthenticatedRoute />}>
                    <Route index element={<Home />} />
                    <Route path="/onboarding" element={<Onboarding />} />
                    <Route path="/onboardingLocation" element={<OnboardingLocation />} />
                    <Route path="/onboardingGuardian" element={<OnboardingGuardian />} />
                    <Route path="/settings" element={<Settings handleClose={() => navigate('/')} />} />
                </Route>

                {/* SA 로그인 안 된 상태에서만 접근 가능한 페이지 */}
                <Route path="/" element={<UnAuthenticatedRoute />}>
                <Route path="/login" element={<LoginBefore />} />
                </Route>

                {/* SA 로그인 여부와 상관없이 접근 가능한 페이지 */}
                <Route path="*" element={<NotFound />} />
                <Route path="/languages" element={<Languages />} />
                <Route path="/auth" element={<AuthRedirect />} />
                <Route path="/confirm-pw" element={<ConfirmPWRedirect />} />
                <Route path="/lost-mode" element={<LostMode />} />
                <Route path="/timeout" element={<Timeout />} />
            </Routes>
        </CacheProvider>
    );
};

export default AppRouter;
