import { createSlice } from '@reduxjs/toolkit';
import { OPERATIONS, OperationPayloadType, OperationResponsePayloadType, OperationsStateType } from './operationsSliceTypes';
import { hasLoadingProperty } from './operationSliceTypeGuard';

const initialState: OperationsStateType = {
    operationsResult: {},
}

export const operationsSlice = createSlice({
    name: OPERATIONS,
    initialState,
    reducers: {
        setOperationStart: (state, action: OperationPayloadType) => {
            const { deviceId, operationType } = action.payload;
            if (!deviceId || !operationType) {
                return;
            }

            if (!state.operationsResult[deviceId]) {
                state.operationsResult[deviceId] = {};
            }

            if (!state.operationsResult[deviceId][operationType]) {
                state.operationsResult[deviceId][operationType] = { // initialize
                    isLoading: false,
                    isTimeout: false,
                    isFailed: false,
                };
            }

            const operationProperty = state.operationsResult[deviceId][operationType];
            if (hasLoadingProperty(operationProperty)) {
                operationProperty.isLoading = true;
                operationProperty.isTimeout = false;
                operationProperty.isFailed = false;
            }
        },
        setOperationResponse: (state, action: OperationResponsePayloadType) => {
            const { deviceId, operationType, data, isFailed, isTimeout } = action.payload;
            if (!deviceId || !operationType) {
                return;
            }

            if (!state.operationsResult[deviceId]) {
                state.operationsResult[deviceId] = {};
            }

            if (!state.operationsResult[deviceId][operationType]) {
                state.operationsResult[deviceId][operationType] = {};
            }

            const operationProperty = state.operationsResult[deviceId][operationType];
            if (data === null && hasLoadingProperty(operationProperty)) {
                operationProperty.isLoading = false;
                operationProperty.isTimeout = isTimeout || operationProperty.isTimeout;
                operationProperty.isFailed = isFailed || operationProperty.isFailed;

            } else {
                state.operationsResult[deviceId][operationType] = {
                    isLoading: false,
                    data: data,
                    isFailed,
                    isTimeout,
                };
            }
        },
        cleanLockOperationByDevice: (state, action: OperationPayloadType) => {
            const { deviceId, operationType } = action.payload;
            if (!deviceId || !operationType) {
                return;
            }
            state.operationsResult[deviceId][operationType] = undefined;
        }
    }
});


export const {
    setOperationStart,
    setOperationResponse,
    cleanLockOperationByDevice
} = operationsSlice.actions;

export default operationsSlice.reducer;