import { styled, Box, IconButton, DialogTitle, DialogContent, DialogActions, Dialog } from "@mui/material";

export const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: "26px !important", // for reset
        minWidth: "300px !important",
        maxWidth: "1000px !important",
        marginLeft: "0 !important",
        marginRight: "0 !important",
    }
});

export const DialogContainer = styled(Box)(({theme}) => ({
    padding: "24px",
    [theme.breakpoints.up('xxs')]: {
        width: "300px",
    },
    [theme.breakpoints.up('xs')]: {
        width: "330px",
    },
    [theme.breakpoints.up('sm')]: {
        width: "440px",
    },
    "& a": {
        textDecoration: "underline",
        color: "#376FDE"
    }
}));

export const StyledIconButton = styled(IconButton)({
    position: "absolute",
    insetInlineEnd: "12px", // for ltr, rtl
    top: "14px",
    color: "gray"
});

export const StyledDialogTitle = styled(DialogTitle)({
    padding: "0 0 24px 0", // for reset
});

export const StyledDialogContent = styled(DialogContent)({
    padding: "0px" // for reset
})

export const ContentBlockContainer = styled(Box)({
    height: "100%"
});

export const StyledDialogActions = styled(DialogActions)({
    justifyContent: "center",
    padding: "0px" // for reset
})