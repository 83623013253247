import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Card, MenuItem } from '@mui/material';

import { DeviceDetailOperationListsPropsType } from "./DeviceDetailOperationListsTypes";
import useDeviceOperations from '../../../../hooks/useDeviceOperations';
import { DEVICE_REGI_STATUS, OPERATION_TYPES } from '../../../../utills/enums/commons';
import { DEVICE_TYPES } from "../../../../utills/enums/Devices";
import { DM_ERROR_STATUS_CODE_SUCCESS } from '../../../../utills/enums/DMErrorCodes';
import { RootStateType } from '../../../../slices/types';

import NotifyMeItemCard from '../../actions/NotifyMeItemCard';
import CustomBatteryGauge from '../../actions/CustomBatteryGauge';
import { ModalIMEI, ModalRemoveDevice } from '../../../modal';
import { MODAL_LOCK_ACTION } from '../../../modal/ModalLock/ModalLockTypes';


const DeviceDetailOperationLists = ({
    deviceId,
    selectedDeviceDetail
}: DeviceDetailOperationListsPropsType) => {

    const { t } = useTranslation();

    // global status
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceType = selectedDevice?.type;

    // Battery
    const { operationsResult, startOperation } = useDeviceOperations();
    const batteryLevel = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.battery;
    const isPendingDevice = selectedDeviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED;

    // Remove device (with Lock Status)
    const modalLockState = useSelector((state: RootStateType) => state.modalLock);
    const mqttConnectionData = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data;
    const mqttLockData = operationsResult[deviceId]?.[OPERATION_TYPES.LOCK]?.data;
    const [dimmEffect, setDimmEffect] = useState(true);

    useEffect(() => {
        if (mqttLockData === undefined) {
            if (mqttConnectionData?.oprnStsCd === "1900" && mqttConnectionData?.oprnResultCode === "1451") {
                setDimmEffect(false);
            } else {
                if (mqttConnectionData?.extra?.lockStatus?.fmmLock === false) {
                    setDimmEffect(false);
                } else {
                    setDimmEffect(true);
                }
            }
        }
        else {
            if (mqttLockData.oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS) {
                setDimmEffect(true);
            } else {
                setDimmEffect(false);
            }
        }
    }, [mqttConnectionData, mqttLockData]);

    useEffect(() => {
        if ((modalLockState?.status === MODAL_LOCK_ACTION.loading) || (modalLockState?.status === MODAL_LOCK_ACTION.waiting)) {
            setDimmEffect(true);
        } else if ((modalLockState?.status === MODAL_LOCK_ACTION.retry)) {
            setDimmEffect(false);
        }
    }, [modalLockState]);

    // Modal
    const [openIMEI, setOpenIMEI] = useState(false);
    const [openRemoveDevice, setOpenRemoveDevice] = useState(false);
    // Modal - IMEI / MAC
    const handleIMEIModalOpen = () => {
        setOpenIMEI(true);
    }
    const handleIMEIModalClose = () => {
        setOpenIMEI(false);
    };
    // Modal - RemoveDevice
    const handleRemoveDeviceModalOpen = () => {
        setOpenRemoveDevice(true);
    }
    const handleRemoveDeviceModalClose = () => {
        setOpenRemoveDevice(false);
    };
    return (
        <div className="action-card">
            {batteryLevel === undefined && !isPendingDevice
                ? <NotifyMeItemCard deviceId={deviceId} />
                : null
            }
            {!isPendingDevice && (
                <Card className="inner-card">
                    <MenuItem>
                        <CustomBatteryGauge batteryLevel={batteryLevel}></CustomBatteryGauge>
                    </MenuItem>
                </Card>
            )}
            <Card className="inner-card-list">
                <MenuItem onClick={handleIMEIModalOpen}>
                    {t('device_info')}
                </MenuItem>
                <hr />
                {deviceType === DEVICE_TYPES.WATCH ?
                    <MenuItem disabled={dimmEffect} onClick={handleRemoveDeviceModalOpen}>
                        {t('remove_device')}
                    </MenuItem>
                    :
                    <MenuItem onClick={handleRemoveDeviceModalOpen}>
                        {t('remove_device')}
                    </MenuItem>
                }
            </Card>

            {/* modal dialog */}
            <ModalIMEI open={openIMEI} onClose={handleIMEIModalClose} passedValue={selectedDeviceDetail} />
            <ModalRemoveDevice open={openRemoveDevice} onClose={handleRemoveDeviceModalClose} deviceId={deviceId} />
        </div >
    );
};

export default DeviceDetailOperationLists;