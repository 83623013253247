export const DEVICE_SUB_TYPES = {
    FIT: 'FIT',     // DEVICE_TYPES = WATCH
    RING: 'RING'    // DEVICE_TYPES = WEARABLE
} as const;
export type DEVICE_SUB_TYPES_TYPE = (typeof DEVICE_SUB_TYPES)[keyof typeof DEVICE_SUB_TYPES];

export const DEVICE_TYPES = {
    BUDS: 'BUDS',
    WATCH: 'WATCH',
    PHONE: 'PHONE',
    TAB: 'TAB',
    SPEN: 'SPEN',
    PC: 'PC',
    TAG: 'TAG',
    WEARABLE: 'WEARABLE'
} as const;
export type DEVICE_TYPES_TYPE = (typeof DEVICE_TYPES)[keyof typeof DEVICE_TYPES];

export const TAB_TYPES = {
    ITEMS: 'ITEM',
    DEVICES: 'DEVICES',
} as const;
export type TAB_TYPES_TYPE = (typeof TAB_TYPES)[keyof typeof TAB_TYPES];

export const DEVICE_OWNER_TYPES = {
    MY: 'my',
    FAMILY: 'family'
} as const;
export type DEVICE_OWNER_TYPES_TYPE = (typeof DEVICE_OWNER_TYPES)[keyof typeof DEVICE_OWNER_TYPES];

export const ALLOW_THIS_DEVICE_TO_BE_FOUND_OPTION = {
    DISABLED: 'DISABLED',
    ALLOW: 'NORMAL'
}
export type ALLOW_THIS_PHONE_OPTION_TYPE = (typeof ALLOW_THIS_DEVICE_TO_BE_FOUND_OPTION)[keyof typeof ALLOW_THIS_DEVICE_TO_BE_FOUND_OPTION];

export const DEVICE_ACTION_FEATURES = {
    RING: 'ring',
    BLINK: 'led',
    VIBRATE: 'vibration',
    LOCATION: 'location',
    LOCK: 'lock',
    WIPE: 'wipe',
    BATTERY: 'battery',
    TRACK_LOCATION: 'trackLoc'
}

export const BUDS_UNITS = {
    LEFT: 'L',
    RIGHT: 'R',
    BOTH: 'BOTH'
}
export type DEVICE_ACTION_FEATURES_TYPE = (typeof DEVICE_ACTION_FEATURES)[keyof typeof DEVICE_ACTION_FEATURES];