import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import './ModalLostSettingStyles.scss';

import BaseModal from '../BaseModal';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import TitleOverContentLayout from '../../layouts/TitleOverContentLayout';
import PlainTextField from '../../text-fields/PlainTextField';

import { isEmailForm, isNumber } from '../../../utills/functions/validators';
import { useDispatch, useSelector } from 'react-redux';
import { postLostModeAction, putTagLostModeAction, setLostModeModalSettingOpen } from '../../../slices/lostModeSlice';
import { RootStateType } from '../../../slices/types';
import { DEVICE_TYPES } from '../../../utills/enums/Devices';
import PlainSelect from '../../selects/PlainSelect';
import { Box } from '@mui/material';


const ModalLostSetting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const lostModeSettingModalOpen = useSelector((state: RootStateType) => state.lostMode.lostModeSettingModalOpen);
    const deviceDetail = useSelector((state: RootStateType) => state.deviceDetail);
    const {
        getLostModeState: {
            data: {
                enabled: lostModeEnabled,
                phone_number: phoneNumber,
                email,
                message
            },
        },
        getTagLostModeState: {
            data: {
                enabled: tagLostmodeEnabled,
                message: tagLostmodeMessage,
                email: tagLostmodeEmail,
                phoneNumber: tagLostmodePhoneNumber,
            }
        }
    } = useSelector((state: RootStateType) => state.lostMode);
    const getTagDataIfTag = (tagData: any, otherData:any) => selectedDevice.type === DEVICE_TYPES.TAG ? tagData : otherData;


    // local states
    const [disabled, setButtonDisabled] = useState(true);
    const [enteredEmail, setEnteredEmail] = useState("");
    const [enteredContact, setEnteredContact] = useState("");
    const [enteredMessage, setEnteredMessage] = useState("");


    useEffect(() => {
        setEnteredEmail(getTagDataIfTag(tagLostmodeEmail, email));
        setEnteredContact(getTagDataIfTag(tagLostmodePhoneNumber, phoneNumber));
        setEnteredMessage(getTagDataIfTag(tagLostmodeMessage || 'DREAM_SACP_BODY_THIS_SMARTTAG_HAS_BEEN_LOST', message || t('lost_buds')));
    }, [email, message, phoneNumber, tagLostmodeEmail, tagLostmodeMessage, tagLostmodePhoneNumber])

    useEffect(() => {
        if (!enteredEmail) {
            setButtonDisabled(true);
        }
        const enabled = getTagDataIfTag(tagLostmodeEnabled, lostModeEnabled);
        if (!enabled && lostModeSettingModalOpen) {
            setEnteredEmail("");    
            setEnteredContact("");
            setEnteredMessage(getTagDataIfTag(tagLostmodeMessage || 'DREAM_SACP_BODY_THIS_SMARTTAG_HAS_BEEN_LOST', message || t('lost_buds')));
        }
    }, [lostModeSettingModalOpen])

    const handleCancel = () => {
        dispatch(setLostModeModalSettingOpen(false));
    }

    // Button - TurnOn
    const handleTurnOn = () => {
        const { deviceId, type, modelInfo } = selectedDevice;

        const displayName = modelInfo?.displayName;

        if (!displayName) {
            return;
        }
        const requestObject = {
            deviceId,
            enabled: 1 as 0 | 1,
            phone_number: enteredContact,
            email: enteredEmail,
            message: enteredMessage,
            device_name: displayName,
        }
        if (type === DEVICE_TYPES.BUDS) {
            Object.assign(requestObject, {
                units: deviceDetail.budsDirection
            })
        }
        // LostMode 서버에 등록
        if (type === DEVICE_TYPES.TAG) {
            Object.assign(requestObject, {
                phoneNumber: enteredContact
            })
            dispatch(putTagLostModeAction(
                requestObject
            ));
        } else {
            dispatch(postLostModeAction(
                requestObject
            ));
        }
        dispatch(setLostModeModalSettingOpen(false));
    }

    const handleTurnOff = () => {
        const { deviceId, type, modelInfo } = selectedDevice;

        const displayName = modelInfo?.displayName;

        if (!displayName) {
            return;
        }
        const requestObject = {
            deviceId,
            enabled: 0 as 0 | 1,
        }
        if (type === DEVICE_TYPES.BUDS) {
            Object.assign(requestObject, {
                units: deviceDetail.budsDirection
            })
        }

        if (type === DEVICE_TYPES.TAG) {
            dispatch(putTagLostModeAction(
                requestObject
            ));
        } else {
            dispatch(postLostModeAction(
                requestObject
            ));
        }
        // LostMode 서버에 등록
        dispatch(setLostModeModalSettingOpen(false));
    }


    // input Valid Email
    const handleValidEmail = (value: string) => {
        setEnteredEmail(value);
        if (isEmailForm(value)) {
            setButtonDisabled(false);  // active
        } else {
            setButtonDisabled(true);  // dim
        }
    }
    const ContentBlock = (
        <div className="modal-lock-setting-container">
            <div className='step-one-container'>
                <TitleOverContentLayout
                    title={t('email_required')} style={'bold'} >
                    <PlainTextField
                        disabled={Boolean(getTagDataIfTag(tagLostmodeEnabled, lostModeEnabled))}
                        rows={1}
                        inputProps={{ maxLength: 254 }}
                        placeholder={t("email_address")}
                        required
                        onChange={(e) => handleValidEmail(e.target.value)}
                        value={enteredEmail}
                    />
                </TitleOverContentLayout>
            </div>

            <div className='step-two-container'>
                <TitleOverContentLayout
                    title={t('contact_optional')} style={'bold'}
                    descript={t('enter_emergency_contact')}>
                    <PlainTextField
                        disabled={Boolean(getTagDataIfTag(tagLostmodeEnabled, lostModeEnabled))}
                        rows={1}
                        inputProps={{ maxLength: 20 }}
                        placeholder={t("enter_phone_number")}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (!isNumber(value)) {
                                return;
                            }
                            setEnteredContact(value);
                        }}
                        value={enteredContact}
                    />
                    {/* nor including suffixes, such as PBX extension numbers) be at most 15 characters. */}
                </TitleOverContentLayout>
            </div>

            <div className='step-three-container'>
                <TitleOverContentLayout
                    title={t('msg')}
                    style={'bold'}
                    descript={getTagDataIfTag(t('msg_for_find'), "")}
                >
                    {
                        selectedDevice?.type === DEVICE_TYPES.TAG
                            ? (
                                <Box marginTop="10px">
                                    <PlainSelect
                                        disabled={Boolean(getTagDataIfTag(tagLostmodeEnabled, lostModeEnabled))}
                                        fullWidth
                                        itemList={[
                                            { title: t('this_smart_tag_lost'), value: 'DREAM_SACP_BODY_THIS_SMARTTAG_HAS_BEEN_LOST' },
                                            { title: t('this_is_lost_item_important_to_me'), value: 'DREAM_SACP_BODY_THIS_IS_A_LOST_ITEM_THATS_IMPORTANT_TO_ME_SO_PLEASE_HELP_ME_GET_IT_BACK' },
                                            { title: t('tracking_this_item_and_trying_to_find_it'), value: 'DREAM_SACP_BODY_IM_TRACKING_THIS_ITEM_AND_TRYING_TO_FIND_IT_SO_PLEASE_CONTACT_ME' },
                                            { title: t('please_contact_me_and_help_my_lost_pet'), value: 'DREAM_SACP_BODY_PLEASE_CONTACT_ME_AND_HELP_MY_LOST_PET_COME_BACK_HOME' },
                                        ]}
                                        onChange={(e) => { setEnteredMessage(e.target.value) }}
                                        value={enteredMessage}
                                    />
                                </Box>
                            )
                            : (
                                <PlainTextField
                                    disabled
                                    onChange={(e) => setEnteredMessage(e.target.value)}
                                    value={enteredMessage}
                                />
                            )
                    }
                </TitleOverContentLayout>
            </div>
        </div>
    );

    const ActionBlock = (
        <>
            {
                getTagDataIfTag(tagLostmodeEnabled, lostModeEnabled)
                    ? (<ModalBottomButton title={t('turn_off')} onClick={handleTurnOff} />)
                    : (<ModalBottomButton title={t('turn_on')} onClick={handleTurnOn} disabled={disabled} />)}
        </>
    );

    return (
        <>
            <BaseModal
                open={lostModeSettingModalOpen}
                onClose={handleCancel}
                title={t('lost_mode')}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
        </>
    );
};

export default ModalLostSetting