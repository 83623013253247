import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from "../enums/Devices";

type TextBundleType = {
    phone: string,
    tablet: string,
    watch: string
};

export const getTextByDeviceType3 = (deviceType: string, { phone, tablet, watch }: TextBundleType) => {
    if (deviceType === DEVICE_TYPES.PHONE) {
        return phone;
    } else if (deviceType === DEVICE_TYPES.TAB) {
        return tablet;
    } else {
        return watch;
    }
}

export const getTextByDeviceType = (deviceType: DEVICE_TYPES_TYPE, deviceTypeToText: { [deviceType in DEVICE_TYPES_TYPE]?: string }): string | undefined => {
    return deviceTypeToText[deviceType];
}

export const getDeviceIdFromState = (stateValue: Record<string, string> | string) => {
    if (typeof stateValue === 'object') {
        return stateValue.deviceId;
    }
    return stateValue;
};