import React from 'react';
import { Stack, Typography } from '@mui/material';
import './TrackLocationBlocksStyles.scss';

import ModalBottomButton from '../../../buttons/ModalBottomButton/ModalBottomButton';
import { track_location_pin } from '../../../../assets/images/trackLocation';
import { t } from 'i18next';
import { ActionBlockTypes } from './TrackLocationBlocksTypes';
import { getTextByDeviceType } from '../../../../utills/functions/branchFunctions';
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from '../../../../utills/enums/Devices';
import { formatNumber } from '../../../../utills/functions/stringPhasers';
import LocationTracker from '../LocationTracker';


export const ContentBlock = ({ deviceType }: { deviceType: DEVICE_TYPES_TYPE }) => (
    <div className='modal-track-location-container'>
        <Stack alignItems='center'>
            <img className='track-location-icon' src={track_location_pin} alt='Track Location' />
            <Typography marginTop='16px' textAlign='center'>
                {t('tracking_stopped')}
            </Typography>
            <Typography marginTop='24px'>
                {getTextByDeviceType(
                    deviceType,
                    {
                        [DEVICE_TYPES.PHONE]: formatNumber(t('phone_location_will_update_every_minutes'), '15'),
                        [DEVICE_TYPES.TAB]: formatNumber(t('tablet_location_will_update_every_minutes'), '15')
                    }
                )}
            </Typography>
            <div className="location-tracker-container">
                <LocationTracker />
            </div>
        </Stack>
    </div>
);

export const ActionBlock = ({ onActionButtonClick }: ActionBlockTypes) => (
    <>
        <ModalBottomButton title={t('restart')} onClick={onActionButtonClick} />
    </>
);
