import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import './ModalNotifyMeStyles.scss';

import { Stack, TextField, Typography } from '@mui/material';
import { asyncPutNotifyMe, asyncTagPutNotifyMe } from '../../../slices/notifyMeSlice';
import { ModalNotifyMeProps } from './ModalNotifyMeType';
import { DEVICE_TYPES } from "../../../utills/enums/Devices";
import { AppThunkDispatch, RootStateType } from '../../../slices/types';
import { isEmailForm } from '../../../utills/functions/validators';
import BaseModal from '../BaseModal';
import ActionButton from '../../buttons/ActionButton';
import useBudsDirection from '../../../hooks/useBudsDirection/useBudsDirection';


const ModalNotifyMe = ({ open, onClose, title, contentText }: ModalNotifyMeProps) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppThunkDispatch>();
    // global states
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const {
        selectedUnitForBuds,
        isBoth,
    } = useBudsDirection();

    // local states
    const [disabled, setButtonDisabled] = useState(true);
    const [enteredEmail, setEnteredEmail] = useState("");

    const handleCancel = () => {
        onClose(false);
    }

    const handleTurnOn = () => {
        const { deviceId, modelInfo, type } = selectedDevice;
        const item = {
                enabled: true,
                userId: selectedDevice?.userId,
                email: enteredEmail,
                language: i18n.language.split('-')[0],
                displayName: modelInfo?.displayName ?? DEVICE_TYPES.TAG
        };
        
        if (type === DEVICE_TYPES.BUDS && !isBoth) {
            Object.assign(item, {units: selectedUnitForBuds});
        };
        if (type === DEVICE_TYPES.TAG) {
           dispatch(asyncTagPutNotifyMe({
               deviceId: deviceId,
               searchingStatus: 'searching',
               email: item.email,
               language: item.language,
               displayName: item.displayName
           }));
         } else {
            dispatch(asyncPutNotifyMe({
                deviceId: deviceId,
                item 
            }));
       }
       onClose(true);
    }

    // Dialog 열릴때 초기화용
    useEffect(() => {
        if (open === true) {
            console.log("ModalNotifyMe dialog open =========================");
            setButtonDisabled(true);  // dim
            setEnteredEmail("");
        }
    }, [open]);


    // input Valid Email
    const handleValidEmail = (value: string) => {
        setEnteredEmail(value);
        if (isEmailForm(value)) {
            setButtonDisabled(false);  // active
        } else {
            setButtonDisabled(true);  // dim
        }
    }

    const ContentBlock = (
        <Stack>
            <Typography sx={{height: "100%"}} variant='modalParagraph'>
                {contentText}
            </Typography>
            <div className='notify-me-text-field'>
                <TextField fullWidth variant="standard"
                    inputProps={{ maxLength: 255 }}
                    placeholder={t("email_address")}
                    required
                    onChange={(e) => handleValidEmail(e.target.value)}
                    value={enteredEmail}
                />
            </div>
        </Stack>
    );

    const ActionBlock = (
        <div className='notify-me-button-container'>
            <ActionButton
                title={t('turn_on')}
                onClick={handleTurnOn}
                disabled={disabled}
            />
        </div>
    );

    return (
        <>
            <BaseModal
                title={title}
                open={open}
                onClose={handleCancel}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
        </>
    );
};

export default ModalNotifyMe
