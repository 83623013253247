import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { signOutAndRedirectToHome, getCountryCode, getCountryCode2, getSelectedLanguage, getChildAccountSupported } from '../utills/auth';
import { confirmPassword } from '../utills/functions/pwConfirmFunctions';
import { isEUCountry } from "../utills/functions";
import { useMediaQuery, Button } from "@mui/material";
import { MOBILE_PORTRAIT_VIEW_QUERY, TABLET_VIEW_QUERY } from '../utills/constants';
import { useSelector } from "react-redux";
import { RootStateType } from "../slices/types";
import { setIsGuardianAgreed } from "../slices/authSlice/authSlice";

//  Samsung Find 사용 동의가 필요한 경우,  Onboarding 화면으로 이동.
//  Samsung Find 사용 동의가 필요하지 않은경우,  Main service화면(MAP)으로 바로 이동.

const Onboarding = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobileView = useMediaQuery(MOBILE_PORTRAIT_VIEW_QUERY);
    const isTabletView = useMediaQuery(TABLET_VIEW_QUERY);

    const isGuardianAgreed = useSelector((state: RootStateType) => state.auth.isGuardianAgreed);

    // local states
    const [linkText, setLinkText] = useState(t('agree_by_continuing'));
    const [buttonText, setButtonText] = useState(t('continue'));
    const [linkUrl, setLinkUrl] = useState("https://account.samsung.com/membership/etc/specialTC.do?fileName=locationinfortnc.html"); // Kor


    // Samsung Account UserInfo's countrycode 에 따른                                 
    useEffect(() => {
        // Button Text
        if (getCountryCode() === 'KOR') {
            setLinkText(t('agree_to_continue'));
            setButtonText(t('agree'));
        }
        else {
            setLinkText(t('agree_by_continuing'));
            setButtonText(t('continue'));
        }

        // Link URL
        // SA 한국계정 성인 / 아동
        const country = getCountryCode();
        const { isChildAccountSupported, isChild } = getChildAccountSupported();

        if (country === 'KOR') {
            setLinkUrl("https://account.samsung.com/membership/etc/specialTC.do?fileName=locationinfortnc.html");
        }
        else {
            // 'use of location'고지문을 어떤 언어로 보여줄 것인가? (EU게정이지만 사용하는 언어는 다 다를테니까)
            // ToDo:  서버에서 지원되는 언어 리스트 : 한국어 영어 https://notice.samsungfind.samsung.com/use-of-location/list/default

            const lang = getSelectedLanguage();
            const country2 = getCountryCode2();

            // SA EU계정 아동 (Supported 국가에 한국과 미국이 포함되어 있지만 앞 조건문에서 처리됨)
            if (isChildAccountSupported && isChild) {
                setLinkUrl("https://notice.samsungfind.samsung.com/use-of-location/content/en-US/child.html");
            }
            // SA EU계정 성인
            // SA 미국계정 성인 / 아동
            // Global 성인/아동
            else {
                // setLinkUrl("https://notice.samsungfind.samsung.com/use-of-location/content/" + `${lang}-${country2}` + "/adult.html");  // 서버에 아직 언어별로 없음...
                setLinkUrl("https://notice.samsungfind.samsung.com/use-of-location/content/en-US/adult.html");
            }
        }
    }, []);  // 처음만 실행

    useEffect(() => {
        if (isGuardianAgreed) {
            navigate('/onboardingGuardian', { replace: true });
        }
    }, [isGuardianAgreed]);

    const handleClickContinue = () => {
        console.log("Onboarding - Click Continue");
        // ** Optin은 최종 단계에서 일괄 업데이트 하기로 함 (feature set 참고)

        const { isChildAccountSupported, isChild } = getChildAccountSupported();
        const countryCode = getCountryCode();

        if (isChildAccountSupported && isChild && countryCode !== 'USA') {
            confirmPassword(setIsGuardianAgreed(true), true);
        }
        else {
            // backward를 대비해서 replace로 이동하자.
            navigate('/onboardingLocation', { replace: true });
        }
    }

    const handleClickCancel = () => {
        console.log("Onboarding - Click Cancel");
        // Optin은 최종 단계에서 일괄 업데이트 하기로 함 (feature set 참고)
        // Onboarding flow 모두 완료 시점에 모든 동의 값을 서버에 저장함
        // [PM_1123] Onboarding flow 중 이탈 후 재진입 시 처음부터 다시 진행 정책 추가

        // SA login 취소되고 Login 화면으로 돌아감.
        signOutAndRedirectToHome();
    }


    return (
        <div className={isMobileView ? "onboarding-container-mobile" : (isTabletView ? "onboarding-container-tablet" : "onboarding-container")} >
            <div className="onboarding-header">
                <p>{t('samsung_find')}</p>
            </div>

            <div className="onboarding-content">
                <p> <b>{t('find_lost_device')}</b> </p>
                <p> {t('locate_lost_device_without_network')} </p>

                <p> <b>{t('share_your_location')}</b> </p>
                <p> {t('allow_people')} </p>
            </div>

            <div className="onboarding-urllink">
                {linkText.split("%s").map((item, idx) => {
                    return (
                        (idx === 0) ?
                            <span key={idx}>
                                {item}
                                <a target="_blank" rel="noreferrer noopener" href={linkUrl}>
                                    {t('use_location_info')}
                                </a>
                            </span>
                            : <span key={idx}> {item} </span>
                    )
                })}
            </div>

            <div className="onboarding-button">
                <Button variant="contained" onClick={handleClickContinue}>
                    {buttonText}
                </Button>
                <Button variant="contained" onClick={handleClickCancel}>
                    {t('cancel')}
                </Button>
            </div>
        </div >
    );
}

export default Onboarding;