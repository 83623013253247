import React from 'react';
import { TextField } from '@mui/material';
import {
  TwoSelectsOverTextFieldProps,
} from './TwoSelectsOverTextFieldTypes';
import './TwoSelectsOverTextFieldStyles.scss';

import PlainSelect from '../../selects/PlainSelect';

const TwoSelectsOverTextField = ({
  firstSelectItemBundle: {
    onChange: firstSelectOnChange,
    itemList: firstSelectItemList,
    value: firstSelectItemValue,
    hasExtentionNumber: firstHasExtentionNumber,
    isHidden: firstIsHidden
  },
  secondSelectItemBundle: {
    onChange: secondSelectOnChange,
    itemList: secondSelectItemList,
    value: secondSelectItemValue,
    hasExtentionNumber: secondHasExtentionNumber,
    isHidden: secondIsHidden,
  },
  textFieldBundle: {
    onChange: textFieldOnChange,
    ...props
  } ,
}: TwoSelectsOverTextFieldProps) => {
  return (
    <div className='two-selects-over-text-field-container'>
      <div className='two-selects-container'>
        <div className='first-select-container'>
          <div style={{ visibility: firstIsHidden ? 'hidden' : 'inherit' }}>
            <PlainSelect
              value={firstSelectItemValue}
              itemList={firstSelectItemList}
              onChange={firstSelectOnChange}
              hasExtentionNumber={firstHasExtentionNumber}
              disableUnderline
            />
          </div>
       </div>
        <div className='second-select-container'>
          <div style={{ visibility: secondIsHidden ? 'hidden' : 'inherit' }}>
            <PlainSelect
              value={secondSelectItemValue}
              itemList={secondSelectItemList}
              onChange={secondSelectOnChange}
              hasExtentionNumber={secondHasExtentionNumber}
              disableUnderline
            />
          </div>
        </div>
      </div>
      <div className='text-field-container'>
        <TextField
          {...props}
          fullWidth
          InputProps={{ disableUnderline: true }}
          variant="standard"
          onChange={textFieldOnChange}
        />
      </div>
    </div>
  )
}

export default TwoSelectsOverTextField;