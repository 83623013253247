
import axios from 'axios';
import { GOOGLE_MAPS_API_KEY } from '../components/maps/MapWithGoogle/MapWithGoogle';
import * as turf from '@turf/turf';
import { BUDS_UNITS, DEVICE_TYPES, DEVICE_SUB_TYPES } from "./enums/Devices";
import { phone_circle, tablet_circle, watch_circle, tag_circle, buds_pair_circle, spen_pro_circle, spen_pro, laptop_circle, phone, tablet, watch, tag, buds_pair, laptop, smart_ring_circle, smart_ring, band_circle, band } from "../assets/images/deviceCard";
import dayjs from 'dayjs';
import { getCountryCode2, getSelectedLanguage } from './auth';
import { extractLangCodeFromSelectedLang } from './functions/stringPhasers';
import { isFmmDeviceIdFormat } from './functions/validators';
import { OPERATION_TYPES } from './enums/commons';
import { createAndSendLog } from '../api/find/loggerApi';
import { LogLevel } from '../api/find/loggerApi/logTypes';

/**
 * DeviceId를 이용해서 deviceLocationList 데이터에서 
 * lat lng 값을 찾아 postion 객체로 반환합니다. 
 * 
 * // 사용 예시
 * const position = getPositionByDeviceId(deviceId, locationListData);
 * @param {*} deviceId 
 * @param {*} locationList 
 * @returns 
 */
export const getDevicePositionByDeviceId = (deviceId, locationList) => {
    if (!locationList || !Array.isArray(locationList) || locationList.length <= 0) {
        console.log("Location list information does not exist.")
        return null;
    }
    const matchingLocation = locationList.find(
        location => location.deviceId === deviceId
    );
    if (!matchingLocation || !matchingLocation.geolocations || matchingLocation.geolocations.length === 0) {
        return null;
    }
    const { latitude, longitude } = matchingLocation.geolocations[0];
    return { // 기존 string 데이터를 숫자로 변환하여 반환합니다. 
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    };
};


/**
 * 현재 선택된 BUDS 디바이스의 Lat,lng을 반환합니다. 
 * 
 * @param {*} selectedDevice 
 * @param {*} locationList 
 * @param {*} budsSelectedUnitList 
 * @returns 
 */
export const getDevicePositionForBuds = (selectedDevice, locationList, budsSelectedUnitList) => {
    if (!selectedDevice || !locationList || !Array.isArray(locationList) || locationList.length <= 0) {
        console.log("Location list information does not exist.")
        return null;
    }
    const deviceId = selectedDevice.deviceId;
    const devicetype = selectedDevice.type;
    const matchingLocation = locationList.find(
        location => location.deviceId === deviceId
    );

    if (!matchingLocation || !matchingLocation.geolocations || matchingLocation.geolocations.length === 0) {
        return null;
    }

    // BUDS인지 확인 
    let locationsToProcess = [];
    if (devicetype === DEVICE_TYPES.BUDS) {
        const selectedUnitForBuds = budsSelectedUnitList?.find(item => item.deviceId === deviceId)?.selectedUnit;
        if (selectedUnitForBuds === BUDS_UNITS.BOTH || selectedUnitForBuds === BUDS_UNITS.LEFT) {
            // device.type이 BUDS이고 Unit이 BOTH 또는 LEFT일 때, units가 L인 것만 사용
            locationsToProcess = matchingLocation.geolocations.filter(geo => geo.units === 'L');
        } else {
            // device.type이 BUDS이고 Unit이 BOTH가 아닐 때, units가 R 인 것을 사용
            locationsToProcess = matchingLocation.geolocations.filter(geo => geo.units === 'R');
        }
    } else {
        // device.type이 BUDS가 아닌 경우, 첫 번째 geolocation만 사용
        locationsToProcess.push(matchingLocation.geolocations[0]);
    }

    const { latitude, longitude } = locationsToProcess[0];
    return { // 기존 string 데이터를 숫자로 변환하여 반환합니다. 
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    };
};



export const getEncryptedLocationByDeviceId = (deviceId, locationList) => {
    if (!locationList || !Array.isArray(locationList) || locationList.length <= 0) {
        console.log("Location list information does not exist.")
        return null;
    }
    const matchingLocation = locationList.find(
        location => location.deviceId === deviceId
    );
    if (!matchingLocation || !matchingLocation.geolocations || matchingLocation.geolocations.length === 0) {
        return null;
    }
    const { latitude, longitude, timeCreated } = matchingLocation.geolocations[0];
    return { latitude, longitude, timeCreated };
};



export const getAddressByDeviceId = (deviceId, addressList) => {
    if (!addressList || !Array.isArray(addressList) || addressList.length <= 0) {
        return null;
    }
    const matchingAddressData = addressList.find(
        address => address.deviceId === deviceId
    );

    if (!matchingAddressData || !matchingAddressData.address) {
        return null;
    }
    return matchingAddressData;
}



export const getPositionUpdatedTimeByDeviceId = (deviceId, locationList) => {
    if (!locationList || !Array.isArray(locationList) || locationList.length <= 0) {
        return null;
    }
    const matchingLocation = locationList.find(
        location => location.deviceId === deviceId
    );
    if (!matchingLocation || !matchingLocation.geolocations || matchingLocation.geolocations.length === 0) {
        return null;
    }
    const { timeCreated } = matchingLocation.geolocations[0];
    return timeCreated;
};


/**
 * DeviceId를 이용해서 deviceList 데이터에서 
 * Device객체를 반환합니다. 
 * 
 * @param {*} deviceId 
 * @param {*} deviceList 
 * @returns 
 */
export const getDeviceByDeviceId = (deviceId, deviceList) => {
    if (!Array.isArray(deviceList)) {
        console.error("deviceList is not an array");
        return null;
    }
    return deviceList.find(item => item.deviceId === deviceId);
};


/**
 * DeviceId를 이용해서 deviceDetailList 데이터에서
 * 해당하는 Detail 데이터를 반환합니다.
 * 
 * @param {*} deviceId 
 * @param {*} detailList 
 * @returns 
 */
export const getDeviceDetailByDeviceId = (deviceId, detailList) => {
    if (!Array.isArray(detailList)) {
        console.error("detailList is not an array");
        return null;
    }
    return detailList.find(item => item.deviceId === deviceId);
}

export const isValidURL = (url) => {
    // 유효성 검사를 위한 정규표현식
    const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return urlPattern.test(url);
}


/**
 * Retrieves the address for the given latitude, longitude, language, and country.
 *
 * @param {number} lat - Latitude of the location.
 * @param {number} lng - Longitude of the location.
 * @param {string} language - Desired language for the returned address (e.g., 'ko', 'en').
 *
 * @returns {Promise<string>} - A promise that resolves to the address string.
 */


export async function getAddress(lat, lng, language) {
    const { kakao } = window;
    try {
        // 한국 내 위치인지 확인
        if (isLocationInKorea(lat, lng) && (kakao && kakao.maps) && language === 'ko') {
            return new Promise((resolve, reject) => {
                // Geocoder 객체 생성
                const geocoder = new kakao.maps.services.Geocoder();
                // 좌표로부터 주소 정보를 요청
                geocoder.coord2Address(lng, lat, (result, status) => {
                    if (status === kakao.maps.services.Status.OK && result[0]) {
                        // 도로명 주소가 있는 경우
                        if (result[0].road_address) {
                            resolve(result[0].road_address.address_name);
                            // 도로명 주소가 없는 경우 지번 주소 사용
                        } else if (result[0].address) {
                            resolve(result[0].address.address_name);
                        } else {
                            reject(new Error('주소 정보를 찾을 수 없습니다.'));
                        }
                    } else {
                        reject(new Error(`Kakao Maps API Error: ${status}`));
                    }
                });
            });

        } else {
            // Google Maps API를 사용하여 주소 조회
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${language}&key=${GOOGLE_MAPS_API_KEY}`
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${language}&key=${GOOGLE_MAPS_API_KEY}`);
            // /geocode API 호출 횟수 측정을 위해서 로그 기록 
            createAndSendLog(null, "googleapis", LogLevel.INFO, null, { apiUrl: apiUrl, responseStatus: response?.data?.status });

            if (response?.data?.status === "OK") {
                const results = response?.data?.results;
                if (results && Array.isArray(results) & results.length > 0) {
                    return response.data?.results[1]?.formatted_address;
                } else {
                    throw new Error(`Google Maps API Error: ${response.data.status}`);
                }
            } else {
                throw new Error(`Google Maps API Error: ${response.data.status}`);
            }
        }
    } catch (error) {
        throw error;
    }
}


/**
 * 사용자의 현재 위치를 받아옵니다.
 * @param {*} successCallback 
 * @param {*} denyCallback 
 * @returns 
 */
export const getUserLocation = (successCallback, denyCallback) => {
    if (!navigator.geolocation) {
        alert('Location services are not available in this browser.');
        return;
    }

    // 위치 정보 접근 거부 시 실행할 함수
    const handleLocationError = (error) => {
        if (error.code === error.PERMISSION_DENIED) {
            denyCallback(); // set locationPermissionDenied as true
        }
        console.error("Error occurred ", error);
    };

    navigator.geolocation.getCurrentPosition(position => {
        const userPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        console.log("User's current location", userPosition);
        if (successCallback) {
            successCallback(userPosition); // set current postion 
        }
    }, handleLocationError, { maximumAge: 1000, timeout: 10000 });
};


/**
 * 검사하는 함수로 주어진 position 객체의 lat, lng 값을 유효성 검사합니다.
 *
 * @param {Object} position - 유효성을 검사할 위치 객체.
 * @returns {boolean} - 유효하면 true, 아니면 false를 반환.
 */
export function isValidPosition(position) {
    if (!position) {
        return false;
    }
    // 위치 객체에서 lat, lng 또는 latitude, longitude 값 가져오기
    const lat = position.lat || position.latitude;
    const lng = position.lng || position.longitude;
    // 값의 유무(숫자 또는 숫자로 변환 가능한 값) 검사
    if (isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) {
        return false;
    }
    // 값의 범위 검사
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
        return false;
    }
    return true;
}

/**
 * Checks if a given point (latitude, longitude) is within the boundary of South Korea.
 *
 * Note:
 * - This function uses the `turf` library, which follows the GeoJSON standard.
 * - In GeoJSON, coordinates are always represented in the order: [longitude, latitude] (i.e., `lng, lat`).
 * - Ensure the correct order of coordinates when using this function to avoid unexpected results.
 *
 * @param {number} lat - The latitude of the point.
 * @param {number} lng - The longitude of the point.
 * @returns {boolean} - Returns `true` if the point is inside South Korea, otherwise returns `false`.
 */
const koreaBoundaryPolygon = turf.polygon([[
    [129.293848, 39.105648],
    [132.697463, 38.021076], // 독도 우상단
    [132.539061, 36.655220], // 독도 우하단
    [129.259321, 34.743466],
    [128.903499, 33.810255],
    [125.157071, 32.599185],
    [124.150105, 34.458362],
    [124.496356, 37.747455], // 백령도 좌하단
    [124.543887, 37.986939], // 백령도 좌상단
    [124.756496, 38.017883], // 백령도 우상단
    [124.972107, 37.65974],
    [129.293848, 39.105648] // The last coordinate must be the same as the starting point to properly close the polygon.
]]);

export const isLocationInKorea = (lat, lng) => {
    const point = turf.point([lng, lat]);
    return turf.booleanPointInPolygon(point, koreaBoundaryPolygon);
};


export function findImageLinkByModelCode(deviceImages, modelCode) {
    if (!deviceImages || Object.keys(deviceImages).length === 0) {
        console.log("findImageLinkByModelCode - deviceImages cannot be null.");
        return null;
    }
    return deviceImages.hasOwnProperty(modelCode) ? deviceImages[modelCode] : null;
}


export const getDefaultCircleImageByDeviceType = (deviceType, deviceSubType) => {
    let defaultImage;

    switch (deviceType) {
        case DEVICE_TYPES.BUDS:
            defaultImage = buds_pair_circle;
            break;
        case DEVICE_TYPES.PC:
            defaultImage = laptop_circle;
            break;
        case DEVICE_TYPES.PHONE:
            defaultImage = phone_circle;
            break;
        case DEVICE_TYPES.SPEN:
            defaultImage = spen_pro;
            break;
        case DEVICE_TYPES.TAB:
            defaultImage = tablet_circle;
            break;
        case DEVICE_TYPES.TAG:
            defaultImage = tag_circle;
            break;
        case DEVICE_TYPES.WATCH:
            if (deviceSubType === DEVICE_SUB_TYPES.FIT) {
                defaultImage = band;
            } else {
                defaultImage = watch_circle;
            }
            break;
        case DEVICE_TYPES.WEARABLE:
            defaultImage = smart_ring;
            break;
        default:
            defaultImage = phone_circle;
    }

    return defaultImage;

}


export const getDefaultDeviceImageByDeviceType = (deviceType) => {
    let defaultImage;

    switch (deviceType) {
        case DEVICE_TYPES.BUDS:
            defaultImage = buds_pair;
            break;
        case DEVICE_TYPES.PC:
            defaultImage = laptop;
            break;
        case DEVICE_TYPES.PHONE:
            defaultImage = phone;
            break;
        case DEVICE_TYPES.SPEN:
            defaultImage = spen_pro;
            break;
        case DEVICE_TYPES.TAB:
            defaultImage = tablet;
            break;
        case DEVICE_TYPES.TAG:
            defaultImage = tag;
            break;
        case DEVICE_TYPES.WATCH:
            defaultImage = watch;
            break;
        case DEVICE_TYPES.WEARABLE:
            defaultImage = smart_ring;
            break;
        default:
            defaultImage = phone;
    }

    return defaultImage;

}


export const getDeviceName = (selectedDevice) => {

    if (selectedDevice) {
        if (selectedDevice.modelInfo && selectedDevice.modelInfo.displayName) {
            return selectedDevice.modelInfo.displayName
        }

        if (selectedDevice.type) {
            return selectedDevice.type
        }
    }


    return 'Device';// TOOD : 다국어 적용
}

// ============  더이상 사용하지 않는 것 같음 =============
export function isDeviceOnline(detailListData, deviceId) {
    // detailListData가 유효한 배열인지 확인
    if (!Array.isArray(detailListData)) {
        return false;
    }
    // detailListData에서 특정 deviceId를 가진 항목 찾기
    const deviceData = detailListData.find(device => device && device.deviceId === deviceId);
    // 해당 deviceId의 데이터가 없거나, operation 필드가 유효한 배열이 아니라면 false 반환
    if (!deviceData || !Array.isArray(deviceData.operation)) {
        return false;
    }
    // operation 배열에서 oprnType이 CHECK_CONNECTION인 객체 찾기
    const checkConnectionOperation = deviceData.operation.find(op => op && op.oprnType === 'CHECK_CONNECTION');
    // 해당 객체가 존재하고 battery 필드가 있으면 true, 그렇지 않으면 false 반환
    return checkConnectionOperation && 'battery' in checkConnectionOperation;
}

// ============  더이상 사용하지 않는 것 같음 =============
export function getBatteryLevel(deviceDetail) {
    // 해당 deviceId의 데이터가 없거나, operation 필드가 유효한 배열이 아니라면 null 반환
    if (!deviceDetail || !Array.isArray(deviceDetail.operation)) {
        return null;
    }
    // operation 배열에서 oprnType이 CHECK_CONNECTION인 객체 찾기
    const checkConnectionOperation = deviceDetail.operation.find(op => op && op.oprnType === 'CHECK_CONNECTION');
    // 해당 객체에서 battery 필드의 값을 반환
    return checkConnectionOperation ? checkConnectionOperation.battery : null;
}

export function getBatteryLevelFromOper(batteryListData, deviceId) {
    if (!batteryListData) {
        return null;
    }

    const batteryData = batteryListData.find(data => data && data.deviceId === deviceId);
    // console.log("function getBatteryLevelFromOper ========================= ", batteryData);

    // operation 배열에서 oprnType이 CHECK_CONNECTION인 객체 찾기
    const data = batteryData?.data;

    if (data) {
        // phone, tablet, spen
        if (data.battery) {
            return data.battery;
        }
        // buds
        else if (data.extra?.left?.status !== -1) {
            // todo) 왼쪽, 오른쪽 각각? 배열로? 어찌 보내야할까?
            return data.extra?.left?.battery;
        }
        else {
            return false;
        }
    }
    return false;
}

export const getFormattedUpdatedTime = (operationDoneDate) => {
    if (!operationDoneDate) {
        return null;
    }
    // 'YYYYMMDDHHmmss' 형식의 문자열을 Date 객체로 변환
    const year = parseInt(operationDoneDate.substring(0, 4), 10);
    const month = parseInt(operationDoneDate.substring(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(operationDoneDate.substring(6, 8), 10);
    const hour = parseInt(operationDoneDate.substring(8, 10), 10);
    const minute = parseInt(operationDoneDate.substring(10, 12), 10);
    const second = parseInt(operationDoneDate.substring(12, 14), 10);
    const date = new Date(Date.UTC(year, month, day, hour, minute, second));

    return date.getTime();
};


export const getTimestampFormatted = (timestamp, format) => {
    if (!timestamp) {
        return null;
    }

    if (!dayjs(timestamp).isValid()) {
        return null;
    }

    dayjs.extend(require('dayjs/plugin/advancedFormat'));

    const date = new Date(timestamp);
    const countryCode = getCountryCode2();
    const selectedLanguage = extractLangCodeFromSelectedLang(getSelectedLanguage());
    const concated = `${selectedLanguage}-${countryCode}`;
    const options = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const formatter = Intl.DateTimeFormat(concated, options);

    if (selectedLanguage === 'en') {
        return dayjs(timestamp).format(format);
    } else {
        return formatter.format(date);
    }
}


/**
 * timestamp를 "YYYYMMDDHHMMSS"형식으로 변환
 * @param {*} timestamp 
 * @returns 
 */
export function timestampToFormattedString(timestamp) {
    // 타임스탬프가 null이거나 숫자가 아니면 유효하지 않음
    if (timestamp === null || isNaN(Number(timestamp))) {
        return null;
    }
    // 타임스탬프를 Date 객체로 변환
    const date = new Date(timestamp);
    // Date 객체가 유효한 날짜를 나타내지 않으면 유효하지 않음
    if (isNaN(date.getTime())) {
        return null;
    }
    // 각 부분을 추출 (월은 0부터 시작하므로 1을 더해줌)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    // 형식에 맞게 문자열 조합
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

export const isEUCountry = (countryCode) => {
    if (!countryCode) {
        return false;
    }

    // 2024.01  27개국
    // 그리스, 네덜란드, 덴마크, 독일, 라트비아            // 루마니아, 룩셈부르크, 리투아니아, 몰타, 벨기에
    // 불가리아, 스웨덴, 스페인, 슬로바키아, 슬로베니아     // 아일랜드, 에스토니아, 오스트리아, 이탈리아, 체코
    // 크로아티아, 키프로스, 포르투칼, 폴란드, 프랑스       // 핀란드, 헝가리
    const EUlist = [
        'GRC', 'NLD', 'DNK', 'DEU', 'LVA', 'ROU', 'LUX', 'LTU', 'MLT', 'BEL',
        'BGR', 'SWE', 'ESP', 'SVK', 'SVN', 'IRL', 'EST', 'AUT', 'ITA', 'CZE',
        'HRV', 'CYP', 'PRT', 'POL', 'FRA', 'FIN', 'HUN'
    ];

    return EUlist.find((item) => item === countryCode);
}


/**
 * TAG list의 응답을 createTime 필드로 정렬 (내림차순, 최신 시간이 우선하도록)
 * @param {*} tagList 
 * @returns 
 */
export const sortItemListByCreateTime = (tagList) => {
    // 배열이 유효하고, 배열인지 확인
    if (!Array.isArray(tagList)) {
        return [];
    }
    // createTime 기준으로 정렬 (내림차순)
    return tagList.sort((a, b) => {
        // a 또는 b의 metadata가 없거나, createTime이 없는 경우 0을 반환
        const timeA = a.metadata?.createTime || 0;
        const timeB = b.metadata?.createTime || 0;
        return timeB - timeA; // 내림차순 정렬
    });
};


/**
 * App to Web SSO 로그인으로 진입한 deviceId에 대한 단말을 가장 앞으로 정렬합니다. 
 * @param {*} devices 
 * @param {*} ssoLoginDeviceId 
 * @returns 
 */
export function prioritizeSsoLoginDevice(devices, ssoLoginDeviceId) {
    // devices가 배열이 아니거나 비어있는 경우, 변경 없이 반환
    if (!Array.isArray(devices) || devices.length === 0) {
        return devices;
    }

    if (!ssoLoginDeviceId || !isFmmDeviceIdFormat(ssoLoginDeviceId)) {
        return devices;
    };
    // ssoLoginDeviceId와 일치하는 디바이스 찾기
    const deviceIndex = devices.findIndex(device => device.deviceId === ssoLoginDeviceId);
    // 일치하는 디바이스가 없거나, 이미 첫 번째 위치에 있는 경우, 변경 없이 반환
    if (deviceIndex === -1 || deviceIndex === 0) {
        return devices;
    }
    // 일치하는 디바이스를 배열에서 제거
    const [ssoDevice] = devices.splice(deviceIndex, 1);
    // 제거한 디바이스를 배열의 첫 번째 위치에 삽입
    devices.unshift(ssoDevice);
    return devices;
}


/**
 * 현재 ITEM의 device on/off 여부를 판단합니다. 
 * CHECK_CONNECTION operation 의 결과에 따른 배터리 값의 여부로 ON/OFF를 판단합니다. 
 * @returns 
 */
export function getIsDeviceOnline(selectedDevice, operationsResult, selectedUnitForBuds) {
    if (!selectedDevice || !operationsResult) {
        return null;
    }

    const deviceId = selectedDevice.deviceId;
    const deviceType = selectedDevice.type;
    const batteryLevel = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.battery;
    const batteryLevelForBudsL = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.extra?.left?.battery;
    const batteryLevelForBudsR = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.extra?.right?.battery;

    if (deviceType === DEVICE_TYPES.BUDS) {
        if (selectedUnitForBuds === BUDS_UNITS.RIGHT) {// 현재 선택된 UNIT이 R일경우 R의 on/off 여부
            return batteryLevelForBudsR ? true : false;
        } else if (selectedUnitForBuds === BUDS_UNITS.LEFT) { // 현재 선택된 UNIT이 L일경우 L의 on/off 여부
            return batteryLevelForBudsL ? true : false;
        } else if (selectedUnitForBuds === BUDS_UNITS.BOTH) {
            return batteryLevelForBudsR || batteryLevelForBudsL; // BOTH일 경우 둘 중 하나라도 on이면 true 
        }
    } else { // not buds
        return batteryLevel ? true : false;
    }
}

/**
 * 한국 아동의 이름을 언어/기호 상관없이 
 * 첫번째와 마지막 문자를 제외하고 마스킹 처리 합니다.
 * @param {*} name 
 * @returns 
 */
export function anonymizeKoreanName(name) {
    const nameWithNoSpaces = name.replace(/\s+/g, ''); // 공백문자 제거
    if (nameWithNoSpaces.length <= 1) {
        // 이름이 1글자 이하일 경우, 그대로 반환
        return name;
    } else if (nameWithNoSpaces.length === 2) {
        // 이름이 2글자인 경우, 첫 글자만 남기고 나머지를 *로 변환
        return `${nameWithNoSpaces[0]}*`;
    } else {
        // 이름이 3글자 이상일 경우, 첫 글자와 마지막 글자만 남기고 나머지를 *로 변환
        const firstChar = nameWithNoSpaces[0];
        const lastChar = nameWithNoSpaces[nameWithNoSpaces.length - 1];
        const middleChars = '*'.repeat(nameWithNoSpaces.length - 2);
        return `${firstChar}${middleChars}${lastChar}`;
    }
}
