import { ListItemButton, Switch } from '@mui/material';
import React from 'react';
import { ListItemButtonWithSwitchProps } from './ListItemButtonWithSwitchTypes';
import './ListItemButtonWithSwitchStyles.scss';

const ListItemButtonWithSwitch = ({ onChange, title, checked, linkURL }: ListItemButtonWithSwitchProps) => {
    return (
        <div>
            {linkURL ?
                <ListItemButton alignItems='center' className='list-item-button-with-switch-container' target="_blank" rel="noreferrer noopener" href={linkURL} >
                    <div className='text-box'>
                        {title}
                    </div>
                    <div className='push-box'>
                        <Switch className='switch-button-common' checked={checked} onChange={onChange} />
                    </div>
                </ListItemButton>
                :
                <ListItemButton alignItems='center' className='list-item-button-with-switch-container'>
                    <div className='text-box'>
                        {title}
                    </div>
                    <div className='push-box'>
                        <Switch className='switch-button-common' checked={checked} onChange={onChange} />
                    </div>
                </ListItemButton>
            }
        </div>
    )
}

export default ListItemButtonWithSwitch;