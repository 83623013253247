/**
 * UnAuthenticatedRoute.js
 *
 * A higher-order component that wraps around the React Router's Route component.
 * This component checks the authentication status of the user using the `isLoggedIn` function
 * from `auth.js` and either renders the desired component (if Unauthenticated) or redirects
 * the user to the main page (if authenticated).
 *
 * For example, if a user tries to access the '/login' route and they are
 * authenticated, they will be redirected to the main page.
 *
 * This component provides a simple and consistent way to protect routes that require Unauthentication.
 */

import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { isLoggedIn } from '../utills/auth';

const UnAuthenticatedRoute = () => {
    if (isLoggedIn()) {
        return <Navigate to="/" replace />;
    }
    return <Outlet />;
};

export default UnAuthenticatedRoute;