import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedDevice } from '../../../slices/deviceListSlice';
import DeviceListCard from '../DeviceListCard';
import DeviceDetailCard from '../DeviceDetailCard';
import DeviceListCardMobile from '../DeviceListCardMobile';
import SettingsCard from '../SettingsCard';
import { DeviceControlPanelProps } from './DeviceControlPanelTypes';
import { RootStateType } from '../../../slices/types';
import { useIsMobileView } from '../../../hooks/useIsMobileView';



const DeviceControlPanel = ({ deviceListData }: DeviceControlPanelProps) => {
    const dispatch = useDispatch();
    const { isMobileView } = useIsMobileView()
    const isDeviceDetailOpen = useSelector((state: RootStateType) => state.deviceList.isDeviceDetailOpen);
    const selectedSettings = useSelector((state: RootStateType) => state.deviceList.selectedSettings);

    const handleClickMenuItemMemo = useCallback(
        (selectedDevice: any) => {
            dispatch(setSelectedDevice(selectedDevice));
        }, [dispatch],
    )

    const renderViewIfMobileOrPC = (isMobile: boolean) => {
        if (isMobile) {
            return (
                <DeviceListCardMobile
                    deviceListData={deviceListData}
                />
            )
        }
        return (
            <DeviceListCard
                handleClickMenuItem={handleClickMenuItemMemo}
                deviceListData={deviceListData}
            />
        );
    }

    return (
        <div>
            {/* Device 목록 카드 */}
            {renderViewIfMobileOrPC(isMobileView)}

            {/* Device Detail 카드 (Device를 선택했을때 Open )*/}
            {isDeviceDetailOpen &&
                <DeviceDetailCard />}

            {/* Settings 카드 */}
            {selectedSettings &&
                <SettingsCard />}
        </div>
    );
}

export default DeviceControlPanel;