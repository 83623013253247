import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import DeviceControlFunctionsMobile from "./DeviceControlFunctionsMobile/DeviceControlFunctionsMobile";
import DeviceListItem from "./DeviceListItem";
import { useDispatch, useSelector } from 'react-redux';
import { setIsDeviceDetailOpen, setSelectedDevice, upsertDeviceDetail } from '../../slices/deviceListSlice';
import useDeviceOperations from '../../hooks/useDeviceOperations';
import { getDeviceDetailByDeviceId } from '../../utills/functions';
import { DEVICE_REGI_STATUS, OPERATION_TYPES } from '../../utills/enums/commons';
import { getDeviceDetails, performOperationLocation, performOperationCheckConnection } from '../../api/findToStf/devicesApi/devicesApi';
import { ModalPlaySound } from '../modal';
import { ring, blink_spen, blink, refresh, more_land } from "../../assets/images/deviceCard";
import { useTranslation } from 'react-i18next';
import useDeviceType from '../../hooks/useDeviceType';
import { DEVICE_SUB_TYPES, DEVICE_TYPES } from '../../utills/enums/Devices';


const DeviceListItemAccordion = ({ panelNumber, device, address, updatedTime, expanded, handleAccordionExpansionChange }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        deviceType,
        deviceSubType
    } = useDeviceType();
    const detailListData = useSelector((state) => state.deviceList.detailListData);
    const deviceDetail = getDeviceDetailByDeviceId(device?.deviceId, detailListData);

    const [soundModalOpen, setSoundModalOpen] = useState(false);

    const { operationsResult, startOperation } = useDeviceOperations();
    const isLoadingCheckConnection = operationsResult[device?.deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.isLoading;

    const isPendingDevice = deviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED;

    const onLeftButtonClick = () => {
        setSoundModalOpen(true);
    };

    const onRefreashClick = () => {
        sendRefreshOperations(device, deviceDetail);
    };

    const onMoreButtonClick = (device) => {
        dispatch(setSelectedDevice(device));
        dispatch(setIsDeviceDetailOpen(true));
    };

    const sendRefreshOperations = (device, deviceDetail) => {
        if (!device) {
            return;
        }
        const deviceId = device.deviceId;
        const userId = device.userId;
        const deviceType = device.type;
        startOperation(device, deviceDetail, OPERATION_TYPES.LOCATION,
            () => {
                return performOperationLocation(deviceId, userId, deviceType)
                    .then(responseData => {
                        return responseData
                    })
                    .catch(e => {
                        throw e;
                    })
            }
        );

        startOperation(device, deviceDetail, OPERATION_TYPES.CHECK_CONNECTION,
            () => {
                return performOperationCheckConnection(deviceId, userId, deviceType)
                    .then(responseData => responseData)
                    .catch(e => {
                        throw e;
                    })
            }
        );
    }

    const onSoundModalClose = () => {
        setSoundModalOpen(false);
    }

    const getButtonPropsList = () => {
        if(isPendingDevice){
            const buttonPropsList = [
                {
                    onButtonClick: () => onMoreButtonClick(device),
                    buttonImageSrc: more_land,
                    buttonTitle: t('more'),
                }
            ]   
            return buttonPropsList; 
        }

        const buttonPropsList = [
            {
                onButtonClick: onRefreashClick,
                buttonImageSrc: isLoadingCheckConnection ? null : refresh,
                buttonTitle: t('refresh'),
            },
            {
                onButtonClick: () => onMoreButtonClick(device),
                buttonImageSrc: more_land,
                buttonTitle: t('more'),
            },
        ]

        if (deviceType === DEVICE_TYPES.SPEN) {
            buttonPropsList.unshift(
                {
                    onButtonClick: onLeftButtonClick,
                    buttonImageSrc: blink_spen,
                    buttonTitle: t('blink_light')
                }
            );
        } else if (deviceType === DEVICE_TYPES.WEARABLE){
            buttonPropsList.unshift(
                {
                    onButtonClick: onLeftButtonClick,
                    buttonImageSrc: blink,
                    buttonTitle: t('blink_light')
                }
            )
        } else {
            buttonPropsList.unshift(
                {
                    onButtonClick: onLeftButtonClick,
                    buttonImageSrc: ring,
                    buttonTitle: (deviceSubType === DEVICE_SUB_TYPES.FIT) ? t('vibrate') : t('ring')
                }
            )
        }
        return buttonPropsList
    }

    return (
        <div>
            <ModalPlaySound open={soundModalOpen} onClose={onSoundModalClose} passedValue={deviceDetail} />
            <Accordion className='accordion-border-bottom-mobile' expanded={panelNumber === expanded} onChange={handleAccordionExpansionChange(panelNumber)}>
                <AccordionSummary>
                    <DeviceListItem
                        device={device}
                        address={address}
                        updatedTime={updatedTime}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <DeviceControlFunctionsMobile
                        buttonPropsList={getButtonPropsList()}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default DeviceListItemAccordion;