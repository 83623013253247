import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Button, Box, Typography, Divider } from '@mui/material';

import BaseModal from '../BaseModal';
import './ModalEraseStyles.scss';

import { ModalEraseProps, ModalEraseActionEnum, ModalEraseActionType } from './ModalEraseTypes';

import ModalRegisteredCard from '../ModalRegisteredCard';
import { erase_data, wallet, wallet_checked, wallet_error, remove_checked, remove_error } from "../../../assets/images/deviceCard";
import BulletPointWithTitleAndContent from '../../texts/BulletPointWithTitleAndContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    setEraseModalOpen,
    setCancelEraseCardOpen,
    asyncPerformOperationErase,
    setActionType,
    asyncPerformOperationEraseCancel,
} from '../../../slices/modalEraseSlice';
import useMqttDeviceConnection from '../../../hooks/useMqttDeviceConnection';
import { AppThunkDispatch, RootStateType } from '../../../slices/types';
import BaseActionCard from '../../cards/actions/BaseActionCard';
import { getUserId } from '../../../utills/auth';
import { OPERATION_TYPES } from '../../../utills/enums/commons';
import { DEVICE_TYPES } from "../../../utills/enums/Devices";
import { getDeviceDetailByDeviceIdAndDeviceList, getOperationStatusFromDeviceList } from '../../../utills/functions/deviceFunctions';
import { hasDataProperty } from '../../../slices/operationSlice/operationSliceTypeGuard';
import { DM_ERROR_RESULT_CODE, DM_ERROR_STATUS_CODE, DM_ERROR_STATUS_CODE_FAIL, DM_ERROR_STATUS_CODE_SUCCESS } from '../../../utills/enums/DMErrorCodes';
import { asyncGetCardList } from '../../../slices/cardListSlice';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { formatString } from '../../../utills/functions/stringPhasers';
import { convertUTCtoBrowserTime, formatDate } from '../../../utills/functions/time';
import { asyncRemoveDevice, asyncUpsertDeviceDetail } from '../../../slices/deviceListSlice/thunks';
import { deleteOperationStatusFromDeviceDetail, setIsDeviceDetailOpen } from '../../../slices/deviceListSlice';
import { getBattery } from '../../../utills/functions/operationInfoFunction';
import { getTextByDeviceType3 } from '../../../utills/functions/branchFunctions';
import LoadingCircle from '../../view-only/LoadingCircle';
import { OperationWipeData } from '../../../slices/operationSlice/operationsSliceTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { TIME_FORMAT } from '../../../utills/enums/timeFormats';


const ModalErase = ({ deviceId }: ModalEraseProps) => {
    const { t } = useTranslation();
    const modalEraseState = useSelector((state: RootStateType) => state.modalErase);
    const cardListState = useSelector((state: RootStateType) => state.cardList);
    const detailListData = useSelector((state: RootStateType) => state.deviceList.detailListData);
    const operationsResult = useSelector((state: RootStateType) => state.operations.operationsResult);
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceType = selectedDevice?.type;

    const [openRegisteredCard, setOpenRegisteredCard] = useState(false);
    const [openDataErasedCard, setOpenDataErasedCard] = useState(false);
    const [factoryDataResetText, setFactoryDataResetText] = useState(t('erase_sd_phone'));
    const [samsungWalletText, setSamsungWalletText] = useState(t('reset_samsung_wallet_and_delete_registered_cards'));
    const dispatch = useDispatch<AppThunkDispatch>();
    const startMqttConnection = useMqttDeviceConnection();

    const battery = getBattery(operationsResult, selectedDevice?.deviceId);


    const getMqttEraseData = (deviceId: string) => {
        if (deviceId) {
            const eraseData = operationsResult[deviceId]?.[OPERATION_TYPES.WIPE] ?? {};
            if (hasDataProperty(eraseData)) {
                return eraseData.data;
            }
        }
        return null;
    }
    const mqttEraseData = getMqttEraseData(deviceId);
    const isRemoteControlDisabled = (mqttEraseData?.oprnStsCd === DM_ERROR_STATUS_CODE.OPERATION_FAIL
        && mqttEraseData?.oprnResultCode === DM_ERROR_RESULT_CODE.DISABED_REMOTE_CONTROL);

    useEffect(() => {
        if(isRemoteControlDisabled) {
            dispatch(setActionType(ModalEraseActionEnum.error));
        }
    }, [isRemoteControlDisabled])

    useEffect(() => {
        if (modalEraseState.operationErase.data?.resultCode === '00' && mqttEraseData) {
            const operationStatusCode = mqttEraseData?.oprnStsCd;
            const operationResultCode = mqttEraseData?.oprnResultCode;
            if (operationStatusCode === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS) {
                dispatch(setActionType(ModalEraseActionEnum.done));
                dispatch(asyncRemoveDevice({ deviceId }));
            } else if (Object.values(DM_ERROR_STATUS_CODE_FAIL).includes(operationStatusCode)) {
                dispatch(setActionType(ModalEraseActionEnum.error));
            }
            if (operationResultCode === DM_ERROR_RESULT_CODE.OPERATION_SUCCESS) {
                setOpenDataErasedCard(true);
            }
        }
    }, [operationsResult, mqttEraseData]);

    useEffect(() => {
        if (selectedDevice?.type === DEVICE_TYPES.PHONE || selectedDevice?.type === DEVICE_TYPES.WATCH) {
            dispatch(asyncGetCardList({ deviceId }));
        }
    }, []);

    const eraseStatus = getOperationStatusFromDeviceList<OperationWipeData>(detailListData, selectedDevice?.deviceId, OPERATION_TYPES.WIPE);
    const willErase = eraseStatus
            && eraseStatus?.oprnStsCd === DM_ERROR_STATUS_CODE.NOTIFICATION_PENDING
            && eraseStatus?.oprnResultCode === DM_ERROR_RESULT_CODE.DEFAULT;
   useEffect(() => {
        if (willErase && (modalEraseState.actionType === ModalEraseActionEnum.cancel) ) {
            const date = formatDate(new Date(convertUTCtoBrowserTime({ utcString: eraseStatus.oprnDoneDate, type: TIME_FORMAT.MONTH_DAY_YEAR })));
            setFactoryDataResetText(getTextByDeviceType3(deviceType, {
                phone: formatString(t('erased_phone'), date),
                tablet: formatString(t('erased_tablet'), date),
                watch: formatString(t('erased_watch'), date)
            }));
            setSamsungWalletText(getTextByDeviceType3(deviceType, {
                phone: formatString(t('erased_wallet_phone'), date),
                tablet: formatString(t('erased_wallet_tablet'), date),
                watch: formatString(t('erased_wallet_watch'), date)
            }));
        }
    }, [willErase, modalEraseState.actionType])

    useEffect(() => {
        if (!willErase && (modalEraseState.actionType === ModalEraseActionEnum.erase)) {
            setFactoryDataResetText(getTextByDeviceType3(deviceType, {
                phone: t('erase_sd_phone'),
                tablet: t('erase_sd_tablet'),
                watch: t('erase_sd_watch')
            }));
            setSamsungWalletText(getTextByDeviceType3(deviceType, {
                phone: t('reset_samsung_wallet_and_delete_registered_cards'),
                tablet: '',
                watch: ''
            }));
        } else if(willErase && (modalEraseState.actionType === ModalEraseActionEnum.erase)) {
            dispatch(setActionType(ModalEraseActionEnum.cancel));
        }
    }, [battery, modalEraseState.actionType]);

    const handleRegisteredCardModalOpen = (e: React.MouseEvent) => {
        e.preventDefault();
        setOpenRegisteredCard(true);
    };

    const handleRegisteredCardModalClose = () => {
        setOpenRegisteredCard(false);
    };

    const handleEraseButton = async () => {
        dispatch(setActionType(ModalEraseActionEnum.cancel));
        const userId = selectedDevice?.userId!; // deviceOwner's userId
        const deviceDetail = await getDeviceDetailByDeviceIdAndDeviceList(deviceId, detailListData);
        dispatch(asyncPerformOperationErase({ userId, deviceId }))
        .then((res) => {
            if (res.meta.requestStatus === 'fulfilled' && res.payload.resultCode === '00') {
                dispatch(asyncUpsertDeviceDetail({ deviceId }));
            }
        });
        startMqttConnection({
            selectedDevice,
            deviceDetail,
            operationType: OPERATION_TYPES.WIPE
        });
    };

    const handleCancelButton = () => {
        dispatch(setCancelEraseCardOpen(true));
    };

    const handleDoneButton = () => {
        closeModalErase();
    };

    const onClose = () => {
        closeModalErase();
    };

    const closeModalErase = () => {
        dispatch(setEraseModalOpen(false));
        dispatch(setActionType(ModalEraseActionEnum.erase));
    }

    const hypertextBundleType = {
        title: t('view_cards'),
        onClick: handleRegisteredCardModalOpen,
        url: "#!"
    };


    const makeStatusText = () => {
        if (modalEraseState.actionType === ModalEraseActionEnum.cancel) {
            if (deviceType === "PHONE") {
                return t('permanently_erase_phone');
            } else if (deviceType === "TAB") {
                return t('permanently_erase_tablet');
            } else if (deviceType === "WATCH") {
                return t('permanently_erase_watch');
            }
        } else if (modalEraseState.actionType === ModalEraseActionEnum.error) {
            if (deviceType === "PHONE") {
                if (isRemoteControlDisabled) {
                    return t('phone_turned_off');
                } else {
                    return t('cant_erase_phone');
                }
            } if (deviceType === "TAB") {
                if (isRemoteControlDisabled) {
                    return t('tablet_turned_off');
                } else {
                    return t('cant_erase_tablet');
                }
            } else if (deviceType === "WATCH") {
                if (isRemoteControlDisabled) {
                    return t('watch_turned_off');
                } else {
                    return t('cant_erase_watch');
                }
            }
        } else if (modalEraseState.actionType === ModalEraseActionEnum.done) {
            if (deviceType === "PHONE") {
                return t('permanently_erase_phone');
            } if (deviceType === "TAB") {
                return t('permanently_erase_tablet');
            } else if (deviceType === "WATCH") {
                return t('permanently_erase_watch');
            }
        } else {
            if (deviceType === "PHONE") {
                return t('permanently_erase_phone');
            } if (deviceType === "TAB") {
                return t('permanently_erase_tablet');
            } else if (deviceType === "WATCH") {
                return t('permanently_erase_watch');
            }
        }
    }

    const makeBulletPointWallet = () => {
        if ((modalEraseState.actionType === ModalEraseActionEnum.cancel) && !willErase) {
            return "loading";
        } else if (modalEraseState.actionType === ModalEraseActionEnum.error) {
            return wallet_error;
        } else if (modalEraseState.actionType === ModalEraseActionEnum.done) {
            return wallet_checked;
        } else {
            return wallet;
        }
    }


    const makeBulletPointRemove = () => {
        if ((modalEraseState.actionType === ModalEraseActionEnum.cancel) && !willErase) {
            return "loading";
        } else if (modalEraseState.actionType === ModalEraseActionEnum.error) {
            return remove_error;
        } else if (modalEraseState.actionType === ModalEraseActionEnum.done) {
            return remove_checked;
        } else {
            return erase_data;
        }
    }



    const makeModalBottomButton = (buttonType: ModalEraseActionType) => {
        if (buttonType === ModalEraseActionEnum.erase || buttonType === ModalEraseActionEnum.error) {
            return (
                <ModalBottomButton title={t('erase')} onClick={handleEraseButton} />
            )
        } else if (buttonType === ModalEraseActionEnum.cancel || buttonType === ModalEraseActionEnum.cancelErase) {
            return (
                <ModalBottomButton title={t('cancel')} onClick={handleCancelButton} />
            )
        } else {
            return (
                <ModalBottomButton title={t('done')} onClick={handleDoneButton} />
            )
        }
    };

    const getCancelEraseText = () => {
        if (deviceType === DEVICE_TYPES.PHONE) {
            return t("cancel_erase_phone");
        } else if (deviceType === DEVICE_TYPES.WATCH) {
            return t("cancel_erase_watch");
        } else if (deviceType === DEVICE_TYPES.TAB) {
            return t("cancel_erase_tablet");
        }
    }

    const getEraseText = () => {
        if (deviceType === DEVICE_TYPES.PHONE) {
            return t("erase_phone");
        } else if (deviceType === DEVICE_TYPES.WATCH) {
            return t("erase_watch");
        } else if (deviceType === DEVICE_TYPES.TAB) {
            return t("erase_tablet");
        }
    }

    const onClickNo = () => {
        dispatch(setCancelEraseCardOpen(false));
    }

    const combinedAction = () => (dispatch: Dispatch, getState:() => any) => {
        dispatch(setActionType(ModalEraseActionEnum.erase));
        dispatch(deleteOperationStatusFromDeviceDetail({ deviceId, operationType: OPERATION_TYPES.WIPE }));
    }

    const onClickYes = async () => {
        const userId = selectedDevice?.userId!; // deviceOwner's userId
        dispatch(asyncPerformOperationEraseCancel({ userId, deviceId }))
        .then((res) => {
            if (res.meta.requestStatus === 'fulfilled' && res.payload.resultCode === '00') {
                dispatch(combinedAction());
            }
        });

    }

    const eraseCardButtonPropsOne = {
        title: t("no"),
        onClick: onClickNo,
    }

    const eraseCardButtonPropsTwo = {
        title: t("yes"),
        onClick: onClickYes
    }

    const getContentBlock = () => {
        if (deviceType === DEVICE_TYPES.PHONE) {
            return (
                <>
                    <div className="erase-text">
                        <p>
                            <Typography > {makeStatusText()} </Typography>
                        </p>
                        <p>
                            <Typography >* {t('google_account_phone')} </Typography>
                        </p>
                    </div>
                    <BulletPointWithTitleAndContent
                        bulletPointBundle={{
                            type: makeBulletPointRemove(),
                            width: '40px'
                        }}
                        title={t('factory_reset')}
                        content={factoryDataResetText}
                    />
                    <Box height={16} />
                    <BulletPointWithTitleAndContent
                        bulletPointBundle={{
                            type: makeBulletPointWallet(),
                            width: '40px'
                        }}
                        title={t('samsung_wallet')}
                        content={samsungWalletText}
                        hypertextBundle={hypertextBundleType}
                    />
                </>
            );
        } else if (deviceType === DEVICE_TYPES.WATCH) {
            return (
                <div className="erase-text">
                    <Typography variant='modalParagraph'>
                        {makeStatusText()}
                    </Typography>
                    {modalEraseState.actionType === ModalEraseActionEnum.cancel ? (
                        <div className='factory-data-reset-container'>
                            <Divider />
                            <div className='watch-loading-circle-container'>
                                <div className='watch-loading-circle'>
                                    <LoadingCircle size='md' />
                                </div>
                                <div>
                                    <Typography variant='actionButton'>
                                        {t('factory_reset')}
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    ) : null}
                </div>

            );
        } else if (deviceType === DEVICE_TYPES.TAB) {
            return (
                <>
                    <div className="erase-text">
                        <p>
                            <Typography> {makeStatusText()} </Typography>
                        </p>
                        <p>
                            <Typography>* {t('google_account_tablet')} </Typography>
                        </p>
                    </div>
                    <BulletPointWithTitleAndContent
                        bulletPointBundle={{
                            type: makeBulletPointRemove(),
                            width: '40px'
                        }}
                        title={t('factory_reset')}
                        content={factoryDataResetText}
                    />
                </>
            );
        }
    }

    const getDataErasedCardContents = () => {
        const date = mqttEraseData?.oprnDoneDate;
        const formattedDate = date ? convertUTCtoBrowserTime({ utcString: mqttEraseData?.oprnDoneDate, type: TIME_FORMAT.DAY_MONTH_YEAR }) : "";
        if (deviceType === DEVICE_TYPES.PHONE) {
            return formatString(t('anymore_phone'), formattedDate);
        } else if (deviceType === DEVICE_TYPES.TAB) {
            return formatString(t('anymore_tablet'), formattedDate);
        } else if (deviceType === DEVICE_TYPES.WATCH) {
            return formatString(t('anymore_watch'), formattedDate);
        }
    }

    const getDataErasedCardTitle = () => {
        if (deviceType === DEVICE_TYPES.PHONE) {
            return t('phone_data_erased');
        } else if (deviceType === DEVICE_TYPES.TAB) {
            return t('tablet_data_erased');
        } else if (deviceType === DEVICE_TYPES.WATCH) {
            return t('watch_data_erased');
        }
    }

    return (
        <>
            <BaseActionCard
                open={modalEraseState.cancelEraseCardOpen}
                contentsField={getCancelEraseText()}
                firstButtonProps={eraseCardButtonPropsOne}
                secondButtonProps={eraseCardButtonPropsTwo}
            />
            <ModalRegisteredCard open={openRegisteredCard} onClose={handleRegisteredCardModalClose} cardList={cardListState.cardList.data?.cards} />
            <BaseActionCard
                open={openDataErasedCard}
                title={getDataErasedCardTitle()}
                contentsField={getDataErasedCardContents()}
                firstButtonProps={{
                    title: t('ok'),
                    onClick: () => {
                        setOpenDataErasedCard(false);
                        dispatch(setEraseModalOpen(false));
                        dispatch(setIsDeviceDetailOpen(false));
                        dispatch(setActionType(ModalEraseActionEnum.erase));
                    }
                }}
            />
            <BaseModal
                open={modalEraseState.open}
                title={getEraseText()}
                contentBlock={getContentBlock()}
                actionBlock={makeModalBottomButton(modalEraseState.actionType)}
                onClose={onClose}
                scroll='body'
            />
        </>
    )
}

export default ModalErase;