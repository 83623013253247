import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { setBudsSoundAgree } from '../../../slices/modalSlice';

import { Button } from '@mui/material';
import BaseModal from '../BaseModal';
import { ModalPlaySoundAlertProps } from './ModalPlaySoundAlertTypes';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';


const ModalPlaySoundAlert = ({ open, onClose }: ModalPlaySoundAlertProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(setBudsSoundAgree(false));
        onClose();
    }

    const handlePlaySound = () => {
        // api call... 여기서말고,
        // ModalPlaySound로 정보를 넘겨서,  거기서 api call 하는 걸로... 
        dispatch(setBudsSoundAgree(true));
        onClose();
    }

    const ContentBlock = (
        <div className='sound-text'>
            <p>
                {t('start_beep_earbuds')}
            </p>
        </div>
    );

    const ActionBlock = (
        <>
            <ModalBottomButton title={t('cancel')} onClick={handleCancel} />
            <ModalBottomButton title={t('play_sound')} onClick={handlePlaySound} />
        </>
    );

    return (
        <div>
            <BaseModal
                open={open}
                onClose={handleCancel}
                title={t('playsound_earbuds')}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                disableX
            />
        </div>
    );
};

export default ModalPlaySoundAlert
