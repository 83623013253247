import React from 'react'
import _ from 'lodash';
import { Stack, Typography, Box, alertTitleClasses } from '@mui/material';
import './LocationTrackerStyles.scss';

import { track_location_pin_white_margin, location_gray_dot } from '../../../../assets/images/trackLocation';
import { getTimestampFormatted } from '../../../../utills/functions';
import { t } from 'i18next';
import { RootStateType } from '../../../../slices/types';
import { useDispatch, useSelector } from 'react-redux';
import useGoogleMapTrackLocation from '../../../../hooks/useGoogleMapTrackLocationPolyLine/useGoogleMapTrackLocationPolyLine';
import { setModalTrackLocationOpen, setTrackLocationInfoAction } from '../../../../slices/modalTrackLocationSlice';

const LocationTracker = () => {
    const items = useSelector((state: RootStateType) => state.modalTrackLocation.trackLocationInfo);
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const trackLocationInfo = useSelector((state: RootStateType) => state.modalTrackLocation.trackLocationInfo);
    const dispatch = useDispatch();
    const locationItems = items[(selectedDevice as any).deviceId]?.data;
    if (!locationItems) {
        return null;
    } else if (locationItems && locationItems.length === 0) {
        return null;
    }

    const onClickItemHandler: (uId: string) => React.MouseEventHandler = (uId: string) => (e) => {
        document.getElementById(uId)?.click();
        dispatch(setModalTrackLocationOpen(false));
    }
    const timeFormat = 'MMM. D, YYYY h:mm A';

    return (
        <div>
            <Stack direction="row">
                <Box className="location-tracker-first-item-container" marginRight="14px">
                    <img src={track_location_pin_white_margin} alt='Track Location Pin' onClick={onClickItemHandler(locationItems[0].uuid)} />
                </Box>
                <Stack gap="16px">
                    {
                        locationItems.map((e, i) => {
                            if (i === 0) {
                                return (
                                    <div className="location-tracker-first-item-container">
                                        <Stack>
                                            <Typography variant='locationTrackerTitle'>{getTimestampFormatted(e.date.toISOString(), timeFormat)}</Typography>
                                            <Typography variant='locationTrackerTitle'>{`(${t('current_location')})`}</Typography>
                                            <Typography variant='locationTrackerSubtitle'>{e.address}</Typography>
                                        </Stack>
                                    </div>
                                )
                            }
                            return (
                                <div className='location-tracker-secondary-item-container'>
                                    <Stack direction="row" alignItems="center" sx={{ position: "relative" }}>
                                        <img className="track-location-dot" src={location_gray_dot} alt='Track Location Dot' onClick={onClickItemHandler(e.uuid)} />
                                        <div className={i === 1 ? "primary-vertical-gray-bar" : "secondary-vertical-gray-bar"} />
                                        <Typography variant='locationTrackerSubtitle'>{getTimestampFormatted(e.date.toISOString(), timeFormat)}</Typography>
                                    </Stack>
                                </div>
                            )
                        }
                        )
                    }
                </Stack>
            </Stack>
        </div>
    )
}


export default LocationTracker;