import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Button, Typography } from '@mui/material';
import { ModalIMEIProps } from './ModalIMEITypes';
import './ModalIMEIStyles.scss';
import { DEVICE_TYPES } from '../../../utills/enums/Devices';
import { formatString } from '../../../utills/functions/stringPhasers';
import BaseModal from '../BaseModal';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';


const ModalIMEI = ({ open, onClose, passedValue }: ModalIMEIProps) => {
    const { t } = useTranslation();

    // global states
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceType = selectedDevice?.type;
    const modelName = (deviceType === 'TAG')
        ? (selectedDevice?.metadata?.vendor?.modelName)
        : (selectedDevice?.modelInfo?.modelName);

    // local states
    const [selectedDeviceDetail, setSelectedDeviceDetail] = useState(passedValue);
    const [subTitle, setSubTitle] = useState('');
    const [deviceAddr, setDeviceAddr] = useState("");


    // Dialog close
    const handleCancel = () => {
        setSelectedDeviceDetail(null);
        onClose();
    }

    // Dialog 열릴때 초기화용
    useEffect(() => {
        if (open === true) {
            // 정보 없음 상태로 표시
            if (passedValue === null) {
                setSubTitle(t('device_info') + ': ');
                setDeviceAddr(' - ');
            }
            else { // (passedValue !== null) 
                setSelectedDeviceDetail(passedValue);
            }
        }
    }, [open]);


    useEffect(() => {
        if (selectedDeviceDetail !== null) {
            const imeiLabel = selectedDeviceDetail?.lastSelectedDevice?.deviceAddress;

            if (imeiLabel) {
                if (deviceType === DEVICE_TYPES.BUDS || deviceType === DEVICE_TYPES.SPEN || deviceType === DEVICE_TYPES.WEARABLE) {
                    // MAC address => 뒤에서 17글자만 추출하기
                    // S Pen  ex) EJ-P5450,00:00:00:00:33:5D  
                    // Buds  ex) F0:CD:31:8F:BA:F4
                    // Wearable  ex) BC:93:07:13:89:1B
                    setSubTitle(t('mac_address') + ":");
                    const formattedPImeiLabel = imeiLabel.slice(-17);
                    setDeviceAddr(formattedPImeiLabel);
                }
                else if (deviceType === DEVICE_TYPES.PHONE) {
                    // IMEI => deviceAddr에 이미 IMEI 붙어서 온다 => IMEI떼어내기
                    // Phone  ex) IMEI:xxxxx... (15 or 17 digits)
                    setSubTitle(t('imei'));
                    const formattedPImeiLabel = imeiLabel.slice(5);
                    setDeviceAddr(formattedPImeiLabel);
                }
                else if (deviceType === DEVICE_TYPES.TAB || deviceType === DEVICE_TYPES.WATCH) {
                    // SerialNumber  =>  뒤에서 11자리 추출하기
                    // Tab  ex) IMEI:35555043004970,  TWID:R32W401A1LY
                    // Watch  ex) TWID:R3AT400EMNL,  SNID:R3AN4005HPW
                    const isIMEI = imeiLabel.slice(0, 4);
                    if (isIMEI === 'IMEI') {
                        setSubTitle(t('imei'));
                        const formattedPImeiLabel = imeiLabel.slice(5);
                        setDeviceAddr(formattedPImeiLabel);
                    }
                    else {
                        setSubTitle('');
                        setDeviceAddr(formatString(t('serial_number'), imeiLabel.slice(-11)));
                    }
                }
                else {  // deviceType === "TAG" "SmartRing"??
                    setSubTitle('');
                    setDeviceAddr('');
                }
            }
        }
    }, [selectedDeviceDetail]);

    const ContentBlock = (
        <>
            <Typography variant='modalParagraph'>
                {t('model')} {modelName} <br />
            </Typography>

            <div className="device-info-dialog">
                {subTitle ?
                    <Typography variant='modalParagraph'> {subTitle}&nbsp; </Typography> : ''
                }
                <Typography variant='modalParagraph'> {deviceAddr} </Typography>
            </div>
        </>
    );

    const ActionBlock = (
        <ModalBottomButton title={t('ok')} onClick={handleCancel} />
    );


    return (
        <div>
            <BaseModal
                title={t('device_info')}
                open={open}
                onClose={handleCancel}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
                disableX
            />
        </div>
    );
};

export default ModalIMEI
