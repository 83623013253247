import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    // Play Sound
    isPlaySoundLoading: false,
    budsSoundAgree: false,
    playsoundInfoPhone: ['0'],
    playsoundInfoTab: ['0'],
    playsoundInfoWatch: ['0'],
    playsoundInfoWatchVib: ['0'],
    playsoundInfoBuds: ['0'],
    playsoundInfoTag: ['0'],
    playsoundInfoSpen: ['0'],
    playsoundInfoWearable: ['0'],
    isDoneOprt: true,

    // Lock
    lockStatusInfo: [],

    // Unlock
    isUnlockLoading: false,
    unlockStatus: ['init'],

    // BackUp
    isBackupLoading: false,

    // Erase Data
    eraseResultInfo: ['0'],
}


const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {

        // Ring(PlaySound)
        setIsPlaySoundLoading: (state, action) => {
            state.isPlaySoundLoading = action.payload;
        },
        setBudsSoundAgree: (state, action) => {
            state.budsSoundAgree = action.payload;
        },
        setPlaySoundPhoneInfo: (state, action) => {
            // resultCode = '00'(성공), '01'(실패) 을 리턴함
            state.playsoundInfoPhone = action.payload;
        },
        setPlaySoundTabInfo: (state, action) => {
            state.playsoundInfoTab = action.payload;
        },
        setPlaySoundWatchInfo: (state, action) => {
            state.playsoundInfoWatch = action.payload;
        },
        setPlaySoundWatchVibInfo: (state, action) => {
            state.playsoundInfoWatchVib = action.payload;
        },
        setPlaySoundBudsInfo: (state, action) => {
            state.playsoundInfoBuds = action.payload;
        },
        setPlaySoundSpenInfo: (state, action) => {
            state.playsoundInfoSpen = action.payload;
        },
        setPlaySoundTagInfo: (state, action) => {
            state.playsoundInfoTag = action.payload;
        },
        setPlaySoundWearableInfo: (state, action) => {
            state.playsoundInfoWearable = action.payload;
        },
        setIsDoneOprt: (state, action) => {
            state.isDoneOprt = action.payload;
        },

        // Lock
        setLockStatusInfo: (state, action) => {
            state.lockStatusInfo = action.payload;
        },
        // Unlock
        setIsUnlockLoading: (state, action) => {
            state.isUnlockLoading = action.payload;
        },
        setUnlockInfo: (state, action) => {
            state.unlockStatus = action.payload;
        },

        // EraseData
        setEraseResultInfo: (state, action) => {
            state.eraseResultInfo = action.payload;
        },
    }
});


export const {
    setIsPlaySoundLoading,
    setBudsSoundAgree,
    setPlaySoundPhoneInfo,
    setPlaySoundTabInfo,
    setPlaySoundWatchInfo,
    setPlaySoundWatchVibInfo,
    setPlaySoundBudsInfo,
    setPlaySoundSpenInfo,
    setPlaySoundTagInfo,
    setPlaySoundWearableInfo,
    setIsDoneOprt,
    setLockStatusInfo,
    setIsUnlockLoading,
    setUnlockInfo,
    setEraseResultInfo,
} = modalSlice.actions;

export default modalSlice.reducer;