import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { ModalLockSettingProps } from './ModalLockSettingTypes';
import './ModalLockSettingStyles.scss';

import {
    SelectChangeEvent, Typography,
} from '@mui/material';

import BaseModal from '../BaseModal/BaseModal';
import TwoSelectsOverTextField from '../../text-fields/TwoSelectsOverTextField';
import TitleOverContentLayout from '../../layouts/TitleOverContentLayout';
import TwoVerticalTextFields from '../../text-fields/TwoVerticalTextFields';
import { LabelTextFieldProps } from '../../text-fields/LabelTextField/LabelTextFieldTypes';
import PlainTextField from '../../text-fields/PlainTextField';
import phoneExtensionInfo from '../../../assets/phone/phoneExtentionInfo.json';
import { concateCountyNameAndCodeForDisplay } from '../../../utills/functions/modalFunctions';
import { extractExtentionNumberFromString, formatNumber, formatString, removeAllButNumber } from '../../../utills/functions/stringPhasers';
import { getCurrentPhoneExtensionFormattedCountry } from '../../../utills/functions/languageFunctions';
import { isNumber } from '../../../utills/functions/validators';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { DEVICE_TYPES } from "../../../utills/enums/Devices";
import { setLockSettingInfo } from '../../../slices/modalLockSlice';


const ModalLockSetting = ({ isOpen, onClose, doLocking }: ModalLockSettingProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // global states
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceType = selectedDevice?.type;

    // local states
    const [lockTitle, setLockTitle] = useState(t('lock_phone'));
    const [isCountrySelectHidden, setIsCountrySelectHidden] = useState(true);

    const [enterPin, setEnterPin] = useState("");
    const [enterPinMsg, setEnterPinMsg] = useState("");
    const [enterPinValid, setEnterPinValid] = useState(true);

    const [confirmPin, setConfirmPin] = useState("");
    const [confirmPinMsg, setConfirmPinMsg] = useState("");
    const [confirmPinValid, setConfirmPinValid] = useState(true);

    const [scope, setScope] = useState('domestic');
    const [country, setCountry] = useState(getCurrentPhoneExtensionFormattedCountry());

    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [msgText, setMsgText] = useState("");
    const [placeholderText, setPlaceholderText] = useState(t('phone_lost'));
    const [inputCount, setInputCount] = useState(0);


    const handleCancel = () => {
        setInputCount(0);
        onClose();
    }

    // Dialog 열릴때 초기화용
    useEffect(() => {
        if (isOpen === true) {
            if (deviceType === 'TAB') {
                setLockTitle(t('lock_tablet'));
                setPlaceholderText(t('tablet_lost'));
            } else if (deviceType === 'WATCH') {
                setLockTitle(t('lock_watch'));
                setPlaceholderText(t('watch_lost'));
            } else {  // PHONE
                setLockTitle(t('lock_phone'));
                setPlaceholderText(t('phone_lost'));
            }

            setEnterPin("");
            setEnterPinMsg("");
            setEnterPinValid(true);

            setConfirmPin("");
            setConfirmPinMsg("");
            setConfirmPinValid(true);

            setScope('domestic');
            setIsCountrySelectHidden(true);
            setPhoneNumber("");

            setMsgText("");
            setInputCount(0);
        }
    }, [isOpen]);


    // input PIN 
    const handleEnterPin = (value: string) => {
        if (!isNumber(value)) {
            return;
        }

        setEnterPin(value);
        setConfirmPinMsg("");

        if (value.length < 4) {
            console.log("aa")
            setEnterPinMsg(formatNumber(t("pin_must"), "4"));
            setEnterPinValid(false);
        }
        else if (value.length > 8) {
            setEnterPinMsg(formatString(t("enter_more"), "8"));
            setEnterPinValid(false);
        }
        else if (confirmPin !== value && confirmPin !== "" && value !== "") {
            setEnterPinMsg("");
            setConfirmPinMsg(t("pin_not_match"));
            setConfirmPinValid(false);
        }
        else {
            setEnterPinMsg("");
            setEnterPinValid(true);
        }
    }


    // input Confirm PIN
    const handleConfirmPin = (value: string) => {
        if (!isNumber(value)) {
            return;
        }
        setConfirmPin(value);
        if (enterPin !== value && enterPin !== "" && value !== "") {
            setConfirmPinMsg(t("pin_not_match"));
            setConfirmPinValid(false);
        }
        else {   // enterPin === confirmPin
            setConfirmPinMsg("");
            setConfirmPinValid(true);
        }
    }


    // Select - domestic, international
    const handleScopeChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (value === "domestic") {
            setCountry(getCurrentPhoneExtensionFormattedCountry());
            setIsCountrySelectHidden(true);
        } else {
            setIsCountrySelectHidden(false);
        }
        setScope(value);
    }


    // Select - 
    const handleSelectCountryChange = (event: SelectChangeEvent<string>) => {
        setCountry(event.target.value);
    }

    // TextField
    const handleOnPhoneNumberChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        const str = removeAllButNumber(event.target.value);
        if (str.length > 20) {
            return
        }
        setPhoneNumber(str);
    }

    const handleOnMsgTextFieldChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        if (event.target.value.length > 100) {
            return
        }
        setMsgText(event.target.value);
        setInputCount(event.target.value.length);
    }

    // Button - Lock
    const handleLock = () => {
        const message = msgText.length === 0 ? placeholderText : msgText;
        const lockSettingInfo = {
            pin: enterPin,
            scope,
            extensionNumber: extractExtentionNumberFromString(country) ?? "",
            phoneNumber,
            msgText: message
        };
        dispatch(setLockSettingInfo(lockSettingInfo));
        doLocking(lockSettingInfo);
        onClose();
    }

    const checkPinValidation = () => {
        return (enterPin.length >= 4) && (confirmPin.length >= 4) && (enterPin === confirmPin) && (enterPin !== "" && confirmPin !== "");
    }


    const locationList = [
        { title: t('domestic'), value: "domestic" },
        { title: t('international'), value: "international" }
    ];

    const locationSelectItemBundle = {
        itemList: locationList,
        value: scope,
        onChange: handleScopeChange,
    }

    const codeSelectItemBundle = {
        itemList: _.sortBy(concateCountyNameAndCodeForDisplay(phoneExtensionInfo), 'title').map((item) => ({ ...item, value: item.title })),
        value: country,
        onChange: handleSelectCountryChange,
        hasExtentionNumber: true,
        isHidden: isCountrySelectHidden
    }

    const phoneNumberTextFieldBundle = {
        placeholder: t('dont_dashes'),
        onChange: handleOnPhoneNumberChange,
        value: phoneNumber
    }
    const firstTextFieldBundle: LabelTextFieldProps = {
        title: t('enter_pin_c'),
        textFieldProps: {
            required: true,
            type: "password",
            inputProps: { maxLength: (deviceType === DEVICE_TYPES.WATCH ? 4 : 8) },
            error: !enterPinValid,
            value: enterPin,
            helperText: enterPinMsg,
            onChange: (e) => handleEnterPin(e.target.value.trim())
        }
    }
    const secondTextFieldBundle: LabelTextFieldProps = {
        title: t('confirm_pin_c'),
        textFieldProps: {
            required: true,
            error: !confirmPinValid,
            type: "password",
            inputProps: { maxLength: (deviceType === DEVICE_TYPES.WATCH ? 4 : 8) },
            value: confirmPin,
            helperText: confirmPinMsg,
            onChange: (e) => handleConfirmPin(e.target.value.trim())
        }
    }

    const ContentBlock = (
        <div className="modal-lock-setting-container">
            {deviceType === DEVICE_TYPES.WATCH ? (
                <div className='step-one-container'>
                    <TitleOverContentLayout
                        title={formatNumber(t('step'), '1') + " : " + t('set_pin_4')} >
                        <TwoVerticalTextFields
                            firstTextFieldBundle={firstTextFieldBundle}
                            secondTextFieldBundle={secondTextFieldBundle}
                        />
                    </TitleOverContentLayout>
                </div>
            ) : (
                <div className='step-one-container'>
                    <TitleOverContentLayout
                        title={formatNumber(t('step'), '1') + " : " + t('set_pin')} >
                        <TwoVerticalTextFields
                            firstTextFieldBundle={firstTextFieldBundle}
                            secondTextFieldBundle={secondTextFieldBundle}
                        />
                    </TitleOverContentLayout>
                </div>
            )}
            {deviceType === DEVICE_TYPES.WATCH ? null : (
                <div className='step-two-container'>
                    <TitleOverContentLayout
                        title={formatNumber(t('step'), '2') + " : " + t('enter_emergency')} >
                        <TwoSelectsOverTextField
                            firstSelectItemBundle={locationSelectItemBundle}
                            secondSelectItemBundle={codeSelectItemBundle}
                            textFieldBundle={phoneNumberTextFieldBundle}
                        />
                    </TitleOverContentLayout>
                </div>
            )}
            {deviceType === DEVICE_TYPES.WATCH ? null : (
                <div className='step-three-container'>
                    <TitleOverContentLayout
                        title={formatNumber(t('step'), '3') + " : " + t('enter_msg')}>
                        <PlainTextField value={msgText} placeholder={placeholderText} onChange={handleOnMsgTextFieldChange} />
                    </TitleOverContentLayout>
                    <p>
                        <Typography variant='modalParagraph'>
                            {inputCount} / 100
                        </Typography>
                    </p>
                </div>
            )}
        </div>
    );


    const ActionBlock = (
        <ModalBottomButton title={t('lock')} onClick={handleLock} disabled={!checkPinValidation()} />
    );

    return (
        <div className='modal-lock-setting-container'>
            <BaseModal
                open={isOpen}
                onClose={handleCancel}
                title={lockTitle}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
        </div>
    );
};

export default ModalLockSetting
