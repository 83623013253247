import { IconButton, List, Menu, MenuItem, Box, Tab, Tabs, ToggleButton, ToggleButtonGroup, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { setting_more } from "../../../assets/images/deviceCard";
import 'react-spring-bottom-sheet/dist/style.css';
import { useTranslation } from 'react-i18next';
import './DeviceListCardMobileStyles.scss';

import NoDevicesPlaceholder from '../NoDevicesPlaceholder';
import DeviceListItemAccordion from '../DeviceListItemAccordion';
import { getAddressByDeviceId } from '../../../utills/functions';
import { signOutAndRedirectToHome } from '../../../utills/auth';
import { DEVICE_OWNER_TYPES, TAB_TYPES } from "../../../utills/enums/Devices";
import { setHeightOfBottomSheet, setBottomSheetMoreMenu } from '../../../slices/mapSlice';
import { setIsDeviceDetailOpen, setSelectedDevice, setActiveTabType, setDeviceOwnerType, setSelectedSettings } from '../../../slices/deviceListSlice';
import DeviceDetailCard from '../DeviceDetailCard';
import { setMobileDeviceDetailVisible } from '../../../slices/deviceDetailSlice';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import UserLocationIconButton from '../../buttons/UserLocationIconButton';
import DeviceSkeleton from '../../view-only/DeviceSkeleton/DeviceSkeleton';



function DeviceListCardMobile({ deviceListData }) {
    const { t } = useTranslation();
    const { isMobileLandscapeView } = useIsMobileView();

    // local statess
    const dispatch = useDispatch();
    const bottomSheetRef = useRef(); // BottomSheet 인스턴스를 가리킵니다. 
    const [accordionExpanded, setAccordionExpanded] = useState(false);


    // global states
    const isDeviceListLoading = useSelector((state) => state.deviceList.isDeviceListLoading);
    const addressListData = useSelector((state) => state.deviceList.addressListData);
    const mobileDeviceDetailVisible = useSelector((state) => state.deviceDetail.mobileDeviceDetailVisible);
    const familyDeviceListData = useSelector((state) => state.deviceList.familyDeviceListData);
    const familyItemListData = useSelector((state) => state.deviceList.familyItemListData);
    const activeTabType = useSelector((state) => state.deviceList.activeTabType);
    const deviceOwnerType = useSelector((state) => state.deviceList.deviceOwnerType);
    const isListEmpty = Array.isArray(deviceListData) && deviceListData.length <= 0 ? true : false;
    const isDeviceDetailOpen = useSelector((state) => state.deviceList.isDeviceDetailOpen);


    useEffect(() => {
        // Tab change할 경우, Device remove된 경우, 펼쳐진 accordian이 없도록 한다.
        setAccordionExpanded(false);
    }, [deviceOwnerType, deviceListData]);

    const handleTabChange = (event, newTab) => {
        dispatch(setActiveTabType(newTab));
        // tab 변경 시 selectedDevice를 null로 변경합니다. 
        dispatch(setSelectedDevice(null));
        if (newTab === TAB_TYPES.ITEMS) { // ITEM 은 My만 표시 함 
            dispatch(setDeviceOwnerType(DEVICE_OWNER_TYPES.MY))
        }

        // Tab change할 경우, 펼쳐진 accordian이 없도록 한다.
        setAccordionExpanded(false);
    };

    // bottom sheet 움직일 땐 more menu close 시킴
    const handleSheetChangeStart = () => {
        dispatch(setBottomSheetMoreMenu(false));
    };

    // BottomSheet이 움직일 때마다 높이를 업데이트합니다.
    const handleSheetChangeEnd = () => {
        if (bottomSheetRef && bottomSheetRef.current) {
            dispatch(setHeightOfBottomSheet(bottomSheetRef.current.height))
        }
    };

    const handleAccordionExpansionChange = (panel) => (event, isExpanded) => {
        setAccordionExpanded(isExpanded ? panel : false);
        if (!isExpanded) {
            dispatch(setSelectedDevice(null)); // 리스트 아이템 한 번 더 클릭해서 닫을 때 
        }
    }

    const checkIsVisible = () => {
        if (activeTabType === TAB_TYPES.DEVICES) {
            if (!Array.isArray(familyDeviceListData) || familyDeviceListData.length <= 0) {
                return false;
            }
        }

        if (activeTabType === TAB_TYPES.ITEMS) {
            if (!Array.isArray(familyItemListData) || familyItemListData.length <= 0) {
                return false;
            }
        }
        return true;
    }

    const getSnapPoints = () => {
        if (isMobileLandscapeView) {
            return [121, window.innerHeight - 20];
        } else {
            return [121, 381, 555];
        }
    }

    return (
        <>
            <div>
                {/* My or Family toggle button */}

                <div className='bottom-sheet-container'>
                    <BottomSheet className="bottom-sheet"
                        {...((!isDeviceListLoading && isListEmpty) ? { 'data-empty': true } : {})}
                        ref={bottomSheetRef}
                        open={true}
                        blocking={false}
                        snapPoints={() => getSnapPoints()}  // 최소, default, 최대 list view 높이
                        defaultSnap={() => 381}
                        header={<BottomSheetHeader IsToggleButtonGroupVisible={checkIsVisible()} />}
                        footer={<BottomSheetFooterTab selectedTab={activeTabType} handleTabChange={handleTabChange}></BottomSheetFooterTab>}
                        onSpringStart={handleSheetChangeStart}
                        onSpringEnd={handleSheetChangeEnd}
                    >
                        {isDeviceListLoading ?
                            <DeviceSkeleton /> :

                            isListEmpty ?
                                <NoDevicesPlaceholder /> :
                                <List className='bottom-sheet-list'>
                                    {
                                        deviceListData.map((device, index) => {
                                            const { address = t('location_unknown'), timeCreated = null } = getAddressByDeviceId(device.deviceId, addressListData) || {};
                                            return (
                                                <DeviceListItemAccordion
                                                    key={device.deviceId}
                                                    panelNumber={index}
                                                    device={device}
                                                    address={address}
                                                    updatedTime={timeCreated}
                                                    expanded={accordionExpanded}
                                                    handleAccordionExpansionChange={handleAccordionExpansionChange}
                                                >
                                                </DeviceListItemAccordion>
                                            )
                                        })
                                    }
                                </List>
                        }
                    </BottomSheet>
                </div>
            </div>
        </>
    );
}

const BottomSheetHeader = ({ IsToggleButtonGroupVisible = true }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobileLandscapeView } = useIsMobileView();

    const deviceOwnerType = useSelector((state) => state.deviceList.deviceOwnerType);
    const activeTabType = useSelector((state) => state.deviceList.activeTabType);
    const isActiveTabTypeItem =  activeTabType === TAB_TYPES.ITEMS ? true : false; 
    const bottomSheetMoreMenu = useSelector((state) => state.map.bottomSheetMoreMenu);
    const [moreMenuAnchorElement, setMoreMenuAnchorElement] = useState(null);
    const open = Boolean(bottomSheetMoreMenu);


    useEffect(() => {
        return () => handleMoreMenuClose(); // BottomSheet가 사라질 때 more menu를 닫아주어야 합니다.
    }, []);

    const handleTitle = () => {
        if (activeTabType === TAB_TYPES.ITEMS) {
            return t('items');
        } else {
            return t('devices');
        }
    }

    const handleMoreButtonClick = (event) => {
        setMoreMenuAnchorElement(event.currentTarget);
        dispatch(setBottomSheetMoreMenu(true));  // bottom sheet 움직일 땐 menu open 시킴
    };

    const handleMoreMenuClose = () => {
        setMoreMenuAnchorElement(null);
        dispatch(setBottomSheetMoreMenu(false));  // bottom sheet 움직일 땐 menu close 시킴
    };


    const handleLogoutClick = () => {
        signOutAndRedirectToHome();
        handleMoreMenuClose();
    };

    const handleSettingsClick = () => {
        // navigate('/settings', { state: { reloadDeviceList: false } });
        dispatch(setSelectedSettings(true));
        handleMoreMenuClose();
    };

    const handleDeviceOwnerTypeChange = (event, changeValue) => {
        if (changeValue !== null) {  // 현재 선택된 버튼 재선택시, 선택 해제되지 않도록 방지함.
            dispatch(setDeviceOwnerType(changeValue));
            // 여기에서,  list를 changeValue(my, family)를 이용해서 변경해주기.
        }
    }

    return (
        <div className='bottom-sheet-header-container'>
            <div className='toggle-button-group-container'>
                <ToggleButtonGroup
                    exclusive
                    value={deviceOwnerType}
                    onChange={handleDeviceOwnerTypeChange}
                    style={{ visibility: IsToggleButtonGroupVisible ? 'visible' : 'hidden' }}
                >
                    <ToggleButton className="owner-type-toggle" variant="contained" value={DEVICE_OWNER_TYPES.MY} aria-label="my">{isActiveTabTypeItem ? t('my_items') : t('my_devices')}</ToggleButton>
                    <ToggleButton className="owner-type-toggle" variant="contained" value={DEVICE_OWNER_TYPES.FAMILY} aria-label="family">{isActiveTabTypeItem ? t('family_items') : t('family_devices')}</ToggleButton>
                </ToggleButtonGroup>
            </div>

            <div className="bottom-sheet-header">
                <div className='title-menu-icon-container'>
                    {
                        isMobileLandscapeView ? null :
                            (
                                <div className='my-location-icon-container'>
                                    <div className='my-location-icon'>
                                        <UserLocationIconButton />
                                    </div>
                                </div>
                            )
                    }
                    <span className="bottom-sheet-header-text">
                        {handleTitle()}
                    </span>
                    <IconButton
                        className="bottom-sheet-header-menu-icon"
                        onClick={handleMoreButtonClick}
                    >
                        <img src={setting_more} alt=" " />
                    </IconButton>
                </div>

                <Menu className='device-list-card-menu'
                    anchorEl={moreMenuAnchorElement}
                    keepMounted
                    open={open}
                    onClose={handleMoreMenuClose}
                >
                    <MenuItem onClick={handleSettingsClick}>
                        <span className='more-menulist-text'> {t('settings')} </span>
                    </MenuItem>
                    <MenuItem onClick={handleLogoutClick}>
                        <span className='more-menulist-text'> {t('sign_out')} </span>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

const BottomSheetFooterTab = ({ selectedTab, handleTabChange }) => {
    const { t } = useTranslation();
    return (
        <div>
            <Box className="tabs-common">
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Device and items control tabs"
                    variant="standard"
                >
                    <Tab label={t('devices')} value={TAB_TYPES.DEVICES} />
                    <Tab label={t('items')} value={TAB_TYPES.ITEMS} />
                </Tabs>
            </Box>
        </div>
    );
}


export default DeviceListCardMobile;