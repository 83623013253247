import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BudsDirectionType, DeviceDetailStateType } from './deviceDetailSliceTypes';


const initialState: DeviceDetailStateType = {
    mobileDeviceDetailVisible: false,
    budsDirection: "NONE" // online 상태인 쪽 : "BOTH", "L", "R", "NONE" 
}

export const operationsSlice = createSlice({
    name: 'deviceDetail',
    initialState,
    reducers: {
        setBudsDirection: (state, action: PayloadAction<BudsDirectionType>) => {
            state.budsDirection = action.payload;
        },
        setMobileDeviceDetailVisible: (state, action: PayloadAction<boolean>) => {
            state.mobileDeviceDetailVisible = action.payload;
        },
    },
});


export const {
    setMobileDeviceDetailVisible,
    setBudsDirection
} = operationsSlice.actions;


export default operationsSlice.reducer;