import { GetHistoryApiResponse } from '../../api/findToStf/historyApi/historyApiTypes';
  import Bowser from "bowser"; 
import { BROWSER_NAME } from '../enums/Browsers';

export const MAIN_TITLE = "[[Personal Information from Find Service]]\r\n";

export const DEVICE_INFO_TITLE = "[Device Information]\r\n";
export const DEVICE_INFO_HEADER = "Phone number, Model Name, IMEI, MCC(Mobile Country Code), MNC(Mobile Network Code), CSC(Customer Software Configuration)\r\n";

export const LOCATION_INFO_TITLE = "[Location Information]\r\n";
export const LOCATION_INFO_HEADER = "Time Requested, Latitude, Longitude\r\n";

export const CALL_AND_MESSAGE_HISTORY_TITLE = "[Retrieve Calls and Messages History]\r\n";

export const LOCK_INFO_TITLE = "[Lock Information]\r\n"
export const LOCK_INFO_HEADER = "Time Requested, Contact number for returning call, Message on lock screen\r\n";

export const SIM_CHANGE_INFO_TITLE = "[SIM Card Changed Information]\r\n";
export const SIM_CHANGE_INFO_HEADER = "Previous phone number, Current phone number, Changed on\r\n";

export const OPERATION_HISTORY_TITLE = "[History of The Service]\r\n";
export const OPERATION_HISTORY_HEADER = "Date requested, Function\r\n";

export const downloadCSV = (csv: string, filename: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);

  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName();
  if (browserName === BROWSER_NAME.SAMSUNG_INTERNET_MOBILE) {
    window.open(link.href);
  } else {
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }
}

export const getTime = () => {
  const date = new Date();
  return `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
}

export const getCSVStringByAscOrderOfKeys = (objArr: Array<Record<string, string>>) => {
  let res = "";
  objArr.forEach(e => {
    const keys = Object.keys(e);
    keys.sort();
    const arr: Array<string> = [];
    keys.forEach(k =>  {
        arr.push(e[k]);
    });
    res += arr.join(", ");
    res += "\r\n";
  });
  return res;
}

export const convertFindInfoObjToCSV = (json: GetHistoryApiResponse['items']) => {
  let res = "\uFEFF";
  res += MAIN_TITLE;
  json.forEach(nthDevice => {
      res += DEVICE_INFO_TITLE;
      res += DEVICE_INFO_HEADER;
      const { phoneNum, modelName, id, mcc, mnc, csc }= nthDevice.device.device;
      res += `${phoneNum}, ${modelName}, ${id}, ${mcc}, ${mnc}, ${csc}\r\n`;
      res += "\r\n";

      res += LOCATION_INFO_TITLE;
      res += LOCATION_INFO_HEADER;
      if (nthDevice.locationHistory.length !== 0) {
          nthDevice.locationHistory.forEach(e => {
              const { timeRequested, latitude, longitude } = e;
              res += `${timeRequested}, ${latitude}, ${longitude}\r\n`;
          })
      }
      res += "\r\n";
      res += CALL_AND_MESSAGE_HISTORY_TITLE;
      if (nthDevice.callHistory.length !== 0) {
          const str = getCSVStringByAscOrderOfKeys(nthDevice.callHistory);
          res += str;
      }
      res += "\r\n";
      res += LOCK_INFO_TITLE;
      res += LOCK_INFO_HEADER;
      if (nthDevice.lockHistory.length !== 0) {
          nthDevice.lockHistory.forEach(e => {
              const { timeRequested, phoneNum, message }= e;
              res += `${timeRequested}, ${phoneNum}, ${message}\r\n`;
          });
      }
      res += "\r\n";
      res += SIM_CHANGE_INFO_TITLE;
      res += SIM_CHANGE_INFO_HEADER;
      if (nthDevice.simChangeHistory.length !== 0) {
          const str = getCSVStringByAscOrderOfKeys(nthDevice.simChangeHistory);
          res += str;
      }
      res += "\r\n";
      res += OPERATION_HISTORY_TITLE;
      res += OPERATION_HISTORY_HEADER;
      if (nthDevice.operationHist.length !== 0) {
          nthDevice.operationHist.forEach(e => {
              const {timeRequested, oprnName} = e;
              res += `${timeRequested}, ${oprnName}\r\n`;

          });
      }
      res += "\r\n";
      res += "\r\n";
  });
  return res;
}