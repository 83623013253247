import { useSelector } from "react-redux";
import { RootStateType } from "../../slices/types";
import { findImageLinkByModelCode, getBatteryLevelFromOper } from "../../utills/functions";
import { DeviceInfo } from "./useCurrentDeviceInfoTypes";
import { useEffect, useState } from "react";

const useDeviceInfo = () => {
    const deviceImageListData = useSelector((state: RootStateType) => state.deviceList.deviceImageListData);
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const batteryListData = useSelector((state: RootStateType) => state.deviceList.batteryListData);
    const [deviceInfoState, setDeviceInfoState] = useState<DeviceInfo>();

    useEffect(() => {
        const modelName = selectedDevice?.modelInfo?.modelName;
        const deviceModelCode = selectedDevice?.modelInfo?.deviceModelCode;
        const imageUrl = findImageLinkByModelCode(deviceImageListData, deviceModelCode || modelName);
        const batteryLevel = getBatteryLevelFromOper(batteryListData, selectedDevice?.deviceId);
        const isOnline = batteryLevel ? true : false;
        setDeviceInfoState({modelName, deviceModelCode, imageUrl, isOnline, type: selectedDevice?.type});
    }, [
        batteryListData,
        deviceImageListData,
        selectedDevice
    ]);
    
    return deviceInfoState;
}

export default useDeviceInfo