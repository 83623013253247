import createSageMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';


const sagaMiddleware = createSageMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    devTools: process.env.REACT_APP_NODE_ENV !== 'production'
});

sagaMiddleware.run(rootSaga);

export default store;