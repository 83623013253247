import React, { useEffect, useState } from 'react';
import { Card, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import CustomBatteryGauge from './CustomBatteryGauge';

import { ModalSimHistory, ModalIMEI, ModalRemoveDevice } from '../../modal';
import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { DEVICE_REGI_STATUS, OPERATION_TYPES } from '../../../utills/enums/commons';
import { DM_ERROR_STATUS_CODE_SUCCESS } from '../../../utills/enums/DMErrorCodes';
import NotifyMeItemCard from './NotifyMeItemCard';
import { MODAL_LOCK_ACTION } from '../../modal/ModalLock/ModalLockTypes';


const PhoneActionCard = ({ deviceId, selectedDeviceDetail }) => {
    const { t } = useTranslation();

    const { operationsResult, startOperation } = useDeviceOperations();
    const batteryLevel = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.battery;
    const isOnline = batteryLevel ? true : false;
    const isPendingDevice = selectedDeviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED;

    // Remove device (with Lock Status)
    const modalLockState = useSelector((state) => state.modalLock);
    const mqttConnectionData = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data;
    const mqttLockData = operationsResult[deviceId]?.[OPERATION_TYPES.LOCK]?.data;
    const [dimmEffect, setDimmEffect] = useState(true);

    useEffect(() => {
        if (mqttLockData === undefined) {
            if (mqttConnectionData?.oprnStsCd === "1900" && mqttConnectionData?.oprnResultCode === "1451") {
                setDimmEffect(false);
            } else {
                if (mqttConnectionData?.extra?.lockStatus?.fmmLock === false) {
                    setDimmEffect(false);
                } else {
                    setDimmEffect(true);
                }
            }
        }
        else {
            if (mqttLockData.oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS) {
                setDimmEffect(true);
            } else {
                setDimmEffect(false);
            }
        }
    }, [mqttConnectionData, mqttLockData]);

    useEffect(() => {
        if ((modalLockState?.status === MODAL_LOCK_ACTION.loading) || (modalLockState?.status === MODAL_LOCK_ACTION.waiting)) {
            setDimmEffect(true);
        } else if ((modalLockState?.status === MODAL_LOCK_ACTION.retry)) {
            setDimmEffect(false);
        }
    }, [modalLockState]);

    // SIM Card
    const hasSimHistory = selectedDeviceDetail?.lastSelectedDevice?.simChangeHistory;

    // Modal
    const [isOpenSimHistory, setIsOpenSimHistory] = useState(false);
    const [openIMEI, setOpenIMEI] = useState(false);
    const [openRemoveDevice, setOpenRemoveDevice] = useState(false);

    // Modal - SIM card history
    const handleSimHistoryModalOpen = () => {
        setIsOpenSimHistory(true);
    }
    const handleSimHistoryModalClose = () => {
        setIsOpenSimHistory(false);
    };

    // Modal - IMEI / MAC
    const handleIMEIModalOpen = () => {
        setOpenIMEI(true);
    }
    const handleIMEIModalClose = () => {
        setOpenIMEI(false);
    };

    // Modal - RemoveDevice
    const handleRemoveDeviceModalOpen = () => {
        setOpenRemoveDevice(true);
    }
    const handleRemoveDeviceModalClose = () => {
        setOpenRemoveDevice(false);
    };


    return (
        <div className="action-card">
            {!isOnline && !isPendingDevice
                ? <NotifyMeItemCard deviceId={deviceId} />
                : null
            }

            {
                !isPendingDevice && (
                    <Card className="inner-card">
                        <MenuItem>
                            <CustomBatteryGauge batteryLevel={batteryLevel}></CustomBatteryGauge>
                        </MenuItem>
                    </Card>
                )
            }


            <Card className="inner-card-list">
                {hasSimHistory &&
                    <>
                        <MenuItem onClick={handleSimHistoryModalOpen}>
                            {t('sim_history')}
                        </MenuItem>
                        <hr />
                    </>
                }
                <MenuItem onClick={handleIMEIModalOpen}>
                    {t('device_info')}
                </MenuItem>
                <hr />
                <MenuItem disabled={dimmEffect} onClick={handleRemoveDeviceModalOpen}>
                    {t('remove_device')}
                </MenuItem>
            </Card>

            {/* modal dialog */}
            <ModalSimHistory open={isOpenSimHistory} onClose={handleSimHistoryModalClose} deviceDetail={selectedDeviceDetail} />
            <ModalIMEI open={openIMEI} onClose={handleIMEIModalClose} passedValue={selectedDeviceDetail} />
            <ModalRemoveDevice open={openRemoveDevice} onClose={handleRemoveDeviceModalClose} deviceId={deviceId} />
        </div>
    )
}

export default PhoneActionCard;