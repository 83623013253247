import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { ModalSimHistoryProps } from './ModalSimHistoryTypes';

import BaseModal from '../BaseModal';
import { Typography } from '@mui/material';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { DEVICE_TYPES } from '../../../utills/enums/Devices';
import dayjs from 'dayjs';
import { transformUTCStringToDate } from '../../../utills/functions/time';


const ModalSimHistory = ({ open: isOpen, onClose, deviceDetail }: ModalSimHistoryProps) => {
    const { t } = useTranslation();

    // global states
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceType = selectedDevice?.type;
    const simHistoryList = deviceDetail?.lastSelectedDevice?.simChangeHistory;

    // ex. "휴대전화에서 지난 1년 동안 다음의 SIM 카드를 사용했습니다.""
    const modalSubtitle = deviceType === DEVICE_TYPES.PHONE ? t('used_sim_cards_phone') :
        deviceType === DEVICE_TYPES.TAB ? t('used_sim_cards_tablet') : null;

    // Dialog close
    const handleCloseModal = () => {
        onClose();
    }

    // 날짜 형식 변환 (from "20240412031250" to "Dec 1, 2023 11:00 PM")
    const formatDate = (dateString:string) :string => {
        const dateObject = transformUTCStringToDate(dateString);
        return dayjs(dateObject).format('MMM D, YYYY h:mm A');
    };

    const ContentBlock = (
        <>
            <Typography variant='modalParagraph'>
                {modalSubtitle}
                {
                    Array.isArray(simHistoryList) && simHistoryList.map(item => {
                        const changeDate = formatDate(item.simChangeDate);
                        const simNumber = item.currentNumber ? item.currentNumber : "Unknown Number";
                        return (
                            <>
                                <br /><br />
                                {changeDate}
                                <br />
                                {simNumber}
                            </>
                        )
                    })
                }
                

            </Typography>
        </>
    );

    const ActionBlock = (
        <>
            <ModalBottomButton title={t('ok')} onClick={handleCloseModal} />
        </>
    );

    return (
        <>
            <BaseModal
                open={isOpen}
                onClose={handleCloseModal}
                title={t('sim_history')}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
                disableX
            />
        </>
    );
};

export default ModalSimHistory