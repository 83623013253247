import { TagSpecificFields, getDeviceDetails } from '../../api/findToStf/devicesApi/devicesApi';
import { OperationDataType } from "../../slices/operationSlice/operationsSliceTypes";
import { DM_ERROR_RESULT_CODE, DM_ERROR_STATUS_CODE, DM_ERROR_STATUS_CODE_SUCCESS } from '../enums/DMErrorCodes';
import { OPERATION_TYPES, OPERATION_TYPES_TYPE } from "../enums/commons";
import { transformUTCStringToDate } from './time';

export const getDeviceDetailByDeviceIdAndDeviceList = async (deviceId: string, deviceList: any) => {
    const deviceDetailExisted = deviceList.find((e: any) => e.deviceId === deviceId);
    if (deviceDetailExisted) {
        return deviceDetailExisted;
    } else {
        try {
            return await getDeviceDetails(deviceId);
        } catch (err) {
            console.error(err);
        }
    }
}

export const getExistedDeviceDetailByDeviceIdAndDeviceList = (deviceId: string, deviceList: any) => {
    const deviceDetailExisted = deviceList.find((e: any) => e.deviceId === deviceId);
    if (deviceDetailExisted) {
        return deviceDetailExisted;
    }
    return null;
}

export const getFindOfflineInfo = (deviceDetail: any): null | { flagUpdateTime: string, isOn: boolean } => {
    const info = deviceDetail?.lastSelectedDevice?.offlineKeyPair;
    if (deviceDetail && info) {
        if (info.flagUpdateTime && info.findFlag) {  // offlineKeyPair 하위 필드에 flagUpdateTime, findFlag이 없는 경우 있음(ex. TAG SECURE)
            return { flagUpdateTime: info.flagUpdateTime, isOn: info.findFlag === 'Y' };
        }
    }
    return null;
}

export const getOperationStatusFromDeviceList = <T extends OperationDataType>(detailListData: Array<any> | null, deviceId: string, type: OPERATION_TYPES_TYPE): T | null => {
    if (!detailListData) {
        return null;
    }
    const detail = detailListData.find((item) => item.deviceId === deviceId);
    if (detail?.operation) {
        return detail.operation.find((item: any) => item.oprnType === type);
    }
    return null;
}

export const isTrackingOperationEnabledAndGetRequestIdFromDeviceList = (detailListData: Array<any> | null, deviceId: string): [boolean, string | null] => {
    if (!detailListData) {
        return [false, null];
    }
    const detail = detailListData.find((item) => item.deviceId === deviceId);
    if (detail?.operation as Array<any>) {
        let startDate = null;
        let requestId = null;
        let stopDate = null;
        detail?.operation.forEach((element: {
            oprnResultCode: any;
            oprnStsCd: string;
            oprnType: string;
            oprnDoneDate: string,
            reqId: string
        }) => {
            if (element.oprnType === OPERATION_TYPES.TRACK_LOCATION_START && (element.oprnStsCd === DM_ERROR_STATUS_CODE.OPERATION_PROCESSING)) {
                startDate = transformUTCStringToDate(element.oprnDoneDate);
                requestId = element.reqId;
            } else if (element.oprnType === OPERATION_TYPES.TRACK_LOCATION_START && element.oprnResultCode === DM_ERROR_RESULT_CODE.OPERATION_SUCCESS && element.oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS){
                stopDate = transformUTCStringToDate(element.oprnDoneDate);
            }
        });
        if (startDate && requestId) {
            return [true, requestId];
        } else if (startDate && requestId && stopDate && (startDate > stopDate)) {
            return [true, requestId];
        }
    }
    return [false, null];
}

export const getAllowThisDeviceToBeFoundOptionStatus = (deviceDetail: any) => {
    const config: 'DISABLED' | 'NORMAL' = deviceDetail?.lastSelectedDevice?.offLineConfig;
    if (deviceDetail && config) {
        return config;
    }
    return null;
};

export const createTagRingSpecificParams = (tagDevice:  { type: string; metadata: any } ): TagSpecificFields => {
    if (!tagDevice || !tagDevice.metadata) {
      console.warn("Tag device or metadata does not exist.");
      return {};
    }
    const { firmware, vendor } = tagDevice.metadata;
    const fields: TagSpecificFields = {};
    if (vendor?.mnId) {
      fields.mnid = vendor.mnId;
    } else {
      console.warn("mnId is missing.");
    }
    if (vendor?.setupId) {
      fields.setupid = vendor.setupId;
    } else {
      console.warn("setupId is missing.");
    }
    if (firmware?.version) {
      fields.firmwareversion = firmware.version;
    } else {
      console.warn("firmware version is missing.");
    }
    return fields;
  }