import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { performOperationLock } from '../../api/findToStf/devicesApi/devicesApi';
import { MODAL_LOCK_ACTION } from "../../components/modal/ModalLock/ModalLockTypes";
import { AsyncPerformOperationLockRequest, ModalLockStateType } from "./modalLockTypes";


const initialState: ModalLockStateType = {
    open: false,
    status: MODAL_LOCK_ACTION.initial,
    operationLock: {
        isLoading: false,
        data: null,
        error: null
    },
    lockSettingInfo: null
}


export const asyncPerformOperationLock = createAsyncThunk<any, AsyncPerformOperationLockRequest>(
    'setting/asyncPerformOperationLock',
    async ({ deviceId, userId, password, restrictionNumber, lockMessage }) => {
        return await performOperationLock({
            deviceId,
            userId,
            password,
            restrictionNumber,
            lockMessage
        });
    }
);

export const settingSlice = createSlice({
    name: "modalLock",
    initialState,
    reducers: {
        clearOperationLockResponse: (state) => {
            state.operationLock = {
                isLoading: false,
                data: null,
                error: null,
            };
        },
        setModalLockStatus: (state, action) => {
            state.status = action.payload;
        },
        setLockSettingInfo: (state, action) => {
            state.lockSettingInfo = action.payload;
        },
    },
    extraReducers: (builder) => {
        // asyncFetchUserHistoryBeforeErase
        builder.addCase(asyncPerformOperationLock.pending, (state, action) => {
            state.operationLock.isLoading = true;
        })
        builder.addCase(asyncPerformOperationLock.fulfilled, (state, action) => {
            state.operationLock.isLoading = false;
            state.operationLock.data = action.payload;
        })
        builder.addCase(asyncPerformOperationLock.rejected, (state, action) => {
            state.operationLock.isLoading = false;
            state.operationLock.error = action.error;
        })
    }
});

export const {
    clearOperationLockResponse,
    setModalLockStatus,
    setLockSettingInfo,
} = settingSlice.actions;

export default settingSlice.reducer;