import axios from "axios";
import axiosInstance from '../findAxiosConfig';
import { GetLostModeResponseType, GetTagLostModeResponseType, PostLostModeRequestType, PostLostModeResponseType, PutTagLostModeReponseType, PutTagLostModeRequestType } from "./lostModeApiTypes";
import { AxiosErrorLogger } from '../../logger/axiosErrorLogger';

const axiosInstanceWithNoHeader = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_FIND_SERVER,
});

axiosInstanceWithNoHeader.interceptors.request.use(
    config => {
        config.headers['x-sec-find-client-type'] = 'web'
        return config;
    },
    error => Promise.reject(error)
);

axiosInstanceWithNoHeader.interceptors.response.use(
    response => response,
    error => {
        AxiosErrorLogger.handle(error);
        return Promise.reject(error);
    }
);

export const getLostMode = async (deviceId: string): Promise<GetLostModeResponseType> => {
    try {
        const response = await axiosInstanceWithNoHeader.get(`/devices/${deviceId}/lostmode`);
        return response.data;
    } catch (error) {
        console.error('Error getLostMode:', error);
        throw error;
    }

}

export const postLostMode = async ({
    deviceId,
    enabled,
    phone_number,
    device_name,
    email,
    message,
    units
}: PostLostModeRequestType): Promise<PostLostModeResponseType> => {
    try {
        const response = await axiosInstance.post(`/devices/${deviceId}/lostmode`, {
            enabled,
            phone_number,
            device_name,
            email,
            message,
            units
        });
        return response.data;
    } catch (error) {
        console.error('Error postLostMode:', error);
        throw error;
    }
};


export const getTagLostMode = async (deviceId: string): Promise<GetTagLostModeResponseType['item']> => {
    try {
        const response = await axiosInstance.get(`/tag/devices/${deviceId}/lostmode`);
        return response.data.item;
    } catch (error) {
        console.error('Error getTagLostMode:', error);
        throw error;
    }

}

export const putTagLostMode = async ({
    deviceId,
    enabled,
    phoneNumber,
    email,
    message,
}: PutTagLostModeRequestType): Promise<PutTagLostModeReponseType> => {
    try {
        const response = await axiosInstance.put(`tag/devices/${deviceId}/lostmode`, {
            enabled,
            phoneNumber,
            email,
            message,
        });
        return response.data;
    } catch (error) {
        console.error('Error postTagLostMode:', error);
        throw error;
    }
};
