import React from 'react'
import { HistoryFunctionContextProvider } from './HistoryFunctionContext';

type PropType = {
  children: React.ReactNode
};

const RootContextProvider: React.FC<PropType> = ({ children }) => {
  return (
    <HistoryFunctionContextProvider>
      {children}
    </HistoryFunctionContextProvider>
  )
}

export default RootContextProvider;