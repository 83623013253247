import React from 'react'
import { TitleOverContentLayoutProps } from './TitleOverContentLayoutTypes';
import './TitleOverContentLayoutStyles.scss';

const TitleOverContentLayout = ({ title, style, descript, children }: TitleOverContentLayoutProps) => {
    return (
        <div className="title-over-content-layout-container">
            <div className={style === 'bold' ? 'title-bold-container' : 'title-container'}>
                {title}
            </div>
            <div className='descript-container'>
                {descript}
            </div>
            <div className='content-container'>
                {children}
            </div>
        </div>
    )
}

export default TitleOverContentLayout;