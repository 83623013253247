
// 로그 레벨을 정의하는 타입// Enums for LogLevel
export enum LogLevel {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
    DEBUG = 'DEBUG',
}

// 로그 데이터의 구조를 정의하는 타입
export type LogEntry = {
    timestamp: string; // 로그 기록 시간을 ISO 형식의 문자열로 나타냅니다.
    level: LogLevel; // 로그 레벨 (ERROR, WARNING, INFO, DEBUG 중 하나)
    component: string; // 로그를 기록하는 컴포넌트의 이름
    message: string; // 로그 메시지
    error?: Record<string, unknown>; // 에러객체 (Optional)
    browserInfo: string; // 사용자의 브라우저 정보
    screenSize: string; // 사용자 화면의 해상도 (너비 x 높이)
    currentPage: string; // 현재 페이지의 URL
    appVersion: string; // 애플리케이션의 버전
    userId?: string; // 사용자의 ID (Optional)
    userName?: string; // 사용자의 이름 (Optional)
    networkStatus?: string; // 네트워크 상태 (온라인 또는 오프라인)
    performanceMetrics?: string; // 브라우저 퍼포먼스 메트릭스
    additionalInfo?: Record<string, unknown>; // 기타 추가 정보 (Optional)
};