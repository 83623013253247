import React from 'react'
import { LoadingCircleProps } from './LoadingCircleTypes'
import './LoadingCircleStyles.scss';


function LoadingCircle({size = 'sm', fullHeight }: LoadingCircleProps) {
    return (
        <div className={`loading-circle-container${fullHeight ? ' loading-container-fullheight' : ''}`}>
            <div className="loading-container timing-function">
                <div className={`pg-icon-size-${size} circle-progress-animation-dot-1`} id="pg-icon-1"></div>
                <div className={`pg-icon-size-${size} circle-progress-animation-dot-2`} id="pg-icon-2"></div>
                <div className={`pg-icon-size-${size} circle-progress-animation-dot-3`} id="pg-icon-3"></div>
                <div className={`pg-icon-size-${size} circle-progress-animation-dot-4`} id="pg-icon-4"></div>
            </div>
        </div>
    )
}


export default LoadingCircle