import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStateType } from '../../slices/types';
import { getDeviceDetailByDeviceId } from '../../utills/functions';

const useSelectedDeviceDetailExtended = () => {
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const detailListData = useSelector((state: RootStateType) => state.deviceList.detailListData);
    const [selectedDeviceDetailExtended, setSelectedDeviceDetailExtended] = useState();

    useEffect(() => {
        const deviceId = selectedDevice?.deviceId;
        if (deviceId) {
            const deviceDetailExtented = getDeviceDetailByDeviceId(deviceId, detailListData);
            setSelectedDeviceDetailExtended(deviceDetailExtented);
        }
    }, [selectedDevice, detailListData]);

    return selectedDeviceDetailExtended;
}

export default useSelectedDeviceDetailExtended