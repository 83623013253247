import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    lang: {title: "USA / English", code: "en"} // Todo: county code + language code 로 추후에 분기할 때 초기 상태 변경하기 
}

const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.lang = action.payload;
        }
    },
});

export const {
    setLanguage
} = languageSlice.actions;

export default languageSlice.reducer;