import { VerifyPasswordsPostRequest, VerifyPasswordsPostResponse } from "./confirmPasswordTypes";

import axiosInstance from "../findAxiosConfig";

export const postVerifyPasswords = async ({
    redirectUri
}: VerifyPasswordsPostRequest): Promise<VerifyPasswordsPostResponse> => {
    try {
        const response = await axiosInstance.post(`/auth/oauth2/verify/passwords`, {
            redirectUri
        });
        return response.data;
    } catch (error) {
        console.error('Error on POST verify passwords:', error);
        throw error;
    }
};
