const typography = {
    fontFamily: ["'sec'", "-apple-system", "BlinkMacSystemFont", "'Segoe UI'", "'Roboto'", "'Oxygen'", "'Ubuntu'", "'Cantarell'", "'Fira Sans'", "'Droid Sans'", "'Helvetica Neue'", "sans-serif"].join(","),
    h1: {
      fontWeight: 500,
      fontSize: '35px',
      lineHeight: '40px'
    },
    h2: {
      fontWeight: 500,
      fontSize: '29px',
      lineHeight: '32px'
    },
    h3: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px'
    },
    h4: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px'
    },
    h5: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px'
    },
    h6: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px'
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '25px'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '14px'
    },
    body1: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '14px'
    },
    button: {
      fontSize: '14px'
    },
    caption: {
      fontSize: '12px'
    },
    overline: {
      fontSize: '12px',
      fontWeight: 500,
    },
    lockNormalTitle: {
        fontSize: '14px',
        fontWeight: 'bold'
    },
    lockNormalContent: {
        fontSize: '14px',
    },
    actionDialogTitle: {
      fontSize: '17px',
      fontWeight: 600
    },
    actionDialogContent: {
      fontSize: '14px',
      fontWeight: 400
    },
    actionButton: {
      fontSize: '16px',
      textTransform: 'none',
      fontWeight: 600
    },
    modalTitle: {
      fontSize: '17px',
      fontWeight: 600
    },
    modalHeading: {
      fontSize: '14px',
      fontWeight: 400
    },
    modalParagraph: {
      fontSize: '14px',
      fontWeight: 400
    },
    modalBoldParagraph: {
      fontSize: '16px',
      fontWeight: 600
    },
    modalBottomButton: {
      fontSize: '17px',
      fontWeight: 600
    },
    lastUpdateField: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'gray'
    },
    deviceDetailTitle: {
      fontSize: '24px',
      fontWeight: 400
    },
    addressInfoField: {
      fontSize: '14px',
      fontWeight: 400
    },
    lostModeTitle: {
      fontSize: '24px',
      fontWeight: 400
    },
    lostModeSubtitle: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#8C8C8C'
    },
    lostModeFooter: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#8C8C8C'
    },
    locationTrackerTitle: {
      fontSize: '17px',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '23px',
    },
    locationTrackerSubtitle: {
      fontSize: '13px',
      fontWeight: 400,
      color: '#8C8C91'
    },
    timeoutParagraph: {
      fontSize: '16px',
      fontWeight: 400,
      textAlign: 'center'
    }
  };
  
export default typography