import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { CustomZoomControlPropsType } from './CustomZoomControlTypes';
import UserLocationIconButton from '../../buttons/UserLocationIconButton';
import './CustomZoomControlStyles.scss';


const CustomZoomControl = ({ onZoomIn, onZoomOut }: CustomZoomControlPropsType) => {

    const { isMobilePortraitView, isMobileLandscapeView } = useIsMobileView();

    if (!isMobilePortraitView) {
        return (
            <div className="custom-zoom-controls">
                <UserLocationIconButton />
                {
                    !isMobileLandscapeView ?
                    (<>
                        <button onClick={onZoomIn}>
                            <AddIcon fontSize="inherit" />
                        </button>
                        <button onClick={onZoomOut}>
                            <RemoveIcon fontSize="inherit" />
                        </button>
                    </>): null
                }
           </div>
        );
    }
}

export default CustomZoomControl;