import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import langAfrica from "../../assets/locale/regions/africa.json";
import langAsiaPacific from "../../assets/locale/regions/asiaPacific.json";
import langEurope from "../../assets/locale/regions/europe.json";
import langAmerica from "../../assets/locale/regions/america.json";
import langMiddleEast from "../../assets/locale/regions/middleEast.json";

import { setSelectedLanguage, setSelectedLangTitle, getSelectedLangTitle, setCountryCode2 } from "../../utills/auth";
import { TABLET_VIEW_QUERY } from '../../utills/constants'
import { setLanguage } from "../../slices/languageSlice";
import { LanguageChangeProps } from "./LanguagesTypes";
import "./LanguageStyles.scss";
import { useIsMobileView } from "../../hooks/useIsMobileView";


const Languages = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();
    const { isMobilePortraitView, isMobileLandscapeView } = useIsMobileView();
    const isTabletView = useMediaQuery(TABLET_VIEW_QUERY);

    const selectedTitle = getSelectedLangTitle();

    const africa = langAfrica;
    const asiaPacific = langAsiaPacific;
    const europe = langEurope;
    const america = langAmerica;
    const middleEast = langMiddleEast

    // '←' text handler
    const handleNavigator = () => {
        navigate(-1);
    }

    const handleChangeLang = ({ title, code, countryCode2 }: LanguageChangeProps) => {
        i18n.changeLanguage(code);  // "ko"
        setCountryCode2(countryCode2);  // "KR"
        setSelectedLanguage(code);  // "ko"
        setSelectedLangTitle(title);  // "Korea / 한국어"
        dispatch(setLanguage({ title, code }))  // "Korea / 한국어", "ko"
        navigate(-1);
    }

    const getContainerClassName = () => {
        if (isMobilePortraitView) {
            return "language-container-mobile";
        } else if (isTabletView || isMobileLandscapeView) {
            return "language-container-tablet";
        } else {
            return "language-container";
        }
    }

    // <Link to="/login"> ← </Link> Select your location and lanuage
    return (
        <div className={getContainerClassName()} >
            <div className="language-header">
                <a onClick={() => { handleNavigator() }}> <ArrowBackIosNewIcon /> </a>
                <p>
                    {t('select_your_location_and_language')}
                </p>
            </div>

            <div>
                <div className="language-list-title">
                    Africa
                </div>
                <div className="language-list">
                    <ul>
                        {africa.map(({ code, title, countryCode2 }, index) => (
                            <li key={index}>
                                <span onClick={() => { handleChangeLang({ code, title, countryCode2 }) }}>
                                    {selectedTitle === title ? <a className="language-selected"> {title} </a> : <a> {title} </a>}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="language-list-title">
                    Asia Pacific
                </div>
                <div className="language-list">
                    <ul>
                        {asiaPacific.map(({ code, title, countryCode2 }, index) => (
                            <li key={index}>
                                <span onClick={() => { handleChangeLang({ code, title, countryCode2 }) }}>
                                    {selectedTitle === title ? <a className="language-selected"> {title} </a> : <a> {title} </a>}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="language-list-title">
                    Europe
                </div>
                <div className="language-list">
                    <ul>
                        {europe.map(({ code, title, countryCode2 }, index) => (
                            <li key={index}>
                                <span onClick={() => { handleChangeLang({ code, title, countryCode2 }) }}>
                                    {selectedTitle === title ? <a className="language-selected"> {title} </a> : <a> {title} </a>}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="language-list-title">
                    America
                </div>
                <div className="language-list">
                    <ul>
                        {america.map(({ code, title, countryCode2 }, index) => (
                            <li key={index}>
                                <span onClick={() => { handleChangeLang({ code, title, countryCode2 }) }}>
                                    {selectedTitle === title ? <a className="language-selected"> {title} </a> : <a> {title} </a>}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="language-list-title">
                    Middle East
                </div>
                <div className="language-list">
                    <ul>
                        {middleEast.map(({ code, title, countryCode2 }, index) => (
                            <li key={index}>
                                <span onClick={() => { handleChangeLang({ code, title, countryCode2 }) }}>
                                    {selectedTitle === title ? <a className="language-selected"> {title} </a> : <a> {title} </a>}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Languages;