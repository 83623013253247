import React, { useEffect, useState } from 'react';
import { Card, MenuItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomBatteryGauge from './CustomBatteryGauge';
import { ModalLost, ModalIMEI } from '../../modal';
import ModalLostSetting from '../../modal/ModalLostSetting';
import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { DEVICE_REGI_STATUS, OPERATION_TYPES } from '../../../utills/enums/commons';
import NotifyMeItemCard from './NotifyMeItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { getTagLostModeByDeviceIdAction, getTagMetadataAction, setLostModeModalOpen, setLostModeModalSettingOpen } from '../../../slices/lostModeSlice';
import { getCountryCode } from '../../../utills/auth';


const TagActionCard = ({ deviceId, selectedDeviceDetail }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { operationsResult, startOperation } = useDeviceOperations();
    const batteryLevel = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.battery;
    const isPendingDevice = selectedDeviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED;


    // Modal
    const [openIMEI, setOpenIMEI] = useState(false);

    const {
        getTagLostModeState: {
            isLoading,
            data: {
                enabled: lostModeEnabled,
            },
            errors
        },
        getTagMetadataState: {
            isGetTagMetaDataLoading,
            data: {
                supportedFeatures: {
                    nfc
                }
            },
        }
    } = useSelector((state) => state.lostMode);
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);


    useEffect(() => {
        dispatch(getTagLostModeByDeviceIdAction(deviceId));

    }, []);

    const firmwareVersion = selectedDevice?.metadata?.firmware?.version;
    const mnId = selectedDevice?.metadata?.vendor?.mnId;
    const setupId = selectedDevice?.metadata?.vendor?.setupId;
    useEffect(() => {
        console.log(firmwareVersion, mnId, setupId)
        if (firmwareVersion && mnId && setupId) {
            dispatch(getTagMetadataAction({
                firmwareVersion,
                mnId,
                setupId
            }));
        }
    }, [dispatch, firmwareVersion, mnId, setupId]);


    // Modal - ModalLost
    const handleLostModeModalOpen = () => {
        // 'Off' 였을때  설명 dialog를 열어야하고
        // 'On' 이었을때  셋팅 dialog를 열어야 함.
        if (!lostModeEnabled) {
            dispatch(setLostModeModalOpen(true));
        } else {
            dispatch(setLostModeModalSettingOpen(true));
        }
    }

    // Modal - IMEI / MAC
    const handleIMEIModalOpen = () => {
        setOpenIMEI(true);
    }
    const handleIMEIModalClose = () => {
        setOpenIMEI(false);
    };

    const isLostModeShown = () => {
        return !isGetTagMetaDataLoading && nfc && (getCountryCode() === "KOR" || getCountryCode() === "USA") && !isPendingDevice;
    }

    return (
        <div className="action-card">
            {!batteryLevel && <NotifyMeItemCard deviceId={deviceId} />}

            {isLostModeShown() && (
                <Card className="inner-card-lostmode">
                    <ListItemButton onClick={handleLostModeModalOpen}>
                        <ListItemText>{t('lost_mode')}</ListItemText>
                        <ListItemIcon className="inner-card-lostmode-status">
                            {lostModeEnabled ? t('on') : t('off')}
                        </ListItemIcon>
                    </ListItemButton>
                </Card>)
            }

            {!isPendingDevice && (
                <Card className="inner-card">
                    <MenuItem>
                        <CustomBatteryGauge batteryLevel={batteryLevel}></CustomBatteryGauge>
                    </MenuItem>
                </Card>
            )}


            <Card className="inner-card-list">
                <MenuItem onClick={handleIMEIModalOpen}>
                    {t('device_info')}
                </MenuItem>
            </Card>

            {/* modal dialog */}
            <ModalLost />
            <ModalLostSetting />
            <ModalIMEI open={openIMEI} onClose={handleIMEIModalClose} passedValue={selectedDeviceDetail} />
        </div>
    );
}

export default TagActionCard;