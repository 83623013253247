import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './TrackLocationBlocksStyles.scss';

import ModalBottomButton from '../../../buttons/ModalBottomButton/ModalBottomButton';
import { t } from 'i18next';
import { ActionBlockTypes } from './TrackLocationBlocksTypes';
import LottieLoadingCircle from '../../../view-only/LottieLoadingCircle/LottieLoadingCircle';
import { getTextByDeviceType } from '../../../../utills/functions/branchFunctions';
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from '../../../../utills/enums/Devices';
import { formatNumber } from '../../../../utills/functions/stringPhasers';
import LocationTracker from '../LocationTracker';


export const ContentBlock = ({ deviceType }: { deviceType: DEVICE_TYPES_TYPE }) => {

    return (
        <div className='modal-track-location-container'>
            <Stack alignItems='center' sx={{ overflowY: "hidden" }}>
                <Box>
                    <LottieLoadingCircle />
                    <Typography marginTop="16px" textAlign='center'>{
                        getTextByDeviceType(
                            deviceType,
                            {
                                [DEVICE_TYPES.PHONE]: t('track_your_phone_dot'),
                                [DEVICE_TYPES.TAB]: t('track_your_tablet_dot')
                            }
                        )
                    }</Typography>
                    <Typography marginTop='24px'>
                        {getTextByDeviceType(
                            deviceType,
                            {
                                [DEVICE_TYPES.PHONE]: formatNumber(t('phone_location_will_update_every_minutes'), '15'),
                                [DEVICE_TYPES.TAB]: formatNumber(t('tablet_location_will_update_every_minutes'), '15')
                            }
                        )}
                    </Typography>
                </Box>
                <div className="location-tracker-container">
                    <LocationTracker />
                </div>
            </Stack>
        </div>
    );
};

export const ActionBlock = ({ onActionButtonClick }: ActionBlockTypes) => (
    <>
        <ModalBottomButton title={t('stop')} onClick={onActionButtonClick} />
    </>
);
