/**
 * AuthRedirect Component
 *
 * This page handles the authentication callback after a user has attempted
 * to log in via an OAuth provider(SamsungAccount). It is responsible for:
 * - Extracting and validating the authentication code and state from the URL.
 * - Making necessary API calls to exchange the authentication code for an access token.
 * - Storing the access token and any related authentication data in session storage.
 * - Redirecting the user to the appropriate page based on the success or failure of the authentication process.
 *
 * Typically, this component is rendered when the OAuth provider redirects the user
 * back to our application after the login attempt.
 * 
 * URL example below
 * http://localhost:3000/auth
 * ?auth_server_url=eu-auth2.samsungosp.com
 * &code=H9hHXjhCV3vJRBDSvkAiVAiZL
 * &code_expires_in=300
 * &state=test_auth_state
 * &api_server_url=eu-auth2.samsungosp.com
 * 
 */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    clearSessionAndUserInfo, setAuthServerUrl, signOutAndRedirectToHome,
    setCountryCode, setTokenAndExpiry, setUserId, isLoggedIn,
    setOptinStatus, getOptinStatus, setOptinParent, setOptinLocation, getOptinLocation, setUserName, setChildAccountSupported, setSsoLoginDeviceId, getSsoLoginDeviceId,
} from '../utills/auth';
import LoadingCircle from '../components/view-only/LoadingCircle';
import { getAccessToken } from '../api/find/userApi';
import { getAllOptinStatus } from '../api/find/optinApi';
import { isFmmDeviceIdFormat } from '../utills/functions/validators';
import { parseJsonOrReturnRaw, replaceAllHtmlEntityWithDoubleQuote } from '../utills/functions/stringPhasers';
import { getDeviceIdFromState } from '../utills/functions/branchFunctions';


const REDIRECT_URI = decodeURIComponent(window.location.origin + "/auth");
// axios 인스턴스 생성


function AuthRedirect() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // If login information exists in local storage  // Skip the authentication process
        if (isLoggedIn()) {
            // except Onboarding flow (온보딩은 Login 이후에 이뤄지므로 예외가 필요하다)
            if (getOptinStatus() === 'false' || getOptinLocation() === 'false') {
                signOutAndRedirectToHome();
            }
            else {
                navigate('/');
            }
        }
        else { // If login information NOT exists in local storage
            // Check if authCode is not delivered to URL, 
            const authCode = new URLSearchParams(location.search).get('code');
            const authServerUrl = new URLSearchParams(location.search).get('auth_server_url');
            const stateValue = new URLSearchParams(location.search).get('state');

            if (authCode && authServerUrl) { // SSO login when authCode is sent to url
                // fetch access token with authCode
                const headers = {
                    authCode: authCode,
                    authServerUrl: authServerUrl,
                    redirectUri: REDIRECT_URI
                }

                getAccessToken(headers)
                    .then(accessTokenData => {
                        setTokenAndExpiry(accessTokenData.accessToken, accessTokenData.expiresIn);
                        setUserId(accessTokenData.profile.userId);
                        setCountryCode(accessTokenData.profile.countryCode);
                        if(accessTokenData.profile.countryCode === 'KOR'){
                            setUserName(`${accessTokenData.profile.familyName}${accessTokenData.profile.givenName}`);
                        } else {
                            setUserName(`${accessTokenData.profile.givenName} ${accessTokenData.profile.familyName}`);
                        }
                        setAuthServerUrl(authServerUrl);
                        setChildAccountSupported(accessTokenData.childAccountSupported);
                        initAndFetchUserData(accessTokenData.profile.userId, accessTokenData.childAccountSupported);
                        if (stateValue) {
                            const decodedStateValue = replaceAllHtmlEntityWithDoubleQuote(stateValue);
                            const parsedStateValue = parseJsonOrReturnRaw(decodedStateValue);
                            const deviceIdFromState = getDeviceIdFromState(parsedStateValue);
                            if (isFmmDeviceIdFormat(deviceIdFromState)) {
                                setSsoLoginDeviceId(deviceIdFromState); // SSO 로그인을 통해 App to Web 진입시 전달 받은 deviceId를 저장 
                            }
                        }
                    })
                    .catch(e => {
                        console.error('Your login information could not be retrieved properly. Please log in again. : ' + e);
                        navigate('/login');
                    });

            } else { // If authCode is NOT delivered to URL, Go to /login
                console.error('Required parameters are missing from the URL. : authCode, authServerUrl');
                console.error('Your login information has expired or is missing. Please log in again.');
                navigate('/login');
            }
        }

    }, [location]);



    async function initAndFetchUserData(userId, childAccountSupported) {
        try {
            // optin status
            const {
                isChild,
                isChildAccountSupported,
            } = childAccountSupported;

            const responseData = await getAllOptinStatus(userId);
            if (responseData) {
                // SA계정으로 앱이든 웹이든 최초 로그인이라서 DB에 필드가 없는경우 (responseData = [] 인 경우)
                if (responseData.length === 0) {
                    setOptinStatus(false);
                    setOptinLocation(false);
                    if (isChild && isChildAccountSupported) { // 아동 
                        setOptinParent(false);
                    } else {  // 성인
                        setOptinParent(undefined);
                    }
                }
                else {
                    // 성인의 경우 이 필드자체가 없으므로 responseData로 set할 수 없음.
                    setOptinParent(undefined);

                    responseData.forEach((item) => {
                        if (item.type === "location") {
                            if (item.is_agreed === 1) {
                                setOptinStatus(true);
                            }
                            else {
                                setOptinStatus(false);
                            }
                        }

                        if (isChild && isChildAccountSupported && item.type === "parent_agreement") {  // 아동 조건 추가하기
                            if (item.is_agreed === 1) {
                                setOptinParent(true);
                            }
                            else {
                                setOptinParent(false);
                            }
                        }

                        if (item.type === "web_location_setting") {
                            if (item.is_agreed === 1) {
                                setOptinLocation(true);
                            }
                            else {
                                setOptinLocation(false);
                            }
                        }
                    })
                }
            }
            // responseData = undefined 인 경우 
            else {
                setOptinStatus(false);
                setOptinParent(false);
                setOptinLocation(false);
            }
        }
        catch (error) {
            console.error("Error in fetchUserData:", error);
            console.error('Failed to retrieve user and device information : ' + error);
            clearSessionAndUserInfo();  // 세션 정보 지우고 다시 로그인화면으로 이동하도록 유도하기 
            navigate('/login', { replace: true });
        }
        finally {
            handleCompleteInitUserData();
        }
    }

    const handleCompleteInitUserData = () => {
        const optinStatus = getOptinStatus();
        const optinLocation = getOptinLocation();

        // Login 완료 후, 동의된게 하나도 없는 상태.
        if (optinStatus === false) {
            navigate('/onboarding');
        }
        // 'Location Settings' '비동의' 상태
        else if (optinLocation === false) {
            navigate('/onboardingLocation');
        }
        // 'use of location' 'Location Settings'  둘 다 '동의' 상태
        else {
            navigate('/');
        }
    }


    return (
        <div>
            <div className='signin-centered-container'>
                <LoadingCircle size='md' />
            </div >
        </div>

    );
}





export default AuthRedirect;