import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import './DeviceSkeletonStyles.scss';

const DeviceSkeleton = () => {
    return (
        <div className='device-list-skeleton-container'>
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
            <div className='typography-container'>
                <div className='title-container'>
                    <Typography variant="h6"><Skeleton /></Typography>
                    <Typography variant="h6"><Skeleton /></Typography>
                </div>

                <div className='subtitle-container'>
                    <Typography variant="h6"><Skeleton /></Typography>
                </div>
            </div>
        </div>
    )
}

export default DeviceSkeleton;
