import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MapWithGoogle, { GOOGLE_MAPS_API_KEY } from "./MapWithGoogle/MapWithGoogle";
import MapWithKakao from "./MapWithKakao";
import { MAP_TYPES } from "../../utills/enums/commons";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCode } from "../../utills/auth";
import { setCurrentMapType, setMapCenter } from "../../slices/mapSlice";
import { getDevicePositionForBuds, getDevicePositionByDeviceId, isLocationInKorea, isValidPosition } from "../../utills/functions";
import axios from "axios";
import { pc_loading_location_bg } from "../../assets/images/map";

const MapWithGoogleMemoized = React.memo(MapWithGoogle);
const MapWithKakaoMemoized = React.memo(MapWithKakao);

const FullScreenMap = ({ deviceListData }) => {
    /* 
    Show google map, kakaomap, etc. according to country
    */
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const currentMapType = useSelector((state) => state.map.currentMapType);
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const locationListData = useSelector((state) => state.deviceList.locationListData);
    const currentUserLocation = useSelector((state) => state.map.currentUserLocation);
    const isMyDeviceLocationLoading = useSelector((state) => state.deviceList.isMyDeviceLocationLoading);
    const mapCenter = useSelector((state) => state.map.mapCenter);
    const budsSelectedUnitList = useSelector(state => state.deviceList.budsSelectedUnitList);

    useEffect(() => {
        // Samsung Account UserInfo's countrycode 에 따른 map type 선택
        if (getCountryCode() === 'KOR') {
            dispatch(setCurrentMapType(MAP_TYPES.KAKAO));
        } else {
            dispatch(setCurrentMapType(MAP_TYPES.GOOGLE));
        }
        fetchDefaultLocationAndSetMapCenter();
    }, []);


    useEffect(() => {
        if (selectedDevice) {
            const countryCode = getCountryCode();
            const position = getDevicePositionForBuds(selectedDevice, locationListData, budsSelectedUnitList);
            if (isValidPosition(position)) {
                determineMapProvider(countryCode, position.lat, position.lng);
                dispatch(setMapCenter(position));
            }

        } else {
            dispatch(setMapCenter(null));
        }

    }, [selectedDevice]);

    useEffect(() => {
        if (currentUserLocation) {
            const countryCode = getCountryCode();
            if (isValidPosition(currentUserLocation)) {
                determineMapProvider(countryCode, currentUserLocation.lat, currentUserLocation.lng);
                dispatch(setMapCenter(currentUserLocation));
            }
        }

    }, [currentUserLocation]);


    useEffect(() => {
        if (deviceListData && deviceListData.length > 0) {
            // geolocations 데이터를 가진 디바이스를 찾습니다.
            const deviceWithValidGeolocations = locationListData.find(device =>
                device.geolocations &&
                device.geolocations.length > 0 &&
                device.geolocations.some(geo => geo.latitude && geo.longitude && geo.timeCreated)
            );
            // geolocations 데이터를 가진 디바이스의 위치로 맵의 중심을 지정합니다.
            const position = getDevicePositionByDeviceId(deviceWithValidGeolocations?.deviceId, locationListData);
            if (isValidPosition(position)) {
                determineMapProvider(getCountryCode(), position.lat, position.lng);
                dispatch(setMapCenter(position));
            }
        }

    }, [locationListData]);


    function fetchDefaultLocationAndSetMapCenter() {

        axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAPS_API_KEY}`)
            .then(response => {
                const lat = response.data?.location?.lat;
                const lng = response.data?.location?.lng;
                if (lat && lng) {
                    const location = { lat, lng };
                    determineMapProvider(getCountryCode(), lat, lng);
                    dispatch(setMapCenter(location));
                } else {
                    console.error('Default Location data is undefined');
                }
            })
            .catch(error => {
                console.error('Error with fetchDefaultLocationAndSetMapCenter: ', error);
            });
    }

    /**
     * Determines the map provider (Kakao or Google) based on user's country and the selected item's location.
     *
     * Rules:
     * 1. If the user's country is 'KOR':
     *    1-1. If the selected item's location is in Korea: Use Kakao Maps.
     *    1-2. If the selected item's location is outside Korea: Use Google Maps.
     * 2. If the user's country is not 'KOR': Always use Google Maps regardless of the item's location.
     *
     * @param {string} userCountryCode - The country code of the user (e.g., 'KOR' for Korea).
     * @param {number} latitude - Latitude of the selected item's location.
     * @param {number} longitude - Longitude of the selected item's location.
     */
    const determineMapProvider = (userCountryCode, latitude, longitude) => {
        if (userCountryCode === 'KOR') {
            // We can use some bounds or conditions to decide if the lat/lng is in Korea.
            if (isLocationInKorea(latitude, longitude)) {
                dispatch(setCurrentMapType(MAP_TYPES.KAKAO));
            } else {
                dispatch(setCurrentMapType(MAP_TYPES.GOOGLE));
            }
        } else {
            dispatch(setCurrentMapType(MAP_TYPES.GOOGLE));
        }
    };


    /**
     * 개발환경에서만 사용하는 토글 맵 버튼 (GOOGLE MAP <-> KAKAO MAP)
     */
    const toggleMapType = () => {
        const mapTypesArray = Object.values(MAP_TYPES);
        const currentIndex = mapTypesArray.indexOf(currentMapType);
        const newMapType = mapTypesArray[(currentIndex + 1) % mapTypesArray.length];
        dispatch(setCurrentMapType(newMapType));
    };

    return (
        <>
            {isMyDeviceLocationLoading ? (
                <div>
                    <img src={pc_loading_location_bg} alt="loading-map-backround" className="loading-map-backround" />
                </div>
            ) : (
                <div>
                    {currentMapType === MAP_TYPES.GOOGLE
                        && (<MapWithGoogleMemoized
                            determineMapProvider={determineMapProvider}
                            deviceListData={deviceListData}
                        ></MapWithGoogleMemoized>)}

                    {currentMapType === MAP_TYPES.KAKAO
                        && (<MapWithKakaoMemoized
                            determineMapProvider={determineMapProvider}
                            deviceListData={deviceListData}
                        ></MapWithKakaoMemoized>)}

                    {/* 개발 환경일 때만 토글 버튼 보이기 */}
                    {process.env.REACT_APP_NODE_ENV === 'development' && (
                        <button
                            onClick={toggleMapType}
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                zIndex: 3 // z-index 값을 높게 설정
                            }}
                        >
                            {currentMapType}
                        </button>
                    )}
                </div>
            )
            }
        </>
    );
};


export default FullScreenMap;
