import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';

import { logFindBaseURLAxios } from './api/find/findAxiosConfig';
import AppRouter from './routes/AppRouter';
import customTheme from './utills/theme/customTheme';
import ErrorBoundary from './components/exception/ErrorBoundary';
import useAutoSignOut from './hooks/useAutoSignOut';
import './assets/scss/base.scss'
import { importFontByLanguage } from './utills/functions/fontFucntions';
import { importLanguageFromStorage } from './utills/functions/languageFunctions';
import { showAppVersion, disableConsoleLogOnProduction } from './utills/functions/envRelatedFunctions';
import RootContextProvider from './contexts/RootContextProvider';


window.version = showAppVersion;

function App() {
  const { i18n } = useTranslation();
  disableConsoleLogOnProduction(); // production 에서 console.log 비활성화
  useAutoSignOut();

  /**
   * 사이트 새로고침 시 최초 한 번 액션 
   */
  useEffect(() => {
    logFindBaseURLAxios();
    importLanguageFromStorage(i18n); // set default lang
    showAppVersion(); // 최초 앱 버전 출력 
  }, []);

  useEffect(() => {
    importFontByLanguage(i18n.language);
  }, [i18n.language]);


  return (
    <BrowserRouter>
      <ThemeProvider theme={customTheme}>
        <RootContextProvider>
          <CssBaseline />
          <ErrorBoundary>
            <AppRouter></AppRouter>
          </ErrorBoundary>
        </RootContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}



export default App;
