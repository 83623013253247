export type ModalEraseProps = {
    open: boolean,
    deviceId: string,
}

export const ModalEraseActionEnum = {
    erase: 'erase',
    cancel: 'cancel',
    done: 'done',
    cancelErase: 'cancel_erase',
    error: 'error'
} as const;

export type ModalEraseActionType = typeof ModalEraseActionEnum[keyof typeof ModalEraseActionEnum];