import React from 'react';
import { Select, MenuItem, FormControl, useMediaQuery } from '@mui/material';
import { PlainSelectProps } from './PlainSelectTypes';
import './PlainSelectStyles.scss';
import { extractParenteses, removeParenthesesValues } from '../../../utills/functions/stringPhasers';
import { useIsMobileView } from '../../../hooks/useIsMobileView';


const PlainSelect = ({ itemList, value, onChange, disableUnderline = false, hasExtentionNumber, fullWidth, disabled }: PlainSelectProps) => {
  const { isMobilePortraitView } = useIsMobileView();

  return (
    <div className="plain-select-container">
      <FormControl variant="standard" fullWidth={fullWidth}>
          <Select
            disabled={disabled}
            id={hasExtentionNumber ? "plain-select-id" : undefined}
            disableUnderline={disableUnderline}
            value={value}
            renderValue={hasExtentionNumber ? (selected) => {
              const targetLength = isMobilePortraitView ? 20 : 35;
              const strLength = selected.length;
              const country = removeParenthesesValues(selected);
              const extension = extractParenteses(selected);

              const mustBeDeletedLength = strLength - targetLength + 3;

              const renderStr = mustBeDeletedLength > 0 ? `${country.slice(0, country.length-mustBeDeletedLength + 1)}...${extension}`: selected;

              return renderStr; // something similar to this
             } : undefined}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "300px",
                  borderRadius: "30px",
                  '&::-webkit-scrollbar-track': {
                    margin: "30px 0px"
                  },
                  },
                }
              }
            }
            onChange={onChange}>
              {itemList
                .map(({title, value}, i) => (
                    <MenuItem sx={{ 
                      whiteSpace: "unset",
                      wordBreak: "break-all"
                    }} key={i} value={value} >{title}</MenuItem>
                  )
                )}
          </Select>
      </FormControl>
    </div>
  );
}
 
export default PlainSelect;