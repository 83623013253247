import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDeviceDetails, removeDevice } from '../../api/findToStf/devicesApi/devicesApi';

export const asyncRemoveDevice = createAsyncThunk<any, {deviceId: string}>(
    'deviceList/asyncRemoveDevice',
    async ({ deviceId }) => {
        const response = await removeDevice(deviceId);
        return { response, deviceId };
    }
);


export const asyncUpsertDeviceDetail = createAsyncThunk<any, {deviceId: string}>(
    'deviceList/asyncUpsertDeviceDetail',
    async ({ deviceId }) => {
        const response = await getDeviceDetails(deviceId);
        return { response, deviceId };
    }
);
