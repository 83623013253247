import React from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import './ModalTrackLocationStyles.scss';

import BaseModal from '../BaseModal';
import {
    FmmOffActionBlock,
    FmmOffContentBlock,
    InitialActionBlock,
    InitialContentBlock,
    StoppedActionBlock,
    StoppedContentBlock,
    StoppingActionBlock,
    StoppingContentBlock,
    TimeoutActionBlock,
    TimeoutContentBlock,
    TrackingActionBlock,
    TrackingContentBlock,
    
} from './TrackLocationBlocks';
import { RootStateType } from '../../../slices/types';
import { clearGetTrackLocationAction, postOperationTrackLocationAction, setModalTrackLocationOpen, setModalTrackLocationState, setRestartTrackingActionCardOpen, setStopTrackingActionCardOpen, setTrackLocationInfoAction, stopOperationTrackLocationAction } from '../../../slices/modalTrackLocationSlice';
import { MODAL_TRACK_LOCATION_STATE } from '../../../slices/modalTrackLocationSlice/modalTrackLocationSliceTypes';
import BaseActionCard from '../../cards/actions/BaseActionCard';
import useMqttDeviceConnection from '../../../hooks/useMqttDeviceConnection';
import { OPERATION_TYPES } from '../../../utills/enums/commons';
import { getTextByDeviceType } from '../../../utills/functions/branchFunctions';
import { DEVICE_TYPES } from '../../../utills/enums/Devices';
import { formatNumber } from '../../../utills/functions/stringPhasers';


const ModalTrackLocation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const startMqttConnection = useMqttDeviceConnection();
    const open = useSelector((state: RootStateType) => state.modalTrackLocation.modalTrackLocationOpen);
    const state = useSelector((state: RootStateType) => state.modalTrackLocation.modalTrackLocationState);
    const stopTrackingActionCardOpen = useSelector((state: RootStateType) => state.modalTrackLocation.stopTrackingActionCardOpen);
    const restartTrackingActionCardOpen = useSelector((state: RootStateType) => state.modalTrackLocation.restartTrackingActionCardOpen);
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceId = selectedDevice?.deviceId as string;

    const doTrackinglocation = () => {
        dispatch(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.TRACKING, deviceId }));
        dispatch(postOperationTrackLocationAction({deviceId}))
        startMqttConnection({
            operationType: OPERATION_TYPES.TRACK_LOCATION_START
        });
    }

    const getBlocks = () => {
        if (state[deviceId] === MODAL_TRACK_LOCATION_STATE.TRACKING) {
            return {
                content: <TrackingContentBlock deviceType={selectedDevice?.type} />,
                action: <TrackingActionBlock onActionButtonClick={
                    () => {
                        dispatch(setStopTrackingActionCardOpen(true));
                    }
                } />
            }
        } else if (state[deviceId] === MODAL_TRACK_LOCATION_STATE.STOPPING) {
            return {
                content: <StoppingContentBlock deviceType={selectedDevice?.type}/>,
                action: <StoppingActionBlock onActionButtonClick={
                    () => {
                    dispatch(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.STOPPED, deviceId: selectedDevice?.deviceId }));
                    }
                } />
            }
        } else if (state[deviceId] === MODAL_TRACK_LOCATION_STATE.STOPPED) {
            return {
                content: <StoppedContentBlock deviceType={selectedDevice?.type}/>,
                action: <StoppedActionBlock onActionButtonClick={
                    () => {
                        dispatch(setRestartTrackingActionCardOpen(true));
                    }
                } />
            }
        } else if (state[deviceId] === MODAL_TRACK_LOCATION_STATE.FMM_OFF) {
            return {
                content: <FmmOffContentBlock deviceType={selectedDevice?.type}/>,
                action: <FmmOffActionBlock onActionButtonClick={
                    () => {
                        dispatch(setRestartTrackingActionCardOpen(true));
                    }
                } />
            };
        } else if (state[deviceId] === MODAL_TRACK_LOCATION_STATE.TIMEOUT) {
            return {
                content: <TimeoutContentBlock deviceType={selectedDevice?.type}/>,
                action: <TimeoutActionBlock onActionButtonClick={
                    () => {
                        dispatch(setRestartTrackingActionCardOpen(true));
                    }
                } />
            };
        } else {
            return {
                content: <InitialContentBlock deviceType={selectedDevice?.type} />,
                action: <InitialActionBlock onActionButtonClick={
                    () => {
                        doTrackinglocation();
                    }
                } />
            };
        }
    }

    return (
        <>
            <BaseActionCard
                open={stopTrackingActionCardOpen}
                title={
                    getTextByDeviceType(
                        selectedDevice?.type,
                        {
                            [DEVICE_TYPES.PHONE]: t('stop_tracking_your_phone_q'),
                            [DEVICE_TYPES.TAB]: t('stop_tracking_your_tablet_q')
                        }
                    )
                }
                contentsField={
                    <Box>
                        <Typography noWrap={false}>{
                            formatNumber(t('tracking_history_will_be_stored_n_days_you_can_start_tracking_again'), '7')
                            }
                        </Typography>
                    </Box>
                    }
                firstButtonProps={{
                    title: t('cancel'),
                    onClick: () => {
                        dispatch(setStopTrackingActionCardOpen(false))
                    }
                }}
                secondButtonProps={{
                    title: t('stop'),
                    onClick: () => {
                        dispatch(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.STOPPING, deviceId }));
                        dispatch(setStopTrackingActionCardOpen(false));
                        dispatch(stopOperationTrackLocationAction({deviceId}))
                        startMqttConnection({
                            operationType: OPERATION_TYPES.TRACK_LOCATION_STOP
                        });
                       
                    }
                }}
            />
            <BaseActionCard
                open={restartTrackingActionCardOpen}
                title={t('restart_tracking')}
                contentsField={<Typography>{t('tracking_history_will_be_erased')}</Typography>}
                firstButtonProps={{
                    title: t('cancel'),
                    onClick: () => {
                        dispatch(setRestartTrackingActionCardOpen(false))
                    }
                }}
                secondButtonProps={{
                    title: t('restart'),
                    onClick: () => {
                        dispatch(setRestartTrackingActionCardOpen(false));
                        dispatch(clearGetTrackLocationAction({deviceId}));
                        dispatch(setTrackLocationInfoAction({ trackLocationInfo: [], deviceId}));
                        doTrackinglocation();
                    }
                }}
            />
            <BaseModal
                open={open}
                onClose={() => {dispatch(setModalTrackLocationOpen(false))}}
                title={
                    getTextByDeviceType(
                        selectedDevice?.type,
                        {
                            [DEVICE_TYPES.PHONE]: t('track_your_phone'),
                            [DEVICE_TYPES.TAB]: t('track_your_tablet')
                        }
                    )
                }
                contentBlock={getBlocks()?.content}
                actionBlock={getBlocks()?.action}
                scroll='body'
            />
        </>
    );
};

export default ModalTrackLocation
