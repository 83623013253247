import { GetTagMetadataResponse } from "../../api/find/devicesApi/devicesApiTypes";
import { GetLostModeResponseType, GetTagLostModeResponseType, PostLostModeResponseType, PutTagLostModeReponseType } from "../../api/find/lostModeApi/lostModeApiTypes";

export type LostModeStateType = {
    lostModeModalOpen: boolean,
    lostModeSettingModalOpen: boolean,
    getLostModeState: {
        isLoading: boolean,
        data: GetLostModeResponseType,
        errors: any
    },
    getTagLostModeState: {
        isLoading: boolean,
        data: GetTagLostModeResponseType['item'],
        errors: null
    },
    putTagLostModeState: {
        isLoading: boolean,
        data: PutTagLostModeReponseType | null,
        errors: any
    },
    postLostModeState: {
        isLoading: boolean,
        data: PostLostModeResponseType | null,
        errors: any
    },
    getTagMetadataState: {
        isLoading: boolean,
        data: GetTagMetadataResponse['item'],
        errors: any
    }
};

export const LOST_MODE = "LOST_MODE";
export type LOST_MODE_TYPE = typeof LOST_MODE;

export const GET_LOST_MODE_BY_DEVICE_ID_ACTION = `${LOST_MODE}/getLostModeByDeviceIdAction`;
export type GET_LOST_MODE_BY_DEVICE_ID_ACTION_TYPE = typeof GET_LOST_MODE_BY_DEVICE_ID_ACTION;

export const GET_TAG_LOST_MODE_BY_DEVICE_ID_ACTION = `${LOST_MODE}/getTagLostModeByDeviceIdAction`;
export type GET_TAG_LOST_MODE_BY_DEVICE_ID_ACTION_TYPE = typeof GET_TAG_LOST_MODE_BY_DEVICE_ID_ACTION;

export const GET_TAG_METADATA_ACTION = `${LOST_MODE}/getTagMetadataAction`;
export type GET_TAG_METADATA_ACTION_TYPE = typeof GET_TAG_METADATA_ACTION;

export const POST_LOST_MODE_ACTION = `${LOST_MODE}/postLostModeAction`;
export type POST_LOST_MODE_ACTION_TYPE = typeof POST_LOST_MODE_ACTION;

export const PUT_TAG_LOST_MODE_ACTION = `${LOST_MODE}/putTagLostModeAction`;
export type PUT_TAG_LOST_MODE_ACTION_TYPE = typeof PUT_TAG_LOST_MODE_ACTION;
