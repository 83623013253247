import ar from './ar.json';
import as from './as.json';
import az_rAZ from './az-rAZ.json';
import be_rBY from './be-rBY.json';
import bg from './bg.json'
import bn_rBD from './bn-rBD';
import bn_rIN from './bn-rIN.json';
import bs from './bs.json';
import ca from './ca.json';
import cs from './cs.json';
import da from './da.json';
import de from './de.json';
import el from './el.json';
import en_rCA from './en-rCA.json';
import en_rGB from './en-rGB.json';
import en_rPH from './en-rPH.json';
import en from './en.json';
import es_rES from './es-rES.json';
import es_rUS from './es-rUS.json';
import et_rEE from './et-rEE.json';
import eu_rES from './eu-rES.json';
import fa from './fa.json';
import fi from './fi.json';
import fr_rCA from './fr-rCA.json';
import fr from './fr.json';
import ga from './ga.json';
import gl_rES from './gl-rES.json';
import gu_rIN from './gu-rIN.json';
import hi from './hi.json';
import hr from './hr.json';
import hu from './hu.json';
import hy_rAM from './hy-rAM.json';
import in_ from './in.json';
import is_rIS from './is-rIS.json';
import it from './it.json';
import iw from './iw.json';
import ja from './ja.json';
import ka_rGE from './ka-rGE.json';
import kk_rKZ from './kk-rKZ.json';
import km_rKH from './km-rKH.json';
import kn_rIN from './kn-rIN.json';
import ko from './ko.json';
import ky_rKG from './ky-rKG.json';
import lo_rLA from './lo-rLA.json';
import lt from './lt.json';
import lv from './lv.json';
import mk_rMK from './mk-rMK.json';
import ml_rIN from './ml-rIN.json';
import mn_rMN from './mn-rMN.json';
import mr_rIN from './mr-rIN.json';
import ms_rMY from './ms-rMY.json';
import my_rMM from './my-rMM.json';
import my_rZG from './my-rZG.json';
import nb from './nb.json';
import ne_rNP from './ne-rNP.json';
import ne from './ne.json';
import nl from './nl.json';
import or from './or.json';
import pa_rIN from './pa-rIN.json';
import pl from './pl.json';
import pt_rBR from './pt-rBR.json';
import pt_rPT from './pt-rPT.json';
import ro from './ro.json';
import ru from './ru.json';
import si_rLK from './si-rLK.json';
import si from './si.json';
import sk from './sk.json';
import sl from './sl.json';
import sq_rAL from './sq-rAL.json';
import sr_v34 from './sr-v34.json';
import sr from './sr.json';
import sv from './sv.json';
import ta_rIN from './ta-rIN.json';
import te_rIN from './te-rIN.json';
import tg from './tg.json';
import th from './th.json';
import tk from './tk.json';
import tl from './tl.json';
import tr from './tr.json';
import uk from './uk.json';
import ur_rPK from './ur-rPK.json';
import uz_rUZ from './uz-rUZ.json';
import vi from './vi.json';
import zh_rCN from './zh-rCN.json';
import zh_rHK from './zh-rHK.json';
import zh_rTW from './zh-rTW.json';

const languages = {
    ar: { translation: ar },
    as: { translation: as },
    az_rAZ: { translation: az_rAZ },
    be_rBY: { translation: be_rBY },
    bg: { translation: bg },
    bn_rBD: { translation: bn_rBD },
    bn_rIN: { translation: bn_rIN },
    bs: { translation: bs },
    ca: { translation: ca },
    cs: { translation: cs },
    da: { translation: da },
    de: { translation: de },
    el: { translation: el },
    en_rCA: { translation: en_rCA },
    en_rGB: { translation: en_rGB },
    en_rPH: { translation: en_rPH },
    en: { translation: en },
    es_rES: { translation: es_rES },
    es_rUS: { translation: es_rUS },
    et_rEE: { translation: et_rEE },
    eu_rES: { translation: eu_rES },
    fa: { translation: fa },
    fi: { translation: fi },
    fr_rCA: { translation: fr_rCA },
    fr: { translation: fr },
    ga: { translation: ga },
    gl_rES: { translation: gl_rES },
    gu_rIN: { translation: gu_rIN },
    hi: { translation: hi },
    hr: { translation: hr },
    hu: { translation: hu },
    hy_rAM: { translation: hy_rAM },
    in: { translation: in_ },
    is_rIS: { translation: is_rIS },
    it: { translation: it },
    iw: { translation: iw },
    ja: { translation: ja },
    ka_rGE: { translation: ka_rGE },
    kk_rKZ: { translation: kk_rKZ },
    km_rKH: { translation: km_rKH },
    kn_rIN: { translation: kn_rIN },
    ko: { translation: ko },
    ky_rKG: { translation: ky_rKG },
    lo_rLA: { translation: lo_rLA },
    lt: { translation: lt },
    lv: { translation: lv },
    mk_rMK: { translation: mk_rMK },
    ml_rIN: { translation: ml_rIN },
    mn_rMN: { translation: mn_rMN },
    mr_rIN: { translation: mr_rIN },
    ms_rMY: { translation: ms_rMY },
    my_rMM: { translation: my_rMM },
    my_rZG: { translation: my_rZG },
    nb: { translation: nb },
    ne_rNP: { translation: ne_rNP },
    ne: { translation: ne },
    nl: { translation: nl },
    or: { translation: or },
    pa_rIN: { translation: pa_rIN },
    pl: { translation: pl },
    pt_rBR: { translation: pt_rBR },
    pt_rPT: { translation: pt_rPT },
    ro: { translation: ro },
    ru: { translation: ru },
    si_rLK: { translation: si_rLK },
    si: { translation: si },
    sk: { translation: sk },
    sl: { translation: sl },
    sq_rAL: { translation: sq_rAL },
    sr_v34: { translation: sr_v34 },
    sr: { translation: sr },
    sv: { translation: sv },
    ta_rIN: { translation: ta_rIN },
    te_rIN: { translation: te_rIN },
    tg: { translation: tg },
    th: { translation: th },
    tk: { translation: tk },
    tl: { translation: tl },
    tr: { translation: tr },
    uk: { translation: uk },
    ur_rPK: { translation: ur_rPK },
    uz_rUZ: { translation: uz_rUZ },
    vi: { translation: vi },
    zh_rCN: { translation: zh_rCN },
    zh_rHK: { translation: zh_rHK },
    zh_rTW: { translation: zh_rTW },
};

export default languages;