import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './TrackLocationBlocksStyles.scss';

import ModalBottomButton from '../../../buttons/ModalBottomButton/ModalBottomButton';
import { t } from 'i18next';
import { ActionBlockTypes } from './TrackLocationBlocksTypes';
import LottieLoadingCircle from '../../../view-only/LottieLoadingCircle/LottieLoadingCircle';
import { getTextByDeviceType } from '../../../../utills/functions/branchFunctions';
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from '../../../../utills/enums/Devices';
import { formatNumber } from '../../../../utills/functions/stringPhasers';
import LocationTracker from '../LocationTracker';


export const ContentBlock = ({ deviceType }: { deviceType: DEVICE_TYPES_TYPE }) => (
    <div className='modal-track-location-container'>
        <Stack alignItems='center'>
            <Box>
                <LottieLoadingCircle />
                <Typography marginTop='16px' textAlign='center'>
                    {t('turning_off_tracking')}
                </Typography>
                <Typography marginTop='24px'>
                    {getTextByDeviceType(
                        deviceType,
                        {
                            [DEVICE_TYPES.PHONE]: formatNumber(t('phone_location_will_update_every_minutes'), '15'),
                            [DEVICE_TYPES.TAB]: formatNumber(t('tablet_location_will_update_every_minutes'), '15')
                        }
                    )}
                </Typography>
            </Box>
            <div className="location-tracker-container">
                <LocationTracker />
            </div>
        </Stack>
    </div>
);

export const ActionBlock = ({ onActionButtonClick }: ActionBlockTypes) => (
    <>
        <ModalBottomButton isLoading onClick={onActionButtonClick} />
    </>
);
