import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import resources from "./res";

i18n
    .use(initReactI18next)
    // .use(LanguageDetector)  // 자동으로 언어를 감지하므로, 명시적인 언어셋팅에 따라 RTL을 적용할 것이라면, 이 속성은 사용하지 않는것이 좋다.
    .init({
        resources,
        fallbackLng:  'en',  //  'en', 'he', 'ko',   // default language
        interpolation: {
            escapeValue: false  // escapes passed in values to avoid XSS injection, react already safes from xss,  so set 'false'
        }
    });

export default i18n;