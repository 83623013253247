import { AsyncThunk, AnyAction } from '@reduxjs/toolkit';
import store from '../../slices/store';
import { postVerifyPasswords } from '../../api/find/confirmPasswordApi';

const URL_LIST = [ // Todo: 임시로 코드에 선언했지만 추후에 환경변수로 뺄지 고민해봐야함
    "https://stg.samsungfind.samsung.com",
    "https://pre.samsungfind.samsung.com",
    "https://samsungfind.samsung.com",
    "https://localhost",
];

export const confirmPassword = async (action: AnyAction | AsyncThunk<any, any, any>, requestToParent = false) => {
    const REDIRECT = decodeURIComponent(window.location.origin+'/confirm-pw');
    const authStateValue = Math.random().toString(36).substring(7);

    const messageHandler = receiveMessage(authStateValue, action);
    window.addEventListener("message", messageHandler);
    
    const params = {
        state: authStateValue,
    };

    const queryParams = new URLSearchParams(params);

    const requestId = await postVerifyPasswords({redirectUri: `${REDIRECT}?${queryParams.toString()}`});
    const url = `https://account.samsung.com/iam/verifications/passwords/${requestId}`;

    const childWindow = window.open(url, "SamsungFind", "width = 500, height = 700, popup=true");
    const intervalId = setInterval(function() {
        if (childWindow?.closed) {
            window.removeEventListener("message", messageHandler);
            clearInterval(intervalId);
        }
    }, 1000);
}

export const receiveMessage = (state: string, asyncThunk: AnyAction | AsyncThunk<any, any, any>) => (event: MessageEvent<any>) =>
{
    if (URL_LIST.some(url => event.origin.includes(url))) { // 특정 자식창만 허용
        const {state: authState} = event.data;
        if (authState === state) {
            store.dispatch(asyncThunk)
        } else if (state && authState !== state) {
            console.warn("State is invalid!");
        }
    }
};

