import { LogEntry, LogLevel } from './logTypes';
import packageJson from '../../../../package.json';
import { getUserId, getUserName } from '../../../utills/auth';
import axiosInstance from '../findAxiosConfig';
const appVersion = packageJson.version || 'unknown';


// 서버로 로그 데이터를 전송하는 함수
export const sendLogToServer = async (logEntry: LogEntry): Promise<void> => {
    try {
        // axios를 사용하여 POST 요청으로 로그 데이터를 서버에 전송합니다.
        await axiosInstance.post('/log-collector', logEntry);
        console.info('send log to server:', JSON.stringify(logEntry));
    } catch (error) {
        console.error('Failed to send log to server:', error);
    }
};


// 로그 데이터를 생성하고 서버로 전송하는 함수
/**
 *
 * Example log usage
 * createAndSendLog(MyComponent,'This is an error message',LogLevel.ERROR,{ deviceId: 'Some device Id' });
 * @param componentFn 
 * @param message 
 * @param logLevel 
 * @param additionalInfo 
 */
export const createAndSendLog = (
    componentFn: Function, // 컴포넌트 생성자 함수
    message: string, // 로그 메시지
    logLevel: LogLevel, // 로그 레벨 : 'ERROR' | 'WARNING' | 'INFO' | 'DEBUG'
    error?: Error,
    additionalInfo?: Record<string, unknown> // 기타 추가 정보 (Optional)
): void => {
    // Use getEntriesByType to gather performance metrics
    const performanceEntries: PerformanceEntryList = performance.getEntriesByType('navigation');
    const firstPerformanceEntry = performanceEntries.length > 0 ? performanceEntries[0] : {};
    const userId = getUserId();
    const userName = getUserName();
    const { stack, ...errorWithoutStack } = error || {};

    // Create log
    const logEntry: LogEntry = {
        timestamp: new Date().toISOString(),
        level: logLevel,
        component: componentFn?.name,
        message: message,
        error: errorWithoutStack,
        browserInfo: navigator?.userAgent,
        screenSize: `${window?.innerWidth}x${window?.innerHeight}`,
        networkStatus: navigator.onLine ? 'Online' : 'Offline',
        currentPage: window?.location.href,
        performanceMetrics: JSON.stringify(firstPerformanceEntry),
        appVersion: appVersion,
        userId: userId,
        userName: userName,
        additionalInfo: additionalInfo,
    };
    // Send Log to server
    sendLogToServer(logEntry);
};