import { GetTrackLocationsResponseType, OperationResponseType } from "../../api/findToStf/devicesApi/deviceApiTypes";


export type ModalTrackLocationStateType = {
    modalTrackLocationOpen: boolean,
    modalTrackLocationState: {
        [x: string]: MODAL_TRACK_LOCATION_STATE_TYPE | undefined,
    }
    stopTrackingActionCardOpen: boolean,
    restartTrackingActionCardOpen: boolean,
    postOperationTrackLocationState: {
        isLoading: boolean,
        data: OperationResponseType['item'] | null,
        errors: any
    },
    stopOperationTrackLocationState: {
        isLoading: boolean,
        data: OperationResponseType['item'] | null,
        errors: any
    },
    getTrackLocationState: {
        [x: string]: {
            isLoading: boolean,
            data: GetTrackLocationsResponseType['item']['locationInfo'] | null,
            errors: any
        } | undefined
    }
    trackLocationInfo: {
        [x: string]: {
            isLoading: boolean,
            data: Array<TrackLocationInfo>,
            errors: any
        } | undefined
    }
};

export type TrackLocationInfo = {
    date: Date,
    address: string,
    latitude: string,
    longitude: string,
    uuid: string,
    picked?: boolean
}

export const MODAL_TRACK_LOCATION_STATE = {
    INITIAL: "initial",
    TRACKING: "tracking",
    STOPPING: "stopping",
    STOPPED: "stopped",
    FMM_OFF: "fmm_off",
    TIMEOUT: "timeout"
}
export type MODAL_TRACK_LOCATION_STATE_TYPE = (typeof MODAL_TRACK_LOCATION_STATE)[keyof typeof MODAL_TRACK_LOCATION_STATE];

export const MODAL_TRACK_LOCATION = "MODAL_TRACK_LOCATION";
export type MODAL_TRACK_LOCATION_TYPE = typeof MODAL_TRACK_LOCATION;

export const SET_MODAL_TRACK_LOCATION_STATE = `${MODAL_TRACK_LOCATION}/setModalTrackLocationState`;
export type SET_MODAL_TRACK_LOCATION_STATE_TYPE = typeof SET_MODAL_TRACK_LOCATION_STATE;

export const SET_MODAL_TRACK_LOCATION_OPEN_ACTION = `${MODAL_TRACK_LOCATION}/setModalTrackLocationOpen`;
export type SET_MODAL_TRACK_LOCATION_ACTION_TYPE = typeof SET_MODAL_TRACK_LOCATION_OPEN_ACTION;

export const POST_OPERATION_TRACK_LOCATION_ACTION = `${MODAL_TRACK_LOCATION}/postOperationTrackLocationAction`;
export type POST_OPERATION_TRACK_LOCATION_ACTION_TYPE = typeof POST_OPERATION_TRACK_LOCATION_ACTION;

export const STOP_OPERATION_TRACK_LOCATION_ACTION = `${MODAL_TRACK_LOCATION}/stopOperationTrackLocationAction`;
export type STOP_OPERATION_TRACK_LOCATION_ACTION_TYPE = typeof STOP_OPERATION_TRACK_LOCATION_ACTION;

export const GET_TRACK_LOCATION_ACTION = `${MODAL_TRACK_LOCATION}/getTrackLocationAction`;
export type GET_TRACK_LOCATION_ACTION_TYPE = typeof GET_TRACK_LOCATION_ACTION;