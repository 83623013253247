const CODES_USE_LATIN_KOREAN = ["en", "ko"];

/**
 * 선택 언어에 따라 동적으로 font를 import 해주는 코드
 */
export const importFontByLanguage = async (lang: string) => {
    // Todo: 언어별 폰트 적용하고 아래의 분기 로직 작성 필요 (아래의 주석은 예시로 남겨둠, latin-korean font는 디폴트로 import 해놓았기에 의미가 없어서 주석처리 해둠)

    // const language = lang.split("_")[0];
    // if (CODES_USE_LATIN_KOREAN.includes(language)) {
    //     console.log(lang)
    //     await import('../../assets/fonts/latin-korean/_index.scss');
    // }
}