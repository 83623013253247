import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { List, ListItemButton, ListItemText, ListItemIcon, Button, Card } from "@mui/material";

import { postAgreementStatus } from '../../api/find/optinApi/optinApi';
import { confirmPassword } from '../../utills/functions/pwConfirmFunctions';
import BaseActionCard from "./actions/BaseActionCard/BaseActionCard";
import {
    asyncDeleteUserHistory, asyncFetchUserHistoryBeforeErase, asyncFetchUserHistoryBeforeDownload,
    setNoPersonalDataDownloadCardOpen, setCantDownloadPersonalDataCardOpen,
    setNoPersonalDataEraseCardOpen, setErasePersonalDataCardOpen, setCantErasePersonalDataCardOpen
} from "../../slices/settingSlice";
import { formatString } from "../../utills/functions/stringPhasers";
import { signOutAndRedirectToHome, getUserId, setOptinStatus, setOptinLocation, getSelectedLangTitle, getChildAccountSupported } from '../../utills/auth';
import ListItemButtonWithSwitch from "../buttons/ListItemButtonWithSwitch";
import TitleListItemButton from "../buttons/TitleListItemButton/TitleListItemButton";
import { ModalLocationInfo } from "../modal";
import { setSelectedSettings } from "../../slices/deviceListSlice";
import { useIsMobileView } from "../../hooks/useIsMobileView";
import useHistoryStack from "../../hooks/useIgnoreBackButton/useHistoryStack";



const SettingsCard = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const setting = useSelector((state) => state.setting);
    const { isMobileView } = useIsMobileView();
    const eraseUserHistoryPromiseRef = useRef(null);
    //  모바일 뒤로가기 버튼 동작
    useHistoryStack({message: "settingCard", open: true, handleClose: () => {
        dispatch(setSelectedSettings(false));
        dispatch(setErasePersonalDataCardOpen(false));
        if (eraseUserHistoryPromiseRef.current) {
            eraseUserHistoryPromiseRef.current.abort();
        }
    }});

    // global states
    const lang = getSelectedLangTitle();

    // local states
    // for location info switch
    const [checkedLocation, setCheckedLocation] = useState(true);


    const closeSettingsCard = () => {
        dispatch(setSelectedSettings(false));
        dispatch(setErasePersonalDataCardOpen(false));
        if (eraseUserHistoryPromiseRef.current) {
            eraseUserHistoryPromiseRef.current.abort();
        }
    }

    const handleSwtichLocation = (event) => {
        // switch Off 상태에서 click시,
        if (event.target.checked) {
            // 시나리오 상, 이런 경우는 발생할 케이스가 없음...
        }
        // switch On 상태에서 click시,  dialog open.
        else {
            handleLocationInfoOpen();
        }
    };


    // Modal open/close
    const [openLocationInfo, setOpenLocationInfo] = useState(false);

    // Modal - Button
    const handleLocationInfoOpen = () => {
        setOpenLocationInfo(true);
    }
    const handleLocationInfoClose = (isAgree) => {
        setOpenLocationInfo(false);

        // dialog에서 'Agree' 버튼 선택시, Agreement(=use of Location) '비동의' 의미가 된다. 
        // 즉,  '비동의'상태로 업데이트하고 Logout을 진행해야 한다.
        if (isAgree === true) {
            //  ToDO : API call - Location Info 동의 철회상태로 업데이트
            doUpdateOptin();
        }
    }

    // Modal - location switch
    async function doUpdateOptin() {
        try {
            // API call 
            const userId = getUserId();

            const respLocation = await postAgreementStatus(0);  // '비동의'로 업데이트 요청
            // response에 아무것도 없음...?  200 OK.
            if (respLocation !== null) {
                console.log("settings > doUpdateOptin : 0(비동의) =================== ");
                setOptinStatus(false);
                setOptinLocation(false);

                signOutAndRedirectToHome();
            }
        } catch (error) {
            console.error("error =================== " + error);
        }
    }


    const onClickDownloadPersonalData = () => {
        confirmPassword(asyncFetchUserHistoryBeforeDownload())
    }

    const onClickErasePersonalData = () => {
        const promise = dispatch(asyncFetchUserHistoryBeforeErase());
        eraseUserHistoryPromiseRef.current = promise;
    }

    const onClickDownloadCardOk = () => {
        dispatch(setNoPersonalDataDownloadCardOpen(false))
    }

    const onClickCantDownloadCardOk = () => {
        dispatch(setCantDownloadPersonalDataCardOpen(false))
    }

    const onClickEraseCardOk = () => {
        dispatch(setNoPersonalDataEraseCardOpen(false))
    }

    const onClickCancel = () => {
        dispatch(setErasePersonalDataCardOpen(false))
    };

    const onClickErase = () => {
        confirmPassword(asyncDeleteUserHistory());
        dispatch(setErasePersonalDataCardOpen(false));
    };

    const onClickCantEraseCardOk = () => {
        dispatch(setCantErasePersonalDataCardOpen(false))
    }

    const noDataDownloadCardButtonPropsOne = {
        title: t("ok"),
        onClick: onClickDownloadCardOk
    }

    const cantDownloadCardButtonPropsOne = {
        title: t("ok"),
        onClick: onClickCantDownloadCardOk
    }

    const noDataEraseCardButtonPropsOne = {
        title: t("ok"),
        onClick: onClickEraseCardOk
    }

    const eraseCardButtonPropsOne = {
        title: t("cancel"),
        onClick: onClickCancel
    }

    const eraseCardButtonPropsTwo = {
        title: t("erase"),
        onClick: onClickErase
    }

    const cantEraseCardButtonPropsOne = {
        title: t("ok"),
        onClick: onClickCantEraseCardOk
    }

    return (
        <div className={isMobileView ? "setting-card-mobile" : "setting-card"}>
            <BaseActionCard
                open={setting.noPersonalDataDownloadCardOpen}
                contentsField={t("no_download")}
                firstButtonProps={noDataDownloadCardButtonPropsOne}
            />
            <BaseActionCard
                open={setting.cantDownloadPersonalDataCardOpen}
                title={t("cant_download_data")}
                contentsField={t("connecting_try_again")}
                firstButtonProps={cantDownloadCardButtonPropsOne}
            />
            <BaseActionCard
                open={setting.noPersonalDataEraseCardOpen}
                contentsField={t("no_erase")}
                firstButtonProps={noDataEraseCardButtonPropsOne}
            />
            <BaseActionCard
                open={setting.erasePersonalDataCardOpen}
                title={t("erase_personal_data")}
                contentsField={t("quit_service")}
                firstButtonProps={eraseCardButtonPropsOne}
                secondButtonProps={eraseCardButtonPropsTwo}
            />
            <BaseActionCard
                open={setting.cantErasePersonalDataCardOpen}
                title={t("cant_erase_data")}
                contentsField={t("connecting_try_again")}
                firstButtonProps={cantEraseCardButtonPropsOne}
            />
            <Card className="outer-card">
                <div className="closeButton">
                    <Button onClick={() => closeSettingsCard()}>
                        <ArrowBackIosNewIcon />
                    </Button>
                    <p>{t('settings')}</p>
                </div>


                <div className="settings-container">
                    <div className="settings-menu">
                        {t('general')}
                    </div>
                    <Card className="settings-submenu">
                        <List>
                            <ListItemButton component={Link} to="/languages">
                                <ListItemText>
                                    {t('language')}
                                </ListItemText>
                                <ListItemIcon className="settings-language">
                                    {lang}
                                </ListItemIcon>
                            </ListItemButton>
                        </List>
                    </Card>


                    <div className="settings-menu">
                        {t('legal_info')}
                    </div>
                    <Card className="settings-submenu">
                        <List>
                            <ListItemButton target="_blank" rel="noreferrer noopener" href="https://account.samsung.com/membership/policy/terms">
                                <ListItemText>
                                    {t('terms_and_conditions')}
                                </ListItemText>
                            </ListItemButton>
                            <hr />

                            <ListItemButton target="_blank" rel="noreferrer noopener" href="https://account.samsung.com/membership/policy/privacy">
                                <ListItemText>
                                    {t('privacy_policy')}
                                </ListItemText>
                            </ListItemButton>
                            <hr />

                            <ListItemButtonWithSwitch
                                title={t('location_info')}
                                checked={checkedLocation}
                                onChange={handleSwtichLocation}
                                linkURL="https://account.samsung.com/membership/etc/specialTC.do?fileName=locationinfortnc.html"
                            />
                        </List>
                    </Card>
                    {getChildAccountSupported()?.isChild ? null : (
                        <>
                            <div className="settings-menu">
                                {t('personal_info')}
                            </div>
                            <Card className="settings-submenu-twoline">
                                <List>
                                    <TitleListItemButton
                                        onClick={onClickDownloadPersonalData}
                                        primaryText={t('download_data')}
                                        secondaryText={formatString(t('download_all'), "Samsung Find")}
                                        isLoading={setting.userHistory.isLoadingBeforeDownload}
                                    />
                                    <hr />
                                    <TitleListItemButton
                                        onClick={onClickErasePersonalData}
                                        primaryText={t('erase_personal_data')}
                                        secondaryText={formatString(t('erase_all'), "Samsung Find")}
                                        isLoading={setting.userHistory.isLoadingBeforeErase}
                                    />
                                </List>
                            </Card>
                        </>
                    )}
                    {/* modal dialog */}
                    <ModalLocationInfo open={openLocationInfo} onClose={handleLocationInfoClose} />
                </div>
            </Card>
        </div>
    );
}

export default SettingsCard;