import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getChildAccountSupported } from '../../utills/auth';


export default function NoDevicesPlaceholder() {
    const { t } = useTranslation();

    // global states
    const activeTabType = useSelector((state) => state.deviceList.activeTabType);

    return (
        <div className="no-device-place-holder">
            <p> {activeTabType === 'DEVICES' ? t('no_devices') : t('no_items')}</p>
            <p> {(activeTabType === 'ITEM' && getChildAccountSupported()?.isChild) ? null : t('will_appear')} </p>
        </div>
    );
}