import React, { ReactEventHandler } from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BaseModalProps } from './BaseModalTypes';
import useHistoryStack from '../../../hooks/useIgnoreBackButton/useHistoryStack';
import * as S from './BaseModal.style';


const BaseModal = ({
    open,
    title,
    contentBlock,
    actionBlock,
    disableX = false,
    scroll,
    onClose,
}: BaseModalProps) => {
    const { popHistoryStack } = useHistoryStack({message: title, open, handleClose: onClose});

    return (
        <div>
            <S.StyledDialog className="base-modal-container"
                open={open}
                onClose={(e, reason) => {
                    if (reason && reason === "backdropClick") {
                        return;
                    }
                    if (onClose) {
                        onClose(e, reason);
                        popHistoryStack();
                    }
                }}
                scroll={scroll}
            >
                <S.DialogContainer>
                    <div className="modal-dialog">
                        {title ?
                            <S.StyledDialogTitle>
                                <Typography variant='modalTitle'>
                                    {title}
                                </Typography>
                            </S.StyledDialogTitle>
                            : null
                        }
                        {disableX
                            ? (null)
                            : (
                                <S.StyledIconButton
                                    onClick={(e) => {
                                        (onClose as ReactEventHandler)(e);
                                        popHistoryStack();
                                    }}
                                >
                                    <CloseIcon />
                                </S.StyledIconButton>
                            )}
                        <S.StyledDialogContent>
                            <S.ContentBlockContainer>
                                {contentBlock}
                            </S.ContentBlockContainer>
                        </S.StyledDialogContent>
                        <S.StyledDialogActions>
                            {actionBlock}
                        </S.StyledDialogActions>
                    </div>
                </S.DialogContainer>
            </S.StyledDialog>
        </div>
    );
}

export default BaseModal;