import EncryptedLocalStorage from "../../managers/EncryptedLocalStorage";

const encryptedStorage = new EncryptedLocalStorage();

export const EMERGENCY_MODE_KEYS = {
  STORAGE_KEY: btoa("samsung_find_web_emergency_dialog_shown"),
};



// 로컬 스토리지에서 다이얼로그 표시 여부를 확인하는 함수
export const hasShownEmergencyModeDialog = (deviceId: string): boolean => {
  try {
    const shown = encryptedStorage.getItem(`${EMERGENCY_MODE_KEYS.STORAGE_KEY}-${deviceId}`);
    return !!shown;
  } catch (error) {
    console.warn("Failed to access localStorage", error);
    return false; // 예외가 발생하면 기본적으로 다이얼로그 표시하지 않음을 반환
  }
};


// 로컬 스토리지에 다이얼로그 표시 여부를 저장하는 함수
export const markEmergencyDialogShown = (deviceId: string): void => {
  try {
    encryptedStorage.setItem(`${EMERGENCY_MODE_KEYS.STORAGE_KEY}-${deviceId}`, 'true');
  } catch (error) {
    console.warn("Failed to set item in localStorage", error);
  }
};


// 로컬 스토리지에서 모든 다이얼로그 표시 정보를 삭제하는 함수 (로그아웃시 리셋 필요)
export const clearEmergencyDialogHistory = (): void => {
  try {
    Object.keys(encryptedStorage).forEach((key) => {
      if (key.startsWith(`${EMERGENCY_MODE_KEYS.STORAGE_KEY}-`)) {
        encryptedStorage.removeItem(key);
      }
    });
  } catch (error) {
    console.error("Failed to clear localStorage", error);
  }
};
