import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest, all } from "typed-redux-saga";
import { getLostMode, getTagLostMode, postLostMode, putTagLostMode } from "../../api/find/lostModeApi";
import { enableLostModeAction, enableTagLostModeAction, getLostModeByDeviceIdErrorAction, getLostModeByDeviceIdSuccessAction, getTagLostModeByDeviceIdErrorAction, getTagLostModeByDeviceIdSuccessAction, getTagMetadataErrorAction, getTagMetadataSuccessAction, postLostModeErrorAction, postLostModeSuccessAction, putTagLostModeErrorAction, putTagLostModeSuccessAction } from "./lostModeSlice";
import { GET_LOST_MODE_BY_DEVICE_ID_ACTION, GET_TAG_LOST_MODE_BY_DEVICE_ID_ACTION, GET_TAG_METADATA_ACTION, POST_LOST_MODE_ACTION, PUT_TAG_LOST_MODE_ACTION } from "./lostModeSliceTypes";
import { PostLostModeRequestType, PutTagLostModeRequestType } from "../../api/find/lostModeApi/lostModeApiTypes";
import { getTagMetadata } from "../../api/find/devicesApi";
import { GetTagMetadataRequest } from "../../api/find/devicesApi/devicesApiTypes";

function* getLostModeSaga({ payload: deviceId }: PayloadAction<string>) {
  try {
    const response = yield* call(getLostMode, deviceId);
    yield put(getLostModeByDeviceIdSuccessAction(response));
  } catch (error) {
    yield put(getLostModeByDeviceIdErrorAction(error));
  }
}

function* postLostModeSaga({ payload }: PayloadAction<PostLostModeRequestType>) {
  try {
    const response = yield* call(postLostMode, payload);
    yield put(postLostModeSuccessAction(response));
    yield put(getLostModeByDeviceIdSuccessAction({
      phone_number: "",
      email: "",
      message: "",
      device_name: "",
      deviceImageUrl: "",
      ...payload
    }));
    yield put(enableLostModeAction(payload.enabled));
  } catch (error) {
    yield put(postLostModeErrorAction(error));
  }
}

function* getTagLostModeSaga({ payload }: PayloadAction<string>) {
  try {
    const response = yield* call(getTagLostMode, payload);
    yield put(getTagLostModeByDeviceIdSuccessAction(response));
  } catch (error) {
    yield put(getTagLostModeByDeviceIdErrorAction(error));
  }
}

function* getTagMetadataSaga({ payload }: PayloadAction<GetTagMetadataRequest>) {
  try {
    const response = yield* call(getTagMetadata, payload);
    yield put(getTagMetadataSuccessAction(response));
  } catch (error) {
    yield put(getTagMetadataErrorAction(error));
  }
}

function* putTagLostModeSaga({ payload }: PayloadAction<PutTagLostModeRequestType>) {
  try {
    const response = yield* call(putTagLostMode, payload);
    yield put(putTagLostModeSuccessAction(response));
    yield put(getTagLostModeByDeviceIdSuccessAction({
      messageType: "",
      phoneNumber: "",
      email: "",
      message: "",
      ...payload
    }));
    yield put(enableTagLostModeAction(payload.enabled));
  } catch (error) {
    yield put(putTagLostModeErrorAction(error));
  }
}

export default function* watchLostModeSaga() {
  yield all([
    takeLatest(GET_LOST_MODE_BY_DEVICE_ID_ACTION, getLostModeSaga),
    takeLatest(POST_LOST_MODE_ACTION, postLostModeSaga),
    takeLatest(GET_TAG_LOST_MODE_BY_DEVICE_ID_ACTION, getTagLostModeSaga),
    takeLatest(PUT_TAG_LOST_MODE_ACTION, putTagLostModeSaga),
    takeLatest(GET_TAG_METADATA_ACTION, getTagMetadataSaga)
  ]);
}
