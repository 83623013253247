import { useState, useEffect } from 'react';


const useCachedImage = (imageUrl, defaultImageUrl) => {
    const [imageSrc, setImageSrc] = useState('');
    const storageKey = `cachedImage_${imageUrl}`;
    const loadImage = async () => {
        try {
            const response = await fetch(imageUrl, {
                headers: {
                    'Accept': 'image/png'
                }
            });

            if (!response.ok) throw new Error(response);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
                sessionStorage.setItem(storageKey, reader.result);
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error loading image:', error);
            setImageSrc(defaultImageUrl);
        }
    };

    useEffect(() => {
        if (!imageUrl) {
            setImageSrc(defaultImageUrl);
            return;
        }

        const cachedImage = sessionStorage.getItem(storageKey);
        if (cachedImage) {
            setImageSrc(cachedImage);
            return;
        }

        loadImage();
    }, [imageUrl, defaultImageUrl]);


    return imageSrc;
};

export default useCachedImage;