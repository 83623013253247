import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FullScreenMap from "../components/maps/FullScreenMap";
import { fetchFamilyDevices, fetchFamilyItemDevices, fetchMyDevices, fetchMyItemDevices, getDeviceLocation, getTagDetail, getTagLocation } from '../api/find/devicesApi/devicesApi';
import { getSsoLoginDeviceId, getUserId, getUserName } from "../utills/auth";
import {
    upsertDeviceAddress,
    setMyDeviceListData,
    setIsDeviceListLoading,
    upsertLocationListData,
    setDeviceImageListData,
    upsertDeviceDetail,
    setFamilyDeviceListData,
    setMyItemListData,
    upsertMyTagDisplayName,
    upsertTagImage,
    setIsMyDeviceLocationLoading,
    upsertFamilyTagDisplayName,
    setFamilyItemListData,
    setAddressList
} from "../slices/deviceListSlice";
import { getAddress, isValidPosition, prioritizeSsoLoginDevice, sortItemListByCreateTime } from "../utills/functions";
import DeviceControlPanel from "../components/cards/DeviceControlPanel";
import { getDeviceDetails, performOperationLocation, performOperationCheckConnection } from '../api/findToStf/devicesApi/devicesApi';
import { OPERATION_TYPES } from "../utills/enums/commons";
import { DEVICE_OWNER_TYPES, TAB_TYPES } from "../utills/enums/Devices";
import useDeviceOperations from "../hooks/useDeviceOperations";
import { useTranslation } from "react-i18next";


const Home = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const locationListData = useSelector((state) => state.deviceList.locationListData);
    const myDeviceListData = useSelector((state) => state.deviceList.myDeviceListData);
    const familyDeviceListData = useSelector((state) => state.deviceList.familyDeviceListData);
    const familyItemListData = useSelector((state) => state.deviceList.familyItemListData);
    const myItemListData = useSelector((state) => state.deviceList.myItemListData);
    const activeTabType = useSelector((state) => state.deviceList.activeTabType);
    const deviceOwnerType = useSelector((state) => state.deviceList.deviceOwnerType);
    const selectedLanguage = useSelector((state) => state.language.lang.code);
    const [currentDeivceListData, setCurrentDeviceListData] = useState(myDeviceListData);

    const { startOperation } = useDeviceOperations();
    const prevLocationListDataRef = useRef(locationListData); // locationListData 변화시 이전 값과 비교하기 위함
    const addressListData = useSelector((state) => state.deviceList.addressListData);


    /**
     * 홈 화면이 새로고침될 때 Device List 및 위치 정보를 서버로부터 새로 로드합니다.
     */
    useEffect(() => {
        if (location.state && location.state.reloadDeviceList === false) {
            return;
        }
        dispatch(setAddressList([])); // reset address 
        loadMyDeviceListData();
        loadMyItemListData();
        loadFamilyDeviceListData();
        loadFamilyItemListData();
    }, []);


    useEffect(() => {

        /**
         * 
        현재 activeTabType 이  TAB_TYPES.DEVICES 일 때
            현재 deviceOwnerType 이 DEVICE_OWNER_TYPES.MY 이면 myDeviceListData 을 보여준다.
            현재 deviceOwnerType 이 DEVICE_OWNER_TYPES.FAMILY 이면 familyDeviceListData을 보여준다.
        현재 activeTabType 이  TAB_TYPES.ITEMS 일 때 
            myItemListData 를 보여준다.

         */
        if (activeTabType === TAB_TYPES.DEVICES) {// 디바이스 탭일 경우
            if (deviceOwnerType === DEVICE_OWNER_TYPES.MY) { // 내 기기 탭일 경우 
                setCurrentDeviceListData(myDeviceListData);
            } else if (deviceOwnerType === DEVICE_OWNER_TYPES.FAMILY) { // 가족기기 탭일 경우 
                if (!Array.isArray(familyDeviceListData) || familyDeviceListData.length <= 0) {  // 가족기기가 없을 경우
                    setCurrentDeviceListData(myDeviceListData);
                } else {
                    setCurrentDeviceListData(familyDeviceListData);
                }
            }
        } else if (activeTabType === TAB_TYPES.ITEMS) {// 아이템 탭일 경우
            if (deviceOwnerType === DEVICE_OWNER_TYPES.MY) { // 내 기기 탭일 경우
                setCurrentDeviceListData(myItemListData);
            } else if (deviceOwnerType === DEVICE_OWNER_TYPES.FAMILY) { // 가족 기기 탭일 경우
                if (!Array.isArray(familyItemListData) || familyItemListData.length <= 0) { // 가족기기가 없을경우
                    setCurrentDeviceListData(myItemListData);
                } else {
                    setCurrentDeviceListData(familyItemListData);
                }
            }
        }

    }, [activeTabType, deviceOwnerType, myDeviceListData, familyDeviceListData, myItemListData]);

    /**
     * locationListData가 업데이트될때마다 address 정보를 업데이트 합니다.  
     */
    useEffect(() => {
        if (locationListData && locationListData.length > 0) {
            fetchAndAddAddress();
            prevLocationListDataRef.current = locationListData; // 추후 업데이트 값과 현재값을 비교하기위해 저장 
        }

    }, [locationListData, selectedLanguage]);


    const fetchAndAddAddress = async () => {
        try {
            for (const locationData of locationListData) {
                const prevLocationData = prevLocationListDataRef.current.find(ld => ld.deviceId === locationData.deviceId) || { geolocations: [] };
                for (const geo of locationData.geolocations) {
                    const { latitude, longitude } = geo;
                    const prevGeo = prevLocationData.geolocations.find(g => g.latitude === latitude && g.longitude === longitude);
                    const prevAddress = addressListData.find(item => item.deviceId === locationData.deviceId);
                    // 이전 geo 데이터에서 동일한 lat,lng을 가지지 않을 경우 || 해당 deviceId 에 대해 address가 없을 경우에만 아래 내용 수행 
                    if (!prevGeo || !prevAddress) {
                        const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
                        if (isValidPosition(position)) {
                            const currentLanguage = i18n?.language;
                            const selectedLanguageForGoogleMaps = (typeof currentLanguage === 'string' && currentLanguage.length >= 2)
                                ? currentLanguage.substring(0, 2)
                                : 'en';
                            const address = await getAddress(geo.latitude, geo.longitude, selectedLanguageForGoogleMaps);
                            const timeCreated = geo.timeCreated ? geo.timeCreated : geo.lastUpdatedTime;
                            // 새로운 주소 정보를 Redux에 추가합니다.
                            dispatch(upsertDeviceAddress({ deviceId: locationData.deviceId, address, timeCreated }));
                        } else { // 암호화 된 lat, lng일 경우 
                            const timeCreated = geo.timeCreated ? geo.timeCreated : geo.lastUpdatedTime;
                            dispatch(upsertDeviceAddress({ deviceId: locationData.deviceId, address: t('location_encrypted'), timeCreated, isEncrypted: true }));
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * Get `MY` device list and save to global state
     */
    async function loadMyDeviceListData() {
        try {
            dispatch(setIsDeviceListLoading(true)); // set loading 
            dispatch(setIsMyDeviceLocationLoading(true));
            const userId = getUserId();
            const responseData = await fetchMyDevices(userId); // get devices from server
            const devices = responseData?.items;

            if (Array.isArray(devices) && devices.length > 0) { // only array 
                const ssoLoginDeviceId = getSsoLoginDeviceId();
                const orderedDevices = prioritizeSsoLoginDevice(devices, ssoLoginDeviceId);
                orderedDevices.forEach(item => {
                    item.userId = userId;
                    item.userName = getUserName();
                }); // 각 디바이스 리스트 아이템에 userId 필드를 추가합니다. 

                dispatch(setMyDeviceListData(orderedDevices)); // save devices as global state
                dispatch(setIsDeviceListLoading(false)); // set loaded 
                loadAllDeviceRelatedInfo(orderedDevices);
            } else {
                dispatch(setMyDeviceListData([])); // save devices as global state
                dispatch(setIsDeviceListLoading(false)); // set loaded 
            }

        } catch (error) {
            console.error(error);
            dispatch(setMyDeviceListData([])); // save devices as global state
            dispatch(setIsDeviceListLoading(false)); // set loaded 
        }
    }


    /**
     * Get `MY` item list and save to global state
     */
    async function loadMyItemListData() {
        try {
            // dispatch(actionSetIsDeviceListLoading(true)); // set loading  TODO : 별도의 loading 변수 필요 
            const userId = getUserId();
            const responseData = await fetchMyItemDevices(userId); // get devices from server
            const devices = responseData.items;

            if (Array.isArray(devices) && devices.length > 0) { // only array 
                devices.forEach(item => {
                    item.userId = userId;
                    item.userName = getUserName();
                }); // 각 디바이스 리스트 아이템에 userId 필드를 추가합니다. 

                const sortedTagList = sortItemListByCreateTime(devices);
                dispatch(setMyItemListData(sortedTagList)); // save devices as global state
                // TODO : 별도의 loading 변수 필요 
                loadAllTagRelatedInfo(sortedTagList);
            } else {
                // TODO : 별도의 loading 변수 필요 
                dispatch(setMyItemListData([])); // save devices as global state
            }

        } catch (error) {
            console.error(error);
            dispatch(setMyItemListData([])); // save devices as global state
            // TODO : 별도의 loading 변수 필요 
        }
    }


    /**
     * Get `Family` item list and save to global state
     */
    async function loadFamilyItemListData() {
        try {
            const userId = getUserId();
            const responseData = await fetchFamilyItemDevices(); // get family TAG devices from server
            const devices = collectOtherUsersDeviceList(responseData, userId);

            if (Array.isArray(devices) && devices.length > 0) { // only array 
                const sortedTagList = sortItemListByCreateTime(devices);
                dispatch(setFamilyItemListData(sortedTagList)); // save devices as global state
                loadAllTagRelatedInfo(sortedTagList);
            } else {
                dispatch(setFamilyItemListData([])); // save devices as global state
            }

        } catch (error) {
            console.error(error);
            dispatch(setFamilyItemListData([])); // save devices as global state
        }
    }


    /**
     * Get `MY` item list and save to global state
     */
    async function loadFamilyDeviceListData() {
        try {
            // dispatch(actionSetIsDeviceListLoading(true)); // set loading  TODO : 별도의 loading 변수 필요 
            const userId = getUserId();
            const responseData = await fetchFamilyDevices(userId); // get devices from server
            const devices = collectOtherUsersDeviceList(responseData, userId);
            dispatch(setFamilyDeviceListData(devices)); // save devices as global state
            loadAllDeviceRelatedInfo(devices);
        } catch (error) {
            console.error(error);
            dispatch(setFamilyDeviceListData([])); // save devices as global state
        }
    }


    /**
     * Collects device lists from other users excluding devices associated with myUserId.
     * This function performs the following steps:
     * 1. Filters out devices associated with myUserId from the responseData.
     * 2. Iterates over the filtered device lists, adding the corresponding userId to each device.
     * 3. Merges all device lists into a single array, with each device now including a userId field.
     */
    function collectOtherUsersDeviceList(responseData, myUserId) {
        if (!responseData || !responseData.devices) {
            return [];
        }

        const familyDeviceGroupList = responseData.devices;
        if (!Array.isArray(familyDeviceGroupList) || familyDeviceGroupList.length <= 0) {
            return [];
        }


        return familyDeviceGroupList
            .filter(item => item.userId !== myUserId) // myUserId가 아닌 userId에 대한 디바이스 리스트만 가져옵니다.
            .flatMap(item =>  //배열의 각 아이템의 deviceList를 하나의 배열로 합치기
                (item.deviceList || []).map(device => ({
                    ...device,
                    userId: item.userId,  // deviceList의 각 아이템에 userId 필드 추가
                    userName: item.name || 'Unknown User', // TODO : deviceList의 각 아이템에 userName 필드 추가
                }))
            );
    }


    const loadAllDeviceRelatedInfo = (devices) => {
        if (!devices || devices.length <= 0) {
            return;
        }

        const deviceImages = {};

        for (const device of devices) {
            const deviceImageUrl = device.modelInfo?.deviceImageUrl;

            if (deviceImageUrl) {
                deviceImages[device.modelInfo.deviceModelCode || device.modelInfo.modelName] = deviceImageUrl;
            }
        }

        dispatch(setDeviceImageListData(deviceImages));

        // 2. get location (/geolocation-report, 여러 deviceId에 대해서 한 방에 요청)
        const groupedDevices = groupDevicesByUserId(devices);
        const locationPromises = groupedDevices.map(group =>
            getDeviceLocation(group).then(responseData => {
                dispatch(upsertLocationListData(responseData.items));
            }).catch(e => console.error(e))
        );
        Promise.all(locationPromises).then(() => {
            dispatch(setIsMyDeviceLocationLoading(false));
        }).catch(e => {
            dispatch(setIsMyDeviceLocationLoading(false));
            console.error("An error occurred when getting locationlist loading", e)
        });


        // 3. get details (각 deviceId에 대해서 별도 요청 )
        devices.forEach(device => {
            getDeviceDetails(device.deviceId)
                .then(detail => {
                    dispatch(upsertDeviceDetail({ deviceId: device.deviceId, detail }));
                    sendRefreshOperations(device, detail);
                })
                .catch(e => console.error(e))
        });

    }

    function groupDevicesByUserId(devices) {
        const grouped = devices.reduce((acc, device) => {
            const userId = device.userId;
            if (!acc[userId]) {
                acc[userId] = [];
            }
            acc[userId].push({ userId: device.userId, deviceId: device.deviceId });
            return acc;
        }, {});
        return Object.values(grouped);
    }

    const sendRefreshOperations = (device, deviceDetail) => {
        if (!device) {
            return;
        }
        const deviceId = device.deviceId;
        const userId = device.userId;
        const deviceType = device.type;

        startOperation(device, deviceDetail, OPERATION_TYPES.LOCATION,
            () => {
                return performOperationLocation(deviceId, userId, deviceType)
                    .then(responseData => responseData)
                    .catch(e => {
                        throw e;
                    })
            }
        );

        startOperation(device, deviceDetail, OPERATION_TYPES.CHECK_CONNECTION,
            () => {
                return performOperationCheckConnection(deviceId, userId, deviceType)
                    .then(responseData => responseData)
                    .catch(e => {
                        throw e;
                    })
            }
        );
    }


    const loadAllTagRelatedInfo = (devices) => {
        if (!devices || devices.length <= 0) {
            return;
        }

        // 1. get device detail(find app api)
        // => image 추출
        // => displayName 추출
        devices.forEach(device => {
            if (device.deviceId) {
                getTagDetail(device.deviceId)
                    .then(responseData => {
                        const { deviceId, label } = responseData.item;
                        if (device.userId === getUserId()) { // 내 태그일 경우
                            dispatch(upsertMyTagDisplayName({ deviceId: deviceId, displayName: label }))
                        } else { // 가족 태그일 경우 
                            dispatch(upsertFamilyTagDisplayName({ deviceId: deviceId, displayName: label }));
                        }
                        const coloredIcon = responseData.item?.icons?.coloredIcon ?? null;
                        dispatch(upsertTagImage({ deviceId: deviceId, imageUrl: coloredIcon }));
                    })
                    .catch(e => console.error(e));
            }
        })

        // 2. tag의 최근 위치 받아오기 
        devices.forEach(device => {
            if (device?.deviceId) {
                getTagLocation(device.deviceId, device.userId, device.userName)
                    .then(responseData => {
                        dispatch(upsertLocationListData(responseData.items))
                    })
                    .catch(e => console.warn(e));
            }
        })

        // 3. get details (각 deviceId에 대해서 별도 요청 )
        devices.forEach(device => {
            sendRefreshOperations(device, null); // tag의 경우 detail 없이도 호출가능 
            getDeviceDetails(device.deviceId)
                .then(detail => {
                    const tempDetail = {
                        ...detail || {}, // detail이 null 또는 undefined인 경우 빈 객체를 사용
                        menu: [
                            ...(detail?.menu || []), // 기존 menu 배열이 있는 경우 그 내용을 유지, 없으면 빈 배열을 사용
                            { "location": "Y" },
                            { "ring": "Y" }
                        ],
                    };

                    dispatch(upsertDeviceDetail({ deviceId: device.deviceId, detail: tempDetail }));
                })
                .catch(e => {
                    // case : fmm login하지 않으면 detail정보를 얻을 수 없음. 500 error return됨. 수동으로 채워줌.
                    if (e.response.status === 500) {
                        const tempDetail = {
                            menu: [
                                { "location": "Y" },
                                { "ring": "Y" }
                            ],
                        };
                        dispatch(upsertDeviceDetail({ deviceId: device.deviceId, detail: tempDetail }));
                    }
                    console.error(e); 
                })
        });
    }


    return (
        <>
            {/* Map */}
            <FullScreenMap deviceListData={currentDeivceListData}></FullScreenMap>
            {/*  Device List & Device Detail */}
            <DeviceControlPanel deviceListData={currentDeivceListData}></DeviceControlPanel>
        </>
    );

}

export default Home;