import React, { useEffect, useState } from 'react';
import { Card, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import CustomBatteryGauge from './CustomBatteryGauge';
import { ModalSimHistory, ModalIMEI, ModalRemoveDevice } from '../../modal';
import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { DEVICE_REGI_STATUS, OPERATION_TYPES } from '../../../utills/enums/commons';
import { DM_ERROR_STATUS_CODE_SUCCESS } from '../../../utills/enums/DMErrorCodes';
import NotifyMeItemCard from './NotifyMeItemCard';
import { MODAL_LOCK_ACTION } from '../../modal/ModalLock/ModalLockTypes';


const TabletActionCard = ({ deviceId, selectedDeviceDetail }) => {
    const { t } = useTranslation();

    // Battery
    const { operationsResult, startOperation } = useDeviceOperations();
    const batteryLevel = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.battery;
    const isPendingDevice = selectedDeviceDetail?.lastSelectedDevice?.mgRegiStatus === DEVICE_REGI_STATUS.NOT_REGISTERED;

    // Remove device (with Lock Status)
    const modalLockState = useSelector((state) => state.modalLock);
    const mqttConnectionData = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data;
    const mqttLockData = operationsResult[deviceId]?.[OPERATION_TYPES.LOCK]?.data;
    const [dimmEffect, setDimmEffect] = useState(true);

    useEffect(() => {
        // device list가 refresh 된 상태에서 detail card 첫 진입, Lock동작 수행한 적 없는 상태 => mqttConnectionData의 fmmLock정보 이용.
        console.log("============================ mqttConnectionData all: ", mqttConnectionData);
        console.log("============================ mqttConnectionData : ", mqttConnectionData?.extra?.lockStatus);
        console.log("============================ mqttLockData : ", mqttLockData);
        if (mqttLockData === undefined) {
            // network OFF 상태인지 아닌지도 모르는 상태 (mqttConnectionData = undefined) => 어떤 설정을 적용할지 판단할 수 없음!!

            // network OFF 상태 (1900 & 1451) => offline이어도 기기를 remove할 수 있어야 함
            if (mqttConnectionData?.oprnStsCd === "1900" && mqttConnectionData?.oprnResultCode === "1451") {
                console.log("============================ mqttConnectionData offline : not dim");
                setDimmEffect(false);
            } else {
                if (mqttConnectionData?.extra?.lockStatus?.fmmLock === false) {
                    console.log("============================ mqttConnectionData fmmLock : not dim");
                    setDimmEffect(false);
                } else {
                    console.log("============================ mqttConnectionData fmmLock : dim");
                    setDimmEffect(true);
                }
            }
        }
        // Lock동작 후, refresh 하지 않은 경우, fmmLock 정보가 갱신되지 않아서 사용할 수 없음. => mqttLockData 정보를 활용.
        else {
            if (mqttLockData.oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS) { // Locked상태 (2800 & 1200)
                console.log("============================ mqttLockData : dim");
                setDimmEffect(true);
            } else { // turn off Allow setting상태 (2900 & 1452) 등...
                console.log("============================ mqttLockData : not dim");
                setDimmEffect(false);
            }
        }
    }, [mqttConnectionData, mqttLockData]);

    useEffect(() => {
        console.log("============================= modalLockState : ", modalLockState.status);
        if ((modalLockState?.status === MODAL_LOCK_ACTION.loading) || (modalLockState?.status === MODAL_LOCK_ACTION.waiting)) {
            console.log("============================ modalLockState : dim");
            setDimmEffect(true);
        } else if ((modalLockState?.status === MODAL_LOCK_ACTION.retry)) {
            console.log("============================ modalLockState : not dim");
            setDimmEffect(false);
        }
    }, [modalLockState]);

    // SIM Card
    const hasSimHistory = selectedDeviceDetail?.lastSelectedDevice?.simChangeHistory;

    // Modal
    const [isOpenSimHistory, setIsOpenSimHistory] = useState(false);
    const [openIMEI, setOpenIMEI] = useState(false);
    const [openRemoveDevice, setOpenRemoveDevice] = useState(false);

    // Modal - SIM card history
    const handleSimHistoryModalOpen = () => {
        setIsOpenSimHistory(true);
    }
    const handleSimHistoryModalClose = () => {
        setIsOpenSimHistory(false);
    };

    // Modal - IMEI / MAC
    const handleIMEIModalOpen = () => {
        setOpenIMEI(true);
    }
    const handleIMEIModalClose = () => {
        setOpenIMEI(false);
    };

    // Modal - RemoveDevice
    const handleRemoveDeviceModalOpen = () => {
        setOpenRemoveDevice(true);
    }
    const handleRemoveDeviceModalClose = () => {
        setOpenRemoveDevice(false);
    };


    return (
        <div className="action-card">
            {batteryLevel === undefined && !isPendingDevice
                ? <NotifyMeItemCard deviceId={deviceId} />
                : null
            }

            {!isPendingDevice && (
                <Card className="inner-card">
                    <MenuItem>
                        <CustomBatteryGauge batteryLevel={batteryLevel}></CustomBatteryGauge>
                    </MenuItem>
                </Card>
            )}

            <Card className="inner-card-list">
                {hasSimHistory &&
                    <>
                        <MenuItem onClick={handleSimHistoryModalOpen}>
                            {t('sim_history')}
                        </MenuItem>
                        <hr />
                    </>
                }

                <MenuItem onClick={handleIMEIModalOpen}>
                    {t('device_info')}
                </MenuItem>
                <hr />
                <MenuItem disabled={dimmEffect} onClick={handleRemoveDeviceModalOpen}>
                    {t('remove_device')}
                </MenuItem>
            </Card>

            {/* modal dialog */}
            <ModalSimHistory open={isOpenSimHistory} onClose={handleSimHistoryModalClose} deviceDetail={selectedDeviceDetail} />
            <ModalIMEI open={openIMEI} onClose={handleIMEIModalClose} passedValue={selectedDeviceDetail} />
            <ModalRemoveDevice open={openRemoveDevice} onClose={handleRemoveDeviceModalClose} deviceId={deviceId} />
        </div>
    );
}

export default TabletActionCard;