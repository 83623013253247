export const getDeviceIdFromSearchParams = (search: string) => {
  try {
    const params = new URLSearchParams(search);
    const deviceId = params.get('deviceId');
    return deviceId;
  } catch(error) {
    console.error('Error invalid search params!')
    throw error;
  }
}
