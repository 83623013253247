import React from 'react';
import { IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import LoadingCircle from '../view-only/LoadingCircle';
import { ReactSVG } from 'react-svg'


function RoundedIconButtonWithLabelBottom({ icon, name, onClick }) {
  return (
    <div className='rounded-icon-buttom-with-label-bottom-container'>
      <IconButton onClick={onClick}>
        {icon
          ? <ReactSVG style={{height: "24px"}} src={icon} alt={name} />
          : <LoadingCircle></LoadingCircle>
        }
      </IconButton>

      <Typography>{name}</Typography>
    </div>
  )
}

RoundedIconButtonWithLabelBottom.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default RoundedIconButtonWithLabelBottom