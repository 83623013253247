import { useState, useEffect, useRef } from 'react';
import blueDot from '../../assets/images/trackLocation/location_blue_dot.svg';
import React from 'react';
import ReactDOM from 'react-dom/client';
import DeviceMarkerImage from '../../components/maps/DeviceMarkerImage';
import { RenderLocationType } from './useGoogleMapTrackLocationPolyLineTypes';
import { setTrackLocationInfoAction } from '../../slices/modalTrackLocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../slices/types';
import { Tooltip, Typography } from '@mui/material';
import { getTimestampFormatted } from '../../utills/functions';
import MapWithGoogle from '../../components/maps/MapWithGoogle';
import { Box } from '@mui/system';

const useGoogleMapTrackLocation = () => {

    const [trackLocationPolyLine, setTrackLocationPolyLine] = useState<any>(null);
    const [trackLocationMarkerList, setTrackLocationMarkerList] = useState<any>(null);
    const isInfoWindowOpenObject = useRef<{prevUUID: string, open: boolean}>({prevUUID: "", open: false});
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const trackLocationInfo = useSelector((state: RootStateType) => state.modalTrackLocation.trackLocationInfo);
    const dispatch = useDispatch();
    const infoWindow = useRef<any>();

    // 컴포넌트가 언마운트될 때 폴리라인 제거
    useEffect(() => {

        return () => {
            removeTrackLocation();
        };
    }, []);


    // 폴리라인을 제거하는 함수
    const removeTrackLocation = () => {
        if (trackLocationPolyLine) {
            trackLocationPolyLine.setMap(null);
            setTrackLocationPolyLine(null); // 상태를 null로 설정하여 폴리라인 제거
        }
        if (trackLocationMarkerList && Array.isArray(trackLocationMarkerList)) {
            trackLocationMarkerList.forEach((e) => {
                e.marker.setMap(null);
            });
            setTrackLocationMarkerList(null);
        }
    };

    // 폴리라인을 렌더링하는 함수, Google맵과 trackLocation좌표정보 리스트를 인자로 받음
    const renderTrackLocation = ({
            map,
            points,
            focusToMarker,
            deviceInfo
        }: RenderLocationType) => {
        let googleMaps = (window as any)?.google?.maps;
        if (!googleMaps || !map || !Array.isArray(points) || points.length === 0) {
            return;
        }
        removeTrackLocation(); // 기존 폴리라인 제거
        if (!infoWindow.current) {
            infoWindow.current = new googleMaps.InfoWindow({
                content: "",
                pixelOffset: new googleMaps.Size(0, 0),
                maxWidth: 200,
            });
        } 
        const path = points.map(point => ({lat: parseFloat(point.latitude), lng: parseFloat(point.longitude)}));

        // 마커 생성
        const markerList = points.map((location, i, arr) => {
            const position = { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) };
            const trackLocationMarkerContainer = document.createElement("div");
            trackLocationMarkerContainer.className = "track-location-marker-container";
            trackLocationMarkerContainer.id = location.uuid;
            const marker = new googleMaps.marker.AdvancedMarkerElement({
                position,
                content: trackLocationMarkerContainer,
                map,
                zIndex: arr.length - i
            })

            if (i === 0) {
                const markerRoot = ReactDOM.createRoot(trackLocationMarkerContainer);
                markerRoot.render(
                    <DeviceMarkerImage deviceImageUrl={deviceInfo?.imageUrl} deviceType={deviceInfo?.type} isOnline={deviceInfo?.isOnline} unitsForBuds={undefined} selectedUnitForBuds={undefined} width={undefined} height={undefined} />
                );
            } else {
                const markerRoot = ReactDOM.createRoot(trackLocationMarkerContainer);
                markerRoot.render(
                    <img src={blueDot} alt="Blue Dot"/>
                );
            }

            trackLocationMarkerContainer.onclick = () => {
                if (i === 0) {
                    infoWindow.current.set("pixelOffset", new googleMaps.Size(0,-45)); 
                } else {
                    infoWindow.current.set("pixelOffset", new googleMaps.Size(0, 29)); 
                }
                const contentString = `<div class="google-map-track-location-tooltip-text">${getTimestampFormatted(location.date.toISOString(), 'Do MMM h:mm A')}</div>`;
                focusToMarker(position);
                const prev = isInfoWindowOpenObject.current?.prevUUID;
                const open = isInfoWindowOpenObject.current?.open;
                if (prev === location.uuid && open) {
                    isInfoWindowOpenObject.current!.prevUUID = location.uuid;
                    isInfoWindowOpenObject.current!.open = false;
                    infoWindow.current.close();
                } else {
                    infoWindow.current.open(map, marker);
                    isInfoWindowOpenObject.current!.prevUUID = location.uuid;
                    isInfoWindowOpenObject.current!.open = true;
                }
                infoWindow.current.setContent(contentString)
            };

           return {
                marker,
                date: location.date,
                uuid: location.uuid,
            };
        });
        setTrackLocationMarkerList(markerList);
    
        const lineSymbol = {
            path: "M 0, -0.5 0, 0.5",
            strokeOpacity: 1,
            strokeColor: '#1C8CEF',
            scale: 4,
        };

        // 점선 경로 생성
        const polyLine = new googleMaps.Polyline({
            strokeOpacity: 0,
            icons: [
                {
                icon: lineSymbol,
                offset: "0",
                repeat: "15px",
                },
            ],
            path: path,
            map,
        });
        setTrackLocationPolyLine(polyLine);
    };

    return { renderTrackLocation, removeTrackLocation, trackLocationPolyLine, trackLocationMarkerList };
};
export default useGoogleMapTrackLocation;