import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOST_MODE, LostModeStateType } from './lostModeSliceTypes';
import { GetLostModeResponseType, GetTagLostModeResponseType, PostLostModeRequestType, PostLostModeResponseType, PutTagLostModeReponseType, PutTagLostModeRequestType } from '../../api/find/lostModeApi/lostModeApiTypes';
import { GetTagMetadataRequest, GetTagMetadataResponse } from '../../api/find/devicesApi/devicesApiTypes';

const initialState: LostModeStateType = {
    lostModeModalOpen: false,
    lostModeSettingModalOpen: false,
    getLostModeState: {
        isLoading: false,
        data: {
            enabled: 0,
            phone_number: '',
            email: '',
            message: '',
            deviceImageUrl: '',
            device_name: ''
        },
        errors: null
    },
    getTagLostModeState: {
        isLoading: false,
        data: {
            enabled: 0,
            phoneNumber: '',
            email: '',
            message: '',
            messageType: '',
        },
        errors: null
    },
    postLostModeState: {
        isLoading: false,
        data: null,
        errors: null
    },
    putTagLostModeState: {
        isLoading: false,
        data: null,
        errors: null
    },
    getTagMetadataState: {
        isLoading: false,
        errors: null,
        data: {
            modelName: '',
            setupId: '',
            mnId: '',
            firmwareVersion: '',
            supportedFeatures: {
                ringTheTag: false,
                updateRingtone: false,
                buttonAction: false,
                batteryType: false,
                leftBehindAlert: false,
                firmwareUpdate: false,
                nfc: false,
                accelerometer: false,
                powerSavingMode: false
            },
            tagType: ''
        }
    }
}

export const lostModeSlice = createSlice({
    name: LOST_MODE,
    initialState,
    reducers: {
        setLostModeModalOpen: (state: LostModeStateType, { payload: open }: PayloadAction<boolean>) => {
            state.lostModeModalOpen = open;
        },
        setLostModeModalSettingOpen: (state: LostModeStateType, { payload: open }: PayloadAction<boolean>) => {
            state.lostModeSettingModalOpen = open;
        },
        getLostModeByDeviceIdAction: (state: LostModeStateType, { payload: deviceId }: PayloadAction<string>) => {
            state.getLostModeState.isLoading = true;
            state.getLostModeState.data = {
                enabled: 0,
                phone_number: '',
                email: '',
                message: '',
                device_name: '',
                deviceImageUrl: ''
            };
            state.getLostModeState.errors = null;
        },
        getLostModeByDeviceIdSuccessAction: (state: LostModeStateType, { payload: getLostModeResponse }: PayloadAction<GetLostModeResponseType>) => {
            state.getLostModeState.isLoading = false;
            state.getLostModeState.data = getLostModeResponse;
        },
        getLostModeByDeviceIdErrorAction: (state: LostModeStateType, { payload: error }: PayloadAction<any>) => {
            state.getLostModeState.isLoading = false;
            state.getLostModeState.errors = error;
        },
        getTagMetadataAction: (state: LostModeStateType, { payload }: PayloadAction<GetTagMetadataRequest>) => {
            state.getTagMetadataState.isLoading = true;
            state.getTagMetadataState.data =  {
                modelName: '',
                setupId: '',
                mnId: '',
                firmwareVersion: '',
                supportedFeatures: {
                    ringTheTag: false,
                    updateRingtone: false,
                    buttonAction: false,
                    batteryType: false,
                    leftBehindAlert: false,
                    firmwareUpdate: false,
                    nfc: false,
                    accelerometer: false,
                    powerSavingMode: false
                },
                tagType: ''
            };
            state.getTagMetadataState.errors = null;
        },
        getTagMetadataSuccessAction: (state: LostModeStateType, { payload }: PayloadAction<GetTagMetadataResponse['item']>) => {
            state.getTagMetadataState.isLoading = true;
            state.getTagMetadataState.data = payload;
        },
        getTagMetadataErrorAction: (state: LostModeStateType, { payload }: PayloadAction<any>) => {
            state.getTagMetadataState.isLoading = true;
            state.getTagMetadataState.errors = payload;
        },
        postLostModeAction: (state: LostModeStateType, { payload }: PayloadAction<PostLostModeRequestType>) => {
            state.postLostModeState.isLoading = true;
            state.postLostModeState.errors = null;
        },
        postLostModeSuccessAction: (state: LostModeStateType, { payload: postLostModeResponse }: PayloadAction<PostLostModeResponseType>) => {
            state.postLostModeState.isLoading = false;
            state.postLostModeState.data = postLostModeResponse;
        },
        postLostModeErrorAction: (state: LostModeStateType, { payload: error }: PayloadAction<any>) => {
            state.postLostModeState.isLoading = false;
            state.postLostModeState.errors = error;
        },
        // TAG
        getTagLostModeByDeviceIdAction: (state: LostModeStateType, { payload: deviceId }: PayloadAction<string>) => {
            state.getTagLostModeState.isLoading = true;
            state.getTagLostModeState.data = {
                enabled: 0,
                phoneNumber: '',
                email: '',
                message: '',
                messageType: '',
            };
            state.getTagLostModeState.errors = null;
        },
        getTagLostModeByDeviceIdSuccessAction: (state: LostModeStateType, { payload: getTagLostModeResponse }: PayloadAction<GetTagLostModeResponseType['item']>) => {
            state.getTagLostModeState.isLoading = false;
            state.getTagLostModeState.data = getTagLostModeResponse;
        },
        getTagLostModeByDeviceIdErrorAction: (state: LostModeStateType, { payload: error }: PayloadAction<any>) => {
            state.getTagLostModeState.isLoading = false;
            state.getTagLostModeState.errors = error;
        },
        putTagLostModeAction: (state: LostModeStateType, { payload: deviceId }: PayloadAction<PutTagLostModeRequestType>) => {
            state.putTagLostModeState.isLoading = true;
            state.putTagLostModeState.data = null;
            state.putTagLostModeState.errors = null;
        },
        putTagLostModeSuccessAction: (state: LostModeStateType, { payload: putTagLostModeResponse }: PayloadAction<PutTagLostModeReponseType>) => {
            state.putTagLostModeState.isLoading = false;
            state.putTagLostModeState.data = putTagLostModeResponse;
        },
        putTagLostModeErrorAction: (state: LostModeStateType, { payload: error }: PayloadAction<any>) => {
            state.putTagLostModeState.isLoading = false;
            state.putTagLostModeState.errors = error;
        },
        enableTagLostModeAction: (state: LostModeStateType, { payload: willEnable }: PayloadAction<0 | 1>) => {
            state.getTagLostModeState.data.enabled = willEnable;
        },
        enableLostModeAction: (state: LostModeStateType, { payload: willEnable }: PayloadAction<0 | 1>) => {
            state.getLostModeState.data.enabled = willEnable;
        }
    },
});


export const {
    setLostModeModalOpen,
    setLostModeModalSettingOpen,
    getLostModeByDeviceIdAction,
    getLostModeByDeviceIdSuccessAction,
    getLostModeByDeviceIdErrorAction,
    getTagMetadataAction,
    getTagMetadataSuccessAction,
    getTagMetadataErrorAction,
    postLostModeAction,
    postLostModeSuccessAction,
    postLostModeErrorAction,
    getTagLostModeByDeviceIdAction,
    getTagLostModeByDeviceIdSuccessAction,
    getTagLostModeByDeviceIdErrorAction,
    putTagLostModeAction,
    putTagLostModeSuccessAction,
    putTagLostModeErrorAction,
    enableTagLostModeAction,
    enableLostModeAction
} = lostModeSlice.actions;

export default lostModeSlice.reducer;
