import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material';
import { LabelTextFieldProps } from './LabelTextFieldTypes';
import "./LabelTextFieldStyles.scss"

const LabelTextField = ({ title, textFieldProps }: LabelTextFieldProps) => {
  const [moveHelperTextLength, setMoveHelperTextLength] = useState(0);

  useEffect(() => {
    const textLength = textFieldProps.helperText?.toString().length;
    if (textLength) {
      const targetLength = 42;
      const diff = textLength + title.length - targetLength;
      if (diff > 0) {
        setMoveHelperTextLength(diff * 4);
      } else {
        setMoveHelperTextLength(0);
      }
    }
  }, [textFieldProps.helperText]);

  return (
    <div className="label-text-field-container">
        <div className="label-text-field-title-container">
            {title}
        </div>
        <div className="text-field-container">
            <TextField
                {...textFieldProps}
                fullWidth
                variant='standard'
                FormHelperTextProps={{ style: { left:`-${moveHelperTextLength}px` } }}
                InputProps={{ disableUnderline: true }}
            />
        </div>
    </div>
  )
}
;
export default LabelTextField;