import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import './ModalBatteryStyles.scss';

import { Typography, Tooltip, Box } from '@mui/material';
import { ModalBatteryProps } from './ModalBatteryTypes';
import BaseModal from '../BaseModal';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { extend_battery_life, success, fail } from "../../../assets/images/deviceCard";
import info from "../../../assets/images/common/info.svg";
import LoadingCircle from '../../view-only/LoadingCircle';
import { performOperationPowerSave } from '../../../api/findToStf/devicesApi/devicesApi';
import { transformDateToDoneTimeString } from '../../../utills/functions/time';
import { OPERATION_TYPES } from '../../../utills/enums/commons';
import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { setOperationResponse } from '../../../slices/operationSlice';
import RoundedTooltip from '../../view-only/RoundedTooltip/RoundedTooltip';
import { DEVICE_TYPES } from '../../../utills/enums/Devices';
import dayjs from 'dayjs';
import { DM_ERROR_STATUS_CODE_FAIL, DM_ERROR_STATUS_CODE_PENDING, DM_ERROR_STATUS_CODE_PROCESSING, DM_ERROR_STATUS_CODE_SUCCESS } from '../../../utills/enums/DMErrorCodes';


enum PowerSaveRequestState {
    Initial,
    Loading,
    Pending,
    Success,
    Error,
}

const ModalBattery = ({ open, onClose, deviceDetail }: ModalBatteryProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const { operationsResult, startOperation } = useDeviceOperations();
    const powerSaveResult = operationsResult[selectedDevice?.deviceId]?.[OPERATION_TYPES.POWER_SAVE];
    const powerSaveResultData = powerSaveResult?.data;
    const isPowerSaveLoading = powerSaveResult?.isLoading;
    const isPowerSaveTimedOut = powerSaveResult?.isTimeout;
    const isPowerSaveFailed = powerSaveResult?.isFailed;
    const maxStandByTimeString = powerSaveResultData?.maxStandByTime ?? '0';
    const operationDoneTime = transformDateToDoneTimeString(powerSaveResultData?.oprnDoneDate ?? '');
    const { hours, minutes } = convertToHoursAndMinutes(maxStandByTimeString);

    const [isPowerSaveRequested, setIsPowerSaveRequested] = useState(false);
    const powerSaveRequestState = getPowerSaveRequestState();

    const isUILoading = getPowerSaveRequestState() === PowerSaveRequestState.Loading || getPowerSaveRequestState() === PowerSaveRequestState.Pending

    console.log('powerSaveResultData: ', powerSaveResult); // debug 
    console.log('getPowerSaveRequestState(): ', getPowerSaveRequestState()); // debug 
    // PowerSaveRequestState를 계산하는 함수
    function getPowerSaveRequestState(): PowerSaveRequestState {
        // 요청 후 로딩이 끝났을 때, 타임아웃은 아닌데, mqtt 결과가 성공이 아니거나 요청 자체에서 실패했을때  
        if (isPowerSaveRequested && !isPowerSaveLoading && !isPowerSaveTimedOut && (powerSaveResultData?.oprnStsCd !== DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS || isPowerSaveFailed)) return PowerSaveRequestState.Error;
        // 로딩중 일 때 
        else if (isPowerSaveLoading) return PowerSaveRequestState.Loading;
        // 요청 후 로딩이 끝났을 때, mqtt 결과를 못 받고 타임아웃이 되었을때 
        else if (isPowerSaveTimedOut) return PowerSaveRequestState.Pending;
        // 아직 요청을 하지 않았고(또는 로딩이 끝나고), 로딩 중이 아니고, mqtt 결과가 없을 때
        else if (!isPowerSaveLoading && !powerSaveResultData?.oprnStsCd) return PowerSaveRequestState.Initial;
        // 아직 요청을 하지 않았고(또는 로딩이 끝나고), 로딩 중이 아니고, mqtt 결과가 2100 (PROCESSING) 일 때 
        else if (!isPowerSaveLoading && powerSaveResultData?.oprnStsCd === DM_ERROR_STATUS_CODE_PROCESSING.OPERATION_PROCESSING) return PowerSaveRequestState.Loading;
        // 아직 요청을 하지 않았고(또는 로딩이 끝나고), 로딩 중이 아니고, mqtt 결과가 2900 (Fail) 일 때 
        else if (!isPowerSaveLoading && powerSaveResultData?.oprnStsCd === DM_ERROR_STATUS_CODE_FAIL.OPERATION_FAIL) return PowerSaveRequestState.Error;
        // 아직 요청을 하지 않았고(또는 로딩이 끝나고), 로딩 중이 아니고, mqtt 결과가 1000 (Pending)일 때 
        else if (!isPowerSaveLoading && powerSaveResultData?.oprnStsCd === DM_ERROR_STATUS_CODE_PENDING.NOTIFICATION_PENDING) return PowerSaveRequestState.Pending;
        // 아직 요청을 하지 않았고(또는 로딩이 끝나고), 로딩 중이 아니고, mqtt 결과가 2800 (Success)일 때 
        else if (!isPowerSaveLoading && powerSaveResultData?.oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS) return PowerSaveRequestState.Success;
        else return PowerSaveRequestState.Error; // 기본값, 예외 상황에 대한 처리
    };

    function convertToHoursAndMinutes(maxStandByTimeString: string): { hours: number, minutes: number } {
        const maxStandByTime = parseInt(maxStandByTimeString, 10);
        if (isNaN(maxStandByTime) || maxStandByTime < 0) {
            // 유효하지 않은 값 처리
            console.warn(`Invalid maxStandByTime value: ${maxStandByTimeString}`);
            return { hours: 0, minutes: 0 };
        }
        const hours = Math.floor(maxStandByTime / 60);
        const minutes = maxStandByTime % 60;
        return { hours, minutes };
    }

    function findPowerSaveOperation(operations: any): any | null {
        if (!Array.isArray(operations)) {
            console.warn('Invalid input: operations is not an array');
            return null;
        }
        const powerSaveOperation = operations.find((operation: any) => operation?.oprnType === OPERATION_TYPES.POWER_SAVE);
        return powerSaveOperation || null;
    }

    useEffect(() => {
        if (open) {
            initOperationResult();
        }
    }, [open]);


    const initOperationResult = () => {
        if (!powerSaveResult) {
            // deviceDetail에 있는 operation 이력을 찾아서 반영
            const powerSaveOperation = findPowerSaveOperation(deviceDetail?.operation);
            const deviceId = selectedDevice?.deviceId;
            const operationType = OPERATION_TYPES.POWER_SAVE;
            dispatch(setOperationResponse({ deviceId, operationType, data: powerSaveOperation, isFailed: false, isTimeout: false }));
        } else {

        }
        setIsPowerSaveRequested(false); // 초기화 (새로 오픈한 모달에서 절전모드 요청 하지 않음 상태)
    }


    const handleCancel = () => {
        onClose();
    }

    const handleExtendBattery = () => {
        setIsPowerSaveRequested(true); // 절전모드 요청 함 상태로 변경 
        startOperation(selectedDevice, deviceDetail, OPERATION_TYPES.POWER_SAVE,
            () => {
                return performOperationPowerSave(selectedDevice?.deviceId, selectedDevice?.userId, selectedDevice?.type)
                    .then(responseData => {
                        return responseData
                    })
                    .catch(e => {
                        throw e;
                    })
            }
        );
    }

    function getNextYearDateString() {
        const nextYear = dayjs().add(1, 'year');
        const day = nextYear.date();
        const suffix = day % 10 === 1 && day % 100 !== 11 ? 'st' : day % 10 === 2 && day % 100 !== 12 ? 'nd' : day % 10 === 3 && day % 100 !== 13 ? 'rd' : 'th';
        return `${nextYear.format('MMM D')}${suffix}, ${nextYear.format('YYYY')}`;
    }

    const getPendingBatteryMessage = () => {
        // ex) "휴대전화가 %s 전에 네트워크에 연결되면, 배터리 사용가능 시간이 늘어납니다.""
        const deviceType = selectedDevice?.type;
        let pendingBatteryMessageByType = "";
        if (deviceType === DEVICE_TYPES.PHONE) {
            pendingBatteryMessageByType = t('battery_extended_phone');
        } else if (deviceType === DEVICE_TYPES.TAB) {
            pendingBatteryMessageByType = t('battery_extended_tablet');
        } else if (deviceType === DEVICE_TYPES.WATCH) {
            pendingBatteryMessageByType = t('battery_extended_watch');
        }

        // TOOD: %s 대신 날짜 삽입하기  
        return pendingBatteryMessageByType.replace("%s", getNextYearDateString());

    }

    const ContentBlock = (
        <>
            <div className='statusImage'>
                {powerSaveRequestState === PowerSaveRequestState.Loading || powerSaveRequestState === PowerSaveRequestState.Pending
                    ? (
                        <>
                            <LoadingCircle></LoadingCircle>
                            <br />
                            {powerSaveRequestState === PowerSaveRequestState.Loading ? t('extend_battery_ing') : getPendingBatteryMessage()}
                        </>
                    )
                    : (
                        <div className='batteryIcon'>
                            <img src={extend_battery_life} alt='' />
                            <div className='successOrFail'>
                                {powerSaveRequestState === PowerSaveRequestState.Success && <img src={success} alt='' />}
                                {powerSaveRequestState === PowerSaveRequestState.Error && <img src={fail} alt='' />}
                            </div>
                            <br />
                            {powerSaveRequestState === PowerSaveRequestState.Success && t('battery_extended')}
                            {powerSaveRequestState === PowerSaveRequestState.Error && t('cant_extend_battery')}

                            {(powerSaveRequestState === PowerSaveRequestState.Success || powerSaveRequestState === PowerSaveRequestState.Error) ?
                                (operationDoneTime && <> <br /> {'(' + operationDoneTime + ')'} </>)
                                : null
                            }
                        </div>
                    )
                }
            </div>

            <Typography variant='modalParagraph'>
                {t('battery_maximum_power_saving')}
                <br />
                * {t('while_battery_extended')}
            </Typography>

            {powerSaveRequestState === PowerSaveRequestState.Success
                && !(hours === 0 && minutes === 0) &&
                <div className='infoText'>
                    <Typography variant='modalParagraph' >
                        {t('estimated_time_remain')}&nbsp;
                        {t('other_hour_other_min').replace("%1$d", String(hours)).replace("%2$d", String(minutes))}
                    </Typography>
                    <RoundedTooltip title={
                        <Box padding="12px">
                            <Typography variant='modalParagraph'>
                                {t('estimated_time_depending')}
                            </Typography>
                        </Box>
                    } arrow enterTouchDelay={0}>
                        <img src={info} alt='info' />
                    </RoundedTooltip>
                </div>
            }
        </>
    );

    const ActionBlock = (
        <ModalBottomButton
            title={t('extend')}
            isLoading={isUILoading}
            onClick={handleExtendBattery}
            disabled={isUILoading}
        />
    );


    return (
        <div>
            <BaseModal
                title={t('extend_battery_life')}
                open={open}
                onClose={handleCancel}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
        </div>
    );
};

export default ModalBattery
