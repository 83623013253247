/**
 * AuthenticatedRoute.js
 *
 * A higher-order component that wraps around the React Router's Route component.
 * This component checks the authentication status of the user using the `isLoggedIn` function
 * from `auth.js` and either renders the desired component (if authenticated) or redirects
 * the user to the login page (if not authenticated).
 *
 * For example, if a user tries to access the '/settings' route and they are not
 * authenticated, they will be redirected to the login page.
 *
 * This component provides a simple and consistent way to protect routes that require authentication.
 */

import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { isLoggedIn, clearSessionAndUserInfo } from '../utills/auth';

const AuthenticatedRoute = () => {

    const [redirectToLogin, setRedirectToLogin] = useState(false);

    useEffect(() => {
        // 이벤트 리스너 등록
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // 컴포넌트가 unmount될 때 이벤트 리스너 해제
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const handleVisibilityChange = () => {
        // 토큰 만료 확인 및 리렌더링 강제하여 로그아웃
        if (!isLoggedIn()) {
            setRedirectToLogin(true);
        }
    };

    // 렌더링 시 로그인 여부 확인하여 페이지 라우팅
    if (!isLoggedIn()) {
        clearSessionAndUserInfo();
        return <Navigate to="/login" replace />;
    }
    return <Outlet />;
};




export default AuthenticatedRoute;