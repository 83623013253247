import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { getCountryCode } from '../utills/auth';
import { useMediaQuery, Button } from "@mui/material";
import { MOBILE_PORTRAIT_VIEW_QUERY, TABLET_VIEW_QUERY } from '../utills/constants';
import { setIsGuardianAgreed } from "../slices/authSlice/authSlice";


const OnboardingGuardian = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobileView = useMediaQuery(MOBILE_PORTRAIT_VIEW_QUERY);
    const isTabletView = useMediaQuery(TABLET_VIEW_QUERY);


    // local states
    const [linkText, setLinkText] = useState(t('agree_by_continuing'));
    const [buttonText, setButtonText] = useState(t('continue'));
    const [linkUrl, setLinkUrl] = useState("https://account.samsung.com/membership/etc/specialTC.do?fileName=locationinfortnc.html");


    useEffect(() => {
        // Button Text
        if (getCountryCode() === 'KOR') {
            setLinkText(t('agree_to_continue'));
            setButtonText(t('agree'));
        } else {
            setLinkText(t('agree_by_continuing'));
            setButtonText(t('continue'));
        }

        // Link URL - 이 페이지는 이미 한국, EU아동만 진입한 상태임.
        // 한국 아동
        const country = getCountryCode();
        if (country === 'KOR') {
            setLinkUrl("https://account.samsung.com/membership/etc/specialTC.do?fileName=locationinfortnc.html");
        }
        // EU 아동
        else {
            setLinkUrl("https://notice.samsungfind.samsung.com/use-of-location/content/en-US/guardian.html");
        }
    }, []);  // 처음만 실행

    const handleClickContinue = () => {
        console.log("OnboardingGuardian - Click Agree");
        // ** Optin은 최종 단계에서 일괄 업데이트 하기로 함 (feature set 참고)
        navigate('/onboardingLocation', { replace: true });
    }

    const handleClickCancel = () => {
        console.log("OnboardingGuardian - Click Cancel");
        // Optin은 최종 단계에서 일괄 업데이트 하기로 함 (feature set 참고)
        // Onboarding flow 모두 완료 시점에 모든 동의 값을 서버에 저장함
        // [PM_1123] Onboarding flow 중 이탈 후 재진입 시 처음부터 다시 진행 정책 추가

        // Guardian 에서는 SA logout 대신, 부모동의 취소하고, Onboarding으로 돌아가야 함.
        dispatch(setIsGuardianAgreed(false));
        navigate('/onboarding', { replace: true });
    }


    return (
        <div className={isMobileView ? "onboarding-container-mobile" : (isTabletView ? "onboarding-container-tablet" : "onboarding-container")}>
            <div className="onboarding-header">
                <p>{t('samsung_find')}</p>
            </div>

            <div className="onboarding-notitle-content">
                <p>{t('sf_explanation_short')}</p>

                {linkText.split("%s").map((item, idx) => {
                    return (
                        (idx === 0) ?
                            <span key={idx}>
                                {item}
                                <a target="_blank" rel="noreferrer noopener" href={linkUrl}>
                                    {t('use_location_info')}
                                </a>
                            </span>
                            : <span key={idx}> {item} </span>
                    )
                })}
            </div>

            <div className="onboarding-button">
                <Button variant="contained" onClick={handleClickContinue}>
                    {buttonText}
                </Button>
                <Button variant="contained" onClick={handleClickCancel}>
                    {t('cancel')}
                </Button>
            </div>
        </div>
    );
}

export default OnboardingGuardian;