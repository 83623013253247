/**
 * Validate function to determine if the input value is numbers
 */
export const isNumber = (value: string): boolean => {
    return /^\d*$/.test(value);
}


/**
 * Validate if the input value is email from
 */
export const isEmailForm = (value: string): boolean => {
    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    return emailRegEx.test(value);
}

// FMM deviceId 형식 검증 유틸리티 함수
export function isFmmDeviceIdFormat(value: string): boolean {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(value);
  }
  