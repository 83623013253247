/**
 * axiosConfig.js Configuration
 *
 * This file contains the configuration settings for the Axios HTTP client to ensure
 * consistent and centralized setup across the application. Key configurations include:
 * - Setting the base URL for API requests.
 * - Configuring request and response interceptors for tasks such as:
 *   - Attaching authentication tokens to outgoing requests.
 *   - Handling errors and potential token expirations.
 * - Managing request headers, timeouts, and other Axios settings.
 *
 * By centralizing these configurations, it simplifies the process of making API
 * calls throughout the app, and ensures a uniform approach to error handling
 * and request preparation.
 */

import axios from 'axios';
import { getAuthServerUrl, getCountryCode, getToken, getUserId, isLoggedIn, signOutAndRedirectToHome } from '../../utills/auth';
import { AxiosErrorLogger } from '../logger/axiosErrorLogger';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_FIND_TO_STF_SERVER, // default base url 
});

export const logFindToSTFBaseURLAxios = () => {
    console.log('Axios Find To STF baseURL initialized with : ', axiosInstance.defaults.baseURL);
}

/**
 * Request interceptor
 */
axiosInstance.interceptors.request.use(
    config => {
        if (!isLoggedIn()) {
            signOutAndRedirectToHome();
            return Promise.reject(new Error('Not logged in'));
        }
        const token = getToken();
        config.headers['Content-Type'] = 'application/json'
        config.headers['x-sec-sa-authserverurl'] = getAuthServerUrl();
        config.headers['x-sec-sa-authtoken'] = token;
        config.headers['x-sec-sa-countrycode'] = getCountryCode();
        config.headers['x-sec-sa-userid'] = getUserId();
        config.headers['x-sec-find-client-type'] = 'web'
        config.headers['x-sec-tab-name'] = 'DEVICES' // DEVICES/PEOPLE/ITEMS

        return config;
    },
    error => Promise.reject(error)
);


// Response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        AxiosErrorLogger.handle(error);

        if (error.response) {
            // 401 Unauthorize 또는 403 Forbidden 상태 코드에 대한 처리
            if (error.response.status === 401 || error.response.status === 403) {
                if (getToken()) {
                    console.log('Handling 401 Unauthorize or 403 Forbidden status codes');
                    signOutAndRedirectToHome();
                }
            }
        }

        return Promise.reject(error);
    }
);
export default axiosInstance;