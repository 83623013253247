import React from "react";

import "./DeviceControlFunctionsMobile.scss";
import RoundedIconButtonWithLabelBottom from "../../buttons/RoundedIconButtonWithLabelBottom";
import { DeviceControlFunctionsMobileProps } from "./DeviceControlFunctionsMobileTypes";


function DeviceControlFunctionsMobile({
	buttonPropsList
}: DeviceControlFunctionsMobileProps) {

	return (
		<div className="device-control-functions-mobile-container">
			{buttonPropsList.map(({
				  onButtonClick,
				  buttonImageSrc,
				  buttonTitle
			}) => {
				return (
					<RoundedIconButtonWithLabelBottom
						onClick={onButtonClick}
						icon={buttonImageSrc}
						name={buttonTitle}
					/>
				);
			})}
		</div>
	);
}

export default DeviceControlFunctionsMobile;
