import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IconButton, ListItemButton, ListItemText } from "@mui/material";
import { findImageLinkByModelCode, getDefaultCircleImageByDeviceType, getDeviceName, getIsDeviceOnline, getTimestampFormatted } from "../../utills/functions";
import { OPERATION_TYPES } from '../../utills/enums/commons';
import useCachedImage from "../../hooks/useCachedImage";
import useDeviceOperations from '../../hooks/useDeviceOperations';
import { useIsMobileView } from "../../hooks/useIsMobileView";
import { setIsDeviceDetailOpen, setSelectedDevice, updateSelectedUnitForBuds } from "../../slices/deviceListSlice";
import { badge_dot_online, badge_dot_offline, toggle_buds_L, toggle_buds_R, buds_left_text, buds_right_text } from "../../assets/images/deviceCard";
import { BUDS_UNITS, DEVICE_TYPES } from "../../utills/enums/Devices";


const DeviceListItem = memo(({ device, address, updatedTime }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobileView } = useIsMobileView();
    const { operationsResult, startOperation } = useDeviceOperations();

    const modelName = device?.modelInfo?.modelName;
    const deviceModelCode = device?.modelInfo?.deviceModelCode;
    const deviceType = device?.type ? device.type : '';
    const deviceSubType = device?.modelInfo?.deviceSubType;

    const deviceId = device?.deviceId ? device.deviceId : null;
    const displayName = getDeviceName(device);
    const lastUpdatedAddressTime = getTimestampFormatted(updatedTime, 'Do MMM h:mm A');  // ex. (1705329769000);

    const deviceImageListData = useSelector(state => state.deviceList.deviceImageListData);
    const defaultImage = getDefaultCircleImageByDeviceType(deviceType, deviceSubType);
    const imageUrl = findImageLinkByModelCode(deviceImageListData, deviceModelCode ? deviceModelCode : modelName);
    const imageSrc = useCachedImage(imageUrl, defaultImage);

    const batteryLevelForBudsL = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.extra?.left?.battery;
    const batteryLevelForBudsR = operationsResult[deviceId]?.[OPERATION_TYPES.CHECK_CONNECTION]?.data?.extra?.right?.battery;

    const selectedUnitForBuds = useSelector(state =>
        state.deviceList.budsSelectedUnitList?.find(detail => detail.deviceId === deviceId)?.selectedUnit
    );

    useEffect(() => {
        initSelectedUnitForBuds();
    }, [batteryLevelForBudsL, batteryLevelForBudsR]); // CHECK_CONNECTION operation 가 업데이트 됨에 따른 액션 


    /**
     * BUDS 디바이스의 경우 좌 우 어느 unit이 선택되었는지를 초기화 합니다.
     * L, R 배터리가 모두 on일 경우에만 BOTH로 지정합니다.
     * 모두 off 일 경우 left를 default로 설정합니다.
     * 둘 중 하나라도 on이면 on인 쪽을 default로 설정합니다. 
     * on, off 가 변화함에 따라 다시 초기화 합니다. 
     */
    function initSelectedUnitForBuds() {
        if (deviceType === DEVICE_TYPES.BUDS) {
            if (batteryLevelForBudsR && batteryLevelForBudsL) { // L, R 배터리가 모두 on일 경우에만 BOTH로 지정합니다.
                dispatch(updateSelectedUnitForBuds({ deviceId: deviceId, selectedUnit: BUDS_UNITS.BOTH }));
            } else if(batteryLevelForBudsR && !batteryLevelForBudsL) { // 오른쪽만 ON일 경우 
                dispatch(updateSelectedUnitForBuds({ deviceId: deviceId, selectedUnit: BUDS_UNITS.RIGHT }));
            } else { // 왼쪽만 on이거나 모두 off 일 경우 
                dispatch(updateSelectedUnitForBuds({ deviceId: deviceId, selectedUnit: BUDS_UNITS.LEFT }));
            }
        }
    }

    // Buds select left / right
    const handleBudsLRClick = (event) => {
        event.stopPropagation();
        if (selectedUnitForBuds === BUDS_UNITS.RIGHT) {
            dispatch(updateSelectedUnitForBuds({ deviceId: deviceId, selectedUnit: BUDS_UNITS.LEFT }));
        } else {
            dispatch(updateSelectedUnitForBuds({ deviceId: deviceId, selectedUnit: BUDS_UNITS.RIGHT }));
        }
    }


    return (
        <ListItemButton key={deviceId} className="device-list"
            onClick={() => {
                dispatch(setSelectedDevice(device));
                if (!isMobileView) {
                    dispatch(setIsDeviceDetailOpen(true));
                }
            }}>

            <IconButton className="device-list-icon-button">
                <img src={imageSrc} alt=" " />
                <img src={getIsDeviceOnline(device, operationsResult, selectedUnitForBuds) ? badge_dot_online : badge_dot_offline} />
                {// BUDS 이면서 UNIT이 BOTH가 아닐 경우에만 L, R 구분하여 표시 
                    (deviceType === DEVICE_TYPES.BUDS && selectedUnitForBuds !== BUDS_UNITS.BOTH)
                        ? <img src={selectedUnitForBuds === BUDS_UNITS.RIGHT ? buds_right_text :buds_left_text} />
                        : null
                }
            </IconButton>

            <ListItemText className="device-list-text"
                primary={
                    <>
                        <span> {displayName} </span>
                        {
                            getIsDeviceOnline(device, operationsResult, selectedUnitForBuds)
                                ? <span> {lastUpdatedAddressTime} </span>
                                : <span> {t('offline')} </span>
                        }
                        {
                            // BUDS 이면서 UNIT이 BOTH가 아닐 경우에만 L, R 구분하여 표시 
                            (deviceType === DEVICE_TYPES.BUDS && selectedUnitForBuds !== BUDS_UNITS.BOTH)
                                ? <span> <img src={selectedUnitForBuds === BUDS_UNITS.RIGHT ? toggle_buds_R : toggle_buds_L} onClick={handleBudsLRClick} /> </span>
                                : null
                        }
                    </>
                }
                secondary={address ? address : t('location_not_found')}
            >
            </ListItemText>
        </ListItemButton>
    );
});

export default DeviceListItem;
