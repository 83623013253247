import React, { useState, useEffect } from 'react';
import {
    Card,
    Switch,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { NotifyMeItemCardProps } from './NotifyMeItemCardTypes';
import { ModalNotifyMe } from "../../../modal";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch, RootStateType } from "../../../../slices/types";
import { asyncPutNotifyMe, asyncTagPutNotifyMe, setNotifyMeModalOpen } from "../../../../slices/notifyMeSlice";
import { DEVICE_TYPES } from "../../../../utills/enums/Devices";
import { formatString } from '../../../../utills/functions/stringPhasers';
import { getDeviceName, getIsDeviceOnline, isDeviceOnline } from "../../../../utills/functions";
import useBudsDirection from '../../../../hooks/useBudsDirection/useBudsDirection';
import { hasShownEmergencyModeDialog, markEmergencyDialogShown } from '../../../../utills/functions/emergencyModeStorage';
import useDeviceOperations from '../../../../hooks/useDeviceOperations';


const NotifyMeItemCard = ({ deviceId }: NotifyMeItemCardProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppThunkDispatch>();
    const {
        selectedUnitForBuds,
        isBoth,
    } = useBudsDirection();

    const notifyMeStatusData = useSelector((state: any) => state.notifyMe.getNotifyMeState.data);
    const isNotifyMeOn = useSelector((state: RootStateType) => state.notifyMe.isNotifyMeOn);
    const notifyMeModalOpen = useSelector((state: RootStateType) => state.notifyMe.notifyMeModalOpen);
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const [title, setTitle] = useState('');
    const [contentText, setContentText] = useState('');
    const displayName = getDeviceName(selectedDevice);
    const { operationsResult, startOperation } = useDeviceOperations();

    useEffect(() => {
        openEmergencyModeDialogIfNeeded();
    }, [notifyMeStatusData]);

    const onSwitchChange = (e: any) => {
        // switch를 off → on,  dialog open 시킴.
        if (e.target.checked) {
            setTitle(t("notify_me"));
            setContentText(formatString(t('send_notification'), displayName));
            dispatch(setNotifyMeModalOpen(true));
        }
        // switch를 on → off
        else {
            const item = {
                enabled: false,
                userId: selectedDevice?.userId,
            };
            if (selectedDevice.type === DEVICE_TYPES.BUDS && !isBoth) {
                Object.assign(item, { units: selectedUnitForBuds });
            }
            if (selectedDevice.type === DEVICE_TYPES.TAG) {
                dispatch(asyncTagPutNotifyMe({ deviceId, searchingStatus: 'stop' }));
            } else {
                dispatch(asyncPutNotifyMe({ deviceId, item }));
            }
        }
    };

    const onModalClose = (e: any) => {
        dispatch(setNotifyMeModalOpen(false));
    }

    const openEmergencyModeDialogIfNeeded = () => {
        if (!selectedDevice) {
            return;
        }
        const deviceId = selectedDevice.deviceId;
        const deviceType = selectedDevice.type;
        const isNotifyMeStatusOff = notifyMeStatusData && !isNotifyMeOn; // notify me on/off 상태를 서버로부터 받아 왔으며, OFF 임이 확인됐을 경우 
        const notShownDialog = !hasShownEmergencyModeDialog(deviceId) // 아직 한번도 다이얼로그 안 띄웠다면 
        const isOffline = !getIsDeviceOnline(selectedDevice, operationsResult, selectedUnitForBuds); // 디바이스가 offline일 경우 (battery 정보 없을경우) 

        // console.log('isNotifyMeStatusOff, notShownDialog, isOffline : ', isNotifyMeStatusOff, notShownDialog, isOffline);
        if (isNotifyMeStatusOff && notShownDialog && isOffline) {
            // 모달 내용 수정해서 다이얼로그 오픈
            if (deviceType === DEVICE_TYPES.BUDS) {
                setTitle(t("earbuds_disconnected"));
                setContentText(t('cant_locate_your_earbuds'));
            } else {
                setTitle(t("turn_on_emergency"));
                setContentText(formatString(t('send_notification'), displayName));
            }
            dispatch(setNotifyMeModalOpen(true)); // emegency mode 추천 다이얼로그 열기 
            markEmergencyDialogShown(deviceId); // 최초 한 번 열었다고 표시해놓기
        }
    }

    return (
        <div>
            <ModalNotifyMe open={notifyMeModalOpen} onClose={onModalClose} title={title} contentText={contentText} />
            <Card className="inner-card-notify">
                <ListItemButton>
                    <ListItemText>{t("notify_me")}</ListItemText>
                    <ListItemIcon>
                        <Switch className='switch-button-common' checked={isNotifyMeOn} onChange={onSwitchChange} />
                    </ListItemIcon>
                </ListItemButton>
            </Card>
        </div>
    );
};

export default NotifyMeItemCard;
