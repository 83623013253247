import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsCard from "../components/cards/SettingsCard";


const Settings = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const goToHome = () => {
        // 현재 settings 페이지에 진입할 때 
        // { state: { reloadDeviceList: false }} 를 전달받았다면,
        // 메인페이지로 이동할때 그대로 전달해서 reload를 방지합니다. 
        navigate('/', { state: location.state }); 
    }


    return (
        <>
            <SettingsCard handleClose={goToHome}></SettingsCard>
        </>
    )
}

export default Settings;