import { all, fork } from "redux-saga/effects";
import watchLostModeSaga from "./lostModeSlice/lostModeSagas";
import watchTrackLocationSaga from "./modalTrackLocationSlice/modalTrackLocationSagas";

const rootSaga = function* () {
  yield all([
    fork(watchLostModeSaga),
    fork(watchTrackLocationSaga),
  ]);
};

export default rootSaga;