/**
 * DM ErrorCode
 * 
 * url: https://mobilerndhub.sec.samsung.net/wiki/display/FME/ErrorCode
 */


// Status Code: oprnStsCd
export const DM_ERROR_STATUS_CODE_PENDING = {
    NOTIFICATION_PENDING: '1000',
    OPERATION_PENDING: '2000',
    NOTIFICATION_PENDING_WEARABLE: '3000',
    NOTIFICATION_PENDING_ST_PLUGIN: '4000',
}
export type DM_ERROR_STATUS_CODE_PENDING_TYPE = typeof DM_ERROR_STATUS_CODE_PENDING[keyof typeof DM_ERROR_STATUS_CODE_PENDING];

export const DM_ERROR_STATUS_CODE_PROCESSING = {
    OPERATION_PROCESSING: '2100',
    OPERATION_PROCESSING_WEARABLE: '3100',
    OPERATION_PROCESSING_ST_PLUGIN: '4100',
}
export type DM_ERROR_STATUS_CODE_PROCESSING_TYPE = typeof DM_ERROR_STATUS_CODE_PROCESSING[keyof typeof DM_ERROR_STATUS_CODE_PROCESSING];

export const DM_ERROR_STATUS_CODE_CANCEL = {
    NOTIFICATION_CANCEL: '1400',
    OPERATION_CANCEL: '2400',
}
export type DM_ERROR_STATUS_CODE_CANCEL_TYPE = typeof DM_ERROR_STATUS_CODE_CANCEL[keyof typeof DM_ERROR_STATUS_CODE_CANCEL];

export const DM_ERROR_STATUS_CODE_FAIL = {
    OPERATION_FAIL: '2900',
    NOTIFICATION_FAIL_TIME_EXPIRED: '1900',
    NOTIFICATION_FAIL_MANDATORY_FIELD_MISSING: '1950'
}
export type DM_ERROR_STATUS_CODE_FAIL_TYPE = typeof DM_ERROR_STATUS_CODE_FAIL[keyof typeof DM_ERROR_STATUS_CODE_FAIL];

export const DM_ERROR_STATUS_CODE_SUCCESS = {
    OPERATION_SUCCESS: '2800'
}
export type DM_ERROR_CODE_SUCCESS_TYPE = typeof DM_ERROR_STATUS_CODE_SUCCESS[keyof typeof DM_ERROR_STATUS_CODE_SUCCESS];

export const DM_ERROR_STATUS_CODE = {
    ...DM_ERROR_STATUS_CODE_PENDING,
    ...DM_ERROR_STATUS_CODE_PROCESSING,
    ...DM_ERROR_STATUS_CODE_CANCEL,
    ...DM_ERROR_STATUS_CODE_FAIL,
    ...DM_ERROR_STATUS_CODE_SUCCESS
}
export type DM_ERROR_STATUS_CODE_TYPE = typeof DM_ERROR_STATUS_CODE[keyof typeof DM_ERROR_STATUS_CODE];


// Result Code: oprnResultCode
export const DM_ERROR_RESULT_CODE = {
    DISABED_REMOTE_CONTROL: '1452',
    OPERATION_SUCCESS: '1200',
    ALERT_BY_API: '900',
    TRACKING_STOPPED_BY_ALERT: '3990',
    NOT_CONNECTED_STATUS: '501',
    ALREADY_LOCKED_STATUS: '504',
    DEFAULT: '200'
}
export type DM_ERROR_RESULT_CODE_TYPE = typeof DM_ERROR_RESULT_CODE[keyof typeof DM_ERROR_RESULT_CODE];