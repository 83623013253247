import React from 'react';
import BaseActionCard from '../BaseActionCard';
import './PinConfirmActionCardStyles.scss';

import { PinConfirmActionCardProps } from './BaseActionCardTypes';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import PasswordTextField from '../../../text-fields/PasswordTextField';
import LoadingCircle from '../../../view-only/LoadingCircle';

const PinConfirmActionCard = ({ open, value, error: isError, isLoading, title, onChange, onClickOk, onClickCancel }: PinConfirmActionCardProps) => {
    const { t } = useTranslation();

    const cancelActionButtonProps = {
        title: t('cancel'),
        onClick: onClickCancel,
        disabled: isError || isLoading
    };
    const okActionButtonProps = {
        title: t('ok'),
        onClick: onClickOk,
        disabled: isError || isLoading || (value ? false : true)
    };

    const ContentField = (
        <div className='pin-confirm-action-card-content-field-container'>
            <p>
                <Typography>{t('enter_pin_to_get_location')}</Typography>
            </p>
            {
                isLoading ? (<LoadingCircle></LoadingCircle>) : null
            }
            <PasswordTextField
                placeholder={isError ? '' : t('pin_6_digits')}
                error={isError}
                errorMessage={isError ? t('incorrect_pin_entered') : undefined}
                value={value}
                onChange={onChange}
            />
        </div>
    );

    return (
        <div>
            <BaseActionCard
                open={open}
                title={title}
                firstButtonProps={cancelActionButtonProps}
                secondButtonProps={okActionButtonProps}
                contentsField={ContentField}
                onClose={onClickCancel}
                isCloseButtonEnabled={true}
            />
        </div>
    )
}

export default PinConfirmActionCard;