import React, { useState, useEffect, useRef } from 'react';
import blueDot from '../../assets/images/trackLocation/location_blue_dot.svg';
import ReactDOM from 'react-dom/client';
import { useSelector } from 'react-redux';
import DeviceMarkerImage from '../../components/maps/DeviceMarkerImage';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'; // 날짜 포맷을 커스텀하기 위해 필요
import advancedFormat from 'dayjs/plugin/advancedFormat'; // 'Do'와 같은 고급 포맷을 사용하기 위해 필요
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export interface TrackLocationPoint {
    latitude: string;
    longitude: string;
    date: Date;
    uuid: string;
}

const TooltipContent = ({ date }: any) => {
    return (
        <div style={{
            padding: '10px 20px', // 좀 더 여유 있는 패딩
            backgroundColor: '#f9f9f9', // 밝은 회색조 배경
            border: '1px solid #ccc', // 경계선 색상 조정
            borderRadius: '8px', // 둥근 모서리
            boxShadow: '0 2px 4px rgba(0,0,0,0.18)', // 그림자 효과 추가
            color: '#333', // 글자 색상
            textAlign: 'center', // 텍스트 중앙 정렬
            fontSize: '14px', // 글자 크기
            width: '200px', // 고정 너비
            boxSizing: 'border-box', // 패딩을 포함한 너비 설정
        }}>
            {date}
        </div>
    );
};


const useKakaoMapTrackLocation = () => {

    const [trackLocationPolyLine, setTrackLocationPolyLine] = useState<any>(null);
    const [trackLocationPositionMarkers, setTrackLocationPositionMarkers] = useState<any>(null);
    const currentTooltipRef = useRef<any>(null);
    const deviceMarkerOverlayRef = useRef<any>(null);
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);

    // 컴포넌트가 언마운트될 때 TrackLocation UI 제거
    useEffect(() => {
        return () => {
            removeTrackLocation();
        };
    }, []);


    // TrackLocation UI를 제거하는 함수
    const removeTrackLocation = () => {
        // Remove PolyLine
        if (trackLocationPolyLine) {
            trackLocationPolyLine.setMap(null);
            setTrackLocationPolyLine(null);
        }

        // Remove position markers 
        if (trackLocationPositionMarkers && Array.isArray(trackLocationPositionMarkers)) {
            trackLocationPositionMarkers.forEach(marker => marker.setMap(null)); // marker를 map에서 삭제 
            setTrackLocationPositionMarkers(null);
        }

        // Remove device image marker
        if (deviceMarkerOverlayRef.current) {
            deviceMarkerOverlayRef.current.setMap(null);
            deviceMarkerOverlayRef.current = null;
        }

        // Remove tooltip 
        if (currentTooltipRef.current) {
            currentTooltipRef.current.setMap(null);
            currentTooltipRef.current = null;
        }

    };


    // TrackLocatin UI를 렌더링 하는 함수 
    function renderTrackLocation(map: any, points: TrackLocationPoint[], focusToMarker: any) {
        const { kakao } = window;
        if (!kakao || !kakao.maps || !map || !Array.isArray(points) || points.length === 0) {
            return;
        }

        // 기존 폴리라인, 마커 제거
        removeTrackLocation();

        // Render PolyLine
        const polyline = createPolyline(map, points);
        setTrackLocationPolyLine(polyline);

        // Render position markers 
        const newPositionMarkers = createPositionMarkers(map, points, focusToMarker);
        setTrackLocationPositionMarkers(newPositionMarkers)

        // Render device image marker
        if (points.length > 0) {
            const lastPoint = points[points.length - 1];
            const position = new kakao.maps.LatLng(lastPoint.latitude, lastPoint.longitude);
            const deviceMarkerOverlay = createDeviceMarker(map, position);
            deviceMarkerOverlayRef.current = deviceMarkerOverlay;
        }
    }


    // PolyLine을 생성하고 카카오맵에 렌더링하는 함수
    function createPolyline(map: any, points: TrackLocationPoint[]) {
        const { kakao } = window;
        if (!kakao || !kakao.maps || !map || !Array.isArray(points) || points.length === 0) {
            return;
        }

        const linePath = points.map(point => new kakao.maps.LatLng(point.latitude, point.longitude));
        const polyline = new kakao.maps.Polyline({
            path: linePath,
            strokeWeight: 5,
            strokeColor: '#387aff',
            strokeOpacity: 0.7,
            strokeStyle: 'dashed'
        });
        polyline.setMap(map);
        return polyline;
    };

    // Device image marker 를 생성하고 카카오맵에 렌더링하는 함수
    function createDeviceMarker(map: any, position: any) {
        const { kakao } = window;
        if (!kakao || !kakao.maps || !map || !selectedDevice) {
            return;
        }

        const overlayElement = document.createElement('div');
        const overlayRoot = ReactDOM.createRoot(overlayElement);
        overlayRoot.render(
            <DeviceMarkerImage deviceImageUrl={null} deviceType={selectedDevice?.type} isOnline={true} unitsForBuds={undefined} selectedUnitForBuds={undefined} width={undefined} height={undefined} />
        );
        const deviceMarkerOverlay = new kakao.maps.CustomOverlay({
            content: overlayElement,
            map: map,
            position: position,
        });
        deviceMarkerOverlay.setMap(map);
        return deviceMarkerOverlay;
    };


    // Position Markers 을 생성하고 카카오맵에 렌더링하는 함수
    function createPositionMarkers(map: any, points: TrackLocationPoint[], focusToMarker: any) {
        const { kakao } = window;
        if (!kakao || !kakao.maps || !map || !Array.isArray(points) || points.length === 0) {
            return;
        }

        const newLocationMarkers = points.map(point => {
            const position = new kakao.maps.LatLng(point.latitude, point.longitude);

            const content = document.createElement('div');
            content.className = 'map-marker';
            content.id = point.uuid;
            content.style.width = '30px';
            content.style.height = '30px';
            content.style.backgroundImage = `url(${blueDot})`;
            content.style.backgroundSize = 'cover';
            content.style.borderRadius = '50%';
            content.style.cursor = 'pointer';
            // 오버레이 생성 시 xAnchor와 yAnchor를 모두 0.5로 설정합니다.
            const overlay = new kakao.maps.CustomOverlay({
                position: position,
                content: content,
                xAnchor: 0.5,
                yAnchor: 0.5
            });
            overlay.setMap(map);

            // 클로저를 사용하여 point.date를 클릭 이벤트 리스너에 포함시킵니다.
            content.addEventListener('click', (function (date) {
                return function () {
                    focusToMarker(position); // 지도 중심 조정
                    // 여기서 date를 사용할 수 있습니다.
                    // 예: 특정 날짜 정보를 표시하는 로직
                    console.log(date); // 실제 구현에서는 이 부분에 로직을 추가합니다.
                    if (currentTooltipRef.current) { // Remove existing tooltips
                        currentTooltipRef.current.setMap(null);
                    }

                    const overlayElement = document.createElement('div');

                    overlayElement.style.position = 'absolute';
                    overlayElement.style.left = '50%'; // 좌우 중앙 정렬
                    overlayElement.style.transform = 'translate(-50%, -150%)'; // 위로 전체 높이만큼 이동
                    overlayElement.style.zIndex = '2'; // z-index 설정
                    const locationDate = dayjs(date).format("MMM Do, h:mm A");
                    const overlayRoot = ReactDOM.createRoot(overlayElement);
                    overlayRoot.render(<TooltipContent date={locationDate}></TooltipContent>);
                    const tooltipOverlay = new kakao.maps.CustomOverlay({
                        content: overlayElement,
                        map: map,
                        position: overlay.getPosition(),
                        yAnchor: 1, // 오버레이가 위치할 Y축 앵커 포인트 (0~1 사이 값, 1이면 완전 상단)
                        xAnchor: 0.5, // 오버레이가 위치할 X축 앵커 포인트 (0~1 사이 값, 0.5면 중앙)
                    });
                    currentTooltipRef.current = tooltipOverlay;
                };
            })(point.date));

            return overlay;
        });

        // Handle when clicking anywhere on the map (Remove tooltip)
        kakao.maps.event.addListener(map, 'click', function () {
            if (currentTooltipRef.current) {
                currentTooltipRef.current.setMap(null);
                currentTooltipRef.current = null;
            }
        });

        return newLocationMarkers;
    };

    return { renderTrackLocation, removeTrackLocation };
};


export default useKakaoMapTrackLocation;