import React from 'react';
import { useTranslation } from "react-i18next";
import { ListItem, ListItemIcon, ListItemText, Button, Typography } from '@mui/material';
import { ic_card_payment_gray } from "../../../assets/images/deviceCard";
import { formatNumber } from "../../../utills/functions/stringPhasers";
import { ModalRegisteredCardProps } from './ModalRegisteredCardTypes';
import './ModalRegisteredCardStyles.scss';
import BaseModal from '../BaseModal';
import { CardData } from '../../../slices/cardListSlice/cardListSliceTypes';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { getCountryCode } from '../../../utills/auth';

const ModalRegisteredCard = ({ open, onClose, cardList }: ModalRegisteredCardProps) => {
    const { t } = useTranslation();

    const handleCancel = () => {
        onClose();
    }

    const numOfCard = formatNumber(t('registered_card'), String(cardList ? cardList?.length : 0));

    const makeCards = (cardList: Array<CardData> | null | undefined) => {
        if (!cardList) {
            return null;
        }
        return cardList.map(card => {
            const last4Digit = (card.last4Digit === "-") ? null : card.last4Digit;
            return (
                <div key={card.id}>
                    <ListItem>
                        <ListItemIcon>
                            <img src={card.art[0].value} alt={ic_card_payment_gray}/>
                        </ListItemIcon>
                        <ListItemText>
                            <div className="card-name-container">
                                <p>{card.name}</p> {last4Digit ? <p>{`...${last4Digit}`}</p> : null}
                            </div>
                            <div className='phone-container'>
                                <a href={`tel:${card.cs.phone}`}>{card.cs.phone}</a>
                            </div>
                            <div className='web-link-container'>
                                <a href={card.cs.web} target="_blank" rel="noreferrer">{card.cs.web}</a>
                            </div>
                        </ListItemText>
                    </ListItem>
                    <hr />
                </div>
            )
        });
    }

    const ContentBlock = (
        <>
            <div>
                <Typography>
                    {t('card_issuer')}
                </Typography>
                {getCountryCode() === 'HKG' ? (
                    <Typography
                        marginTop='10px'
                        sx={{color: 'blue'}}
                    >
                        {`※ ${t('octopus_phone_wallet')}`}
                    </Typography>
                ) : null}
                   <div className="registered-num">
                        {numOfCard}
                    </div>
                    <div className="registered-card-list-container">
                        {makeCards(cardList)}
                    </div>
            </div>
        </>
    );

    const ActionBlock = (
        <ModalBottomButton title={t('close')} onClick={handleCancel} />
    );

    return (
        <>
            <BaseModal
                title={t('registered_card_title')}
                open={open}
                onClose={handleCancel}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
        </>
    );
};

export default ModalRegisteredCard
