import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MODAL_TRACK_LOCATION, MODAL_TRACK_LOCATION_STATE_TYPE, ModalTrackLocationStateType, TrackLocationInfo } from './modalTrackLocationSliceTypes';
import { GetTrackLocationsRequestType, GetTrackLocationsResponseType, OperationRequestType, OperationResponseType } from '../../api/findToStf/devicesApi/deviceApiTypes';

const initialState: ModalTrackLocationStateType = {
    modalTrackLocationOpen: false,
    modalTrackLocationState: {},
    stopTrackingActionCardOpen: false,
    restartTrackingActionCardOpen: false,
    postOperationTrackLocationState: {
        isLoading: false,
        data: null,
        errors: null
    },
    stopOperationTrackLocationState: {
        isLoading: false,
        data: null,
        errors: null
    },
    getTrackLocationState: {},
    trackLocationInfo: {}
}

export const modalTrackLocationSlice = createSlice({
    name: MODAL_TRACK_LOCATION,
    initialState,
    reducers: {
        setModalTrackLocationOpen: (state: ModalTrackLocationStateType, { payload }: PayloadAction<boolean>) => {
            state.modalTrackLocationOpen = payload;
        },
        setModalTrackLocationState: (state: ModalTrackLocationStateType, { payload: { deviceId, modalState } }: PayloadAction<{deviceId: string, modalState: MODAL_TRACK_LOCATION_STATE_TYPE}>) => {
            state.modalTrackLocationState[deviceId] = modalState;
        },
        setStopTrackingActionCardOpen: (state: ModalTrackLocationStateType, { payload }: PayloadAction<boolean>) => {
            state.stopTrackingActionCardOpen = payload;
        },
        setRestartTrackingActionCardOpen: (state: ModalTrackLocationStateType, { payload }: PayloadAction<boolean>) => {
            state.restartTrackingActionCardOpen = payload;
        },
        postOperationTrackLocationAction: (state: ModalTrackLocationStateType, { payload }: PayloadAction<Omit<OperationRequestType, "operation">>) => {
            state.postOperationTrackLocationState.data = null;
            state.postOperationTrackLocationState.isLoading = true;
        },
        postOperationTrackLocationSuccessAction: (state: ModalTrackLocationStateType, { payload: operationResponse }: PayloadAction<OperationResponseType['item']>) => {
            state.postOperationTrackLocationState.isLoading = false;
            state.postOperationTrackLocationState.data = operationResponse;
        },
        postOperationTrackLocationErrorAction: (state: ModalTrackLocationStateType, { payload: error }: PayloadAction<any>) => {
            state.postOperationTrackLocationState.isLoading = false;
            state.postOperationTrackLocationState.errors = error;
        },
        stopOperationTrackLocationAction: (state: ModalTrackLocationStateType, { payload }: PayloadAction<Omit<OperationRequestType, "operation">>) => {
            state.stopOperationTrackLocationState.isLoading = true;
        },
        stopOperationTrackLocationSuccessAction: (state: ModalTrackLocationStateType, { payload: operationResponse }: PayloadAction<OperationResponseType['item']>) => {
            state.stopOperationTrackLocationState.isLoading = false;
            state.stopOperationTrackLocationState.data = operationResponse;
        },
        stopOperationTrackLocationErrorAction: (state: ModalTrackLocationStateType, { payload: error }: PayloadAction<any>) => {
            state.stopOperationTrackLocationState.isLoading = false;
            state.stopOperationTrackLocationState.errors = error;
        },
        getTrackLocationAction: (state: ModalTrackLocationStateType, { payload }: PayloadAction<GetTrackLocationsRequestType>) => {
            state.getTrackLocationState[payload.deviceId] = {
                isLoading: true,
                data: null,
                errors: null
            }
        },
        getTrackLocationSuccessAction: (state: ModalTrackLocationStateType, { payload: {locationInfo, deviceId} }: PayloadAction<{ deviceId: string } & GetTrackLocationsResponseType['item']>) => {
            state.getTrackLocationState[deviceId] = {
                isLoading: false,
                data: locationInfo,
                errors: null,
            }
        },
        getTrackLocationErrorAction: (state: ModalTrackLocationStateType, { payload: { errors, deviceId }}: PayloadAction<{errors: any, deviceId: string}>) => {
            state.getTrackLocationState[deviceId]!.isLoading = false;
            state.getTrackLocationState[deviceId]!.errors = errors;
        },
        clearGetTrackLocationAction: (state: ModalTrackLocationStateType, { payload: {deviceId} }: PayloadAction<{deviceId: string}>) => {
            state.getTrackLocationState[deviceId] = {
                isLoading: false,
                data: null,
                errors: null,
            }
        },
        setTrackLocationInfoAction: (state: ModalTrackLocationStateType, { payload:{ trackLocationInfo, deviceId }}: PayloadAction<{
            trackLocationInfo: Array<TrackLocationInfo>,
            deviceId: string
        }>) => {
            state.trackLocationInfo[deviceId] = {
                isLoading: false,
                data:trackLocationInfo,
                errors: null
            }
        },
        clearTrackLocationInfoAction: (state: ModalTrackLocationStateType, { payload:{ deviceId }}: PayloadAction<{deviceId: string}>) => {
            state.trackLocationInfo[deviceId] = {
                isLoading: false,
                data: [],
                errors: null
            }
        }
    },
});

export const {
    setModalTrackLocationOpen,
    setModalTrackLocationState,
    setStopTrackingActionCardOpen,
    setRestartTrackingActionCardOpen,
    postOperationTrackLocationAction,
    postOperationTrackLocationSuccessAction,
    postOperationTrackLocationErrorAction,
    stopOperationTrackLocationAction,
    stopOperationTrackLocationSuccessAction,
    stopOperationTrackLocationErrorAction,
    getTrackLocationAction,
    getTrackLocationSuccessAction,
    getTrackLocationErrorAction,
    clearGetTrackLocationAction,
    setTrackLocationInfoAction,
    clearTrackLocationInfoAction
} = modalTrackLocationSlice.actions;


export default modalTrackLocationSlice.reducer;