import axios from 'axios';
import axiosInstance from '../findAxiosConfig';
import { AxiosErrorLogger } from '../../logger/axiosErrorLogger';

/**
 * Fetch AccessToken with Auth code from samsung find api sever 
 * @returns 
 */
export const getAccessToken = async (headers: {
    authServerUrl: string,
    authCode: string,
    redirectUri: string
}) => {
    const initialAxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL_FIND_SERVER,
    });
    
    try {
        const response = await initialAxiosInstance.post('/auth/oauth2/token', {}, {
            headers: {
                'Content-Type': 'application/json',
                'x-sec-sa-authserverurl': headers.authServerUrl,
                'x-sec-sa-authcode': headers.authCode,
                'x-sec-sa-redirecturl': headers.redirectUri
            },
        });

        if (response.status === 200) {
            console.log("fetchAccessToken response: ", response.data);
            return response.data;
        } else {
            throw new Error("Failed to fetch access token: Invalid response status");
        }

    } catch (error) {
        AxiosErrorLogger.handle(error);

        console.error("Error in fetchAccessToken:", error);
        throw new Error("Failed to fetch token: " + error);
    }
}

export const getParentId = async () => {
    try {
        const response = await axiosInstance.get(`/family/children`);
        return response.data;
    } catch (error) {
        console.error('Error requesting getParentId :  ', error);
        throw error;
    }
};

