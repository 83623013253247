import { useSelector } from 'react-redux';
import { BUDS_UNITS } from '../../utills/enums/Devices';

const useBudsDirection = () => {
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const selectedUnitForBuds = useSelector((state: any) =>
        state.deviceList.budsSelectedUnitList?.find((detail: any) => detail.deviceId === selectedDevice?.deviceId)?.selectedUnit
    );
    
    return {
        selectedUnitForBuds,
        isRight: selectedUnitForBuds === BUDS_UNITS.RIGHT,
        isLeft: selectedUnitForBuds === BUDS_UNITS.LEFT,
        isBoth: selectedUnitForBuds === BUDS_UNITS.BOTH
    }
}

export default useBudsDirection;