import axiosInstance from "../findAxiosConfig";
import { getChildAccountSupported, getCountryCode, getUserId } from '../../../utills/auth';


/**
 * SA 로그인한 사용자의 'Agreements(=use of location information), Guardian's Consent, Location settings' 상태를 받아옵니다.
 */
export const getAllOptinStatus = async () => {
    try {
        const response = await axiosInstance.get(`/users/optin`);;
        console.log(response)

        // 동의 된 것이 없을 때, response는 빈 배열[]임...  200 OK.
        // 동의 된 것이 하나라도 있을 때, response는 [{type, is_agreed}] 로 들어옴.
        if (response) {
            console.log("fetchOptinStatus response : ", response.data);
            return response.data;
        } else {
            throw new Error("Failed to fetch OptinStatus : Invalid response status");
        }
    } catch (error) {
        console.error("Error in fetchOptinStatus:", error);
        throw new Error("Failed to fetch OptinStatus: " + error);
    }
}

/**
 * SA 로그인한 사용자의 'Agreements(=use of location information), Guardian's Consent, Location settings' 상태를 업데이트 합니다.
 * @param {*} userId
 * @param {*} optinType
 * @returns
 */

export const postAgreementStatus = async (is_agreed: 0 | 1) => {
    // is_agreed => 1 :동의,  0: 비동의
    // 한꺼번에 동의하거나 한꺼번에 취소할 일밖에는 없는데...
    const { isChildAccountSupported, isChild } = getChildAccountSupported();
    const countryCode = getCountryCode();
    let param = {};

    // EU, KOR 아동계정
    if (isChildAccountSupported && isChild && countryCode !== 'USA') {
        param = {
            "location": is_agreed,
            "parent_agreement": is_agreed,
            "web_location_setting": is_agreed
        };
    }

    // 성인계정, 기타국가 아동계정
    else {
        param = {
            "location": is_agreed,
            "web_location_setting": is_agreed
        };
    }

    try {
        const response = await axiosInstance.post(`/users/optin`, param);
        return response.data;
    } catch (error) {
        console.error('Error postAgreementStatus', error);
        throw error;
    }
};


/**
 * Send a text message to child user's parent via SMS/MMS.
 * @returns 
 */
export const postSendMessageToParent = async (text:string) => {
    try {
        const currentUserId = getUserId();
        const params = {
            text : text,
        }
        const response = await axiosInstance.post(`/profile/user/user/${currentUserId}/parent/message`, params);
        return response.data;
    } catch (error) {
        console.error('Error postSendMessageToParent', error);
    }
};