import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useMqttConnections from './useMqttConnections';
import { setOperationResponse, setOperationStart } from '../slices/operationSlice/operationsSlice';
import mqttConnectionsManager from '../managers/MqttConnectionsManager'; // singleton
import timerManager from '../managers/TimerManager'; // singleton
import { OPERATION_TYPES } from '../utills/enums/commons';
import { DEVICE_TYPES } from "../utills/enums/Devices";
import { upsertBatteryListData, upsertDeviceDetail, upsertLocationListData } from '../slices/deviceListSlice';
import { getDeviceDetails } from '../api/findToStf/devicesApi/devicesApi';
import { getFormattedUpdatedTime, timestampToFormattedString } from '../utills/functions';
import { getTagLocation } from '../api/find/devicesApi/devicesApi';
import { getUserId, getUserName } from '../utills/auth';

const useDeviceOperations = () => {
    const dispatch = useDispatch();
    const operationsResult = useSelector((state) => state.operations.operationsResult);
    const { connectMqtt } = useMqttConnections();

    /**
     * 1. Call the operation API and send commands such as CHECK_CONNECTION, LOCK, and RING.
     * 2. The results of the sent command are received as an MQTT message.
     * 3. Make an MQTT connection to each device and wait for a callback message.
     * 4. When a callback message is received through MQTT, it is stored in the redux state(operationsSlice).
     * 5. In the UI, you can read the state(operationsResult) and reflect it in real time.
     * 
     * Usage : startOperation(selectedDevice, selectedDeviceDetail, OPERATION_TYPES.LOCATION, () => await performOperationLocation(deviceId, userId));
     * 
     * @param {*} selectedDevice Device to send operation to. 
     * @param {*} deviceDetail Information for MQTT connection is in deviceDetail.
     * @param {*} operationType CHECK_CONNECTION, LOCK, RING, LOCK (It is defined as a constant called OPERATION_TYPES )
     * @param {*} functionToCallOperation An anonymous function that calls an API that sends an operation ( call performOperation...)
     * @returns 
     */
    const startOperation = async (selectedDevice, deviceDetail, operationType, functionToCallOperation) => {

        if (!selectedDevice) {
            return;
        }

        const deviceId = selectedDevice?.deviceId;

        // Start the operation here and set loading 
        dispatch(setOperationStart({ deviceId, operationType }));


        if (selectedDevice.type === DEVICE_TYPES.TAG) {
            handleTagTypeDevice(deviceId, operationType, selectedDevice, dispatch, functionToCallOperation);
            return;
        }

        if (!deviceDetail) {
            return;
        }

        // set timer for 60 sec
        timerManager.startTimer(deviceId, operationType, () => {
            dispatch(setOperationResponse({ deviceId, operationType, data: null, isTimeout: true })); // cancel loading & set timeout
        }, 60 * 1000);

        // If there is no MQTT connection, try connecting
        if (!mqttConnectionsManager.getConnection(deviceId)) {
            try {
                connectMqtt(selectedDevice, deviceDetail);
            } catch (error) {
                console.error('Failed to connect MQTT', error);
                return;
            }
        }

        // send the operation command to the REST API.
        try {
            const responseData = await functionToCallOperation();
            if (responseData?.resultCode !== "00") { // Operation 요청 성공이 아닌 값이 응답으로 왔을 경우
                dispatch(setOperationResponse({ deviceId, operationType, data: null, isFailed: true })); // cancel loading & set failed
            }
        } catch (error) {
            console.error('Operation API 호출 실패', error);
            dispatch(setOperationResponse({ deviceId, operationType, data: null, isFailed: true })); // cancel loading & set failed
        }
    };

    return { operationsResult, startOperation };
};


// Tag Type Device 처리
const handleTagTypeDevice = (deviceId, operationType, selectedDevice, dispatch, functionToCallOperation) => {
    timerManager.startTimer(deviceId, operationType, () => {

        //CHECK_CONNECTION, LOCATION 의 경우 일정 시간 후 tag/geolocations API 응답으로 결과 업데이트
        if (operationType === OPERATION_TYPES.CHECK_CONNECTION) {
            // CHECK_CONNECTION 결과 업데이트 : battery 값 operationResult에 업데이트하기 
            getTagLocation(deviceId, selectedDevice?.userId, selectedDevice?.userName)
                .then(response => {
                    const { timeCurrent, items } = response;
                    const firstItem = items?.[0];
                    const firstGeoLocation = firstItem?.geolocations?.[0];

                    if (!firstGeoLocation) {
                        // firstGeoLocation이 없으면 에러를 발생시키고 catch 구문으로 이동
                        throw new Error("First geo location is missing");
                    }
                    const { nearby, battery, lastUpdateTime } = firstGeoLocation;
                    const timeDifference = Date.now() - lastUpdateTime;
                    // nearby 가 true이고, lastUpdateTime이 90초 이내이면 true 
                    const isOnline = nearby && (timeDifference <= 90 * 1000);
                    const oprnData = {
                        oprnCrtDate: timestampToFormattedString(timeCurrent),
                        oprnDoneDate: timestampToFormattedString(timeCurrent),
                        oprnType: operationType,
                        battery: isOnline ? battery : null
                    }
                    dispatch(upsertBatteryListData({ deviceId, operationType, data: oprnData }));
                    dispatch(setOperationResponse({ deviceId, operationType, data: oprnData })); // cancel loading
                })
                .catch(e => {
                    console.log('Erorr fetching tag geolocations : ', e);
                });


        } else if (operationType === OPERATION_TYPES.LOCATION) {
            // LOCATION 결과 업데이트 : 위치,시간 값 operationResult에 업데이트, locationList에 업데이트

            getTagLocation(deviceId, selectedDevice?.userId, selectedDevice?.userName)
                .then(response => {
                    const { timeCurrent, items } = response;
                    const firstItem = items?.[0];
                    const firstGeoLocation = firstItem?.geolocations?.[0];

                    if (!firstGeoLocation) {
                        // firstGeoLocation이 없으면 에러를 발생시키고 catch 구문으로 이동
                        throw new Error("First geo location is missing");
                    }
                    const { latitude, longitude, lastUpdateTime } = firstGeoLocation;

                    // locationListData Slice에 업데이트 
                    const locationData = [{
                        deviceId: deviceId,
                        geolocations: [
                            {
                                latitude: latitude,
                                longitude: longitude,
                                timeCreated: lastUpdateTime
                            }
                        ]
                    },];
                    dispatch(upsertLocationListData(locationData));

                    // operationResult Slice에 업데이트
                    const oprnData = {
                        oprnCrtDate: timestampToFormattedString(timeCurrent), // timeCurrent
                        oprnDoneDate: timestampToFormattedString(timeCurrent), // timeCurrent
                        oprnType: operationType,
                        extra: {
                            gpsUtcDt: timestampToFormattedString(lastUpdateTime) // lastUpdateTime
                        },
                        latitude: latitude, // latitude
                        longitude: longitude, // longitude
                    }
                    dispatch(setOperationResponse({ deviceId, operationType, data: oprnData })); // cancel loading

                })
                .catch(e => {
                    console.log('Erorr fetching tag geolocations : ', e);
                });



        } else { // RING 의 경우 일정 시간 후 cancel loading 만 수행
            dispatch(setOperationResponse({ deviceId, operationType, data: null })); // cancel loading
        }


    }, 30 * 1000); // operation 요청 후 N 초 뒤 결과 가져오기 


    // send the operation command to the REST API.
    functionToCallOperation()
        .then(result => console.log(result))
        .catch(error => {
            console.error('Operation API 호출 실패', error);
            dispatch(setOperationResponse({ deviceId, operationType, data: null })); // cancel loading
        });
};

export default useDeviceOperations;