import { createTheme } from '@mui/material/styles';
import typography from './typography';

const customTheme = createTheme({
    direction: 'ltr',  // 'rtl',
    breakpoints: {
        values: {
            xxxs: 0,
            xxs: 320,
            xs: 360,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536 
        }
    },
    palette: {
        primary: {
            main: '#387AFF'
        },
        grey: {
            300: '#fefefe',
            A100: '#f8f9ff'
        },
        secondary: {
            main: '#070919'
        },
        error: {
            main: '#f83245'
        },
        success: {
            main: '#1bc943'
        },
        warning: {
            main: '#f4772e'
        },
        contrastThreshold: 3,
        tonalOffset: 0.1
    },
    typography
});

export default customTheme;
