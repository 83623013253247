import React from 'react';
import { PlainTextFieldProps } from './PlainTextFieldTypes';
import { TextField } from '@mui/material';


const PlainTextField = (props: PlainTextFieldProps) => {

    return (
        <div>
            <TextField
                multiline
                rows={2}
                fullWidth
                sx={{
                    "& fieldset": { border: 'none' },
                }}
                InputProps={{
                    style: {
                        backgroundColor: "#F6F6F8",
                        borderRadius: "20px",
                        padding: "16px 16px 13px 16px" // 상 우 하 좌
                    }
                }}
                {...props}
            />
        </div>
    )
}

export default PlainTextField;