class MqttConnectionsManager {
    constructor() {
        this.connections = {};
    }

    addConnection(deviceId, client) {
        if (!deviceId || !client) {
            console.error('Invalid deviceId or client.');
            return;
        }
        if (this.connections[deviceId]) {
            console.warn(`Connection for deviceId ${deviceId} already exists.`);
        }
        this.connections[deviceId] = client;
    }

    getConnection(deviceId) {
        if (!deviceId) {
            console.error('Invalid deviceId.');
            return null;
        }
        return this.connections[deviceId] || null;
    }

    disconnectConnection(deviceId) {
        if (!deviceId) {
            console.error('Invalid deviceId.');
            return;
        }
        const client = this.connections[deviceId];
        if (client) {
            client.end();
            delete this.connections[deviceId];
        } else {
            console.warn(`Connection for deviceId ${deviceId} not found.`);
        }
    }

    getAllDeviceIds() {
        return Object.keys(this.connections);
    }

    disconnectAllConnections = () => {
        const deviceIds = this.getAllDeviceIds();
        deviceIds.forEach((deviceId) => {
            const client = this.getConnection(deviceId);
            if (client) {
                client.end();
                this.disconnectConnection(deviceId); // MqttConnectionsManager에서 연결 제거
            }
        });
    };
}
export default new MqttConnectionsManager();