import React from 'react'
import DeviceSkeleton from '../DeviceSkeleton';
import './DeviceSkeletonListStyles.scss';

const DeviceSkeletonList = ({ count = 1 }: { count: number }) => {
    return (
        <div className='device-skeleton-list-container'>
            {
                Array.from({ length: count }).map((_, i) => {
                    return (
                        <div key={i} className='item'>
                            <DeviceSkeleton />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default DeviceSkeletonList;
