import React, { ReactEventHandler } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Box } from '@mui/material';
import "./BaseActionCardStyles.scss";

import CloseIcon from '@mui/icons-material/Close';
import { BaseActionCardProps } from './BaseActionCardTypes'
import ActionButton from '../../../buttons/ActionButton';
import ButtonsWithMarginTopLayout from '../../../layouts/ButtonsWithMarginTopLayout';

const BaseActionCard = ({ title, firstButtonProps, secondButtonProps, contentsField, onClose, isCloseButtonEnabled, ...props }: BaseActionCardProps) => {

    const makeButtonfield = () => {
        if (firstButtonProps && secondButtonProps) {
            return (
                <>
                    <ButtonsWithMarginTopLayout
                        firstButton={<ActionButton {...firstButtonProps} />}
                        secondButton={<ActionButton {...secondButtonProps} />}
                    />
                </>
            );
        }
        const definedButtonProps = firstButtonProps || secondButtonProps;
        if (definedButtonProps) {
            return (
                <>
                    <div className='button-container'>
                        <ActionButton {...definedButtonProps} />
                    </div>
                </>
            );
        }
        return null;
    };
    const handleClose = (e: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason === "backdropClick") {
            return;
        }
        if (onClose) {
            onClose(e, reason);
        }
    }

    return (
        <div className="base-action-card-container">
            <Dialog
                sx={
                    {
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "440px !important",
                            },
                        }
                    }
                }
                onClose={handleClose}
                {...props}
            >
                <DialogTitle
                    sx={
                        {
                            pt: "24px",
                            pb: "12px",
                            height: "63px",
                            lineHeight: "27px",
                            display: "flex",
                            justifyContent: "space-between"
                        }
                    }>
                    <Typography variant="actionDialogTitle">
                        {title}
                    </Typography>
                    {isCloseButtonEnabled ? (
                        <IconButton className="base-action-card-close-icon" onClick={(onClose as ReactEventHandler)}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: "100%" }}>
                        <Typography variant="actionDialogContents">
                            {contentsField}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {makeButtonfield()}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BaseActionCard;