import { PhoneExtensionInfoType } from "../../assets/phone/phoneExtentionInfoType"

export const concateCountyNameAndCodeForDisplay = (phoneExtensionInfoList: Array<PhoneExtensionInfoType>) => {
    return phoneExtensionInfoList
        .map((info) => {
            return {
                ...info,
                title: info.title + " (+" + info.extension + ")",
            };
        });
}