import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './TrackLocationBlocksStyles.scss';

import ModalBottomButton from '../../../buttons/ModalBottomButton/ModalBottomButton';
import { track_location_pin } from '../../../../assets/images/trackLocation';
import { t } from 'i18next';
import { ActionBlockTypes } from './TrackLocationBlocksTypes';
import { getTextByDeviceType } from '../../../../utills/functions/branchFunctions';
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from '../../../../utills/enums/Devices';


export const ContentBlock = ({deviceType}: {deviceType: DEVICE_TYPES_TYPE}) => (
    <div className='modal-track-location-content-container'>
        <Stack alignItems='center'>
            <img className='track-location-icon' src={track_location_pin} alt='Track Location'  />
            <Box marginTop='30px'>
                <Typography textAlign="center">{
                    getTextByDeviceType(
                        deviceType,
                        {
                            [DEVICE_TYPES.PHONE]: t('cant_track_phone'),
                            [DEVICE_TYPES.TAB]: t('cant_track_tablet')
                        }
                    )
                }</Typography>
            </Box>
        </Stack>
    </div>
);

export const ActionBlock = ({ onActionButtonClick }: ActionBlockTypes) => (
    <>
        <ModalBottomButton title={t('restart')} onClick={onActionButtonClick} />
    </>
);
