import React from 'react';
/**
 * Phone Extention Number를 추출하는 코드
 * ex) Republic of Korea (+82) 에서 82를 가져옵니다.
*/
export const extractExtentionNumberFromString = (aString: string) => {
    const regex = /\(([^)]+)/;
    const matches = aString.match(regex);
    console.log(matches)

    if (matches && matches[1]) {
        return matches[1];
    };
    return null;
}

/**
 * i18n string에 %s를 치환하는 함수 
 */
export const formatString = (str: string, value: string) => {
    return str.replace(/%s/g, value);
}

/**
 * i18n string에 %d를 치환하는 함수 
 */
export const formatNumber = (str: string, value: string) => {
    return str.replace(/%d/g, value);
}

/**
 * Number를 빼고 전부 지우는 함수
*/
export const removeAllButNumber = (str: string) => {
    return str.replace(/[^0-9]/g, "");;
}

/**
 * "<br>(%s)" 을 지우는 함수 
*/
export const removeBrString = (str: string) => {
    return str.replace("<br>(%s)", "");
}

/**
 * "<br>" 을 모두 "\n"으로 치환하는 함수 
*/
export const replaceBrStringWithRSlashN = (str: string) => {
    return str.replace(/<br\s*\/?>/gi, "\n");
}

/**
 * 괄호를 포함하여 그 안을 지우는 함수
*/
export const removeParenthesesValues = (str: string) => {
    return str.replace(/\([^)]+\)/g, "");
}

/**
 * HTML Entity를 따옴표로 바꾸는 함수
*/
export const replaceAllHtmlEntityWithDoubleQuote = (str: string) => {
    return str.replace(/&#34;/g, "\"");
}

/**
 * 괄호를 포함하려 그 값을 추출하는 함수
 */
export const extractParenteses = (str: string) => {
    return str.match(/\(.*?\)/g) || [];
}

/**
 * String에 포함된 br을 실제 br tag로 변환하는 함수
*/
export const formatStringBrToRealBr = (str: string) => {
    return str.split('<br>').map((line, idx) => { return (<span key={idx}>{line}<br /></span>) });
};

/**
 * SelectedLang에서 언더스코어 앞 부분만 추출하는 함수
*/
export const extractLangCodeFromSelectedLang = (str: string) => {
    return str.split("_")[0];
}

/**
 * Json이 parsable 하면 파싱된 Json 리턴하고 그렇지 않으면 기존 값을 리턴하는 함수
 */
export const parseJsonOrReturnRaw = (str: string): Record<string, string> | string => {
    try {
        const parsedJson = JSON.parse(str);
        return parsedJson;
    } catch {
        return str;
    }
} 