import authReducer from './authSlice';
import deviceListReducer from './deviceListSlice';
import mapReducer from './mapSlice';
import modalReducer from './modalSlice';
import modalEraseReducer from './modalEraseSlice';
import modalLockReducer from './modalLockSlice';
import languageReducer from './languageSlice';
import operationsReducer from './operationSlice';
import settingReducer from './settingSlice';
import notifyMeReducer from './notifyMeSlice';
import deviceDetailReducer from './deviceDetailSlice';
import cardListReducer from './cardListSlice';
import lostModeReducer from './lostModeSlice';
import modalTrackLocationReducer from './modalTrackLocationSlice';

const rootReducer = {
    map: mapReducer,
    auth: authReducer,
    deviceList: deviceListReducer,
    modal: modalReducer,
    modalErase: modalEraseReducer,
    modalLock: modalLockReducer,
    modalTrackLocation: modalTrackLocationReducer,
    language: languageReducer,
    operations: operationsReducer,
    setting: settingReducer,
    notifyMe: notifyMeReducer,
    deviceDetail: deviceDetailReducer,
    cardList: cardListReducer,
    lostMode: lostModeReducer
}

export default rootReducer;