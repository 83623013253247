/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserHistory, deleteUserHistory } from '../api/findToStf/historyApi';
import { GetHistoryApiResponse } from '../api/findToStf/historyApi/historyApiTypes';
import { checkUserHistoryEmpty } from '../utills/functions/settingFunctions';
import {
    convertFindInfoObjToCSV,
    downloadCSV,
    getTime,
} from '../utills/functions/csvFunctions';
import { signOutAndRedirectToHome } from "../utills/auth";

export const asyncFetchUserHistoryBeforeErase = createAsyncThunk(
    'setting/asyncFetchUserHistoryBeforeErase',
    async () => {
        const response = await getUserHistory();
        return response;
    }
);

export const asyncFetchUserHistoryBeforeDownload = createAsyncThunk(
    'setting/asyncFetchUserHistoryBeforeDownload',
    async () => {
        const response = await getUserHistory();
        return response;
    }
);

export const asyncDeleteUserHistory = createAsyncThunk(
    'setting/asyncDeleteUserHistory',
    async () => {
        const response = await deleteUserHistory();
        return response;
    }
);

const initialState = {
    isValidConfirmToken: false,
    noPersonalDataDownloadCardOpen: false,
    cantDownloadPersonalDataCardOpen: false,
    noPersonalDataEraseCardOpen: false,
    erasePersonalDataCardOpen: false,
    cantErasePersonalDataCardOpen: false,
    userHistory: {
        isLoadingBeforeDownload: false,
        isLoadingBeforeErase: false,
        isLoadingDelete: false,
        data: null as null | GetHistoryApiResponse['items'],
        error: null as null | string
    }
}

export const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setIsValidConfirmToken: (state, action) => {
            state.isValidConfirmToken = action.payload;
        },
        setNoPersonalDataDownloadCardOpen: (state, action) => {
            state.noPersonalDataDownloadCardOpen = action.payload;
        },
        setCantDownloadPersonalDataCardOpen: (state, action) => {
            state.cantDownloadPersonalDataCardOpen = action.payload;
        },
        setNoPersonalDataEraseCardOpen: (state, action) => {
            state.noPersonalDataEraseCardOpen = action.payload;
        },
        setErasePersonalDataCardOpen: (state, action) => {
            state.erasePersonalDataCardOpen = action.payload;
        },
        setCantErasePersonalDataCardOpen: (state, action) => {
            state.cantErasePersonalDataCardOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        // asyncFetchUserHistoryBeforeErase
        builder.addCase(asyncFetchUserHistoryBeforeErase.pending, (state, action) => {
            state.userHistory.isLoadingBeforeErase = true;
        })
        builder.addCase(asyncFetchUserHistoryBeforeErase.fulfilled, (state, action) => {
            state.userHistory.data = action.payload;
            state.userHistory.isLoadingBeforeErase = false;
            if (checkUserHistoryEmpty(state.userHistory.data)) {
                state.noPersonalDataEraseCardOpen = true;
            } else {
                state.erasePersonalDataCardOpen = true;
            }
        })
        builder.addCase(asyncFetchUserHistoryBeforeErase.rejected, (state, action) => {
            if (!action.meta.aborted) {
                state.userHistory.error = "Error while getting user history";
                state.cantErasePersonalDataCardOpen = true;
            }
            state.userHistory.isLoadingBeforeErase = false;
       })

        //asyncFetchUserHistoryBeforeDownload
        builder.addCase(asyncFetchUserHistoryBeforeDownload.pending, (state, action) => {
            state.userHistory.isLoadingBeforeDownload = true;
        })
        builder.addCase(asyncFetchUserHistoryBeforeDownload.fulfilled, (state, action) => {
            state.userHistory.data = action.payload;
            state.userHistory.isLoadingBeforeDownload = false;
            if (checkUserHistoryEmpty(state.userHistory.data)) {
                state.noPersonalDataDownloadCardOpen = true;
            } else {
                const csvString = convertFindInfoObjToCSV(state.userHistory.data);
                downloadCSV(csvString, `FindWeb${getTime()}`);
            }
        })
        builder.addCase(asyncFetchUserHistoryBeforeDownload.rejected, (state, action) => {
            state.userHistory.error = "Error while getting user history";
            state.cantDownloadPersonalDataCardOpen = true;
            state.userHistory.isLoadingBeforeDownload = false;
        })

        // asyncDeleteUserHistory
        builder.addCase(asyncDeleteUserHistory.pending, (state, action) => {
            state.userHistory.isLoadingDelete = true;
        })
        builder.addCase(asyncDeleteUserHistory.fulfilled, (state, action) => {
            state.userHistory.isLoadingDelete = false;
            state.userHistory.data = null;
            state.erasePersonalDataCardOpen = false;
            signOutAndRedirectToHome();
        })
        builder.addCase(asyncDeleteUserHistory.rejected, (state, action) => {
            state.userHistory.error = "Error while deleting user history";
            state.cantErasePersonalDataCardOpen = true;
        })
    }
});

export const {
    setIsValidConfirmToken,
    setNoPersonalDataDownloadCardOpen,
    setCantDownloadPersonalDataCardOpen,
    setNoPersonalDataEraseCardOpen,
    setErasePersonalDataCardOpen,
    setCantErasePersonalDataCardOpen
} = settingSlice.actions;

export default settingSlice.reducer;