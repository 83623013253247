import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from "react-i18next";
import { LocalActionType, LockSettingInfoType, MODAL_LOCK_ACTION, ModalLockLocalStateType, ModalLockProps } from './ModalLockTypes';
import './ModalLockStyles.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { getUserId } from "../../../utills/auth";
import BulletPointWithTitleAndContents from '../../texts/BulletPointWithTitleAndContent';

import ModalRegisteredCard from '../ModalRegisteredCard';
import ModalLockSetting from '../ModalLockSetting/ModalLockSetting';
import { locked, partially_locked, not_available, orange_ring, unlocked, waiting } from "../../../assets/images/deviceCard";
import { BulletPointType } from '../../texts/BulletPointWithTitleAndContent/BulletPointWithTitleAndContentTypes';
import { asyncPerformOperationLock, clearOperationLockResponse, setModalLockStatus } from '../../../slices/modalLockSlice/modalLockSlice';
import { AppThunkDispatch, RootStateType } from '../../../slices/types';
import useMqttDeviceConnection from '../../../hooks/useMqttDeviceConnection';
import { OPERATION_TYPES, SCOPE } from '../../../utills/enums/commons';
import { DEVICE_TYPES } from "../../../utills/enums/Devices";
import { convertUTCtoBrowserTime, formatDate, getCurrentTimeFormatted } from '../../../utills/functions/time';
import { DM_ERROR_RESULT_CODE, DM_ERROR_STATUS_CODE, DM_ERROR_STATUS_CODE_FAIL, DM_ERROR_STATUS_CODE_SUCCESS } from '../../../utills/enums/DMErrorCodes';
import { hasDataProperty } from '../../../slices/operationSlice/operationSliceTypeGuard';
import { formatString, removeBrString } from '../../../utills/functions/stringPhasers';
import BaseModal from '../BaseModal';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { getDeviceDetailByDeviceId } from '../../../utills/functions';
import { OperationCheckConnectionData, OperationLockData } from '../../../slices/operationSlice/operationsSliceTypes';
import { getOperationStatusFromDeviceList } from '../../../utills/functions/deviceFunctions';
import { asyncUpsertDeviceDetail } from '../../../slices/deviceListSlice/thunks';
import LoadingCircleInOrangeRing from '../../view-only/LoadingCircleInOrangeRing';
import { TIME_FORMAT } from '../../../utills/enums/timeFormats';
import { cleanLockOperationByDevice } from '../../../slices/operationSlice';
import { getTextByDeviceType } from '../../../utills/functions/branchFunctions';


const ModalLock = ({ open, onClose, passedValue }: ModalLockProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppThunkDispatch>();
    const startMqttConnection = useMqttDeviceConnection();

    const handleCancel = () => {
        setSelectedDeviceDetail(null);
        dispatch(setModalLockStatus(MODAL_LOCK_ACTION.initial))
        //// 리셋 
        onClose();
    }

    // global states
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const modalLockState = useSelector((state: RootStateType) => state.modalLock);
    const cardListState = useSelector((state: RootStateType) => state.cardList);
    const detailListData = useSelector((state: RootStateType) => state.deviceList.detailListData);
    const operationsResult = useSelector((state: RootStateType) => state.operations.operationsResult);
    const deviceType = selectedDevice?.type;

    // local states
    const [selectedDeviceDetail, setSelectedDeviceDetail] = useState(passedValue);

    const getMqttCheckConnectionData = (deviceId: string) => {
        if (deviceId) {
            const checkConnectionData = operationsResult[deviceId][OPERATION_TYPES.CHECK_CONNECTION];
            if (hasDataProperty<OperationCheckConnectionData>(checkConnectionData)) {
                return checkConnectionData.data;
            }
        }
        return null;
    }

    const getMqttLockData = (deviceId: string) => {
        if (deviceId) {
            const lockData = operationsResult[deviceId][OPERATION_TYPES.LOCK];
            if (hasDataProperty(lockData)) {
                return lockData.data;
            }
        }
        return null;
    }

    const getLockTime = (deviceId: string) => {
        if (deviceId) {
            const data = getDeviceDetailByDeviceId(deviceId, detailListData);
            const lockInfo = data.operation.find((e: any) => e.oprnType === OPERATION_TYPES.LOCK);
            const lockedTime = lockInfo?.oprnDoneDate;
            return lockedTime;
        }
    }
    // set Status Info  -  Icon, Status, Status Message, Last Lock Time
    const initialState: ModalLockLocalStateType = {
        title: "",
        statusTitle: "",
        message: "",
        image: partially_locked,
        lastLockTime: "",
        buttonText: "",
        buttonDisabled: false,
        screenLock: "N",
        payLock: "N",
        passLock: "N",
        lockPowerOff: "N",
        lockTitleClassName: "lock-initial-title",
    };

    const reducer = (state: ModalLockLocalStateType, action: LocalActionType): ModalLockLocalStateType => {
        if (action.type === MODAL_LOCK_ACTION.waiting) {
            dispatch(setModalLockStatus(MODAL_LOCK_ACTION.waiting))

            const statusTitle = t('waiting');
            let message = t('phone_connect_network');
            if (deviceType === "PHONE") {
                message = t('phone_connect_network');
            } else if (deviceType === "TAB") {
                message = t('tablet_connect_network');
            } else if (deviceType === "WATCH") {
                message = t('watch_connect_network');
            }
            const payload = action.payload;
            const statusMessage = formatString(message, payload?.time);

            return {
                ...state,
                image: waiting,
                statusTitle,
                message: statusMessage,
                buttonDisabled: true,
                lockTitleClassName: "lock-initial-title"
            }
        }
        else if (action.type === MODAL_LOCK_ACTION.loading) {
            dispatch(setModalLockStatus(MODAL_LOCK_ACTION.loading))

            let statusTitle = t('locking_phone');
            if (deviceType === "PHONE") {
                statusTitle = t('locking_phone');
            } else if (deviceType === "TAB") {
                statusTitle = t('locking_tablet');
            } else if (deviceType === "WATCH") {
                statusTitle = t('locking_watch');
            }

            return {
                ...state,
                image: orange_ring,
                statusTitle,
                message: "",
                buttonDisabled: true,
                lockTitleClassName: "lock-initial-title"
            }
        } else if (action.type === MODAL_LOCK_ACTION.retry) {
            dispatch(setModalLockStatus(MODAL_LOCK_ACTION.retry))
            let message = t('phone_turned_off');
            if (deviceType === "PHONE") {
                message = t('phone_turned_off');
            } else if (deviceType === "TAB") {
                message = t('tablet_turned_off');
            } else if (deviceType === "WATCH") {
                message = t('watch_turned_off');
            }
            return {
                ...state,
                image: not_available,
                statusTitle: t('not_available'),
                message,
                lastLockTime: getCurrentTimeFormatted(),
                buttonText: t('retry'),
                lockTitleClassName: "lock-retry-title"
            };
        } else if (action.type === MODAL_LOCK_ACTION.initial) {
            dispatch(setModalLockStatus(MODAL_LOCK_ACTION.initial));

            let title = t('lock_phone');
            if (deviceType === "PHONE") {
                title = t('lock_phone');
            } else if (deviceType === "TAB") {
                title = t('lock_tablet');
            } else if (deviceType === "WATCH") {
                title = t('lock_watch');
            }

            const data = getMqttCheckConnectionData(selectedDeviceDetail?.deviceId);
            const alreadyLocked = data?.extra?.lockStatus?.normalLock;

            // alreadyLocked === undefined를 넣은 이유는 대부분의 상황에서 폰은 locked 상태 일 것이기에 로딩 시간에 partially locked 상태를 띄운다.
            // watch는 partially locked 상태가 없음
            const isPartiallyLocked = (deviceType !== DEVICE_TYPES.WATCH) && ((alreadyLocked === undefined) || (alreadyLocked === true));

            const image = isPartiallyLocked ? partially_locked : unlocked;
            const statusTitle = isPartiallyLocked ? t('enhance_secu') : t('unlocked');
            const lockTitleClassName = isPartiallyLocked ? "lock-initial-title" : "lock-retry-title";

            let message = t('already_locked_phone');
            if (!isPartiallyLocked && deviceType === "PHONE") {
                if (!isPartiallyLocked) {
                    message = t('isnt_locked_phone');
                } else {
                    message = t('already_locked_phone');
                }
            } else if (deviceType === "TAB") {
                if (!isPartiallyLocked) {
                    message = t('isnt_locked_tablet');
                } else {
                    message = t('already_locked_tablet');
                }
            } else if (deviceType === "WATCH") {
                if (!isPartiallyLocked) {
                    message = t('isnt_locked_watch');
                } else {
                    message = t('already_locked_watch');
                }
            }

            return {
                ...state,
                title,
                image,
                statusTitle,
                message,
                screenLock: isFeatureEnabled("screenLock"),
                payLock: isFeatureEnabled("payLock"),
                passLock: isFeatureEnabled("passLock"),
                lockPowerOff: isFeatureEnabled("lockPowerOff"),
                buttonText: t('next'),
                lockTitleClassName,
                buttonDisabled: false
            }
        } else if (action.type === MODAL_LOCK_ACTION.locked) {
            dispatch(setModalLockStatus(MODAL_LOCK_ACTION.locked));
            const utcTimeString = action.payload;
            let buttonText = '';
            if (state.buttonDisabled) {  // waiting 또는 loading 이후 Locked 완료된 경우
                buttonText = t('done');
            } else {                     // initial 로 진입된 경우
                buttonText = t('next');
            }

            let message = removeBrString(t('phone_locked'));
            if (deviceType === "PHONE") {
                message = removeBrString(t('phone_locked'));
            } else if (deviceType === "TAB") {
                message = removeBrString(t('tablet_locked'));
            } else if (deviceType === "WATCH") {
                message = removeBrString(t('watch_locked'));
            }

            return {
                ...state,
                image: locked,
                statusTitle: t('locked'),
                message,
                lastLockTime: utcTimeString ? convertUTCtoBrowserTime({ utcString: utcTimeString, type: TIME_FORMAT.DAY_MONTH_YEAR }) : "",
                screenLock: isFeatureEnabled("screenLock"),
                payLock: isFeatureEnabled("payLock"),
                passLock: isFeatureEnabled("passLock"),
                lockPowerOff: isFeatureEnabled("lockPowerOff"),
                buttonText,
                buttonDisabled: false,
                lockTitleClassName: "lock-locked-title"
            }
        } else if (action.type === MODAL_LOCK_ACTION.alreadyLocked) {
            dispatch(setModalLockStatus(MODAL_LOCK_ACTION.alreadyLocked));
            const utcTimeString = action.payload;
            let buttonText = t('done');

            let message = removeBrString(t('already_requested_to_lock_this_phone'));
            if (deviceType === "PHONE") {
                message = removeBrString(t('already_requested_to_lock_this_phone'));
            } else if (deviceType === "TAB") {
                message = removeBrString(t('already_requested_to_lock_this_tablet'));
            } else if (deviceType === "WATCH") {
                message = removeBrString(t('already_requested_to_lock_this_watch'));
            }

            return {
                ...state,
                image: locked,
                statusTitle: t('locked'),
                message,
                lastLockTime: utcTimeString ? convertUTCtoBrowserTime({ utcString: utcTimeString, type: TIME_FORMAT.DAY_MONTH_YEAR }) : "",
                screenLock: isFeatureEnabled("screenLock"),
                payLock: isFeatureEnabled("payLock"),
                passLock: isFeatureEnabled("passLock"),
                lockPowerOff: isFeatureEnabled("lockPowerOff"),
                buttonText,
                buttonDisabled: false,
                lockTitleClassName: "lock-locked-title"
            }
        } else if (action.type === MODAL_LOCK_ACTION.buttonActivation) {
            return {
                ...state,
                buttonDisabled: action.payload ?? true,
            }
        }
        return state;
    };

    const [state, localDispatch] = useReducer(reducer, initialState);

    const mqttLockData = getMqttLockData(selectedDeviceDetail?.deviceId);
    useEffect(() => {
        if (modalLockState.operationLock.data?.resultCode === '00' && mqttLockData) { // ToDo : code명서 받은 후 적용할 것.
            if (mqttLockData!.oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS && mqttLockData!.oprnResultCode === DM_ERROR_RESULT_CODE.OPERATION_SUCCESS) {
                localDispatch({ type: MODAL_LOCK_ACTION.locked, payload: mqttLockData.oprnDoneDate })
            } else if (mqttLockData!.oprnStsCd === DM_ERROR_STATUS_CODE.OPERATION_FAIL && mqttLockData!.oprnResultCode === DM_ERROR_RESULT_CODE.ALREADY_LOCKED_STATUS) {
                localDispatch({ type: MODAL_LOCK_ACTION.alreadyLocked, payload: mqttLockData.oprnDoneDate })
            } else if ((mqttLockData!.oprnStsCd === DM_ERROR_STATUS_CODE_FAIL.OPERATION_FAIL && mqttLockData.oprnResultCode === DM_ERROR_RESULT_CODE.DISABED_REMOTE_CONTROL) || 
                (mqttLockData!.oprnStsCd === DM_ERROR_STATUS_CODE_FAIL.OPERATION_FAIL && mqttLockData.oprnResultCode === DM_ERROR_RESULT_CODE.NOT_CONNECTED_STATUS) ) {
                localDispatch({ type: MODAL_LOCK_ACTION.retry, payload: mqttLockData.oprnDoneDate })
                localDispatch({ type: MODAL_LOCK_ACTION.buttonActivation, payload: false });
            }
        }
    }, [operationsResult, mqttLockData, modalLockState?.operationLock.data?.resultCode]);

    // Dialog 열릴때 초기화용
    useEffect(() => {
        if (open === true) {
            if (passedValue === null) {
                handleCancel();
            }
            if (passedValue !== null) {
                setSelectedDeviceDetail(passedValue);
                if (mqttLockData) {
                    const lockedTime = getLockTime(passedValue.deviceId);
                    localDispatch({ type: MODAL_LOCK_ACTION.locked, payload: lockedTime });
                } else {
                    localDispatch({ type: MODAL_LOCK_ACTION.initial });
                }
            }
        }
    }, [open]);

    const lockStatus = getOperationStatusFromDeviceList<OperationLockData>(detailListData, selectedDevice?.deviceId, OPERATION_TYPES.LOCK);
    useEffect(() => {
        if (lockStatus
            && lockStatus?.oprnStsCd === DM_ERROR_STATUS_CODE.NOTIFICATION_PENDING
            && lockStatus?.oprnResultCode === DM_ERROR_RESULT_CODE.DEFAULT) {
            const time = formatDate(new Date(convertUTCtoBrowserTime({ utcString: lockStatus.oprnDoneDate, type: TIME_FORMAT.MONTH_DAY_YEAR })));
            localDispatch({ type: MODAL_LOCK_ACTION.waiting, payload: { time } });
        }
    }, [lockStatus?.oprnStsCd, open]);

    const data = getMqttCheckConnectionData(selectedDeviceDetail?.deviceId);
    const fmmLock = data?.extra?.lockStatus?.fmmLock;
    const normalLock = data?.extra?.lockStatus?.normalLock;
    useEffect(() => {
        if (selectedDeviceDetail) {
            const lockedTime = getLockTime(selectedDeviceDetail?.deviceId);
            if (fmmLock) {
                localDispatch({ type: MODAL_LOCK_ACTION.locked, payload: lockedTime });
            } else if (normalLock) {
                localDispatch({ type: MODAL_LOCK_ACTION.initial, payload: lockedTime });
            }
        }
    }, [data, selectedDeviceDetail])

    // Lock Response 처리
    useEffect(() => {
        // resultCode : Action Completed ?  No
        if (modalLockState.operationLock.data?.resultCode === '01') {  // ToDo : code명서 받은 후 적용할 것.
            // status : Not available
            localDispatch({ type: MODAL_LOCK_ACTION.retry })
            localDispatch({ type: MODAL_LOCK_ACTION.buttonActivation, payload: false });
        }
    }, [modalLockState.operationLock.data]);

    function isFeatureEnabled(featureKey: any) {
        // 해당 기능의 활성화 여부 찾기
        if (selectedDeviceDetail) {
            const feature = selectedDeviceDetail.menu.find((item: any) => item.hasOwnProperty('lock'));
            const subFeature = feature.lock.find((item: any) => item.hasOwnProperty(featureKey));
            if (subFeature) {
                // 'Y' or 'N'를 return
                return subFeature[featureKey];   // test 'Y';
            }
        }
        return 'false';
    }

    const doLocking = (lockSettingInfo: LockSettingInfoType | null) => {
        dispatch(clearOperationLockResponse());
        dispatch(cleanLockOperationByDevice({ deviceId: selectedDeviceDetail.deviceId, operationType: OPERATION_TYPES.LOCK }));
        const {
            pin,
            scope,
            extensionNumber,
            phoneNumber,
            msgText
        } = lockSettingInfo ?? modalLockState.lockSettingInfo!;

        // Display Loading Image
        localDispatch({ type: MODAL_LOCK_ACTION.loading });
        // API request
        const userId = selectedDevice?.userId; // deviceOwner's userId

        const restrictionNumber = scope === SCOPE.INTERNATIONAL ? `(${extensionNumber})${phoneNumber}` : phoneNumber;

        dispatch(asyncPerformOperationLock({
            deviceId: selectedDeviceDetail.deviceId,
            userId: userId!,
            password: pin,
            restrictionNumber,
            lockMessage: msgText
        })).then((res) => {
            if (res.meta.requestStatus === 'fulfilled' && res.payload.resultCode === '00') {
                dispatch(asyncUpsertDeviceDetail({ deviceId: selectedDeviceDetail.deviceId }));
            }
        });

        startMqttConnection({
            selectedDevice,
            deviceDetail: selectedDeviceDetail,
            operationType: OPERATION_TYPES.LOCK
        });
    }

    // Modal - Registered Credit Cards
    const [openRgstCard, setOpenRgstCard] = useState(false);

    const handleRgstCardModalOpen: React.MouseEventHandler = (e) => {
        e.preventDefault();
        setOpenRgstCard(true);
    }
    const handleRgstCardModalClose = () => {
        setOpenRgstCard(false);
    }


    const [openLockSetting, setOpenLockSetting] = useState(false);
    const handleLockDialogButton = () => {
        if (modalLockState.status === MODAL_LOCK_ACTION.initial) {
            setOpenLockSetting(true);
        } else if (modalLockState.status === MODAL_LOCK_ACTION.retry) {
            doLocking(null);
        } else if (modalLockState.status === MODAL_LOCK_ACTION.locked) {
            if (state.buttonText === (t('next'))) {
                setOpenLockSetting(true);
            }
            else {
                handleCancel();
            }
        } else {
            handleCancel();
        }
    }
    const handleLockSettingModalClose = () => {
        setOpenLockSetting(false);
    }

    // Todo: 임시로 선언함 전체적으로 state에 대한 리펙토링 필요
    const lockStatusMapToBulletType: Record<string, BulletPointType> = {
        [MODAL_LOCK_ACTION.loading]: "loading",
        [MODAL_LOCK_ACTION.locked]: "checked",
        [MODAL_LOCK_ACTION.retry]: "info"
    }

    const currentBulletPointType = lockStatusMapToBulletType[modalLockState.status] || "dot";

    const getTitleByDeviceType = () => {
        if (deviceType === "PHONE") {
            return t('protect_phone')
        } else if (deviceType === "TAB") {
            return t('protect_tablet')
        } else {
            return t('protect_watch')
        }
    }

    const getMainImage = () => {
        if (modalLockState.status === MODAL_LOCK_ACTION.loading) {
            return <LoadingCircleInOrangeRing />
        } else if (state.image) {
            return <img src={state.image} alt={state.image} width='80px' height='80px' />
        } else {
            return null;
        }
    }

    const getContentTitleBlock = () => {
        if (deviceType === DEVICE_TYPES.WATCH && normalLock && modalLockState.status === MODAL_LOCK_ACTION.initial) {
            return;
        }
        return (
            <div className="lock-status">
                {getMainImage()}
                <div>
                    <p className={state.lockTitleClassName}> {state.statusTitle} </p>
                    <p> {state.message} </p>
                    {(modalLockState.status === MODAL_LOCK_ACTION.locked)
                        || (modalLockState.status === MODAL_LOCK_ACTION.retry)
                        ? (<p>{`(${state.lastLockTime})`}</p>)
                        : null}
                </div>
            </div>
        )
    }

    const ContentBlock = (
        <>
            {getContentTitleBlock()}
            <div className="lockFunction">
                <Typography variant='modalParagraph'>
                    {getTitleByDeviceType()}
                </Typography>
                <p>  </p>
            </div>

            <div className="lockFunctionList">
                {state.screenLock === 'Y' ?
                    <>
                        <BulletPointWithTitleAndContents
                            bulletPointBundle={{ type: currentBulletPointType }}
                            title={
                                getTextByDeviceType(
                                    deviceType,
                                    {
                                        [DEVICE_TYPES.PHONE]: t('add_message_on_lock_screen'),
                                        [DEVICE_TYPES.TAB]: t('add_message_on_lock_screen'),
                                        [DEVICE_TYPES.WATCH]: t('lock_screen')
                                    }
                                ) as string
                            }
                            content={
                                getTextByDeviceType(
                                    deviceType,
                                    {
                                        [DEVICE_TYPES.PHONE]: t('show_your_contact_information_or_a_custom_message_on_your_lock_screen_phone'),
                                        [DEVICE_TYPES.TAB]: t('show_your_contact_information_or_a_custom_message_on_your_lock_screen_tablet'),
                                        [DEVICE_TYPES.WATCH]: t('shown_emergency_contact')
                                    }
                                ) as string
                            }
                        />
                    </>
                    : null}

                {state.payLock === 'Y' ?
                    <Box marginBottom="16px">
                        <BulletPointWithTitleAndContents
                            bulletPointBundle={{ type: currentBulletPointType }}
                            title={t('lock_samsung_wallet')}
                            content={t('suspend_registered_cards_in_samsung_wallet')}
                            hypertextBundle={{
                                url: "",
                                title: t('view_cards'),
                                onClick: handleRgstCardModalOpen
                            }}
                        />
                    </Box>
                    : null}

                {state.passLock === 'Y' ?
                    <Box marginBottom="16px">
                        <BulletPointWithTitleAndContents
                            bulletPointBundle={{ type: currentBulletPointType }}
                            title={t('lock_pass')}
                            content={
                                t('turn_off_pass') +
                                t('suspend_digital_key')
                            }
                        />
                    </Box>
                    : null}

                {state.lockPowerOff === 'Y' ?
                    <BulletPointWithTitleAndContents
                        bulletPointBundle={{ type: currentBulletPointType }}
                        title={t('lock_power')}
                        content={t('prevent_turnoff')}
                    />
                    : null}
            </div>
        </>);

    const ActionBlock = (
        <>
            <ModalBottomButton
                title={state.buttonText}
                disabled={state.buttonDisabled}
                onClick={handleLockDialogButton}
            />
        </>);

    return (
        <>
            <BaseModal
                open={open}
                onClose={handleCancel}
                title={state.title}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
            <ModalRegisteredCard open={openRgstCard} onClose={handleRgstCardModalClose} cardList={cardListState.cardList.data?.cards} />
            <ModalLockSetting isOpen={openLockSetting} onClose={handleLockSettingModalClose} doLocking={doLocking} />
        </>
    );
};

export default ModalLock
