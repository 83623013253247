import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Typography } from '@mui/material';
import { ModalRemoveDeviceProps } from './ModalRemoveDeviceTypes';
import { formatString, formatStringBrToRealBr } from '../../../utills/functions/stringPhasers';
import BaseModal from '../BaseModal';
import ActionButton from '../../buttons/ActionButton';
import ButtonsWithMarginTopLayout from '../../layouts/ButtonsWithMarginTopLayout';
import { AppThunkDispatch } from '../../../slices/types';
import { asyncRemoveDevice } from '../../../slices/deviceListSlice/thunks';
import { setIsDeviceDetailOpen, setSelectedDevice } from '../../../slices/deviceListSlice';


const ModalRemoveDevice = ({ open, onClose, deviceId }: ModalRemoveDeviceProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppThunkDispatch>();
   // global state
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const isRemoveDeviceLoading = useSelector((state:any) => state.deviceList.removeDevice.isLoading);
    const displayName = selectedDevice && selectedDevice.modelInfo && selectedDevice.modelInfo.displayName
        ? selectedDevice.modelInfo.displayName
        : 'this device'; // 만약 값이 없다면 'N/A' 라는 기본값을 설정

    const handleCancel = () => {
        onClose();
    }

    const handleDelete = () => {
        dispatch(asyncRemoveDevice({deviceId}))
         .then(res => {
            if(res.meta.requestStatus === "fulfilled") {
                dispatch(setIsDeviceDetailOpen(false));
                dispatch(setSelectedDevice(null));
            }
        });
    }
 

    const ContentBlock = (
        <Typography variant='modalParagraph'>
            {formatStringBrToRealBr(t('remove_account'))}
        </Typography>
    );

    const ActionBlock = (
        <>
            <ButtonsWithMarginTopLayout
                firstButton={<ActionButton title={t('cancel')} onClick={handleCancel} />}
                secondButton={<ActionButton title={t('remove')} onClick={handleDelete} disabled={isRemoveDeviceLoading} isLoading={isRemoveDeviceLoading} loadingColor="primary" />}
            />
        </>
    );

    return (
        <div>
            <BaseModal
                open={open}
                onClose={handleCancel}
                title={formatString(t('remove_from'), displayName)}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
                disableX
            />
        </div>
    );
};

export default ModalRemoveDevice
