/**
 * auth.js
 *
 * This utility module provides a set of functions to handle the access token and its expiry time.
 *
 * - setTokenAndExpiry(token, expiresIn): Stores the access token and its expiry time to localStorage.
 * - removeTokenAndExpiry(): Removes the access token and its expiry time from localStorage.
 * - getToken(): Retrieves the access token from localStorage.
 * - isTokenExpired(): Checks whether the stored access token is expired or not.
 * - isLoggedIn(): Checks if the user is logged in by verifying the presence and validity of the access token.
 * - ... 
 *
 * Usage of these utilities ensures a consistent approach to managing authentication tokens and provides
 * error-handling capabilities to ensure smooth UX in case of localStorage errors.
 */
import EncryptedLocalStorage from '../managers/EncryptedLocalStorage';
import mqttConnectionsManager from '../managers/MqttConnectionsManager'; // MqttConnectionsManager (singleton)를 가져옵니다.

const encryptedStorage = new EncryptedLocalStorage();

const APPALIAS = process.env.REACT_APP_SA_APP_ALIAS;
const CLIENT_ID = process.env.REACT_APP_SA_CLIENT_ID;
const REDIRECT_URI_TO_HOME = decodeURIComponent(window.location.origin);
const REDIRECT_URI_TO_TIMEOUT_PAGE = decodeURIComponent(window.location.origin + "/timeout");
const REDIRECT_URI_TO_AUTH = decodeURIComponent(window.location.origin + "/auth");
const SCOPE = "iot.client" ; // scope : iot.client

export const AUTH_KEYS = {
    ACCESS_TOKEN: btoa("samsung_find_web_token"),
    ACCESS_TOKEN_EXPIRY: btoa("samsung_find_web_token_expiry"),
    USER_ID: btoa("samsung_find_web_toke_user_id"),
    USER_NAME: btoa("samsung_find_web_user_name"),
    AUTH_SERVER_URL: btoa("samsung_find_web_auth_server_url"),
    COUNTRY_CODE: btoa("samsung_find_web_country_code"),
    COUNTRY_CODE2: btoa("samsung_find_web_country_code2"),
    REGIONAL_DOMAIN: btoa("samsung_find_web_regional_domain"),
    SELECTED_LANGUAGE: btoa("samsung_find_web_selected_language"),
    SELECTED_LANGUAGE_TITLE: btoa("samsung_find_web_selected_language_title"),
    OPTIN_STATUS: btoa("samsung_find_web_toke_optin_status"),
    OPTIN_PARENT: btoa("samsung_find_web_toke_optin_parent"),
    OPTIN_LOCATION: btoa("samsung_find_web_toke_optin_location"),
    CONFIRM_TOKEN: btoa("samsung_find_web_confirm_token"),
    CHILD_ACCOUNT_SUPPORTED: btoa("samsung_find_web_child_account_supported"),
    SSO_LOGIN_DEVICE_ID: btoa("samsung_find_web_sso_login_device_id"),
};


// access token
export function setTokenAndExpiry(token, expiresIn) {
    try {
        const expiryTime = Date.now() + expiresIn * 1000;
        encryptedStorage.setItem(AUTH_KEYS.ACCESS_TOKEN, token);
        encryptedStorage.setItem(AUTH_KEYS.ACCESS_TOKEN_EXPIRY, expiryTime.toString());
    } catch (error) {
        console.error("Error setting token and expiry in encryptedStorage:", error);
    }
}


export function getToken() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.ACCESS_TOKEN);
    } catch (error) {
        console.error("Error getting token from encryptedStorage:", error);
        return null;
    }
}


export function isTokenExpired() {
    try {
        const expiry = encryptedStorage.getItem(AUTH_KEYS.ACCESS_TOKEN_EXPIRY);
        if (!expiry) return true;
        const now = new Date().getTime();

        // DEBUG
        const expiryTime = parseInt(expiry, 10)
        const expiryDate = new Date(expiryTime);
        console.debug("expiry: " + expiryDate.toLocaleString());

        return now >= parseInt(expiry, 10);
    } catch (error) {
        console.error("Error checking token expiry:", error);
        return true; // 에러가 발생하면 만료된 것으로 간주
    }
}

// userId
export function setUserId(userId) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.USER_ID, userId);
    } catch (error) {
        console.error("Error setting userId in encryptedStorage:", error);
    }
}


export function getUserId() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.USER_ID);
    } catch (error) {
        console.error("Error getting userId from encryptedStorage:", error);
        return null;
    }
}


// userId
export function setSsoLoginDeviceId(deviceId) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.SSO_LOGIN_DEVICE_ID, deviceId);
    } catch (error) {
        console.error("Error setting userId in encryptedStorage:", error);
    }
}


export function getSsoLoginDeviceId() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.SSO_LOGIN_DEVICE_ID);
    } catch (error) {
        console.error("Error getting userId from encryptedStorage:", error);
        return null;
    }
}

// countryCode
export function setCountryCode(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.COUNTRY_CODE, value);
    } catch (error) {
        console.error(`Error setting countryCode in encryptedStorage:`, error);
    }
}


export function getCountryCode() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.COUNTRY_CODE);
    } catch (error) {
        console.error(`Error getting countryCode from encryptedStorage:`, error);
    }
}

// my user name
export function setUserName(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.USER_NAME, value);
    } catch (error) {
        console.error(`Error setting userName in encryptedStorage:`, error);
    }
}


export function getUserName() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.USER_NAME);
    } catch (error) {
        console.error(`Error getting userName from encryptedStorage:`, error);
    }
}


// authServerUrl
export function setAuthServerUrl(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.AUTH_SERVER_URL, value);
    } catch (error) {
        console.error(`Error setting authServerUrl in encryptedStorage:`, error);
    }
}


export function getAuthServerUrl() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.AUTH_SERVER_URL);
    } catch (error) {
        console.error(`Error getting authServerUrl from encryptedStorage:`, error);
    }
}

// selected language
export function setSelectedLanguage(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.SELECTED_LANGUAGE, value);
    } catch (error) {
        console.error(`Error setting language in encryptedStorage:`, error);
    }
}
export function getSelectedLanguage() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.SELECTED_LANGUAGE);
    } catch (error) {
        console.error(`Error getting language from encryptedStorage:`, error);
    }
}

export function setSelectedLangTitle(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.SELECTED_LANGUAGE_TITLE, value);
    } catch (error) {
        console.error(`Error setting language in encryptedStorage:`, error);
    }
}
export function getSelectedLangTitle() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.SELECTED_LANGUAGE_TITLE);
    } catch (error) {
        console.error(`Error getting language from encryptedStorage:`, error);
    }
}


export function setCountryCode2(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.COUNTRY_CODE2, value);
    } catch (error) {
        console.error(`Error setting country code 2 in encryptedStorage:`, error);
    }
}
export function getCountryCode2() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.COUNTRY_CODE2);
    } catch (error) {
        console.error(`Error getting country code 2 from encryptedStorage:`, error);
    }
}


export function setChildAccountSupported(value) {
    try {
        const childAccountSupported = JSON.stringify(value);
        encryptedStorage.setItem(AUTH_KEYS.CHILD_ACCOUNT_SUPPORTED, childAccountSupported);
    } catch (error) {
        console.error(`Error setting child account in encryptedStorage:`, error);
    }
}

export function getChildAccountSupported() {
    try {
        const stringJson = encryptedStorage.getItem(AUTH_KEYS.CHILD_ACCOUNT_SUPPORTED);
        return JSON.parse(stringJson);
    } catch (error) {
        console.error(`Error getting child account from encryptedStorage:`, error);
    }
}




/**
 * To determine whether to log in, the token, authServerUrl, and userId values ​​must exist in local storage and not expire.
 * @returns Boolean
 */
export function isLoggedIn() {
    try {
        const token = getToken();
        const authServerUrl = getAuthServerUrl();
        const userId = getUserId();
        if (!token || !authServerUrl || !userId) {
            return false;
        }
        return !isTokenExpired();
    } catch (error) {
        console.error("Error checking login status:", error);
        return false; // 에러가 발생하면 로그인되지 않은 것으로 간주
    }
}

/**
 * log out
 */
export function clearSessionAndUserInfo() {
    // 삭제 제외 대상
    const countryCode2 = getCountryCode2();
    const lang = getSelectedLanguage();
    const langTitle = getSelectedLangTitle();

    sessionStorage.clear(); // sessionSorgae의 모든 데이터 삭제 
    encryptedStorage.removeItem(AUTH_KEYS.ACCESS_TOKEN);
    localStorage.clear(); // encryptedStorage의 모든 데이터 삭제
    mqttConnectionsManager.disconnectAllConnections();

    // 삭제 제외 대상
    setCountryCode2(countryCode2);
    setSelectedLanguage(lang);
    setSelectedLangTitle(langTitle);

    // 추가적으로 필요한 로그아웃 처리 로직이 있다면 여기에 작성
    // 로그아웃 타이머 리셋
    // 삼성어카운트 로그아웃
    // Reudx 상태 초기화 (새로고침이 안 될 경우 ? 있나? )
    // 쿠키제거 
}

export const getSamsungAccountSignInURI = () => {
    const authStateValue = Math.random().toString(36).substring(7); // 랜덤함 문자열을 state 값으로 설정.
    sessionStorage.setItem('auth_state_value', authStateValue); // 로그인 후 받아오는 state와 비교하기 위해 저장
    const queryParams = new URLSearchParams({
        client_id: CLIENT_ID,
        response_type: 'code',
        redirect_uri: REDIRECT_URI_TO_AUTH,
        scope: SCOPE,
        state: authStateValue,
    });
    return `https://account.samsung.com/accounts/v1/${APPALIAS}/signInGate?${queryParams.toString()}`;
};

export function signOutAndRedirectToHome() {
    clearSessionAndUserInfo();
    window.location.href = getSamsungAccountSignOutURI(REDIRECT_URI_TO_HOME); // SA sing out & redirect to home
}

export function signOutAndRedirectToTimeoutPage() {
    clearSessionAndUserInfo();
    window.location.href = getSamsungAccountSignOutURI(REDIRECT_URI_TO_TIMEOUT_PAGE); // SA sing out & redirect to home
}

export const getSamsungAccountSignOutURI = (redirectUri) => {
    const authStateValue = Math.random().toString(36).substring(7); // 랜덤함 문자열을 state 값으로 설정.
    sessionStorage.setItem('auth_state_value', authStateValue); // 로그인 후 받아오는 state와 비교하기 위해 저장
    const queryParams = new URLSearchParams({
        client_id: CLIENT_ID,
        response_type: 'code',
        redirect_uri: redirectUri,
        state: authStateValue,
    });
    return `https://account.samsung.com/accounts/v1/${APPALIAS}/signOutGate?${queryParams.toString()}`;
};


export function removeTokenAndExpiry() {
    try {
        encryptedStorage.removeItem(AUTH_KEYS.ACCESS_TOKEN);
        encryptedStorage.removeItem(AUTH_KEYS.ACCESS_TOKEN_EXPIRY);
    } catch (error) {
        console.error("Error removing token and expiry from encryptedStorage:", error);
    }
}

// Optin - Agreement
export function setOptinStatus(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.OPTIN_STATUS, value);
    } catch (error) {
        console.error(`Error setting Optin Status in encryptedStorage:`, error);
    }
}
export function getOptinStatus() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.OPTIN_STATUS);
    } catch (error) {
        console.error(`Error setting Optin Status in encryptedStorage:`, error);
    }
}
// Optin - Parent Agreement
export function setOptinParent(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.OPTIN_PARENT, value);
    } catch (error) {
        console.error(`Error setting Optin Status in encryptedStorage:`, error);
    }
}
export function getOptinParent() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.OPTIN_PARENT);
    } catch (error) {
        console.error(`Error setting Optin Status in encryptedStorage:`, error);
    }
}
// Optin - Location Setting
export function setOptinLocation(value) {
    try {
        encryptedStorage.setItem(AUTH_KEYS.OPTIN_LOCATION, value);
    } catch (error) {
        console.error(`Error setting Optin Status in encryptedStorage:`, error);
    }
}
export function getOptinLocation() {
    try {
        return encryptedStorage.getItem(AUTH_KEYS.OPTIN_LOCATION);
    } catch (error) {
        console.error(`Error setting Optin Status in encryptedStorage:`, error);
    }
}
