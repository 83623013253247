import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import useDeviceOperations from '../../../hooks/useDeviceOperations';
import { performOperationRing, performOperationRingStop, performOperationMute } from '../../../api/findToStf/devicesApi/devicesApi';
import {
    setIsPlaySoundLoading, setBudsSoundAgree, setPlaySoundPhoneInfo, setPlaySoundTabInfo,
    setPlaySoundWatchInfo, setPlaySoundWatchVibInfo, setPlaySoundBudsInfo, setPlaySoundSpenInfo,
    setPlaySoundTagInfo, setPlaySoundWearableInfo, setIsDoneOprt
} from '../../../slices/modalSlice';
import { getDeviceName } from "../../../utills/functions";
import { createTagRingSpecificParams } from '../../../utills/functions/deviceFunctions';
import { OPERATION_TYPES } from '../../../utills/enums/commons';
import { DEVICE_TYPES, DEVICE_SUB_TYPES } from '../../../utills/enums/Devices';

import ModalPlaySoundAlert from '../ModalPlaySoundAlert';
import BaseModal from '../BaseModal';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { Button } from '@mui/material';
import { ring, blink_spen, blink_common_light, Progress_Circle, bean_left, bean_left_offline, bean_right, bean_right_offline, ring_btn, ring_btn_dim } from "../../../assets/images/deviceCard";
import LoadingCircle from '../../view-only/LoadingCircle';
import './ModalPlaySoundStyles.scss';


const ModalPlaySound = ({ open, onClose, passedValue }) => {
    // passedValue <= selectedDeviceDetail <= getDeviceDetailByDeviceId

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // global states
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const budsSoundAgree = useSelector((state) => state.modal.budsSoundAgree);
    const playsoundInfoPhone = useSelector(state => state.modal.playsoundInfoPhone);
    const playsoundInfoTab = useSelector(state => state.modal.playsoundInfoTab);
    const playsoundInfoWatch = useSelector(state => state.modal.playsoundInfoWatch);
    const playsoundInfoWatchVib = useSelector(state => state.modal.playsoundInfoWatchVib);
    const playsoundInfoBuds = useSelector(state => state.modal.playsoundInfoBuds);
    const playsoundInfoTag = useSelector(state => state.modal.playsoundInfoTag);
    const playsoundInfoSpen = useSelector(state => state.modal.playsoundInfoSpen);
    const playsoundInfoWearable = useSelector(state => state.modal.playsoundInfoWearable);
    const isDoneOprt = useSelector(state => state.modal.isDoneOprt);  // Ring Start, Ring Stop  두 Operation을 하나의 Transaction개념으로. 

    // mqtt operation response update
    const { operationsResult, startOperation } = useDeviceOperations();
    const isLoadingOprt = operationsResult[selectedDevice?.deviceId]?.[OPERATION_TYPES.RING]?.isLoading;
    const latestRingRsp = operationsResult[selectedDevice?.deviceId]?.[OPERATION_TYPES.RING]?.data;

    const [ringOprtPhone, setRingOprtPhone] = useState("");
    const [ringOprtTab, setRingOprtTab] = useState("");
    const [ringOprtWatch, setRingOprtWatch] = useState("");
    const [ringOprtWatchVib, setRingOprtWatchVib] = useState("");
    const [ringOprtBuds, setRingOprtBuds] = useState("");
    const [ringOprtTag, setRingOprtTag] = useState("");
    const [ringOprtSpen, setRingOprtSpen] = useState("");
    const [ringOprtWearable, setRingOprtWearable] = useState("");


    // local states
    const [title, setTitle] = useState("None Title");
    const [image, setImage] = useState(ring);
    const [disableImg, setDisableImg] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [disableX, setDisableX] = useState(false);

    const [budsImage, setBudsImage] = useState(false);
    const [budsLeft, setBudsLeftImage] = useState(bean_left);
    const [budsRight, setBudsRightImage] = useState(bean_right);
    const [budsLeftRingBtn, setBudsLeftRingBtn] = useState(ring_btn);
    const [budsRightRingBtn, setBudsRightRingBtn] = useState(ring_btn);

    const [buttonText, setButtonText] = useState("");
    const [disabled, setButtonDisabled] = useState(false);  // global value로 바꿔야될까?
    const [visible, setVisible] = useState(true);

    const [selectedDeviceDetail, setSelectedDeviceDetail] = useState(passedValue);
    const [deviceType, setDeviceType] = useState("");
    const [deviceSubType, setDeviceSubType] = useState("");
    const [features, setFeatures] = useState("");
    const displayName = getDeviceName(selectedDevice);

    // Dialog 정보 초기화
    const dialogResetInfo = () => {
        // mqtt, latestRingRsp를 담고있으니까 초기화 필요
        console.log("dialogResetInfo()");
        setSelectedDeviceDetail(null);
        setFeatures(null);
        setBudsImage(false);  // Buds ringing 일때만 true로 설정됨.
    }

    const dialogInfoInit = () => {
        // mqtt, latestRingRsp를 담고있으니까 초기화 필요
        console.log("dialogInfoInit() : latestRingRsp ================== " + latestRingRsp);
        setRingOprtPhone("");
        setRingOprtTab("");
        setRingOprtWatch("");
        setRingOprtWatchVib("");
        setRingOprtBuds("");
        setRingOprtTag("");
        setRingOprtSpen("");
        setRingOprtWearable("");

        setDisableX(false);
        setDisableImg(false);
    }


    // Dialog Close
    const handleCancel = () => {
        // Dialog 재 진입시 초기화 되어야 할 상황이 있고, 내용이 유지되어야 할 상황이 있다.  Dialog 닫을때 정리해주자.

        // dialog 처음에 그냥 닫을때 || network error로 isDoneOprt가 true되지 못한경우는 latestRingRsp = undefined.
        // Select Device 가 바뀌지 않은 상태에서, Dialog 재Open 시키는 경우, 정보 업데이트가 되지 않음. 리셋 필요함.
        if ((isLoadingOprt === undefined) || (latestRingRsp === undefined)) {
            console.log("handleCancel() : isLoadingOprt = undefined ||  latestRingRsp = undefined ");
            dialogResetInfo();
        }

        // function 수행중일 때 dialog를 닫는 경우 (isDoneOprt = false  =>  ring stop 되기전이라는 뜻) 
        else if (isLoadingOprt === false) {
            // ring은 끝났고, ring stop은 안했는데 close시켰을 경우!!
            if (isDoneOprt === false) {
                // 'stop' button 있는 dialog를 'x'로 닫을때, 'stop'button 클릭한 것과 같은 동작을 하라고.. =>  초기화 하지 않아야?? vs 초기화 해야??
                console.log("handleCancel() : isLoadingOprt = false  &&  isDoneOprt = false ");
                doStopSound();
            }
            // ring은 끝났고, ring stop 했거나 한것으로 친 경우!!
            else {
                // '01'받으면, isLoadingOprt = false,  isDoneOprt = true로 만들어놓음.
                // operation이 다 완료된 상태에서 dialog를 닫으면, 재 진입때 dialog 초기화 시켜야하니까.
                console.log("handleCancel() : isLoadingOprt = false  &&  isDoneOprt = true ");
                dialogResetInfo();
            }
        }

        else if (isLoadingOprt === true) {
            if (isDoneOprt === false) {
                console.log("handleCancel() : isLoadingOprt = true  :  isDoneOprt = false ");
                // operation이 다 완료되지 않은 상태에서 dialog를 닫으면, 재 진입시점에 operation 상태를 보여줘야 함. 초기화시키면 안됨.
                // Tag는 예외 케이스라서 isLoadingOprt가 계속 true가 유지됨, 여기서 dialog내용을 초기화해줘야 함.
                if (deviceType === 'TAG') {
                    dialogResetInfo();
                }
            }
        }
        else {
            console.log("handleCancel() :  else?? ");
        }

        onClose();
    }


    // Dialog Open : ImageIcon, Text 초기화용
    useEffect(() => {
        if (open === true) {
            if (passedValue === null) {
                console.log("[useEffect : open]  passedValue = " + passedValue);
                handleCancel();
            }

            if (passedValue !== null) {
                // 초기상태의 Dialog  -  dialog 첫 진입 or operation 수행이 완전히 종료된 후, 재 진입
                if ((isLoadingOprt === undefined) || (isDoneOprt === true)) {
                    console.log("[useEffect : open]  case for (isLoadingOprt = undefined) || (isDoneOprt = true) ", " => isLoadingOprt = ", isLoadingOprt, ", isDoneOprt = ", isDoneOprt);
                    // 초기화
                    setSelectedDeviceDetail(passedValue);
                    dispatch(setBudsSoundAgree(false));
                    setBudsImage(false);
                    dialogInfoInit();
                }

                // 진행중인 상태의 Dialog  -  operation이 진행중일 때 (isLoadingOprt = true), dialog를 'X'버튼으로 이탈했다가 재 진입할 경우,
                else {
                    // operation이 시작되었음 => isLoadingOprt = true
                    if (isLoadingOprt === true) {
                        // operation이 시작되었으나 응답은 받지 못한 상태 => isLoadingOprt = true, latestRingRsp = undefined
                        if (latestRingRsp === undefined) {
                            console.log("[useEffect : open]  isLoadingOprt = true,  latestRingRsp = ", latestRingRsp);
                        }
                        // operation이 시작되었고 응답을 받은 상태 => isLoadingOprt = true, latestRingRsp 있음.
                        else {
                            // 진행중인 function의 상태대로 dialog를 띄워준다.
                            console.log("[useEffect : open]  isLoadingOprt = true,  latestRingRsp = ", latestRingRsp);
                        }
                    }
                    // operation이 종료되고 재진입하는 경우 => isLoadingOprt = false
                    else {
                        // status=4(ringing...) 또는 ring 할 수 없었음이라든지...처리해줘야...
                        console.log("[useEffect : open]  isLoadingOprt = false,  latestRingRsp = ", latestRingRsp);
                        // 초기화
                        setSelectedDeviceDetail(passedValue);
                        dispatch(setBudsSoundAgree(false));

                        dialogInfoInit();
                    }
                }
            }
        }
    }, [open]);


    // selected Device가 바뀌면,  device Type과 device feature를 업데이트 시킨다.
    useEffect(() => {
        if (selectedDevice && selectedDeviceDetail) {
            setDeviceType(selectedDevice.type);
            setDeviceSubType(selectedDevice.modelInfo?.deviceSubType);
            setFeatures(selectedDeviceDetail.menu);
        }
    }, [selectedDeviceDetail]);


    // selected Device가 바뀌어서 feature가 업데이트 되었다면, feature를 새로 가져와서 Dialog의 내용을 새로 작성한다.
    useEffect(() => {
        if (features !== null) {
            // set Dialog Title
            if (deviceType === DEVICE_TYPES.PHONE) {  // phone
                setTitle(t('ring_phone'));
            } else if (deviceType === DEVICE_TYPES.TAB) {  // tablet
                setTitle(t('ring_tablet'));
            } else if (deviceType === DEVICE_TYPES.WATCH) {  // watch
                if (isFeatureEnabled(selectedDeviceDetail, 'ring')) {
                    setTitle(t('ring_watch'));
                }
                else { // 'vibration = Y'
                    if (deviceSubType === DEVICE_SUB_TYPES.FIT) {
                        setTitle(t('vibrate_band'));
                    } else {
                        setTitle(t('vibrate_watch'));  // watch-vivrate
                    }
                }
            } else if (deviceType === DEVICE_TYPES.BUDS) { // buds
                setTitle(t('beep_earbuds'));
            } else if (deviceType === DEVICE_TYPES.TAG) {  // tag
                setTitle(t('ring_tag'));
            } else if (deviceType === DEVICE_TYPES.SPEN) {  // s pen
                setTitle(t('blink_spen'));
            } else if (deviceType === DEVICE_TYPES.WEARABLE) {  // wearable > ring
                setTitle(t('blink_ring'));
            } else {
                setTitle('');
            }

            // set Dialog Info
            if (isFeatureEnabled(selectedDeviceDetail, 'ring')) {
                // for Phone/Tablet/Watch_ring/Buds/Tag
                dispatch(setIsPlaySoundLoading(false));
                setImage(ring);
                if (deviceType === DEVICE_TYPES.BUDS) {
                    setInfoText(t('beeping_earbuds'));
                } else if (deviceType === DEVICE_TYPES.TAG) {
                    setInfoText(t('will_start_ringing').replace("%s", displayName));
                } else if (deviceType === DEVICE_TYPES.WATCH) {
                    setInfoText(t('start_ring_watch').replace("%s", displayName));
                } else if (deviceType === DEVICE_TYPES.PHONE) {
                    setInfoText(t('start_ring_phone').replace("%s", displayName));
                } else {  // TABLET
                    setInfoText(t('start_ring_tablet').replace("%s", displayName));
                }
                setButtonText(t('start'));
                setButtonDisabled(false);
                setVisible(true);
            }
            else if (isFeatureEnabled(selectedDeviceDetail, 'vibration')) {
                // for Watch_vibrate
                dispatch(setIsPlaySoundLoading(false));
                setImage(ring);
                if (deviceSubType === DEVICE_SUB_TYPES.WATCH) {
                    setInfoText(t('start_vibrate_watch'));
                } else { // FIT
                    setInfoText(t('start_vibrate_band'));
                }
                setButtonText(t('start'));
                setButtonDisabled(false);
                setVisible(true);
            }
            else if (isFeatureEnabled(selectedDeviceDetail, 'led')) {
                // for S Pen/Tag
                dispatch(setIsPlaySoundLoading(false));
                if (deviceType === DEVICE_TYPES.SPEN) {
                    setImage(blink_spen);
                    setInfoText(t('start_blink_spen'));
                } else { // Wearable - ring
                    setImage(blink_common_light);
                    setInfoText(t('start_blink_ring'));
                }
                setButtonText(t('start'));
                setButtonDisabled(false);
                setVisible(true);
            }
            else {
                dispatch(setIsPlaySoundLoading(false));
                setImage(ring);
                setInfoText("");
                setButtonText(t('close'));
                setButtonDisabled(false);
                setVisible(true);
            }
        }
    }, [features]);

    // menu 필드 값 체크해서 지원되는 feature들만 표시되도록 한다.
    function isFeatureEnabled(selectedDeviceDetail, featureKey) {
        // selectedDeviceDetail이 null이거나 menu가 없는 경우, 기능을 비활성화
        if (!selectedDeviceDetail || !selectedDeviceDetail.menu) {
            return false;
        }
        // 해당 기능의 활성화 여부 찾기
        const feature = selectedDeviceDetail.menu.find(item => item.hasOwnProperty(featureKey));
        if (feature) {
            // 값이 배열인 경우, 배열 길이가 0보다 크면 활성화
            if (Array.isArray(feature[featureKey])) {
                return feature[featureKey].length > 0;
            }
            // 그렇지 않으면 'Y'인 경우에만 활성화
            return feature[featureKey] === 'Y';
        }
        return false;
    }


    // Button - Play sound & Stop sound
    const handlePlaySound = () => {
        if (buttonText === t('start')) {
            if (deviceType === "BUDS") {
                setOpenBudsAlert(true);
            } else {
                // play sound 요청을 보냄.
                // doPlaySound();
                sendRingOperations(selectedDevice, selectedDeviceDetail);
            }
        } else if (buttonText === t('stop')) {
            doStopSound();
        } else if (buttonText === t('close')) {
            dispatch(setIsDoneOprt(true));  // Close - earbuds status '1' 일 때 사용됨. 동작 종료이므로 dialog 초기화필요.
        } else {  // 'OK' button
            handleCancel();  // 그냥dialog 닫기. 
        }
    }

    const sendRingOperations = (device, deviceDetail) => {
        if (!device) {
            return;
        }
        startOperation(device, deviceDetail, OPERATION_TYPES.RING,
            async function doPlaySound() {
                try {
                    // isPlaySoundLoading => button은 dim처리.
                    dispatch(setIsPlaySoundLoading(true));
                    setImage(null);  // loading spinner
                    setButtonDisabled(true);
                    dispatch(setIsDoneOprt(false));  // ring stop되면 true로 설정해줌.

                    // api call - play sound
                    const userId = device?.userId; // deviceOwner's userId
                    const deviceId = device?.deviceId;

                    let tagRingSpecificParams = {};
                    if (deviceType === DEVICE_TYPES.TAG) {
                        tagRingSpecificParams = createTagRingSpecificParams(device);
                    }
                    const responseData = await performOperationRing(deviceId, userId, deviceType, tagRingSpecificParams);

                    // response data로 state를 업데이트 해둠, useEffect에서 가져다 씀.
                    if (deviceType === DEVICE_TYPES.PHONE) {
                        dispatch(setPlaySoundPhoneInfo(responseData));
                    } else if (deviceType === DEVICE_TYPES.TAB) {  // tablet
                        dispatch(setPlaySoundTabInfo(responseData));
                    } else if (deviceType === DEVICE_TYPES.WATCH) {  // watch
                        if (isFeatureEnabled(selectedDeviceDetail, 'ring')) {  // 'ring = Y'
                            dispatch(setPlaySoundWatchInfo(responseData));
                        } else {  // 'vibration = Y'
                            dispatch(setPlaySoundWatchVibInfo(responseData));
                        }
                    } else if (deviceType === DEVICE_TYPES.BUDS) { // buds 
                        dispatch(setPlaySoundBudsInfo(responseData));
                    } else if (deviceType === DEVICE_TYPES.SPEN) {  // s pen
                        dispatch(setPlaySoundSpenInfo(responseData));
                    } else if (deviceType === DEVICE_TYPES.TAG) {  // tag
                        dispatch(setPlaySoundTagInfo(responseData));
                    } else if (deviceType === DEVICE_TYPES.WEARABLE) {  // ring
                        dispatch(setPlaySoundWearableInfo(responseData));
                    }
                } catch (error) {  // ex) 404 error
                    console.error("error =================== ", error);

                    dispatch(setIsPlaySoundLoading(false));
                    setImage(Progress_Circle);
                    setInfoText(t('network_problem'));
                    setButtonText(t('close'));
                    setButtonDisabled(false);
                }
            }
        );
    }

    async function doStopSound() {
        try {
            if (deviceType === 'BUDS') {
                if (budsLeftRingBtn) setBudsLeftRingBtn(ring_btn_dim);
                if (budsRightRingBtn) setBudsRightRingBtn(ring_btn_dim);
            }
            setButtonDisabled(true);
            dispatch(setIsDoneOprt(false));  // dialog 닫지 않기, mqtt response까지 받고 닫아야함.

            // api call - stop sound
            const userId = selectedDevice?.userId; // deviceOwner의 
            const responseData = await performOperationRingStop(selectedDeviceDetail.deviceId, userId);
            console.error("doStopSound, responseData =================== ", responseData);  // resultCode = 00

        } catch (error) {
            console.error("error =================== ", error);
            dispatch(setIsDoneOprt(true));  // dialog 닫기
        }
    }

    useEffect(() => {
        console.log("[useEffect : isDoneOprt] ");

        // PlaySound과정이 끝난 상태 (ring stop까지 완료된 경우, ring fail된 경우)
        if (isDoneOprt && open && (isLoadingOprt !== undefined)) {
            console.log("[useEffect : isDoneOprt]  now dialog open status,  so will be close");

            // dialog 자동으로 닫기
            // - ring 성공한 이후에, ring stop이 된 경우 (latestRingRsp이 남아있음 이용)
            // - 'network error' dialog에서 'close' 버튼 누른 경우 (latestRingRsp이 undefined)
            //    단,  Tag는 ring 성공했어도 latestRingRsp이 = undefined 이라서,  예외처리해야함.
            if (latestRingRsp?.oprnStsCd === '2800' || (latestRingRsp === undefined)) {
                console.log("go handleCancel  (latestRingRsp, latestRingRsp?.oprnStsCd) = ", latestRingRsp, latestRingRsp?.oprnStsCd);
                if (deviceType !== 'TAG') {
                    handleCancel();
                }
            }

            // dialog를 닫지는 않고, 재진입시 dialog 내용만 초기화 시켜놓으려는 경우
            // - ring fail '01'의 경우 (latestRingRsp이 없음) 
            // - ring unavailable '1900', '2900' (latestRingRsp이 있음)
            else {
                // dialog 안닫음, data만 초기화 시키기
            }
        }
        else if (isDoneOprt && !open) {
            console.log("[useEffect : isDoneOprt]  now dialog close status,  so will be just reset dialog Info");
            dialogResetInfo();
        }
    }, [isDoneOprt]);


    // Ear Buds 'Mute'
    const handleBudsMuteLeft = () => {
        if (ringOprtBuds.extra?.left?.status === '5') { // mute
            doMuteSound('resumeL');
        }
        else {  // ringing
            doMuteSound('muteL');
        }
    }

    const handleBudsMuteRight = () => {
        if (ringOprtBuds.extra?.right?.status === '5') { // mute
            doMuteSound('resumeR');
        }
        else {  // ringing
            doMuteSound('muteR');
        }
    }

    async function doMuteSound(isMuteResume) {
        try {
            setButtonDisabled(true); // Mute/Resume 하는 동안 Stop 못하게

            const userId = selectedDevice?.userId;
            const responseData = await performOperationMute(selectedDeviceDetail.deviceId, userId, isMuteResume);
            dispatch(setPlaySoundBudsInfo(responseData));
        } catch (error) {
            console.error("error =================== " + error);
        }
    }


    // mqtt operation response update 'latestRingRsp'
    useEffect(() => {
        // operation response받음 or time out 발생하면 => isLoading = false된 후, latestRingRsp 들어옴.
        // time out인 경우에 isLoading = false 일수있으므로, responseData null 체크를 해야함.
        if (isLoadingOprt === false && latestRingRsp !== null) {
            console.log("useEffect : isLoadingOprt = false, latestRingRsp !!!!!!!!!!!!!!!!!!!!!! ", latestRingRsp);

            if (deviceType === DEVICE_TYPES.PHONE) {
                setRingOprtPhone(latestRingRsp);
            } else if (deviceType === DEVICE_TYPES.TAB) {  // tablet
                setRingOprtTab(latestRingRsp);
            } else if (deviceType === DEVICE_TYPES.WATCH) {  // watch
                if (isFeatureEnabled(selectedDeviceDetail, 'ring')) {  // 'ring = Y'
                    setRingOprtWatch(latestRingRsp);
                } else { // 'vibration = Y'
                    setRingOprtWatchVib(latestRingRsp);
                }
            } else if (deviceType === DEVICE_TYPES.BUDS) { // buds 
                setRingOprtBuds(latestRingRsp);
            } else if (deviceType === DEVICE_TYPES.TAG) {  // tag
                setRingOprtTag(latestRingRsp);
            } else if (deviceType === DEVICE_TYPES.SPEN) {  // s pen
                setRingOprtSpen(latestRingRsp);
            } else if (deviceType === DEVICE_TYPES.WEARABLE) {  // wearable_ring
                setRingOprtWearable(latestRingRsp);
            }
        }
    }, [latestRingRsp]);


    // response 받은 내용에 따라 처리 - Playsound Phone
    useEffect(() => {
        // result : Fail to ring?  Yes  =>  resultCode === '01'
        if (playsoundInfoPhone.resultCode === '01') {
            dispatch(setIsPlaySoundLoading(false));
            setImage(Progress_Circle);
            setInfoText(t('cant_ring_phone'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoPhone]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtPhone  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtPhone);

        // result : Fail to ring?  No  =>  resultCode === '00'
        if (ringOprtPhone !== "") {
            dispatch(setIsPlaySoundLoading(false));
            setImage(null);

            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtPhone.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'
                if (ringOprtPhone.extra?.status === '0') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : => extra.status === '4'
                else {
                    //  result : Can stop ringing?  Yes
                    if (isFeatureEnabled(selectedDeviceDetail, 'ringStop')) {
                        setImage(ring);
                        setInfoText(t('playing_phone'));
                        setButtonText(t('stop'));
                        setButtonDisabled(false);
                    }

                    //  result : Can stop ringing?  No
                    else {
                        setImage(ring);
                        setInfoText(t('rang_phone'));
                        setButtonDisabled(false);
                        setVisible(false);
                    }
                }
            }

            // result : settings off => oprnStsCd === '2900' 
            else if (ringOprtPhone.oprnStsCd === '2900') {
                setTitle(t('cant_ring'));
                setImage(Progress_Circle);
                setInfoText(t('not_allowed_phone'));
                setButtonText(t('ok'));
                setButtonDisabled(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }

            // result : offline  =>  oprnStsCd === '1900'
            else {
                setImage(Progress_Circle);
                setInfoText(t('offline_phone'));
                setButtonText(t('ok'));
                setButtonDisabled(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }
        }
    }, [ringOprtPhone]); // response reducer


    // response 받은 내용에 따라 처리 - Playsound Tablet
    useEffect(() => {
        // result : Fail to ring?  Yes  =>  resultCode === '01'
        if (playsoundInfoTab.resultCode === '01') {
            setImage(Progress_Circle);
            setInfoText(t('cant_ring_tablet'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoTab]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtTab  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtTab);

        // result : Fail to ring?  No  =>  resultCode === '00'
        if (ringOprtTab !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtTab.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'
                if (ringOprtTab.extra?.status === '0') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : ring 되는 중 => extra.status === '4'
                else {
                    // result : Can stop ringing?  Yes
                    if (isFeatureEnabled(selectedDeviceDetail, 'ringStop')) {
                        setImage(ring);
                        setInfoText(t('playing_tablet'));
                        setButtonText(t('stop'));
                        setButtonDisabled(false);
                    }

                    // result : Can stop ringing?  No
                    else {
                        setImage(ring);
                        setInfoText(t('rang_tablet'));
                        setButtonDisabled(false);
                        setVisible(false);
                    }
                }
            }

            // result : settings off => oprnStsCd === '2900' 
            else if (ringOprtTab.oprnStsCd === '2900') {
                setTitle(t('cant_ring'));
                setImage(Progress_Circle);
                setInfoText(t('not_allowed_tablet'));
                setButtonText(t('ok'));
                setButtonDisabled(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }

            // result : offline => oprnStsCd === '1900'
            else {
                setImage(Progress_Circle);
                setInfoText(t('offline_tablet'));
                setButtonText(t('ok'));
                setButtonDisabled(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }
        }
    }, [ringOprtTab]); // response reducer


    // response 받은 내용에 따라 처리 - Playsound Watch
    useEffect(() => {
        // resultCode : Fail to ring?  Yes
        if (playsoundInfoWatch.resultCode === '01') {
            setImage(Progress_Circle);
            setInfoText(t('cant_connect_watch'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoWatch]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtWatch  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtWatch);

        // resultCode :  Fail to ring?  No
        if (ringOprtWatch !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtWatch.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'?  (재현 로그 상으로는 '2')
                if (ringOprtWatch.extra.status === '0' || ringOprtWatch.extra.status === '2') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : ring 되는 중 => extra.status === '4'
                else {
                    setTitle(t('ring_watch'));
                    setImage(ring);
                    setInfoText(t('playing_watch'));
                    setButtonText(t('stop'));
                    setButtonDisabled(false);
                }
            }

            // result : settings off => oprnStsCd === '2900' 
            else if (ringOprtWatch.oprnStsCd === '2900') {
                setTitle(t('ring_watch'));
                setImage(Progress_Circle);
                setInfoText(t('not_allowed_watch'));
                setButtonText(t('ok'));
                setButtonDisabled(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }

            // result : offline =>  oprnStsCd === '1900' (&& oprnResultCode = 3451)
            else {
                setTitle(t('ring_watch'));
                setImage(Progress_Circle);
                setInfoText(t('cant_ring_watch'));
                setButtonText(t('ok'));
                setButtonDisabled(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }
        }
    }, [ringOprtWatch]); // response reducer


    // response 받은 내용에 따라 처리 - Vibrate Watch 
    useEffect(() => {
        // resultCode : Fail to ring?  Yes
        if (playsoundInfoWatchVib.resultCode === '01') {
            setImage(Progress_Circle);
            setInfoText((deviceSubType === DEVICE_SUB_TYPES.WATCH) ? t('cant_connect_watch_vib') : t('cant_connect_band'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoWatchVib]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtWatchVib  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtWatchVib);

        // resultCode :  Fail to ring?  No
        if (ringOprtWatchVib !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtWatchVib.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'?  (재현 로그 상으로는 '2')
                if (ringOprtWatchVib.extra.status === '0' || ringOprtWatchVib.extra.status === '2') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }
                // result : ring 되는 중 => extra.status === '4'
                else {
                    setTitle((deviceSubType === DEVICE_SUB_TYPES.WATCH) ? t('vibrate_watch') : t('vibrate_band'));
                    setImage(ring);
                    setInfoText((deviceSubType === DEVICE_SUB_TYPES.WATCH) ? t('vibrating_watch') : t('vibrating_band'));
                    setButtonText(t('stop'));
                    setButtonDisabled(false);
                }
            }
        }
    }, [ringOprtWatchVib]); // response reducer


    // response 받은 내용에 따라 처리 - Playsound Buds
    useEffect(() => {
        // API가 안 날라갔다 => resultCode === '01'
        if (playsoundInfoBuds.resultCode === '01') {
            setImage(Progress_Circle);
            setInfoText(t('cant_connect_earbuds'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoBuds]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtBuds  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtBuds);

        // result : Fail to ring?  No  =>  resultCode === '00'
        if (ringOprtBuds !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtBuds.oprnStsCd === '2800') {
                // result : buds쪽에서 케이스에 넣어서 Ring을 중단시킨 상태  => extra.status === '0',  하나라도 ring 되면 창 닫지마.
                if (ringOprtBuds.extra.left?.status === '0' && ringOprtBuds.extra.right?.status === '0') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }
                // result : buds가 ring stop에 의해 중단된 상태 (status = 2 : normal status)
                else if (ringOprtBuds.extra.left?.status === '2' || ringOprtBuds.extra.right?.status === '2') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }
                // result : buds가 ring stop에 의해 중단된 상태 (status = 1 : in ear // 손에 쥐고서 ring stop한 경우)
                else if ((disabled === true) && (ringOprtBuds.extra.left?.status === '1' || ringOprtBuds.extra.right?.status === '1')) {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : Ringing... (status = 4 : ring)
                else {
                    // result : 통화상태 확인 지원 기기  Yes
                    // result : 모단말로 통화중  Yes  (status = 1 : in ear)
                    if (ringOprtBuds.extra.left?.status === '1' || ringOprtBuds.extra.right?.status === '1') {
                        setImage(Progress_Circle);
                        setInfoText(t('cant_use_earbuds'));
                        setButtonText(t('close'));
                        setButtonDisabled(false);

                        setBudsImage(false);
                    }
                    //  result : 모단말로 통화중  No
                    else {
                        setImage(ring);
                        if (1) { // Buds2
                            setInfoText(t('stop_find_earbuds').replace("%s", displayName));
                        } else { //
                            setInfoText(t('stop_find_earbuds_case').replace("%s", displayName));
                        }

                        setBudsImage(true);

                        // 왼쪽 : 소리 X
                        if (ringOprtBuds.extra.left?.status === '0') {
                            setBudsLeftImage(bean_left_offline);
                            // offline text
                            setBudsLeftRingBtn(null);
                        }
                        // 왼쪽 : 소리 O
                        else if (ringOprtBuds.extra.left?.status === '4') {
                            setBudsLeftImage(bean_left);
                            setBudsLeftRingBtn(ring_btn);
                        }
                        // 왼쪽 : Mute 
                        else if (ringOprtBuds.extra.left?.status === '5') {
                            setBudsLeftImage(bean_left);
                            setBudsLeftRingBtn(ring_btn_dim);
                        }

                        // 오른쪽 : 소리 X
                        if (ringOprtBuds.extra.right?.status === '0') {
                            setBudsRightImage(bean_right_offline);
                            // offline text
                            setBudsRightRingBtn(null);
                        }
                        // 오른쪽 : 소리 O
                        else if (ringOprtBuds.extra.right?.status === '4') {
                            setBudsRightImage(bean_right);
                            setBudsRightRingBtn(ring_btn);
                        }
                        // 오른쪽 : Mute 
                        else if (ringOprtBuds.extra.right?.status === '5') {
                            setBudsRightImage(bean_right);
                            setBudsRightRingBtn(ring_btn_dim);
                        }

                        setButtonText(t('stop'));
                        setButtonDisabled(false);
                    }
                }
            }

            // result : settings off => oprnStsCd === '2900'
            else if (ringOprtBuds.oprnStsCd === '2900') {
                // 'Allow found' settings off 
                if (ringOprtBuds.oprnResultCode === '1452') {
                    setTitle(t('cant_ring'));
                    setDisableX(true);
                    setDisableImg(true);
                    setInfoText(t('not_allowed_buds').replace("%1$s", displayName).replace("%2$s", displayName));
                    setBudsImage(false);
                    setButtonText(t('ok'));
                    setButtonDisabled(false);
                    dispatch(setIsDoneOprt(true));  // dialog 안닫음
                }
                // 특수한경우) 3000 = 왼쪽 buds가 off, 오른쪽만 mute/resume 할때, 
                // 특수한경우) 3004 = 왼쪽 buds가 ring, 오른쪽만 mute/resume 할때,
                else if (ringOprtBuds.oprnResultCode === '3000' || ringOprtBuds.oprnResultCode === '3004') {
                    // 오른쪽 : 소리 O
                    if (ringOprtBuds.extra.right?.status === '4') {
                        setBudsRightImage(bean_right);
                        setBudsRightRingBtn(ring_btn);
                    }
                    // 오른쪽 : Mute 
                    else if (ringOprtBuds.extra.right?.status === '5') {
                        setBudsRightImage(bean_right);
                        setBudsRightRingBtn(ring_btn_dim);
                    }
                    setButtonDisabled(false);
                }
                // 특수한경우) 3012 = 오른쪽 buds가 ring, 왼쪽만 mute/resume 할때,
                else if (ringOprtBuds.oprnResultCode === '3008' || ringOprtBuds.oprnResultCode === '3012') {
                    // 왼쪽 : 소리 O
                    if (ringOprtBuds.extra.left?.status === '4') {
                        setBudsLeftImage(bean_left);
                        setBudsLeftRingBtn(ring_btn);
                    }
                    // 왼쪽 : Mute 
                    else if (ringOprtBuds.extra.left?.status === '5') {
                        setBudsLeftImage(bean_left);
                        setBudsLeftRingBtn(ring_btn_dim);
                    }
                    setButtonDisabled(false);
                }
                // 3007 = 양쪽 다 mute 상태인 경우
                // 501 : not connected status,  503 : duplicated request
                else {
                    setTitle(t('cant_ring'));
                    setImage(Progress_Circle);
                    setInfoText(t('cant_connect_earbuds'));
                    setBudsImage(false);
                    setVisible(false);
                    dispatch(setIsDoneOprt(true));  // dialog 안닫음
                }
            }

            // result : offline =>  oprnStsCd === '1900' (&& oprnResultCode = 3451)
            else {
                setTitle(t('cant_ring'));
                setImage(Progress_Circle);
                setInfoText(t('cant_connect_earbuds'));
                setBudsImage(false);
                setVisible(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }
        }
    }, [ringOprtBuds]); // response reducer


    // response 받은 내용에 따라 처리 - Playsound Tag
    useEffect(() => {
        // result : Fail to ring?  Yes  =>  resultCode === '01'
        if (playsoundInfoTag.resultCode === '01') {
            dispatch(setIsPlaySoundLoading(false));
            setImage(Progress_Circle);
            setInfoText(t('cant_connect_tag'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
        // api send 성공 : playsoundInfoTag.resultCode === '00'
        else {
            setImage(ring);
            setInfoText(t('rang_tag').replace("%s", displayName));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoTag]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        console.log("ringOprtTag  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtTag);

        // result : Fail to ring?  No  =>  resultCode === '00'
        /* oprt result 못받아옴 */
        /* if (ringOprtTag !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtTag.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'
                if (ringOprtTag.extra.status === '0') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : ring 되는 중 => extra.status === '4'
                else {
                    setImage(ring);
                    setInfoText(t('rang_tag').replace("%s", displayName));
                    setVisible(false);
                }
            }

            // result : oprnStsCd === '2900' 
            else {
                setImage(Progress_Circle);
                setInfoText(t('cant_connect_tag'));
                setVisible(false);
            }
        } */
    }, [ringOprtTag]); // response reducer


    // response 받은 내용에 따라 처리 - Playsound S Pen
    useEffect(() => {
        // result : Fail to ring?  Yes  =>  resultCode === '01'
        if (playsoundInfoSpen.resultCode === '01') {
            dispatch(setIsPlaySoundLoading(false));
            setImage(Progress_Circle);
            setInfoText(t('cant_connect_spen'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoSpen]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtSpen  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtSpen);

        // result : Fail to ring?  No  =>  resultCode === '00'
        if (ringOprtSpen !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtSpen.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'
                if (ringOprtSpen.extra.status === '0') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : ring 되는 중 => extra.status === '4'
                else {
                    setImage(blink_spen);
                    setInfoText(t('blinking_spen'));
                    setButtonText(t('stop'));
                    setButtonDisabled(false);
                }
            }

            // result : oprnStsCd === '2900' 
            else {
                setImage(Progress_Circle);
                setInfoText(t('cant_connect_spen'));
                setVisible(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }
        }
    }, [ringOprtSpen]); // response reducer


    // response 받은 내용에 따라 처리 - Playsound SmartRing
    useEffect(() => {
        // result : Fail to ring?  Yes  =>  resultCode === '01'
        if (playsoundInfoWearable.resultCode === '01') {
            dispatch(setIsPlaySoundLoading(false));
            setImage(Progress_Circle);
            setInfoText(t('cant_connect_ring'));
            setVisible(false);
            dispatch(setIsDoneOprt(true));  // dialog 안닫음
        }
    }, [playsoundInfoWearable]); // response reducer

    useEffect(() => {
        // 이후에 mqtt로 업데이트 된 operation response '2800', '2900' 들어옴. 그리고 status까지도...
        // console.log("ringOprtSpen  !!!!!!!!!!!!!!!!!!!!!!  ", ringOprtSpen);

        // result : Fail to ring?  No  =>  resultCode === '00'
        if (ringOprtWearable !== "") {
            // result : Allow to be found?  Yes  => oprnStsCd === '2800'
            if (ringOprtWearable.oprnStsCd === '2800') {
                // result : device쪽에서 Ring을 중단시킨 상태  => extra.status === '0'
                if (ringOprtWearable.extra.status === '0') {
                    dispatch(setIsDoneOprt(true));  // dialog 닫기
                }

                // result : ring 되는 중 => extra.status === '4'
                else {
                    setImage(blink_common_light);
                    setInfoText(t('blinking_ring'));
                    setButtonText(t('stop'));
                    setButtonDisabled(false);
                }
            }

            // result : oprnStsCd === '2900' 
            else {
                setImage(Progress_Circle);
                setInfoText(t('cant_connect_ring'));
                setVisible(false);
                dispatch(setIsDoneOprt(true));  // dialog 안닫음
            }
        }
    }, [ringOprtWearable]); // response reducer


    // Modal - Alert
    const [openBudsAlert, setOpenBudsAlert] = useState(false);

    const handleBudsAlertModalClose = () => {
        // Alert dialog를 닫는다.
        setOpenBudsAlert(false);
    }

    useEffect(() => {
        // Alert Dialog에서 PlaySound를 click했다면, PlaySound 요청을 보냄.
        if (budsSoundAgree === true) {
            // doPlaySound();
            sendRingOperations(selectedDevice, selectedDeviceDetail);
        }
    }, [budsSoundAgree]);


    const ContentBlock = (
        <>
            <div className='sound-text'>
                <div align="center">
                    {disableImg
                        ? null
                        : (image ? (<img src={image} width='46px' height='46px' />) : (<LoadingCircle></LoadingCircle>))
                    }
                </div>
                <p> {infoText} </p>
            </div>

            {/* only for buds */}
            {
                budsImage === true ?
                    <>
                        <div className="buds-img">
                            <img src={budsLeft} alt='BudsLeft' />
                            <img src={budsRight} alt='BudsRight' />
                        </div>

                        <div className="buds-img-button">
                            {budsLeftRingBtn
                                ? <Button onClick={handleBudsMuteLeft} disabled={disabled}> <img src={budsLeftRingBtn} alt='' /> </Button>
                                : t('offline')
                            }
                            {budsRightRingBtn
                                ? <Button onClick={handleBudsMuteRight} disabled={disabled}> <img src={budsRightRingBtn} alt='' /> </Button>
                                : t('offline')
                            }
                        </div>
                    </>
                    : null
            }
        </>
    );

    const ActionBlock = (
        <>
            {visible
                ?
                <ModalBottomButton
                    title={buttonText}
                    disabled={disabled}
                    onClick={handlePlaySound}
                />
                : null
            }
        </>
    );

    return (
        <>
            <BaseModal
                open={open}
                onClose={handleCancel}
                title={title}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
                disableX={disableX}
            />
            <ModalPlaySoundAlert open={openBudsAlert} onClose={handleBudsAlertModalClose} />
        </>
    );
};

export default ModalPlaySound
