import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import  { useNavigate } from 'react-router-dom';
import './LostModeDeviceDetailCardStyles.scss';

import { LostModeDeviceDetailCardPropsType } from './LostModeDeviceDetailCardTypes';
import call_phone from "../../../assets/images/common/call_phone.svg";
import emailIcon from "../../../assets/images/common/email.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLostModeByDeviceIdAction } from '../../../slices/lostModeSlice';
import { RootStateType } from '../../../slices/types';
import TitleOverSubtitleWithImageButton from '../../texts/TitleOverSubtitleWithImageButton';
import TitleOverPargraphs from '../../texts/TitleOverParagraphs/TitleOverParagraphs';
import { replaceBrStringWithRSlashN } from '../../../utills/functions/stringPhasers';
import LottieLoadingCircle from '../../view-only/LottieLoadingCircle/LottieLoadingCircle';
import useCachedImage from '../../../hooks/useCachedImage';

const LostModeDeviceDetailCard = ({ deviceId }: LostModeDeviceDetailCardPropsType) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        getLostModeState: {
            isLoading,
            data: {
                enabled,
                phone_number: phoneNumber,
                email,
                message,
                device_name: deviceName,
                deviceImageUrl
            },
            errors
        },
    } = useSelector((state: RootStateType) => state.lostMode);
    const imageSrc = useCachedImage(deviceImageUrl, '');

    useEffect(() => {
        dispatch(getLostModeByDeviceIdAction(deviceId));
    }, []);

    if (errors) {
        navigate("/not-found");
    }

    if (isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                sx={{height: "100%"}}
            >
                <LottieLoadingCircle />
            </Box>
        );
    }

    return (
    <div className="lost-mode-device-detail-card-container">
        <Stack>
            <div className="device-image-area">
                <div className="device-image-background">
                    <img className="device-image" src={imageSrc} alt="Device" />
                </div>
            </div>
            <div className="detail-header">
                <Typography variant="lostModeTitle" component="div">{deviceName}</Typography>
                <Typography variant='lostModeSubtitle'>{t('this_device_claimed_to_someone_else')}</Typography>
            </div>
            <Stack spacing="20px" marginTop="20px">
                {phoneNumber
                ? (
                    <TitleOverSubtitleWithImageButton
                        title={t("phone")}
                        subtitle={phoneNumber}
                        imageInfo={{ imageSrc: call_phone, alt: "call", type: "call", value: phoneNumber}} // Todo: api 작동하면 연동 필요
                    />)
                : null}
               <TitleOverSubtitleWithImageButton
                    title={t("email")}
                    subtitle={email}
                    imageInfo={{ imageSrc: emailIcon, alt: "email", type: "email", value: email}} // Todo: api 작동하면 연동 필요
                />
            </Stack>
            <Box marginTop="20px">
                <TitleOverPargraphs
                    title={t("information")}
                    paragraphList={[`${message}\n\n`, replaceBrStringWithRSlashN(t("device_is_lost_msg"))]}
                />
            </Box>
        </Stack>
        <Box marginBottom="2%">
            <Typography variant='lostModeFooter'>
                Secured by SmartThings Find
            </Typography>
        </Box>
   </div>
  )
}

export default LostModeDeviceDetailCard;
