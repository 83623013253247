import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import CustomZoomControl from './CustomZoomControl';
import DeviceMarkerImage from './DeviceMarkerImage';
import ReactDOM from 'react-dom/client'
import { findImageLinkByModelCode, getDeviceByDeviceId, isLocationInKorea, isValidPosition, getIsDeviceOnline } from '../../utills/functions';
import { setSelectedDevice } from '../../slices/deviceListSlice';
import DeviceClusterMarker from './DeviceClusterMarker';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import useKakaoMapTrackLocation from '../../hooks/useKakaoMapTrackLocation';
import { MODAL_TRACK_LOCATION_STATE } from '../../slices/modalTrackLocationSlice/modalTrackLocationSliceTypes';
import { BUDS_UNITS, DEVICE_TYPES } from '../../utills/enums/Devices';
import useDeviceOperations from '../../hooks/useDeviceOperations';
const { kakao } = window;


const DEFAULT_ZOOM_LEVEL_KAKAO = 4;

const MapWithKakao = ({ deviceListData: deviceListDataOfCurrentTab }) => {

    const dispatch = useDispatch();
    const { isMobileView } = useIsMobileView();
    const { isMobilePortraitView } = useIsMobileView();
    const widthOfDeviceControlPanelPC = 360; // PC View DeviceControlPanel Width (px) 
    // global state
    const deviceLocationList = useSelector((state) => state.deviceList.locationListData);
    const heightOfBottomSheet = useSelector((state) => state.map.heightOfBottomSheet);
    const mapCenter = useSelector((state) => state.map.mapCenter);
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const deviceImageListData = useSelector((state) => state.deviceList.deviceImageListData);
    const myDeviceListData = useSelector((state) => state.deviceList.myDeviceListData);
    const familyDeviceListData = useSelector((state) => state.deviceList.familyDeviceListData);
    const myItemListData = useSelector((state) => state.deviceList.myItemListData);
    const familyItemListData = useSelector((state) => state.deviceList.familyItemListData);
    const batteryListData = useSelector((state) => state.deviceList.batteryListData);
    /* Track Location state */
    const trackLocationInfo = useSelector((state) => state.modalTrackLocation.trackLocationInfo);
    const modalTrackLocationState = useSelector((state) => state.modalTrackLocation.modalTrackLocationState);
    const newLocationInfo = trackLocationInfo[selectedDevice?.deviceId]?.data;
    const trackLocationState = modalTrackLocationState[selectedDevice?.deviceId];

    const budsSelectedUnitList = useSelector(state => state.deviceList.budsSelectedUnitList);

    const mapDivRef = useRef(null);
    const mapInstanceRef = useRef(null);  // 추가: ref로 mapInstance를 저장
    const [markers, setMarkers] = useState([]); // 카카오맵 마커 정보를 담고있는 객체 배열 
    const clustererRef = useRef(null);

    const { renderTrackLocation, removeTrackLocation } = useKakaoMapTrackLocation();
    const { operationsResult, startOperation } = useDeviceOperations();

    // 컴포넌트가 마운트될 때 지도를 생성
    useEffect(() => {
        if (!kakao || !kakao.maps) {
            return;
        }
        const container = mapDivRef.current;
        mapInstanceRef.current = createMap(container);
        createClusterer(mapInstanceRef?.current);
    }, []);

    useEffect(() => {
        if (!kakao || !kakao.maps || !mapInstanceRef.current) {
            return;
        }
        if (trackLocationState === MODAL_TRACK_LOCATION_STATE.TRACKING) {
            // Tracking on 
            clearMarkersAndClusterer(); // 일반 device marker는 삭제     
            renderTrackLocation(mapInstanceRef.current, newLocationInfo, focusToMarker); // tracking location 그리기
        } else {
            removeTrackLocation();
            createClusterer(mapInstanceRef?.current);
            createMarkers(clustererRef.current);
        }

    }, [newLocationInfo, trackLocationState])

    // 지도 사이즈 변경시
    useEffect(() => {
        const dir = document.dir;
        if (isMobileView && dir === 'ltr') {
            mapInstanceRef.current?.setCopyrightPosition(kakao.maps.CopyrightPosition.BOTTOMRIGHT, true);
        } else {
            mapInstanceRef.current?.setCopyrightPosition(kakao.maps.CopyrightPosition.BOTTOMLEFT, false);  // false = 로고가 뒤로 가도록
        }
    });

    // deviceListData가 변경될 때마다 마커를 새로 생성
    useEffect(() => {
        if (!kakao || !kakao.maps) {
            return;
        }
        if (mapInstanceRef?.current && clustererRef?.current) {
            createMarkers(clustererRef.current);
        }
    }, [deviceLocationList,
        deviceListDataOfCurrentTab,
        batteryListData,
        budsSelectedUnitList,
        deviceImageListData,
        selectedDevice]);


    useEffect(() => {
        if (!kakao || !kakao.maps) {
            return;
        }
        if (isValidPosition(mapCenter)) {
            const kakaoMapPosition = new kakao.maps.LatLng(mapCenter.lat, mapCenter.lng);
            focusToMarker(kakaoMapPosition);
        }
    }, [mapCenter]);


    // 선택된 디바이스가 변경될때 해당하는 마커를 가장 상위로 위치시킵니다. 
    useEffect(() => {
        if (!kakao || !kakao.maps) {
            return;
        }
        if (selectedDevice) {
            setSelectedMarkerOnTop(selectedDevice.deviceId);
        }
    }, [selectedDevice, markers])



    const clearMarkersAndClusterer = () => {
        // markers가 배열인지 확인합니다.
        if (Array.isArray(markers)) {
            // 모든 마커를 지도에서 제거합니다.
            markers.forEach(marker => {
                if (marker && typeof marker.setMap === 'function') {
                    marker.setMap(null);
                }
            });
            // 마커 상태를 빈 배열로 초기화합니다.
            setMarkers([]);
        } else {
            console.error('markers가 배열이 아닙니다.');
        }
        // 클러스터러가 존재하고, clear 메서드가 유효한지 확인합니다.
        if (clustererRef.current && typeof clustererRef.current.clear === 'function') {
            clustererRef.current.clear();
        } else if (clustererRef.current) {
            console.error('clustererRef.current에 clear 메서드가 없습니다.');
        }
    };

    // 지도 생성 함수
    const createMap = (container) => {
        if (mapInstanceRef.current) return; // 이미 지도가 생성되어 있다면 함수를 종료
        const options = {
            center: new kakao.maps.LatLng(mapCenter.lat, mapCenter.lng), // ma center
            level: DEFAULT_ZOOM_LEVEL_KAKAO  // zoon level
        };
        const mapInstance = new kakao.maps.Map(container, options);

        mapInstance.setMaxLevel(12);        // 지도의 확대/축소 레벨 제한 설정
        return mapInstance
    }

    // clusterer 생성 함수 
    const createClusterer = (mapInstance) => {

        if (!mapInstance) {
            return;
        }

        // 클러스터러가 존재하고, clear 메서드가 유효한지 확인합니다.
        if (clustererRef.current && typeof clustererRef.current.clear === 'function') {
            clustererRef.current.clear();
        } else if (clustererRef.current) {
            console.error('clustererRef.current에 clear 메서드가 없습니다.');
        }

        clustererRef.current = new kakao.maps.MarkerClusterer({
            map: mapInstance, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
            disableClickZoom: true,
            averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
            minLevel: 1, // 클러스터 할 최소 지도 레벨 
        });

    }


    function clusteredHandler(clusters) {
        if (!mapInstanceRef.current) {
            return;
        }

        clusters.forEach(function (cluster) {
            const markers = cluster.getMarkers();
            const devicesAndUnits = markers.map(item => {
                return { deviceId: item.deviceId, units: item.units }
            });
            // 커스텀 오버레이용 DOM 요소 생성
            const deviceMarkerElement = document.createElement('div');
            // 클러스터 마커(Marker Group) 에 대한 React 루트 인스턴스 생성
            const markerRoot = ReactDOM.createRoot(deviceMarkerElement);
            markerRoot.render(
                <DeviceClusterMarker
                    devicesAndUnits={devicesAndUnits}
                    deviceListDataOfCurrentTab={[...myDeviceListData, ...familyDeviceListData, ...myItemListData, ...familyItemListData]}
                    deviceImageListData={deviceImageListData}
                    selectedDevice={selectedDevice}
                    operationsResult={operationsResult}
                    budsSelectedUnitList={budsSelectedUnitList}
                />
            );

            // 클러스터 마커(Marker Group) 에 대한 클릭 이벤트 리스너를 추가합니다.
            deviceMarkerElement.addEventListener('click', () => {
                if (isMobilePortraitView) {
                    mapInstanceRef.current?.setBounds(cluster.getBounds(), 0, 0, heightOfBottomSheet, 0);
                } else {
                    mapInstanceRef.current?.setBounds(cluster.getBounds(), 0, 0, 0, widthOfDeviceControlPanelPC);

                }
            });

            // 새로 생성한 클러스터 마커(Marker Group) 를 클러스터의 content로 지정합니다. 
            cluster.getClusterMarker().setContent(deviceMarkerElement);
        });
    }


    // 마커 생성 함수
    const createMarkers = (clusterer) => {
        if (!deviceLocationList) {
            markers.forEach(marker => marker.setMap(null));
            return;
        }

        // 기존 마커들을 지우기
        markers.forEach(marker => marker.setMap(null));
        clusterer.clear();
        kakao.maps.event.removeListener(clusterer, 'clustered', clusteredHandler);

        if (!Array.isArray(deviceListDataOfCurrentTab) || !Array.isArray(deviceLocationList)) {
            return; // 둘 중 하나라도 배열이 아니면 marker 생성 안 함 
        }

        // deviceListDataOfCurrentTab에서 현재 선택된 탭에 대한 deviceId만 추출
        const deviceIdsOfCurrentTab = deviceListDataOfCurrentTab.map(device => device?.deviceId).filter(id => id);

        // 한국 내의 위치에 대해서만 필터링합니다.
        const filteredLocations = deviceLocationList.filter(item => {
            // item이 유효하고, 현재 탭의 deviceId 목록에 해당하는지 확인
            if (item && deviceIdsOfCurrentTab.includes(item.deviceId)) {
                const { geolocations } = item;
                // geolocations 배열이 유효하고 요소가 있는지 확인
                if (Array.isArray(geolocations) && geolocations.length > 0) {
                    const { latitude, longitude } = geolocations[0];
                    // 위도와 경도가 유효한 숫자인지 확인
                    if (!isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude))) {
                        // 첫 번째 geolocation의 위도와 경도를 isLocationInKorea 함수에 전달
                        return isLocationInKorea(
                            parseFloat(latitude),
                            parseFloat(longitude)
                        );
                    }
                }
            }
            return false;
        });


        const newMarkers = [];

        filteredLocations.forEach(locationDataItem => {
            const { deviceId, geolocations } = locationDataItem;
            const device = getDeviceByDeviceId(deviceId, deviceListDataOfCurrentTab);
            const selectedUnitForBuds = budsSelectedUnitList?.find(item => item.deviceId === deviceId)?.selectedUnit;
            const isOnline = getIsDeviceOnline(device, operationsResult, selectedUnitForBuds)
            let locationsToProcess = [];
            if (device?.type === DEVICE_TYPES.BUDS) {
                const selectedUnitForBuds = budsSelectedUnitList?.find(item => item.deviceId === deviceId)?.selectedUnit;
                if (selectedUnitForBuds === BUDS_UNITS.BOTH) {
                    // device.type이 BUDS이고 Unit이 BOTH일 때, units가 L인 것만 사용
                    locationsToProcess = geolocations
                        .filter(geo => geo.units === BUDS_UNITS.LEFT) // BUDS_UNITS.LEFT와 일치하는 항목 필터링
                        .map(geo => ({
                            ...geo,
                            units: BUDS_UNITS.BOTH // 필터링된 항목의 units 속성을 BUDS_UNITS.BOTH로 변경
                        }));
                } else {
                    // device.type이 BUDS이고 Unit이 BOTH가 아닐 때, units가 L, R 모두 사용
                    locationsToProcess = geolocations;
                }
            } else {
                // device.type이 BUDS가 아닌 경우, 첫 번째 geolocation만 사용
                locationsToProcess.push(geolocations[0]);
            }

            locationsToProcess.forEach(geo => {
                const position = new kakao.maps.LatLng(
                    parseFloat(geo.latitude),
                    parseFloat(geo.longitude)
                );

                const device = getDeviceByDeviceId(deviceId, deviceListDataOfCurrentTab);
                const modelName = device?.modelInfo?.modelName;
                const deviceModelCode = device?.modelInfo?.deviceModelCode;
                const imageUrl = findImageLinkByModelCode(deviceImageListData, deviceModelCode ? deviceModelCode : modelName);



                // 커스텀 오버레이용 DOM 요소 생성
                const deviceMarkerElement = document.createElement('div');
                // 마커에 대한 React 루트 인스턴스 생성
                const markerRoot = ReactDOM.createRoot(deviceMarkerElement);
                markerRoot.render(<DeviceMarkerImage deviceImageUrl={imageUrl} deviceType={device?.type} isOnline={isOnline} unitsForBuds={geo.units} selectedUnitForBuds={selectedUnitForBuds} />);

                // 오버레이를 생성하기 전에 먼저 클릭 이벤트 리스너를 추가합니다.
                deviceMarkerElement.addEventListener('click', () => {
                    handleMarkerClick(deviceId);
                });
                // 커스텀 오버레이를 생성합니다.
                const overlay = new kakao.maps.CustomOverlay({
                    position: position,
                    content: deviceMarkerElement,
                    zIndex: 1
                });
                overlay.deviceId = deviceId;
                overlay.units = geo.units;
                overlay.markerRoot = markerRoot;

                newMarkers.push(overlay);
            });

        });

        setMarkers(newMarkers); // 마커 배열 상태 업데이트

        kakao.maps.event.addListener(clusterer, 'clustered', clusteredHandler);
        // 클러스터러에 마커들을 추가합니다
        clusterer.addMarkers(newMarkers);
    }

    // 선택된 디바이스에 해당하는 마커가 가장 상위에 보일 수 있도록 zIndex를 조정
    function setSelectedMarkerOnTop(deviceId) {

        console.log('setSelectedMarkerOnTop - deviceId, markers', deviceId, markers)
        if (deviceId && markers) {
            // 모든 마커의 zIndex를 초기화
            markers.forEach((marker) => {
                marker.setZIndex(1);
            });


            // 선택된 deviceId에 해당하는 마커를 찾아 zIndex를 변경
            const selectedMarker = markers.find(marker => marker.deviceId === deviceId);
            if (selectedMarker) {
                selectedMarker.setZIndex(99);
            }

            markers.forEach((marker, index) => {
                console.log(`Marker ${marker.deviceId} zIndex:`, marker.getZIndex());
            });

        }
    }

    /**
     * Focus the map on the selected marker
     */
    const focusToMarker = (position) => {
        if (position == null) {
            return;
        }

        if (mapInstanceRef.current) {
            const bounds = new kakao.maps.LatLngBounds();    // 현재 지도의 보이는 영역을 가져옵니다.
            bounds.extend(position);
            if (isMobilePortraitView) {
                mapInstanceRef.current.setBounds(bounds, 0, 0, heightOfBottomSheet, 0); // 모바일 뷰에서 y 축 방향으로 이동
            } else {
                mapInstanceRef.current.setBounds(bounds, 0, 0, 0, widthOfDeviceControlPanelPC); // PC 뷰에서 x 축 방향으로 이동
                // var proj = mapInstanceRef.current.getProjection();
                // var point = proj.containerPointFromCoords(position);
                // point.x -= widthOfDeviceControlPanelPC/2;
                // mapInstanceRef.current.setCenter(proj.coordsFromContainerPoint(point));
            }

        }
    };


    /**
    * Action when a marker is clicked
    */
    const handleMarkerClick = (deviceId) => {
        const selectedDevice = getDeviceByDeviceId(deviceId, deviceListDataOfCurrentTab);
        dispatch(setSelectedDevice(selectedDevice));
    };


    const handleZoomIn = () => {
        if (mapInstanceRef.current) {
            const level = mapInstanceRef.current.getLevel();
            mapInstanceRef.current.setLevel(level - 1);
        }
    }

    const handleZoomOut = () => {
        if (mapInstanceRef.current) {
            const level = mapInstanceRef.current.getLevel();
            mapInstanceRef.current.setLevel(level + 1);
        }
    }


    return (
        <>
            {/* TODO : isDeviceListLoading 대신 isLocationLoading으로 바꾸기 (Location 정보 받아오는 API완료 후)  */}

            <div
                ref={mapDivRef}
                style={{
                    width: '100vw',
                    height: '100vh'
                }}>
            </div>

            <CustomZoomControl
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
            />
        </>
    )
}

export default MapWithKakao;