import React, { createContext, useEffect, useRef, useState } from 'react';
import { HistoryFunctionContextProviderPropType, HistoryFunctionContextType } from './HistoryFunctionContextTypes';

export const HistoryFunctionContext =  createContext<HistoryFunctionContextType>({
  historyStack: [],
  // popFunctionObject: {},
  // setPopFunctionObject: undefined
});

export const HistoryFunctionContextProvider: React.FC<HistoryFunctionContextProviderPropType> = ({ children }) => {
  const historyStack = useRef<HistoryFunctionContextType['historyStack']>([]);
  useEffect(() => {
    window.history.pushState({}, "", window.location.href);
    window.onpopstate = (event) => {
      event.preventDefault();
      const message = event.state?.message;
      console.log("### On Pop State!")
      console.log(message)
      console.log(historyStack);
      const obj = historyStack.current.pop();
      const handleCloseFunction = obj?.handleClose;
      const modalMessage = obj?.message;
      console.log(modalMessage)
      if (handleCloseFunction) {
        handleCloseFunction();
      }
    }
  }, [])
  return (
    <HistoryFunctionContext.Provider value={{historyStack: historyStack.current}}>
      {children}
    </HistoryFunctionContext.Provider>
  )
}
