import React from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import './ModalLostStyles.scss';

import BaseModal from '../BaseModal';
import { Box, Typography } from '@mui/material';
import BulletPointWithTitleAndContents from '../../texts/BulletPointWithTitleAndContent';
import ModalBottomButton from '../../buttons/ModalBottomButton/ModalBottomButton';
import { DEVICE_TYPES } from '../../../utills/enums/Devices';
import { RootStateType } from '../../../slices/types';
import { setLostModeModalOpen, setLostModeModalSettingOpen } from '../../../slices/lostModeSlice';
import useHistoryStack from '../../../hooks/useIgnoreBackButton/useHistoryStack';


const ModalLost = () => {
    const { t } = useTranslation();
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const lostModeModalOpen = useSelector((state: RootStateType) => state.lostMode.lostModeModalOpen);
    const dispatch = useDispatch();
    const deviceType = selectedDevice?.type;
    const { popHistoryStack } = useHistoryStack({message: "ModalLostPop", open: false, handleClose: ()=>{}});

    const handleCancel = () => {
        dispatch(setLostModeModalOpen(false));
    }

    // Modal
    const handleLostSettingModalOpen = () => {
        handleCancel();
        popHistoryStack();
        dispatch(setLostModeModalSettingOpen(true));
    }

    const ContentBlock = (
        <>
            <Typography variant='modalParagraph'>
                {deviceType === DEVICE_TYPES.TAG
                    ? t('lost_mode_protect_tag')
                    : t('lost_mode_protect_buds')}
            </Typography>

            <div className='lostFuctionList'>
                <Box>
                    <BulletPointWithTitleAndContents
                        bulletPointBundle={{ type: 'dot' }}
                        title={t('emergency_contact_info')}
                        content={t('show_emergency_contact_info')}
                    />
                </Box>
                <Box>
                    <BulletPointWithTitleAndContents
                        bulletPointBundle={{ type: 'dot' }}
                        title={t('notify_me')}
                        content={t('receive_notification')} 
                        content_sec={t('turn_off_lost_mode')}
                    />
                </Box>
                <Box>
                    <BulletPointWithTitleAndContents
                        bulletPointBundle={{ type: 'dot' }}
                        title={t('pairing_lock')}
                        content={deviceType === DEVICE_TYPES.TAG
                            ? t('tag_locked')
                            : t('buds_locked')}
                    />
                </Box>
            </div>
        </>
    );

    const ActionBlock = (
        <>
            <ModalBottomButton title={t('next')} onClick={handleLostSettingModalOpen} />
        </>
    );

    return (
        <>
            <BaseModal
                open={lostModeModalOpen}
                onClose={handleCancel}
                title={t('lost_mode')}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                scroll='body'
            />
        </>
    );
};

export default ModalLost