import React from "react";
import mqtt from 'mqtt';
import { useDispatch } from 'react-redux';
import { getDeviceLocation, getTagLocation } from '../api/find/devicesApi/devicesApi';
import { decryptMqttMessage } from '../api/findToStf/devicesApi/devicesApi';
import { setOperationResponse } from '../slices/operationSlice/operationsSlice';
import mqttConnectionsManager from '../managers/MqttConnectionsManager';
import { upsertLocationListData, upsertBatteryListData } from '../slices/deviceListSlice';
import { OPERATION_TYPES } from '../utills/enums/commons';
import TimerManager from "../managers/TimerManager";

const useMqttConnections = () => {

    const dispatch = useDispatch();

    /**
     * Request location data for the deviceId 
     * with the geoloactions-report API 
     * And updsert the result in the redux state
     */
    const updateLocation = (device) => {
        if (!device) {
            return;
        }

        getDeviceLocation([{
            deviceId: device.deviceId,
            userId: device.userId
        }]).then(responseData => {
            dispatch(upsertLocationListData(responseData.items));
        }).catch(e => console.warn(e));

    }
    const updateBatteryInfo = (payload) => {
        if (!payload) {
            return;
        }
        
        // payload 구조 :  data{...}, deviceId, operationType
        dispatch(upsertBatteryListData(payload));
    }

    /**
     * Store the message received through MQTT 
     * in the state `operationsResponse` of operationsSlice.
     * And keep only the most recent responses (per deviceId and per operationType.)
     * 
     * @param {*} message 
     * @param {*} deviceId 
     */
    const setMessageAsOperationResponse = async (message, selectedDevice) => {
        try {
            if (!selectedDevice) {
                return;
            }
            const deviceId = selectedDevice.deviceId;
            const userId = selectedDevice.userId;
            const textDecoder = new TextDecoder('utf-8'); // Use utf-8 encoding
            const decodedString = textDecoder.decode(message);
            const decryptedMessage = await decryptMqttMessage(deviceId, userId, decodedString);

            if (decryptedMessage.operation && decryptedMessage.operation.length > 0) {

                const firstOperation = decryptedMessage.operation[0];
                const operationType = firstOperation?.oprnType;
                const payload = {
                    userId,
                    deviceId: deviceId,
                    operationType: operationType,
                    data: firstOperation
                }
                dispatch(setOperationResponse(payload));

                if (operationType === OPERATION_TYPES.LOCATION) {
                    updateLocation(selectedDevice);
                }

                if (operationType === OPERATION_TYPES.CHECK_CONNECTION) {
                    updateBatteryInfo(payload);
                }

                TimerManager.stopTimer(deviceId, operationType); // clear timer 

            } else {
                console.error('No operations found in MQTT message');
            }

        } catch (error) {
            console.error('Error handling MQTT message:', error);
        }
    }


    /**
     * Connect MQTT and register handlers for MQTT client
     * And it is managed per deviceId and per operationType.
     * 
     * @param {*} deviceId  Connect MQTT to each deviceId
     * @param {*} deviceDetail  MQTT connection data is in the detail object.
     * @returns 
     */
    const connectMqtt = (selectedDevice, deviceDetail) => {
        if (!selectedDevice || !deviceDetail) {
            return;
        }

        const deviceId = selectedDevice?.deviceId
        const { topic, mqttUrl, mqttWillTopic, mqttClientId, mquserName, mquserCode } = deviceDetail;

        // Check connectivity via MqttConnectionsManager(singleton)
        if (mqttConnectionsManager.getConnection(deviceId)) {
            return;
        }

        let client;

        if (mqttWillTopic) {
            client = mqtt.connect(`wss://${mqttUrl}/mqtt`, {
                clientId: mqttClientId,
                rejectUnauthorized: false,
                will: {
                    topic: mqttWillTopic,
                    payload: 'good-bye',
                    qos: 0,
                    retain: false,
                },
                username: mquserName,
                password: mquserCode,
            });
        }

        if (!client) {
            console.error('MQTT client creation failed.');
            return;
        }

        client.on('connect', () => {
            console.log('useMqttConnections.js - on connect for : ', deviceId);
            if (topic) {
                client.subscribe(topic);
            }
        });

        client.on('close', () => {
            console.log('Disconnected from MQTT Broker for : ', deviceId);
        });

        client.on('error', (error) => {
            console.error('Connection Error:', error);
        });


        client.on('message', async (topic, message) => {
            console.log('useMqttConnections.js - on message for :', deviceId);
            setMessageAsOperationResponse(message, selectedDevice);
        });

        // TODO : Create a Timer to terminate the connection if there is no message for a certain period of time

        // Update MQTT connection with MqttConnectionsManager
        mqttConnectionsManager.addConnection(deviceId, client);
    };

    return { connectMqtt };
};
export default useMqttConnections;
