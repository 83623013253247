import React from 'react'
import { styled } from '@mui/system';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

const RoundedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(() => {
    const { isMobileView } = useIsMobileView();
    return ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#FCFCFF',
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.30);',
            left: isMobileView ? undefined : "-100px",
            color: 'black',
            maxWidth: '330px',
            borderRadius: "30px"
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#FCFCFF',
            left: isMobileView ? undefined : "100px !important",
            fontSize: 20
        }})
    });

export default RoundedTooltip;