/**
 * batteryGauge.js
 *
 * This file defines custom BatteryGauge component.
 * These settings control the visual appearance of the gauge.
 *
 * For more detailed configuration or additional properties,
 * refer to the official documentation of the react-battery-gauge library.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BUDS_UNITS } from '../../../utills/enums/Devices';

const CustomBatteryGauge = ({ batteryLevelL, batteryLevelR }) => {
    const { t } = useTranslation();

    // global state
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const selectedUnitForBuds = useSelector(state =>
        state.deviceList.budsSelectedUnitList?.find(detail => detail.deviceId === selectedDevice?.deviceId)?.selectedUnit
    );

    const getBatteryText = (batteryLevelL, batteryLevelR) => {

        // 선택된 방향별 battery 용량 표시 text
        if (selectedUnitForBuds === BUDS_UNITS.RIGHT) {
            if (!batteryLevelR) return t("could_not_get_battery_info");
            else if (batteryLevelR <= 10) return <span style={{ color: '#ff0000' }}> {`R ${batteryLevelR} %`} </span>;
            else return `R ${batteryLevelR} %`;
        } else if (selectedUnitForBuds === BUDS_UNITS.LEFT) {
            if (!batteryLevelL) return t("could_not_get_battery_info");
            else if (batteryLevelL <= 10) return <span style={{ color: '#ff0000' }}> {`L ${batteryLevelL} %`} </span>;
            else return `L ${batteryLevelL} %`;
        } else if (selectedUnitForBuds === BUDS_UNITS.BOTH) {
            // 버즈. 배터리가 10% 이하일 때   [ L • R 5% ]
            if (batteryLevelL <= 10 || batteryLevelR <= 10) return <span style={{ color: '#ff0000' }}> {`L • R ${batteryLevelL} %`} </span>;
            // L/R의 배터리가 15% 이상차이  [ L 100% • R 80% ]
            if (Math.abs(batteryLevelL - batteryLevelR) >= 15) return `L ${batteryLevelL} % • R ${batteryLevelR} %`;
            // L/R의 배터리가 15% 이하 차이 날 경우   [ L • R 80% ]
            if (Math.abs(batteryLevelL - batteryLevelR) <= 15) return `L • R ${batteryLevelL} %`;
        } else {
            return t("could_not_get_battery_info");
        }

    }

    const batteryText = getBatteryText(batteryLevelL, batteryLevelR);

    return (
        <>
            {batteryText === t("could_not_get_battery_info") ?
                <div className="battery-unknown-text-buds">
                    <p title={batteryText}> {batteryText} </p>
                </div>
                :
                <div className="battery-level-text-buds">
                    <p className="battery-title"> {t('battery')} </p>
                    <p className="battery-level" title={batteryText}> {batteryText} </p>
                </div>
            }
        </>
    );
}

export default CustomBatteryGauge;