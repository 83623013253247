import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const ConfirmPWRedirect = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.search.includes('state=')) {
            const urlParams = new URLSearchParams(location.search);
            const state = urlParams.get('state');
            window.opener.postMessage({
                state,
            });
        }
        window.close();
    }, [location.search])
    
    return (
        <div>Confirming Password...</div>
    )
}

export default ConfirmPWRedirect;