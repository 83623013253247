import i18n from "../../assets/locale/i18n";
import { TIME_FORMAT, TIME_FORMAT_TYPE } from "../enums/timeFormats";
const languagesWithYYYYMMDD = ['ko', 'ja', 'zh', 'hu'];


export const getCurrentTimeFormatted = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear();
  const hour = now.getHours().toString().padStart(2, '0');;
  const minute = now.getMinutes().toString().padStart(2, '0');;

  const currentLanguageCode = i18n.language?.substring(0, 2);
  if (languagesWithYYYYMMDD.includes(currentLanguageCode)) {
    return `${year}/${month}/${day} ${hour}:${minute}`;
  } else {
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }
}

export const formatDate = (date: Date) => {
  console.log(date)
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear() + 1;
  console.log(day);
  const dayString = (day < 10) ? '0' + day : day.toString();
  const monthString = (month < 10) ? '0' + month : month.toString();
  return monthString + '/' + dayString + '/' + year;
}

export const convertUTCtoBrowserTime = ({ utcString, type }: { utcString: string, type: TIME_FORMAT_TYPE }) => {
  // yyyy-mm-ddThh:mm:ss 형식으로 변환
  const formattedUtcString = `${utcString.slice(0, 4)}-${utcString.slice(4, 6)}-${utcString.slice(6, 8)}T${utcString.slice(8, 10)}:${utcString.slice(10, 12)}:${utcString.slice(12, 14)}.000Z`;
  const date = new Date(formattedUtcString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const currentLanguageCode = i18n.language?.substring(0, 2);

  if (type === TIME_FORMAT.YEAR_MONTH_DAY || languagesWithYYYYMMDD.includes(currentLanguageCode)) {
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  } else if (type === TIME_FORMAT.DAY_MONTH_YEAR) {
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  } else {
    return `${month}/${day}/${year} ${hours}:${minutes}`;
  }
}

/**
 * 입력: "2024-01-05 22:51:11"
 */
export const parseDateStringToDateObject = (dateString: string): Date | null => {
  const parts = dateString.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);

  if (parts === null || parts.length < 7) {
    return null;
  }

  return new Date(Date.UTC(
    parseInt(parts[1], 10),
    parseInt(parts[2], 10) - 1,
    parseInt(parts[3], 10),
    parseInt(parts[4], 10),
    parseInt(parts[5], 10),
    parseInt(parts[6], 10))
  );
}

/**
 *  입력: 20231206135418
 *  출력: Date
 * */
export const transformUTCStringToDate = (utcString: string) => {
  // 문자열을 구성 요소로 분해
  const year = parseInt(utcString.substring(0, 4), 10);
  const month = parseInt(utcString.substring(4, 6), 10) - 1; // 월은 0부터 시작
  const day = parseInt(utcString.substring(6, 8), 10);
  const hour = parseInt(utcString.substring(8, 10), 10);
  const minute = parseInt(utcString.substring(10, 12), 10);
  const second = parseInt(utcString.substring(12, 14), 10);

  // Date.UTC를 사용하여 UTC 시간으로 Date 객체 생성
  const date = new Date(Date.UTC(year, month, day, hour, minute, second));
  return date;
}


/**
 *  입력: 20231206135418
 *  출력: 05/04/2024 20:35
 * */
export const transformDateToDoneTimeString = (utcString: string): string | null => {
  const dateObject = transformUTCStringToDate(utcString);
  // dateObject가 Date 객체가 아니거나, 유효하지 않은 날짜인 경우 null 반환
  if (!(dateObject instanceof Date) || isNaN(dateObject.getTime())) {
    return null;
  }
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const hour = dateObject.getHours().toString().padStart(2, '0');
  const minute = dateObject.getMinutes().toString().padStart(2, '0');

  const currentLanguageCode = i18n.language?.substring(0, 2);
  if (languagesWithYYYYMMDD.includes(currentLanguageCode)) {
    return `${year}/${month}/${day} ${hour}:${minute}`;
  } else {
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }
};