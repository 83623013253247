
/**
 * // 사용 예시
 * const timerManager = new TimerManager();
 * 
 * timerManager.startTimer(deviceId, operationType, () => {
 *   dispatch(setOperationResponse({ deviceId, operationType, data: null })); // cancel loading
 * });
 * 
 * timerManager.stopTimer(deviceId, operationType);

 */
class TimerManager {
    constructor() {
        if (TimerManager.instance) {
            return TimerManager.instance;
        }
        this.timers = {};
        TimerManager.instance = this;
    }


    startTimer(deviceId, operationType, callback, timeout = 120000) {
        if (!deviceId || !operationType || typeof callback !== 'function') {
            console.error('Invalid arguments for startTimer');
            return;
        }

        this.timers[deviceId] = this.timers[deviceId] || {};
        
        if (this.timers[deviceId][operationType]) {
            clearTimeout(this.timers[deviceId][operationType]);
        }

        this.timers[deviceId][operationType] = setTimeout(() => {
            try {
               callback();
            } catch (e) {
                console.error('Error in timer callback:', e);
            }
            delete this.timers[deviceId][operationType];
        }, timeout);

    }


    stopTimer(deviceId, operationType) {
        if (!this.timers[deviceId] || !this.timers[deviceId][operationType]) {
            console.warn('No timer to stop for', deviceId, operationType);
            return;
        }

        clearTimeout(this.timers[deviceId][operationType]);
        delete this.timers[deviceId][operationType];
    }
}

export default new TimerManager();