import React from 'react';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { orange_ring } from '../../../assets/images/deviceCard';
import './LoadingCircleInOrangeRingStyles.scss';

const LoadingCircleInOrangeRing = () => {
  return (
    <div className='loading-circle-in-orange-ring-container'>
        <img src={orange_ring} alt={orange_ring} />
        <div className='loading-circle-container'>
            <LoadingCircle />
        </div>
    </div>
  )
}

export default LoadingCircleInOrangeRing;