import React from 'react'
import { ButtonsWithMarginTopLayoutProps } from './ButtonsWithMarginTopLayoutTypes';
import { Divider } from '@mui/material';
import './ButtonsWithMarginTopLayoutStyles.scss'

const ButtonsWithMarginTopLayout = ({ firstButton, secondButton }: ButtonsWithMarginTopLayoutProps) => {
  return (
    <div className='button-with-margin-top-layout-container'>
        {firstButton}
        {secondButton ? (
            <>
                <div className='divider-container'>
                    <Divider orientation='vertical'/>
                </div>
                {secondButton}
            </>
        ): null}
    </div>
  )
}

export default ButtonsWithMarginTopLayout;