import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { performOperationErase, performOperationEraseCancel } from '../../api/findToStf/devicesApi/devicesApi';
import { ModalEraseActionEnum } from '../../components/modal/ModalErase/ModalEraseTypes';
import { ModalEraseStateType } from "./modalEraseTypes";


const initialState: ModalEraseStateType = {
    open: false,
    cancelEraseCardOpen: false,
    actionType: ModalEraseActionEnum.erase,
    operationErase: {
        isLoading: false,
        data: null,
        error: null
    },
    operationEraseCancel: {
        isLoading: false,
        data: null,
        error: null
    },
}

export const asyncPerformOperationErase = createAsyncThunk<any, {userId: string, deviceId: string}>(
    'modalErase/asyncPerformOperationErase',
    async ({ userId, deviceId }) => {
        const response = await performOperationErase(deviceId, userId);
        return response;
    }
);

export const asyncPerformOperationEraseCancel = createAsyncThunk<any, {userId: string, deviceId: string}>(
    'modalErase/asyncPerformOperationEraseCancel',
    async ({ userId, deviceId }) => {
        const response = await performOperationEraseCancel(deviceId, userId);
        return response;
    }
);

export const modalEraseSlice = createSlice({
    name: "modalErase",
    initialState,
    reducers: {
        setEraseModalOpen: (state, action) => {
            state.open = action.payload;
        },
        setActionType: (state, action) => {
            state.actionType = action.payload;
        },
        setCancelEraseCardOpen: (state, action) => {
            state.cancelEraseCardOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        // asyncPerformOperationErase
        builder.addCase(asyncPerformOperationErase.pending, (state, action) => {
            state.operationErase.isLoading = true;
        })
        builder.addCase(asyncPerformOperationErase.fulfilled, (state, action) => {
            state.operationErase.data = action.payload;
            if (action.payload.resultCode === "01") {
                state.actionType = ModalEraseActionEnum.error;
            }
        })
        builder.addCase(asyncPerformOperationErase.rejected, (state, action) => {
            state.operationErase.isLoading = false;
            state.operationErase.error = action.payload;
            state.actionType = ModalEraseActionEnum.error;
        })
        // asyncPerformOperationEraseCancel
        builder.addCase(asyncPerformOperationEraseCancel.pending, (state, action) => {
            state.operationEraseCancel.isLoading = true;
            state.cancelEraseCardOpen = false;
        })
        builder.addCase(asyncPerformOperationEraseCancel.fulfilled, (state, action) => {
            state.operationEraseCancel.isLoading = false;
            state.operationEraseCancel.data = action.payload;
            if (action.payload.resultCode === "01") {
                state.actionType = ModalEraseActionEnum.error;
            }
        })
        builder.addCase(asyncPerformOperationEraseCancel.rejected, (state, action) => {
            state.operationEraseCancel.isLoading = false;
            state.operationEraseCancel.error = action.payload;
            state.actionType = ModalEraseActionEnum.error;
        })
    }
});

export const {
    setEraseModalOpen,
    setActionType,
    setCancelEraseCardOpen
} = modalEraseSlice.actions;

export default modalEraseSlice.reducer;