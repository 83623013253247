import africa from './africa.json';
import america from './america.json';
import asiaPacific from './asiaPacific.json';
import europe from './europe.json';


export { africa, america, asiaPacific, europe };

const combined = [
    ...africa,
    ...america,
    ...asiaPacific,
    ...europe
]

export default combined;