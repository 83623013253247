import React from 'react'
import { TitleOverParagraphsPropsType } from './TitleOverParagraphsTypes';
import { Typography } from '@mui/material';

const TitleOverPargraphs = ({ title, paragraphList, options }: TitleOverParagraphsPropsType) => {
  return (
    <div className="title-over-paragraphs-container">
      <Typography
        fontSize={options?.title?.fontSize ?? "13px"}
        color={options?.title?.color ?? "#8C8C91"}
        fontWeight="600"
      >
        {title}
      </Typography>
      {paragraphList.map((p) => (
        <Typography
          fontSize={options?.paragraph?.fontSize ?? "14px"}
          color={options?.paragraph?.color ?? "black"}
          whiteSpace="pre-wrap"
        >
          {p}
        </Typography>
      ))}
    </div>
  )
}

export default TitleOverPargraphs;