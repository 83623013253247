import React from 'react'
import { useTranslation } from "react-i18next";

import { Button, Typography } from '@mui/material';
import BaseModal from '../BaseModal';
import { ModalLocationProps } from './ModalLocationInfoTypes';
import ActionButton from '../../buttons/ActionButton';
import ButtonsWithMarginTopLayout from '../../layouts/ButtonsWithMarginTopLayout';


const ModalLocationInfo = ({ open, onClose }: ModalLocationProps) => {
    const { t } = useTranslation();

    const handleCancel = () => {
        onClose(false);
    }

    const handleAgree = () => {
        onClose(true);
    }

    const ContentBlock = (
        <Typography variant='modalParagraph'>
           {t('withdraw_consent')} 
        </Typography>
    );

    const ActionBlock = (
        <>
            <ButtonsWithMarginTopLayout
                firstButton={<ActionButton title={t('cancel')} onClick={handleCancel} />}
                secondButton={<ActionButton title={t('agree')} onClick={handleAgree} />}
            />
        </>
    );

    return (
        <>
            <BaseModal
                open={open}
                onClose={handleCancel}
                contentBlock={ContentBlock}
                actionBlock={ActionBlock}
                disableX
            />
        </>
    );
};

export default ModalLocationInfo
