import { createSlice } from "@reduxjs/toolkit";

import { DEVICE_OWNER_TYPES, TAB_TYPES } from "../../utills/enums/Devices";
import { DEVICE_TYPES } from "../../utills/enums/Devices";
import { asyncRemoveDevice, asyncUpsertDeviceDetail } from "./thunks";


const initialState = {
    activeTabType: TAB_TYPES.DEVICES, // devices탭인지 items탭인지 선택 
    deviceOwnerType: DEVICE_OWNER_TYPES.MY, // my devices or family device인지 선택

    // selected device
    selectedDevice: null, // 디바이스 목록 및 맵에서 현재 선택된 디바이스

    // selected detail card
    isDeviceDetailOpen: false,

    // selected settings
    selectedSettings: false,  // settings menu

    // device list 
    isDeviceListLoading: false, // 전체 디바이스 목록이 로딩 중인지 여부
    myDeviceListData: [], // 전체 디바이스 (my) 목록 (위치정보, 디테일정보는 별도)
    familyDeviceListData: [], // 전체 패밀리 디바이스 목록 (위치정보, 디테일정보는 별도)
    myItemListData: [], // 내 아이템 디바이스 목록 (위치정보, 디테일정보는 별도)
    familyItemListData: [], // 가족기기 태그 디바이스 목록

    // location list
    isMyDeviceLocationLoading: false, // 디바이스 위치 정보 로딩 중인지 여부 
    locationListData: [], // 전체 디바이스 위치 정보 목록

    // address list
    addressListData: [],

    // detail list
    isDeviceDetailLoading: false, // 디바이스 디테일 로딩 중 여부 
    detailListData: [], // 전체 디바이스의 디테일 정보 목록
    budsSelectedUnitList: [], // deviceId별 선택된 unit을 저장합니다.
    // device image list
    deviceImageListData: {},

    // device battery (Online/ offline)
    batteryListData: [],

    removeDevice: {
        isLoading: false,
        data: null,
        error: null
    },

    upsertDeviceDetail: {
        isLoading: false,
        error: null
    }
}

export const deviceListSlice = createSlice({
    name: "deviceList",
    initialState,
    reducers: {
        setActiveTabType: (state, action) => {
            state.activeTabType = action.payload;
        },
        setDeviceOwnerType: (state, action) => {
            state.deviceOwnerType = action.payload;
        },
        setSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload;
        },
        setIsDeviceDetailOpen: (state, action) => {
            state.isDeviceDetailOpen = action.payload;
        },
        setSelectedSettings: (state, action) => {
            state.selectedSettings = action.payload;
        },
        setRemovedDeviceIsLoading: (state, action) => {
            state.removeDevice.isLoading = action.payload;
        },
        upsertDeviceDetail: (state, action) => {
            const currentDetail = {
                deviceId: action.payload.deviceId,
                ...action.payload.detail,
            }
            const detailIndex = state.detailListData.findIndex(detail => detail.deviceId === currentDetail.deviceId);
            // deviceId가 일치하는 항목이 이미 있다면 해당 항목을 업데이트합니다.
            if (detailIndex !== -1) {
                // 상태의 불변성을 유지하기 위해 새로운 detailListData 배열을 생성합니다.
                const newDetailListData = [...state.detailListData];
                // 해당 인덱스에 있는 항목을 업데이트합니다.
                newDetailListData[detailIndex] = currentDetail;
                // 새로운 detailListData 배열과 함께 전체 상태를 변경합니다.
                state.detailListData = newDetailListData;
            } else {
                // deviceId가 일치하는 항목이 없다면 새로운 항목을 배열에 추가합니다.
                state.detailListData = [...state.detailListData, currentDetail];
            }
        },
        // ex) dispatch(updateSelectedUnitForBuds({ deviceId, selectedUnit }));
        updateSelectedUnitForBuds: (state, action) => {
            const { deviceId, selectedUnit } = action.payload;
            const detailIndex = state.budsSelectedUnitList.findIndex(detail => detail.deviceId === deviceId);
            if (detailIndex !== -1) {
                // deviceId에 해당하는 항목이 있으면 selectedUnit 필드를 업데이트
                state.budsSelectedUnitList[detailIndex] = {
                    ...state.budsSelectedUnitList[detailIndex],
                    selectedUnit: selectedUnit, // "L", "R", ... 
                };
            } else {
                // deviceId에 해당하는 항목이 없으면 새로운 항목을 추가 (이 경우 필요에 따라 다른 필드도 함께 추가해야 할 수 있음)
                state.budsSelectedUnitList.push({ deviceId, selectedUnit });
            }
        },
        setIsDetailLoading: (state, action) => {
            state.isDeviceDetailLoading = action.payload;
        },
        setMyDeviceListData: (state, action) => {
            state.myDeviceListData = action.payload;
        },
        setMyItemListData: (state, action) => {
            state.myItemListData = action.payload;
        },
        setFamilyItemListData: (state, action) => {
            state.familyItemListData = action.payload;
        },
        setFamilyDeviceListData: (state, action) => {
            state.familyDeviceListData = action.payload;
        },
        setIsDeviceListLoading: (state, action) => {
            state.isDeviceListLoading = action.payload;
        },
        setIsMyDeviceLocationLoading: (state, action) => {
            state.isMyDeviceLocationLoading = action.payload;
        },
        upsertLocationListData: (state, action) => {
            const newLocationDataList = action.payload;
            const upsertedLocationListData = [...state.locationListData]; // 기존 데이터를 복사합니다.
            newLocationDataList.forEach(newLocationData => {
                // 기존 state.locationListData에 deviceId가 동일한 것이 있는지 확인합니다.
                const existingLocationIndex = state.locationListData.findIndex(currentLocationData => currentLocationData.deviceId === newLocationData.deviceId);
                if (existingLocationIndex !== -1) { // 일치하는 deviceId가 있는 경우 새 데이터로 업데이트 합니다. 
                    upsertedLocationListData[existingLocationIndex] = newLocationData;
                } else { // 일치하는 deviceId가 없는 경우 새 데이터를 추가
                    upsertedLocationListData.push(newLocationData);
                }
            });
            state.locationListData = upsertedLocationListData;
        },
        upsertDeviceAddress: (state, action) => {
            const addressIndex = state.addressListData.findIndex(item => item.deviceId === action.payload.deviceId);
            if (addressIndex !== -1) {
                // 기존 항목을 업데이트합니다.
                const newList = [...state.addressListData];
                newList[addressIndex] = action.payload;
                state.addressListData = newList;
            } else {
                // 새로운 항목을 추가합니다.
                state.addressListData = [
                    ...state.addressListData,
                    action.payload,
                ];
            }
        },
        setAddressList: (state, action) => {
            state.addressListData = action.payload;
        },
        setDeviceImageListData: (state, action) => {
            // Payload에서 전달받은 리스트
            state.deviceImageListData = { // 새로 로드한 images 객체를 Upsert 합니다. 
                ...state.deviceImageListData,
                ...action.payload
            };
        },
        upsertBatteryListData: (state, action) => {
            const newBatteryData = action.payload;
            const upsertedBatteryListData = [...state.batteryListData];

            const existIndex = state.batteryListData.findIndex(currentBatteryData => currentBatteryData.deviceId === newBatteryData.deviceId);
            // 일치하면 없데이트, 없으면 추가
            if (existIndex !== -1) {
                upsertedBatteryListData[existIndex] = newBatteryData;
            } else {
                upsertedBatteryListData.push(newBatteryData);
            }

            state.batteryListData = upsertedBatteryListData;
        },
        upsertMyTagDisplayName(state, action) {
            const { deviceId, displayName } = action.payload;
            if (!deviceId || !displayName) {
                console.warn('Invalid deviceId or label');
                return;
            }
            const existingItem = state.myItemListData.find(item => item.deviceId === deviceId);
            if (existingItem) {
                if (!existingItem.modelInfo) {
                    existingItem.modelInfo = {};
                }
                existingItem.modelInfo.displayName = displayName;
                existingItem.modelInfo.deviceModelCode = deviceId;
            } else {
                state.myItemListData.push({
                    type: DEVICE_TYPES.TAG,
                    deviceId: deviceId,
                    modelInfo: {
                        displayName: displayName,
                        deviceModelCode: deviceId
                    }
                });
            }
        },
        upsertFamilyTagDisplayName(state, action) {
            const { deviceId, displayName } = action.payload;
            if (!deviceId || !displayName) {
                console.warn('Invalid deviceId or label');
                return;
            }
            const existingItem = state.familyItemListData.find(item => item.deviceId === deviceId);
            if (existingItem) {
                if (!existingItem.modelInfo) {
                    existingItem.modelInfo = {};
                }
                existingItem.modelInfo.displayName = displayName;
                existingItem.modelInfo.deviceModelCode = deviceId;
            } else {
                state.familyItemListData.push({
                    type: DEVICE_TYPES.TAG,
                    deviceId: deviceId,
                    modelInfo: {
                        displayName: displayName,
                        deviceModelCode: deviceId
                    }
                });
            }
        },
        upsertTagImage(state, action) {
            const { deviceId, imageUrl } = action.payload;
            state.deviceImageListData = { // 새로 로드한 images 객체를 Upsert 합니다. 
                ...state.deviceImageListData,
                [deviceId]: imageUrl
            };
        },
        deleteOperationStatusFromDeviceDetail(state, action) {
            const { deviceId, operationType } = action.payload;
            const deviceDetail = state.detailListData
                .find(item => item.deviceId === deviceId);
            const filteredList = state.detailListData
                .filter(item => item.deviceId !== deviceId);
            if (deviceDetail) {
                const operation = deviceDetail
                    .operation.filter(oprn => oprn.oprnType !== operationType);

                const deletedDeviceDetail = {
                    ...deviceDetail,
                    operation
                };

                state.detailListData = [
                    ...filteredList,
                ].concat([deletedDeviceDetail]);
            }

        }
    },
    extraReducers: (builder) => {
        // asyncRemoveDevice
        builder.addCase(asyncRemoveDevice.pending, (state, action) => {
            state.removeDevice.isLoading = true;
        })
        builder.addCase(asyncRemoveDevice.fulfilled, (state, action) => {
            const { deviceId } = action.payload;
            state.removeDevice.data = action.payload;
            // 내 디바이스 리스트에서 일치하는 디바이스 삭제 
            state.myDeviceListData = state.myDeviceListData
                .filter((item) => item.deviceId !== deviceId);
            // 패밀리 디바이스 리스트에서 일치하는 디바이스 삭제 
            state.familyDeviceListData = state.familyDeviceListData
                .filter((item) => item.deviceId !== deviceId);
            // 내 디바이스 탭으로 초기화
            state.deviceOwnerType = DEVICE_OWNER_TYPES.MY; 
            state.removeDevice.isLoading = false;
        })
        builder.addCase(asyncRemoveDevice.rejected, (state, action) => {
            state.removeDevice.isLoading = false;
            state.removeDevice.error = action.error;
        })
        // asyncUpdateDeviceDetail
        builder.addCase(asyncUpsertDeviceDetail.pending, (state, action) => {
            state.upsertDeviceDetail.isLoading = true;
        })
        builder.addCase(asyncUpsertDeviceDetail.fulfilled, (state, action) => {
            const { deviceId, response } = action.payload;
            const upsertedList = state.detailListData
                .filter(item => item.deviceId !== deviceId)
                .concat([{ ...response, deviceId }])
            state.detailListData = upsertedList;
        })
        builder.addCase(asyncUpsertDeviceDetail.rejected, (state, action) => {
            state.upsertDeviceDetail.error = action.error;
        })

    }
});

export const {
    setActiveTabType,
    setDeviceOwnerType,
    setSelectedDevice,
    setIsDeviceDetailOpen,
    setSelectedSettings,
    setRemovedDeviceIsLoading,
    upsertDeviceDetail,
    updateSelectedUnitForBuds,
    setIsDetailLoading,
    setMyDeviceListData,
    setMyItemListData,
    setFamilyItemListData,
    setFamilyDeviceListData,
    setIsDeviceListLoading,
    setIsMyDeviceLocationLoading,
    upsertLocationListData,
    upsertDeviceAddress,
    setAddressList,
    setDeviceImageListData,
    upsertBatteryListData,
    upsertMyTagDisplayName,
    upsertFamilyTagDisplayName,
    upsertTagImage,
    deleteOperationStatusFromDeviceDetail
} = deviceListSlice.actions;

export default deviceListSlice.reducer;