import { PayloadAction } from '@reduxjs/toolkit';
import { OPERATION_TYPES_TYPE } from '../../utills/enums/commons';

export type OperationCommonDataType = {
    devicePhysicalAddress: string,
    oprnCrtDate: string,
    oprnStsCd: string,
    oprnResultCode: string,
    oprnDoneDate: OPERATION_TYPES_TYPE,
    oprnType: string,
    reqId: string
    sessionId: string
}

export type OperationLocationDataType = OperationCommonDataType & {
    extra: {
        gpsUtcDt: string,
    }
    latitude: string
    longitude: string
    horizontalUncertainty: string
    verticalUncertainty: string
    locationType: string
}

export type OperationCheckConnectionData = OperationCommonDataType & {
    extra: {
        lockStatus: {
            fmmLock: boolean,
            normalLock: boolean 
        },
        left: {
            battery: string
        },
        right: {
            battery: string
        }
    },
    remoteControl: 'Y' | 'N'
    battery: string
}

export type OperationWipeData = OperationCommonDataType & { }; // Todo: 채우기

export type OperationLockData = OperationCommonDataType & { }; // Todo: 채우기

export type OperationRingData = OperationCommonDataType & { }; // Todo: 채우기

export type OperationTrackLocationData = OperationCommonDataType & { };

export type OperationDataType = OperationLocationDataType | OperationCheckConnectionData | OperationWipeData | OperationRingData | OperationTrackLocationData;


export type OperationCommonFieldType<T extends OperationDataType> = {
    isLoading: boolean,
    data: T,
    isTimeout: boolean,
    isFailed: boolean,
}

export type OperationsResultType = {
    [x: string]: {
        [y in OPERATION_TYPES_TYPE]?: OperationCommonFieldType<OperationDataType> | {}
    }
};

export type OperationsStateType = {
    operationsResult: OperationsResultType;
};

export type OperationPayloadType = PayloadAction<{
    deviceId: string,
    operationType: OPERATION_TYPES_TYPE
}>;

export type OperationResponseType = {
    deviceId: string,
    userId?: string,
    operationType: OPERATION_TYPES_TYPE
    data?: OperationDataType | null
    isTimeout?: boolean,
    isFailed?: boolean,
};

export type OperationResponsePayloadType = PayloadAction<OperationResponseType>;

export const OPERATIONS = "OPERATIONS";
export type OPERATIONS_TYPE = typeof OPERATIONS;

export const SET_OPERATION_RESPONSE_ACTION = `${OPERATIONS}/setOperationResponse`;
export type SET_OPERATION_RESPONSE_ACTION_TYPE = typeof SET_OPERATION_RESPONSE_ACTION;