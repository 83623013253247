import { v4 as uuidv4 } from 'uuid';
import { GetTrackLocationsResponseType } from "../../api/findToStf/devicesApi/deviceApiTypes";
import i18n from "../../assets/locale/i18n";
import { getAddress } from "../functions";
import { transformUTCStringToDate } from "./time";
import { TrackLocationInfo } from '../../slices/modalTrackLocationSlice/modalTrackLocationSliceTypes';

export const getTrackLocationTime = async (data: GetTrackLocationsResponseType['item']['locationInfo'], currentData: Array<TrackLocationInfo>) => {
    if (!data) {
        return [];
    }
    const newDataList = currentData.length === 0 ? data : data.slice(0, data.length - currentData.length);

    const currentLanguage = i18n?.language;
    const selectedLanguage = (typeof currentLanguage === 'string' && currentLanguage.length >= 2)
    ? currentLanguage.substring(0, 2)
    : 'en';
    let result = [];
    for (const { latitude, longitude, oprnCrtDate  } of newDataList) {
        const address = await getAddress(parseFloat(latitude), parseFloat(longitude), selectedLanguage);
        const date = transformUTCStringToDate(oprnCrtDate);
        result.push({address, date, latitude, longitude, uuid: uuidv4()});
    }
    return [...result, ...currentData];
}