import React from 'react';
import { Button, Typography } from '@mui/material';
import { BaseButtonProps } from './BaseButtonTypes';
import LottieLoadingCircle from '../../view-only/LottieLoadingCircle/LottieLoadingCircle';

const BaseButton = ({ title, typographyVariant, isLoading, loadingColor, ...props }: BaseButtonProps) => {
  return (
    <>
        <Button className="base-button" fullWidth {...props}>
            {isLoading ? (
              <LottieLoadingCircle color={loadingColor} size='s' />
            ) : (
              <Typography variant={typographyVariant}>
                {title}
              </Typography>
            )}
        </Button>
    </>
  )
}

export default BaseButton;