import { useSelector } from 'react-redux';

const useDeviceType = () => {
    const selectedDevice = useSelector((state: any) => state.deviceList.selectedDevice);
    const deviceType = selectedDevice?.type;
    const deviceSubType = selectedDevice?.modelInfo?.deviceSubType;

    return { deviceType, deviceSubType };
}

export default useDeviceType;