import { AxiosResponse } from "axios";
import { GetNotifyMeRequestType, GetNotifyMeResponseType, PutNotifyMeRequestType, PutNotifyMeResponseType, TagPutNotifyMeRequestType, TagPutNotifyMeResponseType } from "./notifyMeTypes";
import axiosInstance from "../findAxiosConfig";

export const getNotifyMe = async ({deviceId}: GetNotifyMeRequestType): Promise<GetNotifyMeResponseType> => {
    try {
        const response: AxiosResponse<GetNotifyMeResponseType> = await axiosInstance.get(`devices/${deviceId}/notifications/found`);
        return response.data;
    } catch (error) {
        console.error('Error while requesting location found info:', error);
        throw error;
    }
};

export const putNotifyMe = async ({
    deviceId,
    item
}: PutNotifyMeRequestType): Promise<PutNotifyMeResponseType> => {
    try {
        const response: AxiosResponse<PutNotifyMeResponseType> = await axiosInstance.put(`devices/${deviceId}/notifications/found`, {
            item
        });
        return response.data;
    } catch (error) {
        console.error('Error while putting location found info:', error);
        throw error;
    }
}

export const putTagNotifyMe = async ({
    deviceId,
    searchingStatus,
    email,
    language,
    displayName
}: TagPutNotifyMeRequestType): Promise<TagPutNotifyMeResponseType> => {
    try {
        const response: AxiosResponse<PutNotifyMeResponseType> = await axiosInstance.put(`/tag/devices/${deviceId}/searchingstatus`, {
            searchingStatus,
            email,
            language,
            displayName
        });
        return response.data;
    } catch (error) {
        console.error('Error while putting location found info:', error);
        throw error;
    }
}
