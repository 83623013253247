import React from 'react';
import './DeviceNotSupported.styles.scss';
import { getSelectedLanguage } from '../../../utills/auth';

const DeviceNotSupported = () => {
  if (getSelectedLanguage() === 'ko') {
    return (
      <div className='device-not-supported-container'>
        <p>
          현재 이 브라우저는 지원되지 않습니다.
          <br />
          <br />
          최적의 경험을 위해 &nbsp;
          <a href="https://www.google.com/chrome/">Google Chrome</a> 또는&nbsp;
          <a href="https://www.microsoft.com/edge/">Microsoft Edge</a> 브라우저 사용을 권장합니다.
        </p>
      </div>
    )
  } 
  return (
    <div className='device-not-supported-container'>
      <p>
        This browser is currently unsupported.
        <br />
        <br />
        For an optimal experience, we kindly recommend utilizing&nbsp;
        <a href="https://www.google.com/chrome/">Google Chrome</a> or&nbsp;
        <a href="https://www.microsoft.com/edge/">Microsoft Edge</a> browsers.
      </p>
    </div>
  )
}

export default DeviceNotSupported;