import { TextField } from '@mui/material';
import React from 'react'
import { PasswordTextFieldPropsType } from './PasswordTextFieldTypes';

const PasswordTextField = ({ onChange, value, placeholder, error, errorMessage }: PasswordTextFieldPropsType) => {
	return (
		<div>
			<TextField
				value={value}
				placeholder={placeholder}
				fullWidth
				error={error}
				helperText={errorMessage}
				FormHelperTextProps={{ style: { top: '28px' } }}
				variant='standard'
				type="password"
				onChange={onChange}
				inputRef={(input) => {
					if (input != null) {
						input.focus();
					}
				}}
			/>
		</div>
	)
}

export default PasswordTextField;