/**
 * batteryGauge.js
 *
 * This file defines custom BatteryGauge component.
 * These settings control the visual appearance of the gauge.
 *
 * For more detailed configuration or additional properties,
 * refer to the official documentation of the react-battery-gauge library.
 */
import React from 'react';
import { battery1_very_low, battery2_low, battery3_sufficient, battery4_full } from '../../../assets/images/deviceCard';
import { useTranslation } from 'react-i18next';

const CustomBatteryGauge = ({ batteryLevel }) => {
    const { t } = useTranslation();

    const getBatteryIcon = (batteryLevel) => {
        if (batteryLevel === null || batteryLevel === undefined) {
            return battery1_very_low;
        }

        const numericValue = parseInt(batteryLevel, 10); // 10진수로 변환

        if (!isNaN(numericValue)) { // 배터리값이 숫자일 경우 
            if (batteryLevel >= 0 && batteryLevel <= 5) return battery1_very_low;
            if (batteryLevel >= 6 && batteryLevel <= 15) return battery2_low;
            if (batteryLevel >= 16 && batteryLevel <= 90) return battery3_sufficient;
            if (batteryLevel >= 91 && batteryLevel <= 100) return battery4_full;
            return battery4_full
        } else { // 배터리 값이 텍스트일 경우 
            switch (batteryLevel.toUpperCase()) {
                case 'FULL':
                    return battery4_full;
                case 'MEDIUM':
                    return battery3_sufficient;
                case 'LOW':
                    return battery2_low;
                default:
                    return battery1_very_low;
            }
        }

    };

    const getBatteryText = (batteryLevel) => {
        if (!batteryLevel) {
            return t("could_not_get_battery_info");
        }

        const numericValue = parseInt(batteryLevel, 10); // 10진수로 변환
        if (!isNaN(numericValue)) {  // 숫자일 때
            return `${batteryLevel} %`;
        } else { // 텍스트일 때 
            switch (batteryLevel.toUpperCase()) {
                case 'FULL':
                    return t('full');
                case 'MEDIUM':
                    return t('sufficient');
                case 'LOW':
                    return t('low');
                default:
                    return t('very_low');
            }
        }
    }

    const batteryIcon = getBatteryIcon(batteryLevel);
    const batteryText = getBatteryText(batteryLevel);

    return (
        <>
            {batteryLevel ?
                <div className="battery-level-text">
                    <p> {t('battery')} </p>
                    <p title={batteryText} > {batteryText} </p>
                    <img src={batteryIcon} alt='Battery' />
                </div>
                :
                <div className="battery-unknown-text">
                    <p title={batteryText}> {batteryText} </p>
                    <img src={batteryIcon} alt='Battery' />
                </div>
            }
        </>
    );
}

export default CustomBatteryGauge;