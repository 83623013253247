import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    ToggleButton, ToggleButtonGroup,
    Card, Menu, MenuItem, Box, Tabs, Tab, List, IconButton, ListSubheader
} from '@mui/material';
import { setting_more } from "../../assets/images/deviceCard";

import NoDevicesPlaceholder from './NoDevicesPlaceholder';
import DeviceListItem from './DeviceListItem';
import { getUserId, signOutAndRedirectToHome } from '../../utills/auth';
import { getAddressByDeviceId } from '../../utills/functions';
import { DEVICE_OWNER_TYPES, TAB_TYPES } from "../../utills/enums/Devices";
import {
    setActiveTabType,
    setDeviceOwnerType,
    setSelectedSettings
} from '../../slices/deviceListSlice';
import LoadingCircle from '../view-only/LoadingCircle';
import { t } from 'i18next';
import { setErasePersonalDataCardOpen } from '../../slices/settingSlice';
import DeviceSkeletonList from '../view-only/DeviceSkeletonList';


const DeviceListCard = ({ deviceListData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    dayjs.extend(customParseFormat); // dayjs에 customParseFormat 플러그인을 확장합니다.

    // date
    var advancedFormat = require('dayjs/plugin/advancedFormat');
    dayjs.extend(advancedFormat);

    // global states
    const familyDeviceListData = useSelector((state) => state.deviceList.familyDeviceListData);
    const addressListData = useSelector((state) => state.deviceList.addressListData);
    const isDeviceListLoading = useSelector((state) => state.deviceList.isDeviceListLoading);
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const activeTabType = useSelector((state) => state.deviceList.activeTabType);
    const isActiveTabTypeItem = activeTabType === TAB_TYPES.ITEMS ? true : false;
    const deviceOwnerType = useSelector((state) => state.deviceList.deviceOwnerType);
    const selectedSettings = useSelector((state) => state.deviceList.selectedSettings);
    const familyItemListData = useSelector((state) => state.deviceList.familyItemListData);

    // local states
    const [anchorEl, setAnchorEl] = useState(null); // More Button : Settings , Log out
    const open = Boolean(anchorEl);


    // Handler - My, Family Button
    const handleChangeOwnerType = (event, changeValue) => {
        if (changeValue !== null) {  // 현재 선택된 버튼 재선택시, 선택 해제되지 않도록 방지함.
            dispatch(setDeviceOwnerType(changeValue))
            // 여기에서,  list를 changeValue(my, family)를 이용해서 변경해주기.
        }
    }

    // Handler - More menu button
    const handleMoreButton = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleMoreButtonClose = () => {
        setAnchorEl(null);
    }

    // Handler - Devices, Items Tab 
    const handleTabChange = (event, newValue) => {
        dispatch(setActiveTabType(newValue));
    };

    // Handler - More menu, click 'Settings'
    const handleClickSettings = () => {
        dispatch(setSelectedSettings(true));
    }

    /**
     * 메뉴에서 로그아웃 버튼 클릭 시 
     * @returns 
     */
    const handleClickLogOut = () => {
        setAnchorEl(null);
        signOutAndRedirectToHome();
    }


    const checkIsVisible = () => {
        if (selectedDevice || selectedSettings) {
            return false;
        }

        if (activeTabType === TAB_TYPES.DEVICES) {
            if (!Array.isArray(familyDeviceListData) || familyDeviceListData.length <= 0) {
                return false;
            }
        }

        if (activeTabType === TAB_TYPES.ITEMS) {
            if (!Array.isArray(familyItemListData) || familyItemListData.length <= 0) {
                return false;
            }
        }
        return true;
    }



    return (
        <div className="device-list-card">

            {/* My , Family 구분 버튼 */}
            <div className="home-toggle-group" style={{ visibility: checkIsVisible() ? 'visible' : 'hidden' }}>
                <ToggleButtonGroup exclusive value={deviceOwnerType} onChange={handleChangeOwnerType} >
                    <ToggleButton className="home-toggle-button" variant="contained" value={DEVICE_OWNER_TYPES.MY} aria-label="my">{isActiveTabTypeItem ? t('my_items') : t('my_devices')}</ToggleButton>
                    <ToggleButton className="home-toggle-button" variant="contained" value={DEVICE_OWNER_TYPES.FAMILY} aria-label="family">{isActiveTabTypeItem ? t('family_items') : t('family_devices')}</ToggleButton>
                </ToggleButtonGroup>
            </div>

            {/* Devices or Items 목록 */}
            <Card className="outer-card">
                <div className="list-header">
                    {activeTabType === TAB_TYPES.DEVICES ? t('devices') : t('items')}
                    <IconButton onClick={handleMoreButton}>
                        <img src={setting_more} alt=" " />
                    </IconButton>
                </div>

                {isDeviceListLoading
                    ? <DeviceSkeletonList count={5} />
                    : <Card className="inner-card">
                        <DeviceListGroup
                            deviceListData={deviceListData}
                            addressListData={addressListData}
                        />
                    </Card>
                }

                {/* Devices , Items Tab 선택 버튼 */}
                <div className="list-footer">
                    <Box className="tabs-common">
                        <Tabs value={activeTabType} onChange={handleTabChange}>
                            <Tab disableRipple label={t('devices')} value={TAB_TYPES.DEVICES} />
                            <Tab disableRipple label={t('items')} value={TAB_TYPES.ITEMS} />
                        </Tabs>
                    </Box>
                </div>
            </Card>

            {/* more menu */}
            <Menu className='device-list-card-menu' anchorEl={anchorEl} open={open} onClick={handleMoreButtonClose}>
                <MenuItem onClick={handleClickSettings}>
                    <span className='more-menulist-text'> {t('settings')} </span>
                </MenuItem>
                <MenuItem onClick={handleClickLogOut}>
                    <span className='more-menulist-text'> {t('sign_out')} </span>
                </MenuItem>
            </Menu>
        </div >
    )
}

const DeviceListGroup = ({ deviceListData, addressListData }) => {
    const getGroupedByUserId = (data) => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return {};
        }
        return data.reduce((acc, device) => {
            if (device && device.userId) {
                if (!acc[device.userId]) {
                    acc[device.userId] = {
                        userName: device.userName || null, // userName이 없는 경우 null로 설정
                        devices: []
                    };
                }
                acc[device.userId].devices.push(device);
            }
            return acc;
        }, {});
    };

    const groupedByUserId = getGroupedByUserId(deviceListData);
    if (!Array.isArray(deviceListData) || deviceListData.length === 0) {
        return <NoDevicesPlaceholder />;
    }
    return (
        <List>
            {Object.entries(groupedByUserId).map(([userId, { userName, devices }]) => (
                <li key={userId}>
                    <ul>
                        {getUserId() && userId !== getUserId() && <ListSubheader className='device-list-subheader'>{`${userName}'s device`}</ListSubheader>}
                        {devices.map(device => {
                            const { address = t('location_unknown'), timeCreated = null } = getAddressByDeviceId(device.deviceId, addressListData) || {};
                            return (
                                <DeviceListItem
                                    key={device.deviceId}
                                    device={device}
                                    address={address}
                                    updatedTime={timeCreated}
                                />
                            );
                        })}
                    </ul>
                </li>
            ))}
        </List>
    );
};

DeviceListCard.propTypes = {
    handleClickMenuItem: PropTypes.func,
    deviceListData: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            deviceId: PropTypes.string,
            hashId: PropTypes.string,
            modelInfo: PropTypes.shape({
                modelName: PropTypes.string,
                displayName: PropTypes.string,
                brandName: PropTypes.string
            })
        }))
}


export default DeviceListCard
