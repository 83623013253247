import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest, all, delay, takeEvery, select } from "typed-redux-saga";
import { STOP_OPERATION_TRACK_LOCATION_ACTION, POST_OPERATION_TRACK_LOCATION_ACTION, GET_TRACK_LOCATION_ACTION, MODAL_TRACK_LOCATION_STATE, SET_MODAL_TRACK_LOCATION_OPEN_ACTION  } from "./modalTrackLocationSliceTypes";
import { getDeviceDetails, getTrackLocations, performOperationTrackLocation, stopOperationTrackLocation } from "../../api/findToStf/devicesApi/devicesApi";
import { OperationRequestType } from "../../api/findToStf/devicesApi/deviceApiTypes";
import { clearGetTrackLocationAction, clearTrackLocationInfoAction, getTrackLocationErrorAction, getTrackLocationSuccessAction, postOperationTrackLocationErrorAction, postOperationTrackLocationSuccessAction, setModalTrackLocationState, setTrackLocationInfoAction, stopOperationTrackLocationErrorAction, stopOperationTrackLocationSuccessAction } from "./modalTrackLocationSlice";
import { OperationResponseType, OperationTrackLocationData, SET_OPERATION_RESPONSE_ACTION } from "../operationSlice/operationsSliceTypes";
import { OPERATION_TYPES } from "../../utills/enums/commons";
import { RootStateType } from "../types";
import { DM_ERROR_RESULT_CODE, DM_ERROR_STATUS_CODE, DM_ERROR_STATUS_CODE_PROCESSING, DM_ERROR_STATUS_CODE_SUCCESS } from "../../utills/enums/DMErrorCodes";
import { hasDataProperty } from "../operationSlice/operationSliceTypeGuard";
import { isTrackingOperationEnabledAndGetRequestIdFromDeviceList } from "../../utills/functions/deviceFunctions";
import { upsertDeviceDetail } from "../deviceListSlice";
import { getTrackLocationTime } from "../../utills/functions/asyncFunctions";


function* postOperationTrackLocationSaga({ payload }: PayloadAction<OperationRequestType>) {
  try {
    const response = yield* call(performOperationTrackLocation, payload);
    yield put(postOperationTrackLocationSuccessAction(response));
  } catch (error) {
    yield put(postOperationTrackLocationErrorAction(error));
  }
}

function* stopOperationTrackLocationSaga({ payload }: PayloadAction<OperationRequestType>) {
  try {
    const response = yield* call(stopOperationTrackLocation, payload);
    yield put(stopOperationTrackLocationSuccessAction(response));
  } catch (error) {
    yield put(stopOperationTrackLocationErrorAction(error));
  }
}

function* getTrackLocationSaga(params: { deviceId: string, requestId: string, userId: string }) {
  try {
    while(true) {
      const response = yield* call(getTrackLocations, params);
      if (!response.locationInfo) {
        console.warn("Track Location Api Saga Warnning: No location Info!")
        continue;
      }
      yield* put(getTrackLocationSuccessAction({ locationInfo: response.locationInfo, deviceId: params.deviceId}));
      const modalTrackLocationInfo = yield* select((state: RootStateType) => state.modalTrackLocation.trackLocationInfo);
      const trackLocationInfo = yield* call(getTrackLocationTime, response.locationInfo, modalTrackLocationInfo[params.deviceId]?.data ?? []);
      yield* put(setTrackLocationInfoAction({ trackLocationInfo, deviceId: params.deviceId }));
      yield* delay(36000);
    }
  } catch (errors) {
    yield put(getTrackLocationErrorAction({errors, deviceId: params.deviceId}));
  }
}

function* upsertDeviceDetailSaga(params: {deviceId: string}) {
  const response = yield* call(getDeviceDetails, params.deviceId);
  yield put(upsertDeviceDetail({ deviceId: params.deviceId, detail: response }));
}

function* getOperationsSaga({ payload: { operationType, data, deviceId, userId, isTimeout } }: PayloadAction<OperationResponseType>) {
  try {
    const operationResult = yield* select((state: RootStateType) => state.operations.operationsResult);
    // timeout 시
    if (operationType === OPERATION_TYPES.TRACK_LOCATION_START && isTimeout) {
      yield put(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.TIMEOUT, deviceId }));
    } else if (operationType === OPERATION_TYPES.TRACK_LOCATION_START && userId) {
      const trackLocationStartData = operationResult[deviceId]?.[OPERATION_TYPES.TRACK_LOCATION_START];
      if (hasDataProperty<OperationTrackLocationData>(trackLocationStartData)) {
        const oprnStsCd = trackLocationStartData.data.oprnStsCd;
        const oprnResultCode = trackLocationStartData.data.oprnResultCode;

        // FMM이 off상태일때
        if (oprnStsCd === DM_ERROR_STATUS_CODE.OPERATION_FAIL
          && oprnResultCode === DM_ERROR_RESULT_CODE.DISABED_REMOTE_CONTROL) {
          yield put(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.FMM_OFF, deviceId}));
        }

        // Track Location Stop을 요청해서 응답을 받았을 때
        if (
          oprnResultCode === DM_ERROR_RESULT_CODE.TRACKING_STOPPED_BY_ALERT
          && oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS
        ) {
          // Detail 정보 업데이트 -- 이것을 하지 않으면 다시 modal을 띄울 때 바로 추적 중 상태가 되어버림
          yield* call(upsertDeviceDetailSaga, { deviceId })
          yield put(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.STOPPED, deviceId}));
          // 불필요한 정보 clear
          yield put(clearGetTrackLocationAction({ deviceId }));
          yield put(clearTrackLocationInfoAction({ deviceId }));
        }

        // Track Location Start 후 tracking 중이며 기기가 최초로 location을 서버로 올렸을 때
        if (
          oprnResultCode === DM_ERROR_RESULT_CODE.ALERT_BY_API && oprnStsCd === DM_ERROR_STATUS_CODE_PROCESSING.OPERATION_PROCESSING
        ) {
          yield* call(getTrackLocationSaga, { deviceId, requestId: data!.reqId, userId });
          yield* call(upsertDeviceDetailSaga, { deviceId });
        } else if (oprnResultCode === DM_ERROR_RESULT_CODE.OPERATION_SUCCESS && oprnStsCd === DM_ERROR_STATUS_CODE.OPERATION_SUCCESS)
        {
          yield put(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.TRACKING, deviceId }));
        }
      }
    } else if (operationType === OPERATION_TYPES.TRACK_LOCATION_STOP && userId) {
      const trackLocationStopData = operationResult[deviceId]?.[OPERATION_TYPES.TRACK_LOCATION_STOP];
      if (hasDataProperty<OperationTrackLocationData>(trackLocationStopData)) {
        const oprnStsCd = trackLocationStopData.data.oprnStsCd;
        const oprnResultCode = trackLocationStopData.data.oprnResultCode;
        // Track Location Stop을 요청해서 응답을 받았을 때 2
        if (
          oprnResultCode === DM_ERROR_RESULT_CODE.OPERATION_SUCCESS
          && oprnStsCd === DM_ERROR_STATUS_CODE_SUCCESS.OPERATION_SUCCESS
        ) {
          // Detail 정보 업데이트 -- 이것을 하지 않으면 다시 modal을 띄울 때 바로 추적 중 상태가 되어버림
          yield* call(upsertDeviceDetailSaga, { deviceId })
          yield put(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.STOPPED, deviceId}));
          // 불필요한 정보 clear
          yield put(clearGetTrackLocationAction({ deviceId }));
          yield put(clearTrackLocationInfoAction({ deviceId }));
        }
      }
    }
  } catch (error) {
    console.error("GetOperationsSaga Error!");
  }
}

function* restartTrackingLocationOperationSaga({payload}: PayloadAction<any>) {
  try {
    const detailList = yield* select((state: RootStateType) => state.deviceList);
    const detailListData = yield* select((state: RootStateType) => state.deviceList.detailListData);
    const deviceId = (detailList!.selectedDevice as any)?.deviceId;
    const userId = (detailList!.selectedDevice as any)?.userId;
    const [isTracking, requestId] = isTrackingOperationEnabledAndGetRequestIdFromDeviceList(detailListData, deviceId);
    if (isTracking && requestId) {
      yield put(setModalTrackLocationState({ modalState: MODAL_TRACK_LOCATION_STATE.TRACKING, deviceId }));
      yield* call(getTrackLocationSaga, { deviceId, requestId, userId });
    }
  } catch (error) {

  }
}

export default function* watchModalTrackLocationSaga() {
  yield all([
    takeLatest(POST_OPERATION_TRACK_LOCATION_ACTION, postOperationTrackLocationSaga),
    takeLatest(STOP_OPERATION_TRACK_LOCATION_ACTION, stopOperationTrackLocationSaga),
    takeLatest(SET_MODAL_TRACK_LOCATION_OPEN_ACTION, restartTrackingLocationOperationSaga),
    takeEvery(SET_OPERATION_RESPONSE_ACTION, getOperationsSaga),
  ]);
}
