import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Box, Container, Stack, Typography } from '@mui/material';
import appIcon from '../../assets/images/common/app icon.svg';
import ActionButton from '../../components/buttons/ActionButton';


const Timeout = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Container>
            <Stack sx={{ height: "100vh", alignItems: "center", justifyContent: "center" }} gap="70px">
                <Box sx={{ width: "150px" }}>
                    <img src={appIcon} alt="App Icon" />
                </Box>
                <Box sx={{ maxWidth: "400px" }}>
                    <Typography display="block" variant='timeoutParagraph'> {t('you_were_away_30')} </Typography>
                </Box>
                <Box sx={{ width: "160px" }}>
                    <ActionButton
                        backgroundColor="lightgray"
                        title={'Samsung Find'}
                        onClick={() => { navigate('/') }}
                    />
                </Box>
            </Stack>
        </Container>
    )
}

export default Timeout;