export type ModalLockProps = {
    open: boolean
    onClose(): void 
    passedValue: any
};

type StringBooleanType = "Y" | "N" | "false";

export type ModalLockLocalStateType = {
    title: string,
    statusTitle: string,
    message: string,
    image: string,
    lastLockTime: string,
    buttonText: string,
    buttonDisabled: boolean,
    screenLock: StringBooleanType,
    payLock: StringBooleanType,
    passLock: StringBooleanType,
    lockPowerOff: StringBooleanType,
    lockTitleClassName: string
}

export type LocalActionType = {
    payload?: any;
    type: string;
}

export const MODAL_LOCK_ACTION = {
    initial: 'initial',
    loading: 'loading',
    waiting: 'waiting',
    locked: 'locked',
    alreadyLocked: 'alreadylocked',
    retry: 'retry',
    buttonActivation: 'buttonActivation'
} as const;

export type ModalLockActionType = typeof MODAL_LOCK_ACTION[keyof typeof MODAL_LOCK_ACTION];

export type LockSettingInfoType = {
    pin: string,
    scope: string,
    extensionNumber: string,
    phoneNumber: string,
    msgText: string
}