import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import ReactDOM from 'react-dom/client';
import '../MapStyles.scss';


import { useDispatch, useSelector } from 'react-redux';
import { findImageLinkByModelCode, getDeviceByDeviceId, isValidPosition, getBatteryLevelFromOper, getIsDeviceOnline } from '../../../utills/functions';
import CustomZoomControl from '../CustomZoomControl';
import DeviceMarkerImage from '../DeviceMarkerImage';
import DeviceClusterMarker from '../DeviceClusterMarker';
import { setSelectedDevice } from '../../../slices/deviceListSlice';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import blueDot from '../../../assets/images/trackLocation/location_blue_dot.svg';
import useGoogleMapTrackLocation from '../../../hooks/useGoogleMapTrackLocationPolyLine/useGoogleMapTrackLocationPolyLine';
import useCurrentDeviceInfo from '../../../hooks/useCurrentDeviceInfo/useCurrentDeviceInfo';
import { MODAL_TRACK_LOCATION_STATE } from '../../../slices/modalTrackLocationSlice/modalTrackLocationSliceTypes';
import { BUDS_UNITS, DEVICE_TYPES } from '../../../utills/enums/Devices';
import useDeviceOperations from '../../../hooks/useDeviceOperations';


export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID;

const MapWithGoogle = ({ deviceListData: deviceListDataOfCurrentTab }) => {
    const { isMobilePortraitView } = useIsMobileView();
    const { renderTrackLocation, removeTrackLocation, trackLocationMarkerList } = useGoogleMapTrackLocation();
    const dispatch = useDispatch();
    const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);
    const deviceInfo = useCurrentDeviceInfo();

    // global state
    const heightOfBottomSheet = useSelector((state) => state.map.heightOfBottomSheet);
    const mapCenter = useSelector((state) => state.map.mapCenter);

    const deviceLocationList = useSelector((state) => state.deviceList.locationListData);
    const deviceImageListData = useSelector((state) => state.deviceList.deviceImageListData);
    const selectedDevice = useSelector((state) => state.deviceList.selectedDevice);
    const batteryListData = useSelector((state) => state.deviceList.batteryListData);
    const trackLocationInfo = useSelector((state) => state.modalTrackLocation.trackLocationInfo);
    const modalTrackLocationState = useSelector((state) => state.modalTrackLocation.modalTrackLocationState);
    const budsSelectedUnitList = useSelector(state => state.deviceList.budsSelectedUnitList);

    const [zIndexesOfMarkers, setZIndexesOfMarkers] = useState({}); // 각 마커의 zIndex 상태를 관리하기 위한 상태
    const { operationsResult, startOperation } = useDeviceOperations();

    const widthOfDeviceControlPanelPC = 360; // PC View DeviceControlPanel Width (px) 

    const mapDivRef = useRef(null); // googlemap을 그릴 <div> 컴포넌트를 저장하는 ref 
    let mapRef = useRef(null); // map 저장하는 ref 
    let markersRef = useRef([]); // 마커를 저장하는 ref
    let clustererRef = useRef(null); // 클러스터를 저장하는 ref
    let googleMaps = window?.google?.maps;

    useEffect(() => {
        initGoogleMap(mapDivRef)
    }, []);

    const newLocationInfo = trackLocationInfo[selectedDevice?.deviceId]?.data;
    const trackLocationState = modalTrackLocationState[selectedDevice?.deviceId];

    useEffect(() => {
        if (googleMaps && newLocationInfo && trackLocationState === MODAL_TRACK_LOCATION_STATE.TRACKING && newLocationInfo?.length > 0) {
            removeMarkers();
            removeClusterers();
            renderTrackLocation({ map: mapRef.current, points: newLocationInfo, focusToMarker, deviceInfo });
        }
    }, [isGoogleMapLoaded, googleMaps, deviceInfo, trackLocationState, newLocationInfo]);


    useEffect(() => {
        if (isValidPosition(mapCenter)) {
            focusToMarker(mapCenter);
        }

    }, [mapRef, mapCenter]);


    useEffect(() => {
        // Todo: 추적기능이 켜져있으면 여기 실행안되게 막기, 모달에 시간 클릭하면 포커스 및 모달끄기 추적기능 끄면 다시 실행되게 만들기
        if ((trackLocationState === MODAL_TRACK_LOCATION_STATE.TRACKING && newLocationInfo?.length === 0)
            || trackLocationState !== MODAL_TRACK_LOCATION_STATE.TRACKING) {
            removeTrackLocation()
            updateMarkersAndClusterer(mapRef.current);
        }
    }, [deviceListDataOfCurrentTab, deviceImageListData, deviceLocationList, selectedDevice, trackLocationState, newLocationInfo, batteryListData, budsSelectedUnitList])


    useEffect(() => {
        updateZindexOfMarkers()
    }, [selectedDevice]);

    const handleZoomIn = () => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            const currentZoom = mapInstance.getZoom();
            mapInstance.setZoom(currentZoom + 1);
        }
    };

    const handleZoomOut = () => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            const currentZoom = mapInstance.getZoom();
            mapInstance.setZoom(currentZoom - 1);
        }
    };



    /**
     * Load javascript Googlemaps API and init markers, clusterer
     * @param {*} mapDivRef 
     * @returns 
     */
    async function initGoogleMap(mapDivRef) {
        if (!mapDivRef || !mapDivRef.current) {
            return;
        }

        const googleMapLoader = new Loader({
            apiKey: GOOGLE_MAPS_API_KEY,
            version: 'beta',
        });

        googleMapLoader.load().then(async () => {
            googleMaps = window?.google?.maps;

            if (!googleMaps) {
                return;
            }

            if (!googleMaps.marker) {// load additional library to use `window.google.marker..`
                await googleMaps.importLibrary("marker");
            }

            const map = creatGoogleMap(mapDivRef);
            mapRef.current = map;
            const markers = createMarkers(map);
            markersRef.current = markers;
            const clusterer = createClusterer(map, markers);
            clustererRef.current = clusterer;
            setIsGoogleMapLoaded(true);
        });
    }


    function creatGoogleMap(mapDivRef) {
        if (!googleMaps) {
            return;
        }

        if (!mapDivRef || !mapDivRef.current) {
            return;
        }


        const map = new googleMaps.Map(mapDivRef.current, {
            center: mapCenter,
            zoom: 12,
            scaleControl: true,
            disableDefaultUI: true,
            gestureHandling: 'greedy',  // MAP 이동에 대한 안내문구 발생 방지용
            mapId: GOOGLE_MAP_ID
        });

        return map;
    }

    /**
     * Action when a marker is clicked
     */
    const handleMarkerClick = (deviceId) => {
        const selectedDevice = getDeviceByDeviceId(deviceId, deviceListDataOfCurrentTab);
        dispatch(setSelectedDevice(selectedDevice));
    };

    const updateZindexOfMarkers = () => {
        if (!Array.isArray(deviceListDataOfCurrentTab) || deviceListDataOfCurrentTab.length <= 0) {
            return;
        }

        let deviceId = deviceListDataOfCurrentTab[0].deviceId;
        // let deviceId;
        if (selectedDevice) {
            deviceId = selectedDevice?.deviceId;
        }

        // 모든 마커의 zIndex를 초기화하고, 선택된 마커만 높은 zIndex를 부여합니다.
        const updatedZIndexes = deviceLocationList.reduce((acc, item) => {
            acc[item.deviceId] = item.deviceId === deviceId ? 99 : 1; // 선택된 마커는 99, 나머지는 1
            return acc;
        }, {});
        setZIndexesOfMarkers(updatedZIndexes);
    }

    function createMarkers(map) {
        if (!googleMaps || !map) {
            return;
        }

        if (!googleMaps.marker) {
            return;
        }

        if (!Array.isArray(deviceListDataOfCurrentTab) || !Array.isArray(deviceLocationList)) {
            return null;
        }

        // deviceListDataOfCurrentTab에서 deviceId만 추출
        const validDeviceIds = deviceListDataOfCurrentTab.map(device => device?.deviceId).filter(id => id);
        const filteredLocations = deviceLocationList
            .filter(({ deviceId, geolocations }) => {
                if (!validDeviceIds.includes(deviceId)) {
                    return false;
                }

                if (!Array.isArray(geolocations) || geolocations.length <= 0) {
                    return false;
                }

                const { latitude, longitude } = geolocations[0];
                const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

                if (!isValidPosition(position)) {
                    return false;
                }

                return true;
            });

        const newMarkers = [];

        filteredLocations.map((locationDataItem) => {
            const { deviceId, geolocations } = locationDataItem;

            const device = getDeviceByDeviceId(deviceId, deviceListDataOfCurrentTab);
            const selectedUnitForBuds = budsSelectedUnitList?.find(item => item.deviceId === deviceId)?.selectedUnit;
            const isOnline = getIsDeviceOnline(device, operationsResult, selectedUnitForBuds)

            let locationsToProcess = [];
            if (device?.type === DEVICE_TYPES.BUDS) {
                const selectedUnitForBuds = budsSelectedUnitList?.find(item => item.deviceId === deviceId)?.selectedUnit;
                if (selectedUnitForBuds === BUDS_UNITS.BOTH) {
                    // device.type이 BUDS이고 Unit이 BOTH일 때, units가 L인 것만 사용
                    locationsToProcess = geolocations
                        .filter(geo => geo.units === BUDS_UNITS.LEFT) // BUDS_UNITS.LEFT와 일치하는 항목 필터링
                        .map(geo => ({
                            ...geo,
                            units: BUDS_UNITS.BOTH // 필터링된 항목의 units 속성을 BUDS_UNITS.BOTH로 변경
                        }));
                } else {
                    // device.type이 BUDS이고 Unit이 BOTH가 아닐 때, units가 L, R 모두 사용
                    locationsToProcess = geolocations;
                }
            } else {
                // device.type이 BUDS가 아닌 경우, 첫 번째 geolocation만 사용
                locationsToProcess.push(geolocations[0]);
            }


            locationsToProcess.forEach(geo => {
                const { latitude, longitude } = geo;
                const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

                const modelName = device?.modelInfo?.modelName;
                const deviceModelCode = device?.modelInfo?.deviceModelCode;
                const imageUrl = findImageLinkByModelCode(deviceImageListData, deviceModelCode || modelName);

                const markerElement = document.createElement("div");
                const markerRoot = ReactDOM.createRoot(markerElement);
                markerRoot.render(
                    <DeviceMarkerImage deviceImageUrl={imageUrl} deviceType={device?.type} isOnline={isOnline} unitsForBuds={geo.units} selectedUnitForBuds={selectedUnitForBuds} />
                );

                const marker = new googleMaps.marker.AdvancedMarkerElement({
                    position: position,
                    map: map,
                    content: markerElement,
                    zIndex: zIndexesOfMarkers[deviceId]
                });

                marker.setAttribute('deviceid', deviceId);
                if (geo.units) {
                    marker.setAttribute('units', geo.units);
                }

                marker.addListener('click', () => handleMarkerClick(deviceId));
                newMarkers.push(marker);
            });
        })



        return newMarkers;
    }


    function createClusterer(map, markers) {
        if (!googleMaps || !map) {
            return;
        }

        return new MarkerClusterer({
            markers: markers,
            map: map,
            algorithmOptions: { maxZoom: 22 },
            onClusterClick: (event, cluster, map) => {
                const position = cluster?.position;
                console.log('//// position : ', position)
                focusToMarker(position);
            },
            renderer: {
                render: ({ count, position, markers }) => {
                    // cluster marker 그리면 된다. 여기서. 
                    const devicesAndUnits = markers.map(item => {
                        return { deviceId: item.getAttribute('deviceid'), units: item.getAttribute('units') }
                    });

                    // 커스텀 오버레이용 DOM 요소 생성
                    const markerElement = document.createElement("div");
                    // 클러스터 마커(Marker Group) 에 대한 React 루트 인스턴스 생성
                    const markerRoot = ReactDOM.createRoot(markerElement);

                    markerRoot.render(
                        <DeviceClusterMarker
                            devicesAndUnits={devicesAndUnits}
                            deviceListDataOfCurrentTab={deviceListDataOfCurrentTab}
                            deviceImageListData={deviceImageListData}
                            selectedDevice={selectedDevice}
                            batteryListData={batteryListData}
                            operationsResult={operationsResult}
                            budsSelectedUnitList={budsSelectedUnitList}
                        />
                    );

                    const clusterMarker = new googleMaps.marker.AdvancedMarkerElement({
                        position: position,
                        map: map,
                        content: markerElement,
                    });

                    return clusterMarker;
                },
            },
        });
    }
    /**
     * Focus the map on the selected marker
     */
    const focusToMarker = (position) => {
        if (position == null) {
            return;
        }

        if (mapRef.current) {
            const mapInstance = mapRef.current;
            mapInstance.panTo(position); // Set map center
            // mapInstance.setZoom(DEFAULT_ZOOM_LEVEL_GOOGLE); // Zoom in 
            if (isMobilePortraitView) {
                // 여기서는 패널 너비의 절반만큼 지도를 y 축 방향으로 이동합니다.
                console.log('여기서는 패널 너비의 절반만큼 지도를 y 축 방향으로 이동합니다.! ');
                mapInstance.panBy(0, heightOfBottomSheet / 2);
            } else {
                // 여기서는 패널 너비의 절반만큼 지도를 x 축 방향으로 이동합니다.
                console.log('여기서는 패널 너비의 절반만큼 지도를 x 축 방향으로 이동합니다. ');
                mapInstance.panBy(-widthOfDeviceControlPanelPC / 2, 0);
            }

        }
    };

    function updateMarkersAndClusterer(map) {
        if (!markersRef || !clustererRef) {
            return;
        }

        removeMarkers();

        // 새로운 마커 생성 
        const newMarkers = createMarkers(map);
        markersRef.current = newMarkers;

        removeClusterers();

        // 새로운 클러스터러 생성 
        clustererRef.current = createClusterer(map, newMarkers);
    }

    const removeMarkers = () => {
        // 기존 마커 제거 
        if (markersRef.current && Array.isArray(markersRef.current)) {
            markersRef.current.forEach(marker => marker.setMap(null));
            markersRef.current = [];
        }
    }

    const removeClusterers = () => {
        // 기존 클러스터러 제거 
        if (clustererRef.current) {
            clustererRef.current.clearMarkers();
        }

    }



    return (
        <>
            <div
                ref={mapDivRef}
                style={{ height: '100vh', width: '100%' }}
            >
            </div>
            <CustomZoomControl
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
            />
        </>

    );
};




export default MapWithGoogle;