import { AxiosResponse } from "axios";
import axiosInstance from "../findToSTFAxiosConfig";
import { GetHistoryApiResponse, DeleteHistoryApiResponse } from './historyApiTypes';

/**
 * User의 Device들의 정보 기록 History를 받아옵니다.
 */
export const getUserHistory = async (): Promise<GetHistoryApiResponse['items']> => {
    try {
        const response: AxiosResponse<GetHistoryApiResponse> = await axiosInstance.get(`/find/user/history`);
        return response.data.items;
    } catch (error) {
        console.error('Error while getting user history:', error);
        throw error;
    }
};

/**
 * User의 Device들의 서버에 기록된 History를 삭제합니다.
 * 단순히 서버 기록만을 비우며 find 서비스 탈퇴와는 관련이 없습니다.
 */
export const deleteUserHistory = async (): Promise<DeleteHistoryApiResponse['items']> => {
    try {
        const response: AxiosResponse<DeleteHistoryApiResponse> = await axiosInstance.delete(`/find/user/history`);
        return response.data.items;
    } catch (error) {
        console.error('Error while deleting user history:', error);
        throw error;
    }
};
