import axiosInstance from "../findAxiosConfig";
import { getUserName } from '../../../utills/auth';
import { GetTagMetadataRequest, GetTagMetadataResponse } from "./devicesApiTypes";
import { AxiosResponse } from "axios";

/**
 * 사용자의 Device 리스트를 서버로부터 가져옵니다.
 */
export const fetchMyDevices = async (userId: string) => {
    try {
        const response = await axiosInstance.get('/devices', {
            params: {
                userId: userId,
                type: 'FMM'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching my devices:', error);
        throw error;
    }
};

/**
 * 사용자의 Item 리스트를 서버로부터 가져옵니다.
 */
export const fetchMyItemDevices = async (userId: string) => {
    try {
        const response = await axiosInstance.get('/devices', {
            params: {
                userId: userId,
                type: 'TAG'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching my item devices:', error);
        throw error;
    }
};

/**
 * 사용자의 Family Device 리스트를 서버로부터 받아옵니다.
 */
export const fetchFamilyDevices = async () => {
    try {
        const response = await axiosInstance.get('/devices/family?type=FMM');
        return response.data;
    } catch (error) {
        console.error("Error fetching family devices:", error);
        throw error;
    }
};

/**
 * 사용자의 Family Tag 리스트를 서버로부터 받아옵니다.
 */
export const fetchFamilyItemDevices = async () => {
    try {
        const response = await axiosInstance.get('/devices/family?type=TAG');
        return response.data;
    } catch (error) {
        console.error("Error fetching family devices:", error);
        throw error;
    }
};


export const getDeviceLocation = async (items: any) => {
    try {
        const response = await axiosInstance.post('/geolocations-report', {
            items,
            requestUserName: getUserName()
        });
        return response.data;
    } catch (error) {
        console.error('Error posting device location:', error);
        throw error;
    }
};

export const getTagLocation = async (deviceId: string, userId: string, userName?: string ) => {
    try {
        const response = await axiosInstance.get(`/tag/geolocations?deviceId=${deviceId}&userId=${userId}&requestUserName=${userName}`);
        return response.data;

    } catch (error) {
        console.error('Error requesting getTagLocations :  ', error);
        throw error;
    }
};


export const getTagDetail = async (deviceId: string) => {
    try {
        const response = await axiosInstance.get(`/tag/devices/${deviceId}`);
        return response.data;

    } catch (error) {
        console.error('Error requesting getTagDetail :  ', error);
        throw error;
    }
};


export const getTagMetadata = async ({mnId, setupId, firmwareVersion}: GetTagMetadataRequest): Promise<GetTagMetadataResponse['item']> => {
    try {
        const response: AxiosResponse<GetTagMetadataResponse> = await axiosInstance.get(`/tag/vendor/firmware/metadata?mnid=${mnId}&setupid=${setupId}&firmwareversion=${firmwareVersion}`);
        return response.data.item;
    } catch (error) {
        console.error('Error requesting getTagMetadata :  ', error);
        throw error;
    }
};
