export class AxiosErrorLogger {
    static handle(error: any) {
        try {
            if (!error.response || process.env.REACT_APP_NODE_ENV === "production"){
                return;
            }

            const { config, response } = error;

            console.error('\n========== Error report ==========\n', JSON.stringify({
                "request": AxiosErrorLogger.#extractRequestInfo(config),
                "response": AxiosErrorLogger.#extractResponseInfo(response)
            }, null, 2));
        } catch (error) {
            console.warn('AxiosErrorLogger.handle(): Failed to record request/response error log');
        }
    }

    static #filterSecHeaders(headers: any) {
        const filteredHeadersEntries = Object.entries(headers).filter(([headerName]) => headerName.startsWith('x-sec'));
        return Object.fromEntries(filteredHeadersEntries);
    }

    static #resolveUrl(config: any) {
        if (config.baseURL) {
            return `${config.baseURL}${config.url || ''}`;
        }

        return config.url;
    }

    static #extractRequestInfo(config: any) {
        const resolvedUrl = AxiosErrorLogger.#resolveUrl(config);
        const secHeaders = AxiosErrorLogger.#filterSecHeaders(config.headers);
        const queryParams = Object.fromEntries(new URL(resolvedUrl).searchParams);

        return {
            "url": resolvedUrl,
            "method": config.method?.toUpperCase() || 'No method',
            "headers": Object.keys(secHeaders).length === 0 ? 'No headers' : secHeaders,
            "params": Object.keys(queryParams).length === 0 ? 'No params' : queryParams,
            "body": config.data ? AxiosErrorLogger.#parseJSON(config.data) : 'No body'
        };
    }

    static #extractResponseInfo(response: any) {
        const responseDate = new Date (response.headers?.date || Date.now());

        return {
            "status": response.status,
            "headers": {
                ...response.headers,
                "date": `${new Date(responseDate.getTime()).toISOString().slice(0, -1)} UTC`,
                "date-kst": `${new Date(responseDate.getTime() - (responseDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -1)} KST`,
            },
            "body": AxiosErrorLogger.#parseJSON(response.data) || 'No body'
        };
    }

    static #parseJSON(body: any) {
        try {
            return JSON.parse(body);
        } catch (error) {
            return body;
        }
    }
}
