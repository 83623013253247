import React from 'react';
import TitleOverSubTitle from '../TitleOverSubTitle';
import { TitleOverSubtitleWithImageButtonType } from './TitleOverSubtitleWithImageButtonTypes';
import './TitleOverSubtitleWithImageButtonStyles.scss';

const TitleOverSubtitleWithImageButton = ({ title, subtitle, options, imageInfo: { imageSrc, alt, type, value } }: TitleOverSubtitleWithImageButtonType) => {
  const linkPrefix = {
    email: "mailto:",
    call: "tel:"
  }
  return (
    <div className="title-over-subtitle-with-image-button-container">
        <TitleOverSubTitle
            title={title}
            subtitle={subtitle}
            options={options}
        />
        <a className='image-link' href={`${linkPrefix[type]}${value}`}>
          <span className='image-container'>
              <img className='image' src={imageSrc} alt={alt} />
          </span>
        </a>
    </div>
  )
}

export default TitleOverSubtitleWithImageButton;